import { useMutation } from '@apollo/client';
import {
  Actions,
  Alert,
  Button,
  Dialog,
  Stack,
  Text,
  useToast,
} from 'braid-design-system';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { readNestedStringField } from 'src/hooks/apollo';
import type {
  DeleteWebhookMutation,
  DeleteWebhookMutationVariables,
  WebhookSubscriptionsQuery,
} from 'src/types/graphql';

import { DELETE_WEBHOOK } from './queries';

interface DeleteDialogProps {
  subscriptionId: string;
  show: boolean;
  closeDialog: () => void;
}

export const DeleteDialog = ({
  subscriptionId,
  show,
  closeDialog,
}: DeleteDialogProps) => {
  const showToast = useToast();
  const navigate = useNavigate();

  const [deleteSubscription, deleteResult] = useMutation<
    DeleteWebhookMutation,
    DeleteWebhookMutationVariables
  >(DELETE_WEBHOOK, {
    update: (cache, { data }) => {
      if (!data?.deleteWebhookSubscription) {
        return;
      }

      cache.modify<any>({
        fields: {
          webhookSubscriptions: (
            connection: WebhookSubscriptionsQuery['webhookSubscriptions'],
            { readField },
          ) => ({
            ...connection,
            edges: connection.edges.filter((edge) => {
              const id = readNestedStringField(
                readField,
                ['node', 'id', 'value'],
                edge,
              );

              return id && id !== subscriptionId;
            }),
          }),
        },
      });
    },
  });

  useEffect(() => {
    if (deleteResult.called && !deleteResult.loading && !deleteResult.error) {
      closeDialog();
      showToast({
        tone: 'positive',
        message: 'Subscription successfully deleted',
      });
      navigate('/manage/webhooks');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteResult]);

  return (
    <Dialog
      id="deleteDialog"
      title="Delete subscription"
      open={show}
      onClose={closeDialog}
    >
      <Stack space="large">
        <Text>
          Deleting a webhook subscription cannot be undone, and events will stop
          being delivered. Continue?
        </Text>

        <Actions>
          <Button
            onClick={() =>
              deleteSubscription({ variables: { subscriptionId } })
            }
            tone="critical"
          >
            Delete
          </Button>
          <Button onClick={closeDialog} variant="transparent">
            Cancel
          </Button>
        </Actions>

        {deleteResult.error ? (
          <Alert tone="critical">
            <Stack space="small">
              <Text>We couldn’t delete your subscription.</Text>
              <Text size="small">{deleteResult.error.message}</Text>
            </Stack>
          </Alert>
        ) : null}
      </Stack>
    </Dialog>
  );
};
