
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The type of the question response.`}</p>
    <p>{`Currently, two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` for choosing one or more responses from `}<inlineCode parentName="p">{`responseChoice`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SingleSelect`}</inlineCode>{` for choosing a single response from `}<inlineCode parentName="p">{`responseChoice`}</inlineCode>{`.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;