
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A SEEK ANZ work type code.`}</p>
    <p>{`Currently, four codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Casual`}</inlineCode>{` indicates a casual position.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ContractTemp`}</inlineCode>{` indicates a fixed-length contract position.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`FullTime`}</inlineCode>{` indicates a full-time position.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PartTime`}</inlineCode>{` indicates a part-time position.`}</p>
      </li>
    </ul>
    <p>{`This information allows us to better forecast the performance of the advertisement products.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;