import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Stack } from 'braid-design-system';
import { useMemo, useState } from 'react';

import { CopyableText } from 'src/components/CopyableText/CopyableText';

import { AdSelectionLoader } from './AdSelectionLoader';
import type { AdSelectionProps } from './types';

type Props = Pick<
  AdSelectionProps['positionProfile'],
  | 'jobCategories'
  | 'positionLocation'
  | 'positionOrganizations'
  | 'positionTitle'
>;

export const AdSelectionPanel = ({
  positionLocation,
  positionOrganizations,
  positionTitle,
  jobCategories,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [productOid, setProductOid] = useState<string>();

  const options = useMemo(() => {
    const onChange: AdSelectionProps['onChange'] = ({ selectedProduct }) =>
      setProductOid(selectedProduct?.id);
    return {
      getAuthToken: () => getAccessTokenSilently(),
      positionProfile: {
        positionOrganizations,
        positionTitle,
        positionLocation,
        jobCategories,
      },
      onChange,
    };
  }, [
    positionOrganizations,
    positionTitle,
    positionLocation,
    jobCategories,
    getAccessTokenSilently,
  ]);

  return (
    <Stack space="gutter">
      <AdSelectionLoader props={options} />
      {productOid ? (
        <>
          <Divider />
          <CopyableText inlineCode>{productOid}</CopyableText>
        </>
      ) : null}
    </Stack>
  );
};
