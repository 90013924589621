
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The end date of the posting.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For the `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme this must be no more than 30 days in the future.`}</p>
        <p parentName="li">{`If an end date is omitted, the job ad will default to the maximum period of 30 calendar days.
The precise end date can be queried from the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/PostingInstruction#/field/end",
            "title": "The end date of the posting"
          }}><inlineCode parentName="a">{`PostingInstruction.end`}</inlineCode></a>{` field once the job ad goes live.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;