
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The JSON sent to a webhook endpoint when a candidate profile is purchased in Talent Search. Use the `}<a parentName="p" {...{
        "href": "/schema-new/query/candidateProfile",
        "title": "The `CandidateProfile` for the given `id`"
      }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode></a>{` query to retrieve the current state of the profile; see `}<a parentName="p" {...{
        "href": "https://developer.seek.com/use-cases/proactive-sourcing/profile-purchases"
      }}>{`profile purchases`}</a>{` for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;