import { Text } from 'braid-design-system';
import { type GraphQLType, getNamedType } from 'graphql';
import { TableRow } from 'scoobie';

import { TypeLink } from 'src/components/TypeLink/TypeLink';

import { SchemaMarkdown } from './SchemaMarkdown';

interface Props {
  type: GraphQLType;
}

/**
 * Renders an inline snippet of a type with a type link
 *
 * This is intended when for situations like interface implementation lists
 * where we want more than the link but not the full details.
 */
export const TypeSnippet = ({ type }: Props) => {
  const namedType = getNamedType(type);

  return (
    <TableRow>
      <Text>
        <TypeLink type={type} />
      </Text>

      <SchemaMarkdown type={namedType.name} />
    </TableRow>
  );
};
