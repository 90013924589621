
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The indication of how well the candidate scored on the questionnaire overall.`}</p>
    <p>{`The score is a calculation between 0 and 1 as a floating point.
For example, if the candidate received a score of 1 on one question, and a score of 0 on another, this overall score would be calculated as 0.5.
If there are no scored questions this score will be null.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;