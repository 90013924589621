
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of locations which are preferred by the candidate.`}</p>
    <p>{`The locations are ordered in descending preference.`}</p>
    <p>{`A maximum of 5 locations may be provided.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;