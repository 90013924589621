
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The executed action that constitutes this history item.`}</p>
    <p>{`This action may or may not trigger a change in the status of the underlying process.
For example, an action may be to add a note against a candidate's profile,
which has no material effect on the stage through the application process.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;