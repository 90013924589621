
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether this choice was explicitly selected as a preference.`}</p>
    <p>{`When authoring a new questionnaire based on existing questions,
response choice selections may be pre-filled from this indicator.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For a custom question, this always matches `}<inlineCode parentName="p">{`preferredIndicator`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For a library question, this is set for bounding preferences only.`}</p>
        <p parentName="li">{`For example, a library question may define choices 1–5,
and require a minimum and maximum to be selected as a range preference.
Selecting the range 2–4 will set `}<inlineCode parentName="p">{`preferredIndicator`}</inlineCode>{` for choices 2, 3, and 4,
and `}<inlineCode parentName="p">{`selectedIndicator`}</inlineCode>{` for bounding choices 2 and 4.`}</p>
      </li>
    </ul>
    <p>{`This is not displayed to the candidate.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;