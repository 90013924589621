
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A non-negative integer in the minor currency unit for the ISO currency code.`}</p>
    <p>{`For example, this is the number of cents in dollar-denominated currencies.`}</p>
    <p>{`The currency of the tax value matches the parent `}<a parentName="p" {...{
        "href": "/schema-new/named-type/AdvertisementProductPriceDetails#/field/currency",
        "title": "The three-letter ISO 4217 currency code of the product price, in uppercase"
      }}><inlineCode parentName="a">{`AdvertisementProductPriceDetails.currency`}</inlineCode></a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;