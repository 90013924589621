
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The underlying library question choice that the question choice is based on.`}</p>
    <p>{`The availability of this field is dependent on the parent `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationQuestion#/field/sourceCode",
        "title": "The source of the component"
      }}><inlineCode parentName="a">{`ApplicationQuestion.sourceCode`}</inlineCode></a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Custom`}</inlineCode>{` is always null.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Library`}</inlineCode>{` is always non-null.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;