const EVENT_TYPE_SUFFIX = 'Event';

/**
 * Converts a webhook JSON schema definition name to an event name
 *
 * This returns `undefined` if the definition is from a non-event name.
 */
export const definitionNameToEventType = (
  definitionName: string,
): string | undefined =>
  definitionName !== EVENT_TYPE_SUFFIX &&
  definitionName?.endsWith(EVENT_TYPE_SUFFIX)
    ? definitionName.slice(0, -EVENT_TYPE_SUFFIX.length)
    : undefined;
