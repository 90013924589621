
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The JSON sent to a webhook endpoint when the relationship configuration for a hirer is changed. Use the `}<a parentName="p" {...{
        "href": "/schema-new/query/hiringOrganization",
        "title": "The hiring organization for the given `id`"
      }}><inlineCode parentName="a">{`hiringOrganization`}</inlineCode></a>{` query to retrieve the current state of your relationship with the hirer; see `}<a parentName="p" {...{
        "href": "https://developer.seek.com/auth/hirer-relationships#events"
      }}>{`hirer relationships`}</a>{` for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;