
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The supported methods of applying to job ads posted by the hirer.`}</p>
    <p>{`All SEEK hirers can use SEEK's Apply Form by omitting the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationMethod",
        "title": "A method of applying to a position"
      }}><inlineCode parentName="a">{`ApplicationMethod`}</inlineCode></a>{` object when posting.
This enumerates additional application methods SEEK has configured for the hirer.`}</p>
    <p>{`Currently, one code is defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ApplicationUri`}</inlineCode>{` indicates that job ads can link out to an external apply form.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;