
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The date & time the event was created.`}</p>
    <p>{`This is commonly linked to the creation of an object that can be retrieved from the SEEK API.`}</p>
    <p>{`The data source for this field differs by event type and scheme.
This field has weak ordering guarantees, so it should not be used as a pagination argument.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;