
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The response from the `}<a parentName="p" {...{
        "href": "/schema-new/mutation/updateUploadedCandidatePerson",
        "title": "Updates the personal details of an uploaded candidate"
      }}><inlineCode parentName="a">{`updateUploadedCandidatePerson`}</inlineCode></a>{` mutation.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;