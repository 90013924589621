import { type GraphQLType, isNonNullType } from 'graphql';

import type { ObjectType } from './jsonSchema';

interface Entity {
  deprecationReason?: string | null;
  name: string;
  type: GraphQLType;
}

const isDeprecated = (entity: Entity) =>
  typeof entity.deprecationReason === 'string';

/**
 * Sorts GraphQL entities to achieve the following order:
 *
 * 1. Deprecated entities last
 * 2. Non-nullable entities first
 * 3. Lexicographical order
 *
 * It depends on the root schema being lexicographically sorted with
 * `lexicographicSortSchema` from the `graphql` package.
 */
export const sortGraphqlEntities = (
  rawA: Entity | [unknown, Entity],
  rawB: Entity | [unknown, Entity],
) => {
  const a = Array.isArray(rawA) ? rawA[1] : rawA;
  const b = Array.isArray(rawB) ? rawB[1] : rawB;

  if (!isDeprecated(a) && isDeprecated(b)) {
    return -1;
  }

  if (isDeprecated(a) && !isDeprecated(b)) {
    return 1;
  }

  if (isNonNullType(a.type) && !isNonNullType(b.type)) {
    return -1;
  }

  if (!isNonNullType(a.type) && isNonNullType(b.type)) {
    return 1;
  }

  return 0;
};

/**
 * Extract properties from a JSON schema object in the following order:
 *
 * 1. Required properties first
 * 2. Lexicographical order
 */
export const getSortedJsonSchemaProperties = (objectType: ObjectType) => {
  const isRequired = (name: string) =>
    objectType.required?.includes(name) ?? false;

  return Object.entries(objectType.properties).sort(([a], [b]) => {
    if (isRequired(a) && !isRequired(b)) {
      return -1;
    }

    if (!isRequired(a) && isRequired(b)) {
      return 1;
    }

    return a.localeCompare(b);
  });
};
