
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The hirer IDs of the hirer-filtered webhook subscriptions to retrieve.`}</p>
    <p>{`If this is not provided then both hirer-filtered and unfiltered subscriptions will be returned.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;