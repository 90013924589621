import { useMutation } from '@apollo/client';
import {
  Actions,
  Alert,
  Button,
  Checkbox,
  Column,
  Columns,
  Heading,
  List,
  Stack,
  Strong,
  Text,
  TextField,
} from 'braid-design-system';
import { addDays, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';

import { CardSection } from 'src/components/CardSection/CardSection';
import { DatePicker } from 'src/components/DatePicker/DatePicker';
import {
  HirerPicker,
  useHirerState,
} from 'src/components/HirerPicker/HirerPicker';
import type {
  ReplayWebhookMutation,
  ReplayWebhookMutationVariables,
} from 'src/types/graphql';

import { REPLAY_WEBHOOK } from './queries';

interface ReplayWebhookProps {
  subscriptionId: string;
  subscriptionHirerId?: string;
  subscriptionHirerName?: string;
}

const getInitialCreatedAfterDate = () => {
  const today = startOfDay(new Date());
  const timestamp = today.getTime() - today.getTimezoneOffset() * 60000;
  return new Date(timestamp);
};

const getInitialCreatedBeforeDate = () => {
  const today = startOfDay(addDays(new Date(), 1));
  const timestamp = today.getTime() - today.getTimezoneOffset() * 60000;
  return new Date(timestamp);
};

export const ReplayWebhook = ({
  subscriptionId,
  subscriptionHirerId,
  subscriptionHirerName,
}: ReplayWebhookProps) => {
  const [createdAfterDate, setCreatedAfterDate] = useState(
    getInitialCreatedAfterDate(),
  );
  const [createdBeforeDate, setCreatedBeforeDate] = useState(
    getInitialCreatedBeforeDate(),
  );
  const [replayDeliveredEvents, setReplayDeliveredEvents] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const hirerState = useHirerState();

  const onChangeDate = (name: string, date?: Date) => {
    const newDate = date ?? new Date();
    const updateDateFn =
      name === 'createdAfterDate' ? setCreatedAfterDate : setCreatedBeforeDate;

    updateDateFn(newDate);
  };

  const [replay, replayResult] = useMutation<
    ReplayWebhookMutation,
    ReplayWebhookMutationVariables
  >(REPLAY_WEBHOOK);

  useEffect(() => {
    if (!replayResult.called || replayResult.loading || replayResult.error) {
      return;
    }

    if (replayResult.data?.replayWebhookSubscription) {
      setShowSuccess(true);
    }
  }, [replayResult]);

  const postSuccessAlert = (
    <Alert tone="positive">
      <Text>
        All events between <Strong>{createdAfterDate.toLocaleString()}</Strong>{' '}
        and <Strong>{createdBeforeDate.toLocaleString()}</Strong> are being
        replayed{' '}
        {hirerState.hirer
          ? `for ${hirerState.hirer.name}`
          : 'across all of your related hirers'}
        . Allow some time for these events to be received.
      </Text>
    </Alert>
  );

  const postFailureAlert = (
    <Alert tone="critical">
      <Stack space="small">
        <Text>We couldn’t replay your events.</Text>
        <Text size="small">{replayResult.error?.message}</Text>
      </Stack>
    </Alert>
  );

  const replayEventsMessage = (
    <Stack space="medium">
      <Text>
        Events that were created between{' '}
        <Strong>{createdAfterDate.toLocaleString()}</Strong> and{' '}
        <Strong>{createdBeforeDate.toLocaleString()}</Strong> (
        {new Intl.DateTimeFormat().resolvedOptions().timeZone}) will be requeued
        for delivery. These will include:
      </Text>
      <List>
        <Text tone="secondary">
          Events that your software has failed to process on delivery.
        </Text>
        {replayDeliveredEvents && (
          <>
            <Text tone="secondary">
              Events that have been successfully delivered prior.
            </Text>
            <Text tone="secondary">
              Events that have not been queued for delivery due to a missing
              webhook subscription or hirer relationship.
            </Text>
          </>
        )}
      </List>
    </Stack>
  );

  return (
    <CardSection gutter header={<Heading level="3">Replay webhooks</Heading>}>
      <Stack space="large">
        {showSuccess && postSuccessAlert}
        {replayResult.error && postFailureAlert}

        <Columns alignY="bottom" collapseBelow="tablet" space="large">
          <Column>
            <DatePicker
              name="createdAfterDate"
              type="datetime-local"
              value={createdAfterDate}
              max={new Date()}
              label="From"
              onChange={onChangeDate}
            />
          </Column>
          <Column>
            <DatePicker
              name="createdBeforeDate"
              type="datetime-local"
              value={createdBeforeDate}
              label="To"
              onChange={onChangeDate}
            />
          </Column>
          <Column>
            <Checkbox
              id="replayDeliveredEvents"
              checked={replayDeliveredEvents}
              onChange={() => setReplayDeliveredEvents(!replayDeliveredEvents)}
              label="Include all events"
            />
          </Column>
        </Columns>

        {subscriptionHirerId ? (
          // Having a `subscriptionHirerId` means the subscription is scoped to a single hirer
          // therefore don't allow a partner to change the hirer ID
          <TextField
            id="hirerId"
            label="Hirer"
            onChange={() => {}}
            value={subscriptionHirerName ?? ''}
            disabled={true}
          />
        ) : (
          <HirerPicker id={hirerState.hirer?.id.value ?? ''} {...hirerState} />
        )}

        {replayEventsMessage}

        <Actions>
          <Button
            loading={replayResult.loading}
            tone="brandAccent"
            onClick={() => {
              const variables: ReplayWebhookMutationVariables = {
                subscriptionId,
                createdAfterDateTime: createdAfterDate.toISOString(),
                createdBeforeDateTime: createdBeforeDate.toISOString(),
                replayDeliveredEventsIndicator: replayDeliveredEvents,
                hirerId: subscriptionHirerId ?? hirerState.hirer?.id.value,
              };

              replay({
                variables,
              });
            }}
          >
            Replay events
          </Button>
        </Actions>
      </Stack>
    </CardSection>
  );
};
