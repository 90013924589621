import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import * as styles from './Strikethrough.css';

interface Props {
  children: ReactNode;
  strike: boolean;
}

export const Strikethrough = ({ children, strike }: Props) => (
  <Box className={strike ? styles.strikethrough : undefined} component="span">
    {children}
  </Box>
);
