
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The upper limit of advertisement products to return in the list.`}</p>
    <p>{`If your user interface can only display a certain number of products,
set this limit so that SEEK can provide the most relevant set of products within your constraints.
Do not manually truncate the returned list as that may exclude highly relevant products.`}</p>
    <p>{`This must be a positive integer that is greater than or equal to 4.
Defaults to a unbounded list though expect this to remain within reason;
we typically return up to 3 products and have only discussed an increase to 4 in future.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;