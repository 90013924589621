
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The type of the replay.`}</p>
    <p>{`Currently, two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`RangeReplayRequest`}</inlineCode>{` which corresponds to the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/WebhookSubscriptionReplayByRangeRequest",
            "title": "The date range criteria used to determine which events will be replayed"
          }}><inlineCode parentName="a">{`WebhookSubscriptionReplayByRangeRequest`}</inlineCode></a>{` type.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`IdReplayRequest`}</inlineCode>{` which corresponds to the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/WebhookSubscriptionReplayByIdRequest",
            "title": "The event ID criteria used to determine which events will be replayed"
          }}><inlineCode parentName="a">{`WebhookSubscriptionReplayByIdRequest`}</inlineCode></a>{` type.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;