
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The underlying source for the item.`}</p>
    <p>{`For example, items related to an application process would note the job board or other channel that sourced the application.`}</p>
    <p>{`This is required if `}<a parentName="p" {...{
        "href": "/schema-new/query/positionProfile",
        "title": "A position profile with the given `id`"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode></a>{` is specified.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;