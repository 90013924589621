import {
  CheckboxStandalone,
  Column,
  Columns,
  Divider,
  IconAdd,
  IconClear,
  RadioGroup,
  RadioItem,
  Stack,
  Strong,
  Text,
  TextDropdown,
  TextField,
  TextLinkButton,
} from 'braid-design-system';
import { useEffect, useState } from 'react';
import { CodeBlock } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard/BorderCard';
import { useSessionStorage } from 'src/hooks/storage';

type CustomQuestionType = (typeof CUSTOM_QUESTION_TYPES)[number];

const CUSTOM_QUESTION_TYPES = [
  'free text',
  'yes/no',
  'single select',
  'multi select',
] as const;

const CUSTOM_QUESTION_TYPE_OPTIONS: CustomQuestionType[] = Array.from(
  CUSTOM_QUESTION_TYPES,
);

interface ApplicationQuestionChoiceInput {
  preferredIndicator: boolean;
  text: string;
}

type Answer = Omit<ApplicationQuestionChoiceInput, 'value'>;

const blankAnswer = (): Answer => ({ preferredIndicator: false, text: '' });

interface QuestionnaireComponentProps {
  setResponseChoice: React.Dispatch<
    React.SetStateAction<ApplicationQuestionChoiceInput[] | undefined>
  >;
}

const FreeText = ({ setResponseChoice }: QuestionnaireComponentProps) => {
  useEffect(() => setResponseChoice(undefined), [setResponseChoice]);

  return (
    <Text tone="secondary">The candidate can write a free text response</Text>
  );
};

const YesNo = ({ setResponseChoice }: QuestionnaireComponentProps) => {
  const id = 'custom-question-yes-no-input';

  const [preferred, setPreferred] = useSessionStorage<'Yes' | 'No'>(
    `/use-cases/job-posting/questionnaires/${id}`,
    'Yes',
  );

  useEffect(
    () =>
      setResponseChoice([
        { preferredIndicator: preferred === 'Yes', text: 'Yes' },
        { preferredIndicator: preferred === 'No', text: 'No' },
      ]),
    [preferred, setResponseChoice],
  );

  return (
    <Stack space="large">
      <Text tone="secondary">The candidate can select yes or no</Text>

      <Stack space="medium">
        <Text>Select your preferred response</Text>

        <RadioGroup
          aria-label="Preferred response"
          id={id}
          onChange={(event) =>
            setPreferred(event.currentTarget.value as 'Yes' | 'No')
          }
          value={preferred}
        >
          <RadioItem label="Yes" value="Yes" />
          <RadioItem label="No" value="No" />
        </RadioGroup>
      </Stack>
    </Stack>
  );
};

const Select = ({
  description,
  id,
  setResponseChoice,
}: QuestionnaireComponentProps & Record<'description' | 'id', string>) => {
  const [answers, setAnswers] = useSessionStorage<
    Array<Omit<ApplicationQuestionChoiceInput, 'value'>>
  >(
    `/use-cases/job-posting/questionnaires/${id}`,
    new Array(2).fill(null).map(blankAnswer),
  );

  useEffect(
    () =>
      setResponseChoice(
        answers
          .map((answer, index) => ({ ...answer, value: `1.${index + 1}` }))
          .filter((answer) => Boolean(answer.text)),
      ),
    [answers, setResponseChoice],
  );

  return (
    <Stack space="large">
      <Text tone="secondary">{description}</Text>

      <Stack space="medium">
        <Text>
          Add your answer options (maximum 7) and select your preferred
          response(s)
        </Text>

        {answers.map((answer, index) => (
          <Columns alignY="center" key={index} space="small">
            <Column>
              <TextField
                aria-label={`Answer ${index + 1}`}
                id={`${id}-text-${index}`}
                onChange={(event) =>
                  setAnswers((prev) =>
                    prev.map((prevAnswer, prevIndex) =>
                      prevIndex === index
                        ? {
                            ...prevAnswer,
                            text: event.currentTarget.value,
                          }
                        : prevAnswer,
                    ),
                  )
                }
                placeholder={`Answer ${index + 1}`}
                value={answer.text}
              />
            </Column>

            <Column width="content">
              <CheckboxStandalone
                aria-label="Preferred"
                checked={answer.preferredIndicator}
                onChange={(event) =>
                  setAnswers((prev) =>
                    prev.map((prevAnswer, prevIndex) =>
                      prevIndex === index
                        ? {
                            ...prevAnswer,
                            preferredIndicator: event.currentTarget.checked,
                          }
                        : prevAnswer,
                    ),
                  )
                }
                id={`${id}-preferred-${index}`}
              />
            </Column>

            {answers.length > 2 ? (
              <Column width="content">
                <Text size="large">
                  <TextLinkButton
                    aria-label="Delete answer"
                    onClick={() =>
                      setAnswers((prev) =>
                        prev.filter((_, prevIndex) => prevIndex !== index),
                      )
                    }
                  >
                    <IconClear />
                  </TextLinkButton>
                </Text>
              </Column>
            ) : null}
          </Columns>
        ))}
      </Stack>

      {answers.length < 7 ? (
        <Text>
          <TextLinkButton
            icon={<IconAdd />}
            onClick={() => setAnswers((prev) => prev.concat(blankAnswer()))}
          >
            Add another answer
          </TextLinkButton>
        </Text>
      ) : null}
    </Stack>
  );
};

const SingleSelect = ({ setResponseChoice }: QuestionnaireComponentProps) => (
  <Select
    description="The candidate can select one answer"
    id="custom-question-single-select-input"
    setResponseChoice={setResponseChoice}
  />
);
const MultiSelect = ({ setResponseChoice }: QuestionnaireComponentProps) => (
  <Select
    description="The candidate can select multiple answers"
    id="custom-question-multi-select-input"
    setResponseChoice={setResponseChoice}
  />
);

const forCustomQuestionType = {
  'free text': {
    Component: FreeText,
    responseTypeCode: 'FreeText' as const,
  },
  'yes/no': {
    Component: YesNo,
    responseTypeCode: 'SingleSelect' as const,
  },
  'single select': {
    Component: SingleSelect,
    responseTypeCode: 'SingleSelect' as const,
  },
  'multi select': {
    Component: MultiSelect,
    responseTypeCode: 'MultiSelect' as const,
  },
};

export const CustomQuestion = () => {
  const [responseChoice, setResponseChoice] =
    useState<ApplicationQuestionChoiceInput[]>();

  const [customQuestionType, setCustomQuestionType] =
    useState<CustomQuestionType>('free text');

  const [questionHtml, setQuestionHtml] = useState('');

  const { Component, responseTypeCode } =
    forCustomQuestionType[customQuestionType];

  return (
    <BorderCard>
      <Stack space="large">
        <TextField
          id="custom-question-html-input"
          label="Question 1"
          onChange={(event) => setQuestionHtml(event.currentTarget.value)}
          placeholder="Enter a question"
          value={questionHtml}
        />

        <Text>
          This is a{' '}
          <Strong>
            <TextDropdown<CustomQuestionType>
              id="custom-question-type-input"
              label="Question type"
              onChange={setCustomQuestionType}
              options={CUSTOM_QUESTION_TYPE_OPTIONS}
              value={customQuestionType}
            />
          </Strong>{' '}
          question
        </Text>

        <Component setResponseChoice={setResponseChoice} />

        <Divider />

        <CodeBlock label="Component" language="json">
          {JSON.stringify(
            [
              {
                componentTypeCode: 'Question',
                question: {
                  componentTypeCode: 'Question',
                  questionHtml,
                  responseChoice,
                  responseTypeCode,
                },
              },
            ],
            null,
            2,
          )}
        </CodeBlock>
      </Stack>
    </BorderCard>
  );
};
