import { Divider, IconDocument, Text } from 'braid-design-system';
import type { GraphQLInterfaceType } from 'graphql';
import React from 'react';
import { InlineCode } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';
import { sortGraphqlEntities } from 'src/utils/sort';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { FieldDetails } from '../components/FieldDetails';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSeenIn } from '../components/TypeSeenIn';
import { TypeSnippet } from '../components/TypeSnippet';
import { schema } from '../schema';

export const InterfaceSchemaPage = ({
  interfaceType,
  fieldName,
}: {
  interfaceType: GraphQLInterfaceType;
  fieldName?: string;
}) => {
  const implementations = schema.getImplementations(interfaceType).objects;

  return (
    <BaseSchemaPage
      titleText={interfaceType.name}
      title={<InlineCode weight="weak">{interfaceType.name}</InlineCode>}
      subHeading={
        <Text
          icon={<IconDocument />}
          size="small"
          weight="medium"
          tone="secondary"
        >
          Interface
        </Text>
      }
    >
      <SchemaMarkdown type={interfaceType.name} />

      <Divider />

      <SchemaSection heading="Fields" wrapper="usage-table">
        {Object.entries(interfaceType.getFields())
          .filter(([_, field]) => !field.name.startsWith('_unstable_'))
          .sort(sortGraphqlEntities)
          .map(([fieldNameKey, field]) => (
            <FieldDetails
              key={fieldNameKey}
              objectName={interfaceType.name}
              highlight={fieldName === fieldNameKey}
              field={field}
              deprecated={typeof field.deprecationReason === 'string'}
            />
          ))}
      </SchemaSection>

      <Divider />

      <SchemaSection heading="Implementations">
        {[...implementations].map((objectType) => (
          <TypeSnippet key={objectType.name} type={objectType} />
        ))}
      </SchemaSection>

      <Divider />

      <TypeSeenIn name={interfaceType.name} />
    </BaseSchemaPage>
  );
};
