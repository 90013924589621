
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "branding"
    }}>{`Branding`}</h1>
    <p>{`Branding allows SEEK hirers to promote their company brand on their job ads through a logo and optional cover image.
Not all `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection"
      }}>{`SEEK ad products`}</a>{` include branding;
you can query the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/SeekAnzAdProductFeatures"
      }}>{`ad product’s features`}</a>{` to determine if branding is available.`}</p>
    <p>{`The SEEK API provides two queries for retrieving brands:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/branding#advertisementbrandings"
          }}><inlineCode parentName="a">{`advertisementBrandings`}</inlineCode></a>{` returns a paginated list of brands for a given hirer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/branding#advertisementbranding"
          }}><inlineCode parentName="a">{`advertisementBranding`}</inlineCode></a>{` returns a brand for a given brand object identifier.`}</p>
      </li>
    </ul>
    <p>{`If you haven’t yet built this feature into your software, refer to the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/branding"
      }}>{`Branding`}</a>{` documentation.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;