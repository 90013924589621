
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The active relationship types between the requesting partner and the hirer.`}</p>
    <p>{`Currently, three codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ApplicationExport`}</inlineCode>{` enables exporting candidate applications from SEEK's Apply Form.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ApplicationPrefill`}</inlineCode>{` enables retrieving candidate profile data to pre-fill an external apply form.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`JobPosting`}</inlineCode>{` enables posting job ads to the SEEK job board.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ProactiveSourcing`}</inlineCode>{` enables hirers to proactively search for and connect with suitable candidates.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;