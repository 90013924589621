
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "questionnaires"
    }}>{`Questionnaires`}</h1>
    <p>{`A job ad may specify a questionnaire to include in SEEK’s Apply Form.
Candidates complete the questionnaire as part of their job application,
and their responses are made available through the `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` use case.
Questionnaires are rendered on SEEK’s candidate website and in our mobile apps.`}</p>
    <p>{`By integrating questionnaires into your posting flow, you can enable hirers to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`View and select question suggestions from SEEK’s library of commonly used and relevant questions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add custom questions if they do not find the questions in SEEK’s existing library to be relevant.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select preferred response choices for each selected question.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Attach a company privacy policy if applicable.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "data-structure"
    }}>{`Data structure`}</h2>
    <p>{`An `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{` is represented as an immutable collection of components.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/ApplicationPrivacyConsent"
          }}><inlineCode parentName="a">{`ApplicationPrivacyConsent`}</inlineCode></a>{` allows the hirer to link to a privacy policy for candidates to consent to.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestion"
          }}><inlineCode parentName="a">{`ApplicationQuestion`}</inlineCode></a>{` allows the hirer to ask a screening question:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`FreeText`}</inlineCode>{` presents the candidate with a free text input labelled with the question’s `}<inlineCode parentName="p">{`questionHtml`}</inlineCode>{`.
They must input at least one character before submitting their application.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`SingleSelect`}</inlineCode>{` presents the candidate with a set of mutually exclusive choices.
They must select a single choice before submitting their application.`}</p>
            <p parentName="li">{`SEEK renders these questions either as radio buttons or a dropdown list, depending on the number of choices.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` presents the candidate with a set of non-exclusive choices.
They must select at least one choice before submitting their application.`}</p>
            <p parentName="li">{`SEEK renders these questions as a list of checkboxes.`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <p><inlineCode parentName="p">{`SingleSelect`}</inlineCode>{` and `}<inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` questions may be suggested from SEEK’s library via the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/applicationLibraryQuestionSuggestions"
      }}><inlineCode parentName="a">{`applicationQuestionSuggestions`}</inlineCode></a>{` query or created as custom by the hirer during the job posting flow.`}</p>
    <h3 {...{
      "id": "questionnaire-submission-association"
    }}>{`Questionnaire submission association`}</h3>
    <p>{`A candidate’s `}<a parentName="p" {...{
        "href": "/use-cases/application-export/questionnaire-submissions"
      }}>{`questionnaire submission`}</a>{` can be retrieved through the `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` use case.
The submission can be correlated back to the presented questionnaire and its individual components.`}</p>
    <h3 {...{
      "id": "preferred-choices--scoring"
    }}>{`Preferred choices & scoring`}</h3>
    <p>{`The SEEK API can optionally calculate a numeric score for each questionnaire submission.
This requires that your software indicates which response choices are preferred by the hirer.
The calculated score and preference indicators are not otherwise used by SEEK.`}</p>
    <p>{`Each `}<inlineCode parentName="p">{`SingleSelect`}</inlineCode>{` and `}<inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestion"
      }}><inlineCode parentName="a">{`ApplicationQuestion`}</inlineCode></a>{` specifies a collection of possible choices through the `}<inlineCode parentName="p">{`responseChoice`}</inlineCode>{` field.
An `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionChoice"
      }}><inlineCode parentName="a">{`ApplicationQuestionChoice`}</inlineCode></a>{` can be preferenced by setting its `}<inlineCode parentName="p">{`preferredIndicator`}</inlineCode>{` during questionnaire creation.
Candidate responses are scored against these preferred choices.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaireSubmission"
          }}><inlineCode parentName="a">{`ApplicationQuestionnaireSubmission`}</inlineCode></a>{` schema definition details scoring of the overall questionnaire submission`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionResponse"
          }}><inlineCode parentName="a">{`ApplicationQuestionResponse`}</inlineCode></a>{` schema definition details scoring of individual questionnaire components`}</p>
      </li>
    </ul>
    <p>{`These scores are provided as an advisory screening indicator for the hirer.
All candidate applications will be delivered to your software;
SEEK does not support knockout questions and does not reject applications based on score.`}</p>
    <h3 {...{
      "id": "limits"
    }}>{`Limits`}</h3>
    <p>{`The SEEK API imposes the following limits on questionnaires:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`56 `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Kibibyte"
          }}>{`KiB`}</a>{` for the `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/mutation/createApplicationQuestionnaire"
          }}>{`create mutation`}</a>{` input`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`100 total components`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1 `}<inlineCode parentName="p">{`PrivacyConsent`}</inlineCode>{` component`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`99 response choices for `}<inlineCode parentName="p">{`SingleSelect`}</inlineCode>{` or `}<inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` components.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1,000 `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#string-lengths"
          }}>{`characters`}</a>{` for the `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionInput/field/questionHtml"
          }}><inlineCode parentName="a">{`ApplicationQuestionInput.questionHtml`}</inlineCode></a>{` field`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`255 `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#string-lengths"
          }}>{`characters`}</a>{` for every other text field, which is our `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#string-lengths"
          }}>{`default limit`}</a></p>
      </li>
    </ul>
    <p>{`Lengthy apply forms deter candidates from applying,
so we encourage hirers to keep their questionnaires short and focused.`}</p>
    <h3 {...{
      "id": "immutability"
    }}>{`Immutability`}</h3>
    <p>{`For an equitable screening process,
a SEEK job ad presents the same questionnaire to all applicants.
This is enforced in two ways:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaire"
          }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{` object is immutable.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad#updatepostedpositionprofile"
          }}><inlineCode parentName="a">{`updatePostedPositionProfile`}</inlineCode>{` mutation`}</a>{` will fail if a `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/UpdatePostedPositionProfile_PositionProfileInput/field/seekApplicationQuestionnaireId"
          }}><inlineCode parentName="a">{`seekApplicationQuestionnaireId`}</inlineCode>{` field`}</a>{` is provided.`}</p>
        <p parentName="li">{`To avoid confusion, your software’s frontend should not allow hirers to modify a live job ad’s questionnaire.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "implementation-options"
    }}>{`Implementation options`}</h2>
    <p>{`The SEEK API supports multiple options for implementing questionnaires.`}</p>
    <img alt="" data-scoobie-style="none" height="550" src={require('../../../../mermaid/.a41aa05050076ba2309382fac64ac51470a71511.mmd.svg')} title="Questionnaire options" width="496" />
    <h3 {...{
      "id": "option-1-questionnaire-panel"
    }}>{`Option 1: Questionnaire Panel`}</h3>

    <Alert tone="promote" mdxType="Alert">
  <Text mdxType="Text">
    SEEK strongly recommends using the Questionnaire Panel for your integration.
    It will significantly reduce development time and ongoing maintenance, as
    well as provide an up-to-date user experience for SEEK hirers.
  </Text>
    </Alert>
    <p>{`SEEK offers a Questionnaire Panel that you can embed into your posting flow.`}</p>
    <p>{`Jump to the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires/v2/panel"
      }}>{`panel section`}</a>{` to get started.`}</p>
    <h3 {...{
      "id": "option-2-map-existing-questions-in-your-ats"
    }}>{`Option 2: Map existing questions in your ATS`}</h3>
    <p>{`If your software already has a mechanism for creating and managing questions, you can reuse them by internally mapping to the SEEK API’s questionnaire structure.`}</p>
    <p>{`Jump to the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires/ats-questions"
      }}>{`ATS Screening Questions section`}</a>{` to get started.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;