
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The grouping that the source falls under.`}</p>
    <p>{`Currently, three types are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PartnerUpload`}</inlineCode>{` indicates that the candidate was uploaded to SEEK from a partner system.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SeekApplication`}</inlineCode>{` indicates that the candidate applied for a position on the SEEK job board.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SeekPurchase`}</inlineCode>{` indicates that the candidate profile was purchased from SEEK Talent Search.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;