import { TextLink } from 'braid-design-system';
import { type GraphQLType, getNamedType } from 'graphql';
import { InlineCode } from 'scoobie';

import { hrefForNamedType } from 'src/utils/href';
import { shortDescription } from 'src/utils/shortDescription';

interface Props {
  type: GraphQLType;
}

/**
 * Renders a text link to a GraphQL type
 *
 * Lists and non-null types will be rendered using GraphQL syntax and link to
 * their inner type.
 */
export const TypeLink = ({ type }: Props) => {
  const namedType = getNamedType(type);

  return (
    <TextLink
      href={hrefForNamedType(namedType)}
      title={shortDescription(namedType.description)}
    >
      <InlineCode weight="weak">{type.toString()}</InlineCode>
    </TextLink>
  );
};
