
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of `}<a parentName="p" {...{
        "href": "/schema-new/named-type/Location",
        "title": "A physical location with a persistent identifier"
      }}><inlineCode parentName="a">{`Location`}</inlineCode></a>{` identifiers.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme has a maximum of one element.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;