import {
  Alert,
  Divider,
  IconDocumentBroken,
  PageBlock,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { ContentHeader } from 'src/components/ContentHeader/ContentHeader';
import { NavActions } from 'src/components/NavActions/NavActions';
import { useStaticRender } from 'src/hooks/staticRender';

export const Oops = () => (
  <Stack space="large">
    <Divider />

    <Alert tone="caution">
      <Text>
        <Strong>404</Strong>, we can’t seem to find what you’re looking for!
      </Text>
    </Alert>

    <NavActions />
  </Stack>
);

export const OopsPage = () => {
  const staticRender = useStaticRender();

  const location = useLocation();

  return (
    <PageBlock width="large">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <ContentHeader icon={<IconDocumentBroken />}>
        {staticRender ? '/' : location.pathname}
      </ContentHeader>

      <Oops />
    </PageBlock>
  );
};
