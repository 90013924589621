import { Box, IconLink, Text } from 'braid-design-system';
import type { GraphQLType } from 'graphql';
import { type ReactNode, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import { Strikethrough } from 'src/pages/schema/components/Strikethrough';

import { NameLabel } from '../NameLabel/NameLabel';

import * as styles from './NameTextLink.css';

export const NameTextLink = ({
  id,
  name,
  type,
  deprecated,
  required,
  highlight,
  to,
  children,
}: {
  id: string;
  name: string;
  type?: GraphQLType;
  deprecated: boolean;
  required?: boolean;
  highlight?: boolean;
  to: string;
  children?: ReactNode;
}) => {
  useLayoutEffect(() => {
    if (highlight) {
      const target = document.getElementById(id);

      if (target) {
        window.scrollTo({
          behavior: 'smooth',
          top: target?.getBoundingClientRect().top + window.scrollY - 30,
        });
      }
    }
  }, [id, highlight]);

  return (
    <Text id={id} tone={deprecated ? 'secondary' : undefined} weight="strong">
      <Box component="pre" className={styles.headAnchorLink}>
        <Strikethrough strike={deprecated}>
          <NameLabel inline required={required} type={type}>
            {name}
          </NameLabel>
        </Strikethrough>{' '}
        <Box
          component="span"
          className={
            highlight
              ? styles.deepAnchorLink.highlight
              : styles.deepAnchorLink.hidden
          }
        >
          <Link to={to} preventScrollReset>
            <IconLink />
          </Link>
        </Box>
        {children}
      </Box>
    </Text>
  );
};
