import { Divider, IconDocument, Text } from 'braid-design-system';
import type { GraphQLInputObjectType } from 'graphql';
import { InlineCode } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';
import { sortGraphqlEntities } from 'src/utils/sort';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { InputFieldDetails } from '../components/InputFieldDetails';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSeenIn } from '../components/TypeSeenIn';

export const InputObjectSchemaPage = ({
  inputObjectType,
  fieldName,
}: {
  inputObjectType: GraphQLInputObjectType;
  fieldName?: string;
}) => (
  <BaseSchemaPage
    titleText={inputObjectType.name}
    title={<InlineCode weight="weak">{inputObjectType.name}</InlineCode>}
    subHeading={
      <Text
        icon={<IconDocument />}
        size="small"
        weight="medium"
        tone="secondary"
      >
        Input object
      </Text>
    }
  >
    <SchemaMarkdown type={inputObjectType.name} />

    <Divider />

    <SchemaSection heading="Fields" wrapper="usage-table">
      {Object.entries(inputObjectType.getFields())
        .filter(([_, field]) => !field.name.startsWith('_unstable_'))
        .sort(sortGraphqlEntities)
        .map(([fieldNameKey, field]) => (
          <InputFieldDetails
            key={fieldNameKey}
            objectName={inputObjectType.name}
            highlight={fieldName === fieldNameKey}
            field={field}
          />
        ))}
    </SchemaSection>

    <Divider />

    <TypeSeenIn name={inputObjectType.name} />
  </BaseSchemaPage>
);
