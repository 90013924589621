
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "panels"
    }}>{`Panels`}</h1>
    <p>{`The SEEK API includes panels that can be embedded in web-based software.
These JavaScript components provide an alternative to building features from scratch;
for example,
you can embed the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/panel"
      }}>{`Ad Selection Panel`}</a>{` instead of implementing `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/graphql"
      }}>{`ad selection via GraphQL`}</a>{`.`}</p>
    <p>{`Where the SEEK API provides a panel implementation,
we strongly recommend using it for your integration.
It will significantly reduce development time and ongoing maintenance, as
well as provide an up-to-date user experience for SEEK hirers.`}</p>
    <p>{`Our panels share a standardised entry point, interface and `}<a parentName="p" {...{
        "href": "/introduction/browser-support"
      }}>{`browser support`}</a>{` policy for ease of integration.
The common process of embedding a panel is outlined below,
and you can refer to the specific documentation of each panel for more information:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection/v2/panel"
          }}>{`Ad Selection Panel`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/questionnaires/v2/panel"
          }}>{`Questionnaire Panel`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/ad-performance/panel"
          }}>{`Ad Performance Panel`}</a></p>
      </li>
    </ul>
    <p>{`Our panels do not use `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe"
      }}><inlineCode parentName="a">{`<iframe>`}</inlineCode>{`s`}</a>{`.`}</p>
    <h2 {...{
      "id": "include-the-panel"
    }}>{`Include the panel`}</h2>
    <p>{`Add the following script tag to the page where you want to insert a panel:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script
  type="text/javascript"
  src="https://integration.seek.com/panels/SeekApi.js"
></script>
`}</code></pre>
    <p>{`This will expose a `}<inlineCode parentName="p">{`SeekApi.render`}</inlineCode>{` function which renders an instance of a panel within a specified DOM element.`}</p>
    <h2 {...{
      "id": "render-the-panel"
    }}>{`Render the panel`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` function must be called on page load and whenever dynamic props change.
For example, if you have included the Ad Selection Panel and the hirer changes the position’s location, you must re-render the panel to reflect updated pricing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(containerNode, panelName, props);
`}</code></pre>
    <h3 {...{
      "id": "handle-browser-token-requests"
    }}>{`Handle browser token requests`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function must be supplied via props:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`getAuthToken: async () => {
  // Do not implement caching in your \`getAuthToken\` implementation.
  // The panel will internally memoise the response.
  const token = await fetchAuthToken();

  return token;
},
`}</code></pre>
    <p>{`Your software should implement the following auth flow:`}</p>
    <img alt="" data-scoobie-style="none" height="540" src={require('../../../mermaid/.aaaaf88d36867e4aa60b047f3e4b05879b1919a4.mmd.svg')} title="Panel authentication" width="1092" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The panel loads and invokes the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function passed to it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function should request a new token for the appropriate hirer ID.
If a user switches to a different SEEK hirer account or a job ad from another SEEK hirer account,
your software should ensure that subsequent invocations of `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` will request a token for the new hirer ID before re-rendering the panel.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorizes the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorized to access a given hirer ID.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend `}<a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`requests a browser token from the SEEK API`}</a>{` for the appropriate hirer ID and scope.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend responds with the browser token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend returns the browser token from the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel can now make requests to the GraphQL endpoint.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "query:ad-products query:organizations",
  "userId": "317665"
}
`}</code></pre>
    <h2 {...{
      "id": "localise-the-panel"
    }}>{`Localise the panel`}</h2>
    <p>{`By default,
the panel will infer the preferred locale of the user from their web browser.`}</p>
    <p>{`The SEEK API supports a limited set of languages at this time and falls back to Australian English when preferred locales are not available.
The panel may serve mixed language content where translations are partially available.`}</p>
    <p>{`If your software manages the user locale in a different manner,
such as including it in a URL segment like `}<inlineCode parentName="p">{`/en-AU/content`}</inlineCode>{`,
you may use the `}<inlineCode parentName="p">{`locale`}</inlineCode>{` prop to force a specific preference:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(containerNode, panelName, {
  locale: 'id-ID'
});
`}</code></pre>
    <p>{`See our general `}<a parentName="p" {...{
        "href": "/graphql/in-practice#content-localisation"
      }}>{`content localisation`}</a>{` documentation for more information.`}</p>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`Our panels are designed with the hirer in mind.
When something goes wrong,
they do not render technical troubleshooting information as part of the UI.
Instead, they prompt the hirer to contact their recruitment software provider or SEEK.`}</p>
    <p>{`To troubleshoot a problem with a panel,
open the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/Common_questions/Tools_and_setup/What_are_browser_developer_tools"
      }}>{`developer tools`}</a>{` in your web browser and try the following:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`View logs and errors in the JavaScript console`}</p>
        <p parentName="li">{`Our panels generally output technical troubleshooting information to the console.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`View HTTP requests and responses in the Network tab`}</p>
        <p parentName="li">{`Our panels communicate with our GraphQL server, so you can follow the same guidance on `}<a parentName="p" {...{
            "href": "/graphql/error-responses"
          }}>{`GraphQL error responses`}</a>{`.`}</p>
      </li>
    </ol>
    <p>{`If you are unable to resolve the issue independently,
include the console logs when escalating to your SEEK contact.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;