
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier for the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionOpening",
        "title": "A job requisition or position opening within an organization"
      }}><inlineCode parentName="a">{`PositionOpening`}</inlineCode></a>{`.`}</p>
    <p>{`Scheme restrictions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme creates the position opening asynchronously.
This identifier will initially reference a missing object;
the object should be created within a few minutes.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;