
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The type of contract consumption.`}</p>
    <p>{`Currently, three codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SameAdType`}</inlineCode>{` indicates payment due will be consumed from a contract of the same ad type as this product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`OtherAdType`}</inlineCode>{` indicates payment due will be consumed from a contract of a different ad type to this product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SameAdTypePlusInvoice`}</inlineCode>{` indicates payment due will be consumed from a contract of the same ad type as this product and an invoice will be generated.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;