export const shortDescription = (
  description: string | undefined | null,
): string | undefined => {
  if (!description) {
    return;
  }

  const [firstLine] = description.split('\n');

  // Did we get a full sentence?
  if (firstLine.endsWith('.')) {
    return firstLine.slice(0, -1);
  }

  return;
};
