
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The candidate's primary email address.`}</p>
    <p>{`The value is only set for candidates with `}<a parentName="p" {...{
        "href": "/schema-new/named-type/CandidateSource",
        "title": "A source from which the candidate was obtained from"
      }}><inlineCode parentName="a">{`CandidateSource`}</inlineCode></a>{` value `}<inlineCode parentName="p">{`PartnerUpload`}</inlineCode>{`.
Values will be unique within a given hirer.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;