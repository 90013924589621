
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier of the library question or suggestion that the component is based on.`}</p>
    <p>{`If the library question was suggested via the `}<a parentName="p" {...{
        "href": "/schema-new/query/applicationLibraryQuestionSuggestions",
        "title": "An array of suggested application questions for the provided partial `PositionProfile` in decreasing order of relevance"
      }}><inlineCode parentName="a">{`applicationLibraryQuestionSuggestions`}</inlineCode></a>{` query,
you should supply the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationLibraryQuestionSuggestion#/field/id",
        "title": "The identifier for the `ApplicationLibraryQuestionSuggestion`"
      }}><inlineCode parentName="a">{`ApplicationLibraryQuestionSuggestion.id`}</inlineCode></a>{` rather than the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationLibraryQuestion#/field/id",
        "title": "The identifier for the `ApplicationLibraryQuestion`"
      }}><inlineCode parentName="a">{`ApplicationLibraryQuestion.id`}</inlineCode></a>{`.`}</p>
    <p>{`If the library question was retrieved outside of a suggestion context,
you may supply its underlying `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationLibraryQuestion#/field/id",
        "title": "The identifier for the `ApplicationLibraryQuestion`"
      }}><inlineCode parentName="a">{`ApplicationLibraryQuestion.id`}</inlineCode></a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;