
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The candidate's primary email address.`}</p>
    <p>{`The value must match one of the candidate's email addresses.
Duplicate uploads will result in a `}<inlineCode parentName="p">{`BAD_USER_INPUT`}</inlineCode>{` error.`}</p>
    <p>{`This field has a maximum length of 255 bytes in UTF-8 encoding.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;