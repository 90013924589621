import { Box, Stack } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { MiniHeading } from 'src/components/MiniHeading/MiniHeading';

export const MenuSection = ({
  children,
  heading,
}: {
  children: ComponentProps<typeof Stack>['children'];
  heading: ReactNode;
}) => (
  <Box>
    <Stack space="small">
      {heading ? (
        <Box marginLeft="xsmall">
          <MiniHeading>{heading}</MiniHeading>
        </Box>
      ) : null}

      <Stack space="none">{children}</Stack>
    </Stack>
  </Box>
);
