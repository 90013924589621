
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The date & time the job ad was successfully posted within SEEK's systems. This corresponds to the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/Event#/field/createDateTime",
        "title": "The date & time the event was created"
      }}><inlineCode parentName="a">{`Event.createDateTime`}</inlineCode></a>{` field in the GraphQL schema.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;