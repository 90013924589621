
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { InlineCode } from 'scoobie';
import { SeekAdSelectionFallback } from './panel';
import { IconClear, IconTick } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-selection-panel"
    }}>{`Ad Selection Panel`}</h1>
    <p>{`SEEK provides a themeable Ad Selection Panel you can embed within your job posting flow.
It displays the SEEK ad products available to the hirer along with their associated prices.`}</p>
    <h2 {...{
      "id": "browser-support"
    }}>{`Browser support`}</h2>
    <p>{`The Ad Selection Panel tracks our standard `}<a parentName="p" {...{
        "href": "/introduction/browser-support"
      }}>{`browser support policy`}</a>{`.`}</p>
    <p>{`While the panel is currently functional in Internet Explorer 11,
backward compatibility has been dropped in its `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/panel#browser-support"
      }}>{`next revision`}</a>{`.
We are not able to provide ongoing support for Internet Explorer as `}<a parentName="p" {...{
        "href": "https://blogs.windows.com/windowsexperience/2022/06/15/internet-explorer-11-has-retired-and-is-officially-out-of-support-what-you-need-to-know/"
      }}>{`it is retired and out of support`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-1-include-the-panel"
    }}>{`Step 1: Include the panel`}</h2>
    <p>{`Add the following script tag to the page where you want to insert the Ad Selection Panel:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script
  type="text/javascript"
  src="https://seekcdn.com/hirer/indirect-posting/product-selection-panel/seek.js"
></script>
`}</code></pre>
    <h2 {...{
      "id": "step-2-render-the-panel"
    }}>{`Step 2: Render the panel`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` method renders an instance of the panel in the specified DOM element.`}</p>
    <p>{`This method must be called on page load and whenever the properties of the `}<a parentName="p" {...{
        "href": "#options"
      }}><inlineCode parentName="a">{`draftAdvertisement`}</inlineCode>{` object`}</a>{` change.
For example, if the hirer selects a new location, you must re-render the panel to reflect updated pricing.`}</p>
    <p>{`The panel is `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Responsive_web_design"
      }}>{`responsive`}</a>{` and will inherit the width of the `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{`’s parent.
If you don’t have any preference for the panel’s width, we recommend that you add a `}<inlineCode parentName="p">{`max-width: 822px`}</inlineCode>{` style to the parent of the `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "example-javascript"
    }}>{`Example JavaScript`}</h3>

    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SEEKAdPostingWidget.render(containerNode, options);
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`containerNode`}</inlineCode>{` –
(DOM Node) The DOM element to render the widget into.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`options`}</inlineCode>{` –
Options for rendering the panel; see `}<a parentName="p" {...{
            "href": "#options"
          }}>{`options`}</a>{` below.`}</p>
      </li>
    </ul>
    <Alert tone="caution" mdxType="Alert">
  <Text mdxType="Text">
    Modifying the <InlineCode mdxType="InlineCode">containerNode</InlineCode> or its children after
    the <InlineCode mdxType="InlineCode">render</InlineCode> function has been called may lead to
    unexpected behaviour.
  </Text>
    </Alert>
    <h3 {...{
      "id": "options"
    }}>{`Options`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Option property`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`instanceId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`A unique identifier for the new instance. This will be passed into callback methods. Should be unique per instance of the panel on the page.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`mode`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Create`}</inlineCode>{` or `}<inlineCode parentName="p">{`Update`}</inlineCode>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`onInitialLoad(event)`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`() => void`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`Function to call when panel is loaded.`}</p><p><inlineCode parentName="td">{`event`}</inlineCode>{` `}<small>{`(object)`}</small></p><ul><li><p><inlineCode parentName="td">{`instanceId`}</inlineCode>{` `}<small>{`(string `}{`|`}{` undefined)`}</small></p></li><li><p><inlineCode parentName="td">{`isVisible`}</inlineCode>{` `}<small>{`(boolean)`}</small></p><p>{`Flag indicating whether the panel is visible.`}</p></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`onChange(event)`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`() => void`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`See `}<a parentName="p" {...{
                "href": "#step-4-handle-ad-product-selection"
              }}>{`handling ad product selection`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`getAuthToken`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`() => Promise<string>`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Function to call to retrieve the `}<a parentName="p" {...{
                "href": "#step-3-handle-browser-token-requests"
              }}>{`browser token`}</a>{` to use when calling the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`isValidationEnabled`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`boolean`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Flag indicating whether to show an error message when `}<inlineCode parentName="p">{`draftAdvertisement.typeCode`}</inlineCode>{` is undefined. This will typically be false until the hirer presses save.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`draftAdvertisement`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The proposed state of the job ad to be posted or updated.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`draftAdvertisement.typeCode`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`One of: `}<inlineCode parentName="td">{`Classic`}</inlineCode>{`, `}<inlineCode parentName="td">{`StandOut`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Premium`}</inlineCode>{`.`}</p><p>{`Update mode only.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`draftAdvertisement.hirerJobReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p><p><small>{`≤50 characters`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/identifiers-and-references"
              }}>{`hirer’s job reference`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`draftAdvertisement.positionTitle`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/PostPosition_PositionProfileInput/field/positionTitle"
              }}>{`position’s title`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`draftAdvertisement.jobCategoryId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`The identifier for the position’s `}<a parentName="td" {...{
                "href": "/use-cases/job-posting/job-categories"
              }}>{`job category`}</a>{`.`}</p><p>{`e.g. `}<inlineCode parentName="td">{`seekAnzPublicTest:jobCategory:seek:27HXTkNXh`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`draftAdvertisement.positionLocationId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The identifier for the position’s `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/locations"
              }}>{`location`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`object`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`The current state of the posted job ad.`}</p><p>{`Update mode only.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.id`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`The position profile ID of the job ad.`}</p><p>{`If this is provided, the other `}<inlineCode parentName="td">{`advertisement`}</inlineCode>{` properties will be loaded automatically.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`One of: `}<inlineCode parentName="p">{`Classic`}</inlineCode>{`, `}<inlineCode parentName="p">{`StandOut`}</inlineCode>{`, or `}<inlineCode parentName="p">{`Premium`}</inlineCode>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.hirerJobReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p><p><small>{`≤50 characters`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/identifiers-and-references"
              }}>{`hirer’s job reference`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.positionTitle`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/PostPosition_PositionProfileInput/field/positionTitle"
              }}>{`position’s title`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.jobCategoryId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`The identifier for the position’s `}<a parentName="td" {...{
                "href": "/use-cases/job-posting/job-categories"
              }}>{`job category`}</a>{`.`}</p><p>{`e.g. `}<inlineCode parentName="td">{`seekAnzPublicTest:jobCategory:seek:2PiXemmou`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.positionLocationId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The identifier for the position’s `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/locations"
              }}>{`location`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`theme`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`object`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Options for configuring the look of the panel.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`theme.selectionColor`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`default: `}<inlineCode parentName="td">{`#e60278`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The colour to set the product border and description to when a product is selected.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`theme.linkColor`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`Defaults to `}<inlineCode parentName="td">{`selectionColor`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The colour to set links and popup triggers to.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><del parentName="p"><inlineCode parentName="del">{`theme.contractBarPrimaryBackgroundColor`}</inlineCode></del></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><del parentName="td"><inlineCode parentName="del">{`string`}</inlineCode></del></p><p><small><del parentName="td">{`default: `}<inlineCode parentName="del">{`#e60278`}</inlineCode></del></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`No longer in use`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><del parentName="p"><inlineCode parentName="del">{`theme.contractBarSecondaryBackgroundColor`}</inlineCode></del></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><del parentName="td"><inlineCode parentName="del">{`string`}</inlineCode></del></p><p><small><del parentName="td">{`default: `}<inlineCode parentName="del">{`#fceff5`}</inlineCode></del></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`No longer in use`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`theme.cautionColor`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`default: `}<inlineCode parentName="td">{`#b38800`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The colour to set the product message to when the product has a cautionary message for the hirer.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`theme.errorColor`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`default: `}<inlineCode parentName="td">{`#ff0000`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The colour to set the product list border to when `}<inlineCode parentName="p">{`isValidationEnabled`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` and a product hasn’t been selected.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`disableCSSOMInjection`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`boolean`}</inlineCode></p><p><small>{`default: `}<inlineCode parentName="td">{`false`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Disable CSS Object Model injection. Reduces render performance. This may need to be used if the panel is rendering unstyled. See `}<a parentName="p" {...{
                "href": "#troubleshooting"
              }}>{`Troubleshooting`}</a>{`.`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "sample-code"
    }}>{`Sample code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SEEKAdPostingWidget.render(document.getElementById('seekWidget1ContainerDiv'), {
  // Unique identifier for the instance.
  instanceId: 'widget1',

  // Mode should be \`Create\` while posting a new job ad
  // or \`Update\` while updating a job ad.
  mode: 'Create',

  // Function to call when panel is loaded.
  onInitialLoad: function (event) {
    const { instanceId, isVisible } = event;
    if (isVisible) {
      console.log(\`Widget \${instanceId} loaded\`);
      // -> Widget widget1 loaded
    }
  },

  // \`onChange\` will be called each time a hirer selects an ad product.
  // See "Step 4" for more details.
  onChange: function (event) {
    const { advertisement } = event;

    // \`typeCode\` should be persisted in memory to be used
    //  for job posting mutations.
    const { typeCode, features } = advertisement;

    console.log(\`The hirer selected the \${typeCode} ad product\`);
    // -> The hirer selected the Premium ad product

    // Your software should use "features" to conditionally display bullet
    // points and branding inputs based on the selected ad product.
    const { brandingIndicator, searchBulletPointsIndicator } = features;

    if (brandingIndicator) {
      // Show brand selector
    } else {
      // Hide brand selector
    }

    if (searchBulletPointsIndicator) {
      // Show fields to enter bullet points
    } else {
      // Hide bullet point fields
    }
  },

  // Function to call to retrieve the browser token to use
  // when calling the SEEK API, see "Step 3" for more details.
  getAuthToken: function () {
    return Promise.resolve('browser token');
  },

  // \`draftAdvertisement\` should contain all of the hirer’s
  // current inputs if they are posting or updating.
  draftAdvertisement: {
    positionTitle: 'Developer',
    jobCategoryId: 'seekAnzPublicTest:jobCategory:seek:2EFstqFvP',
    positionLocationId: 'seekAnzPublicTest:location:seek:2TCFhBtw9',
    hirerJobReference: 'Premium521'
  },

  // \`advertisement\` specifies the current state of the job ad in update mode.
  //
  // Typically you would only specify the \`id\` of the live job ad.
  // Alternatively, you can provide the job ad's fields if you don't have the
  // position profile ID.
  advertisement: {
    // Position profile ID
    id: 'seekAnzPublicTest:positionProfile:jobAd:27cuZeA47'
  },

  // Specify a \`theme\` to style your ad selection
  // panel with your own colours.
  theme: {
    selectionColor: '#e60278',
    linkColor: '#e60278',
    cautionColor: '#b38800',
    errorColor: '#ff0000'
  }
});
`}</code></pre>
    <h2 {...{
      "id": "step-3-handle-browser-token-requests"
    }}>{`Step 3: Handle browser token requests`}</h2>
    <img alt="" data-scoobie-style="none" height="540" src={require('../../../../../mermaid/.aaaaf88d36867e4aa60b047f3e4b05879b1919a4.mmd.svg')} title="Panel authentication" width="1092" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The panel loads and invokes the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function passed to it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function should request a new token for the hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`.
If a user switches to a different SEEK hirer account in your posting form,
your software should re-render the panel with the new hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`,
and ensure that subsequent invocations of `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` will request a token for the new hirer ID.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorizes the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorized to access a given hirer ID.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend `}<a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`requests a browser token from the SEEK API`}</a>{` for the appropriate hirer ID and `}<inlineCode parentName="p">{`query:ad-products query:organizations`}</inlineCode>{` scope.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend responds with the browser token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend returns the browser token from the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel can now make requests to the GraphQL endpoint.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "query:ad-products query:organizations",
  "userId": "317665"
}
`}</code></pre>
    <h2 {...{
      "id": "step-4-handle-ad-product-selection"
    }}>{`Step 4: Handle ad product selection`}</h2>
    <p>{`When a hirer selects an ad product, the `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` function you provided within your `}<a parentName="p" {...{
        "href": "#options"
      }}>{`options`}</a>{` will be called with the below event structure.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode>{` property should be persisted in memory to be used when `}<a parentName="p" {...{
        "href": "#step-5-posting-the-job-ad"
      }}>{`posting the job ad`}</a>{`.`}</p>
    <h3 {...{
      "id": "onchange-event"
    }}><inlineCode parentName="h3">{`onChange`}</inlineCode>{` event`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Option property`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`instanceId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The unique identifier initially passed in via `}<a parentName="p" {...{
                "href": "#options"
              }}>{`options`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertiserId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The ID of the hirer that has selected an ad product.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.id`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The position profile ID of the job ad.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.hirerJobReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/identifiers-and-references"
              }}>{`hirer’s job reference`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><a parentName="td" {...{
                "href": "https://developer.seek.com/schema/#/named-type/SeekAnzAdProduct/field/advertisementTypeCode"
              }}><inlineCode parentName="a">{`advertisementTypeCode`}</inlineCode></a>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The ad product type code selected by the hirer. This should be persisted in memory and used for job posting mutations.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.jobCategoryId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The identifier for the position’s `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/job-categories"
              }}>{`job category`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.positionLocationId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The identifier for the position’s `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/locations"
              }}>{`location`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.positionTitle`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/PostPosition_PositionProfileInput/field/positionTitle"
              }}>{`position’s title`}</a>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.price`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`integer`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The price of the ad product in the minor currency unit. For example, this is the number of cents in dollar-denominated currencies, e.g `}<inlineCode parentName="p">{`26989`}</inlineCode>{` would be returned for `}<inlineCode parentName="p">{`$269.89 AUD`}</inlineCode>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.priceDescription`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Descriptive summary of the product price, e.g `}<inlineCode parentName="p">{`A$269.89 will be deducted from your SEEK contract`}</inlineCode>{` or `}<inlineCode parentName="p">{`A$319.39 + GST`}</inlineCode>{`. See `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/ad-selection/graphql#6-price"
              }}>{`price`}</a>{` for details on how the currency is determined.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.paymentDueExcludingTax`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><a parentName="td" {...{
                "href": "https://developer.seek.com/schema/#/named-type/CurrencyMinorUnit"
              }}><inlineCode parentName="a">{`CurrencyMinorUnit`}</inlineCode></a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The amount left to be paid after contract deductions. This is an object with both `}<inlineCode parentName="p">{`currency`}</inlineCode>{` and `}<inlineCode parentName="p">{`value`}</inlineCode>{` properties.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`advertisement.features`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><a parentName="td" {...{
                "href": "https://developer.seek.com/schema/#/named-type/SeekAnzAdProductFeatures"
              }}><inlineCode parentName="a">{`SeekAnzAdProductFeatures`}</inlineCode></a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`See `}<a parentName="p" {...{
                "href": "#supporting-features"
              }}>{`Supporting features`}</a>{`.`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "supporting-features"
    }}>{`Supporting features`}</h3>
    <p>{`Ad products have features that dynamically impact your software’s job posting flow.
The available features are passed to your `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` handler when an ad product is selected:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/SeekAnzAdProductFeatures/field/searchBulletPointsIndicator"
          }}><inlineCode parentName="a">{`advertisement.features.searchBulletPointsIndicator`}</inlineCode></a>{` property indicates the ad product supports bullet points in search results.
Your software’s frontend should conditionally display bullet point input fields based on this property.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/SeekAnzAdProductFeatures/field/brandingIndicator"
          }}><inlineCode parentName="a">{`advertisement.features.brandingIndicator`}</inlineCode></a>{` property indicates the ad product supports `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/branding"
          }}>{`branding`}</a>{`.
Your software’s frontend should conditionally display a brand selector based on this property.`}</p>
      </li>
    </ul>
    <p>{`Previously these features needed to be inferred from the `}<inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode>{` property.
This may not work in the future when additional `}<a parentName="p" {...{
        "href": "#forward-compatibility"
      }}><inlineCode parentName="a">{`typeCode`}</inlineCode>{` values are added`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-5-posting-the-job-ad"
    }}>{`Step 5: Posting the job ad`}</h2>
    <p>{`When the hirer is ready to post or update the job ad, `}<inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode>{` persisted above should be passed into the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CreatePostingInstructionInput/field/seekAnzAdvertisementType"
      }}><inlineCode parentName="a">{`CreatePostingInstructionInput.seekAnzAdvertisementType`}</inlineCode></a>{` field in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/postPosition"
      }}><inlineCode parentName="a">{`postPosition`}</inlineCode></a>{`, `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/postPositionProfileForOpening"
      }}><inlineCode parentName="a">{`postPositionProfileForOpening`}</inlineCode></a>{` or `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updatePostedPositionProfile"
      }}><inlineCode parentName="a">{`updatePostedPositionProfile`}</inlineCode></a>{` job posting mutations.`}</p>
    <p>{`When a hirer submits the form in your software, you will need to toggle `}<inlineCode parentName="p">{`isValidationEnabled`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` within `}<a parentName="p" {...{
        "href": "#options"
      }}>{`options`}</a>{` to flag to the panel to display a validation error border around the panel if an ad product has not yet been selected.`}</p>
    <p>{`The validation error border can be styled using the `}<inlineCode parentName="p">{`theme.errorColor`}</inlineCode>{` property within `}<a parentName="p" {...{
        "href": "#options"
      }}>{`options`}</a>{`.`}</p>
    <h2 {...{
      "id": "forward-compatibility"
    }}>{`Forward compatibility`}</h2>
    <p>{`Additional `}<inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode>{` values may be added in the future.
However, the existing `}<inlineCode parentName="p">{`Classic`}</inlineCode>{`, `}<inlineCode parentName="p">{`StandOut`}</inlineCode>{` & `}<inlineCode parentName="p">{`Premium`}</inlineCode>{` values will continue to be accepted when posting a job ad.`}</p>
    <p>{`Your software should not have conditional business logic based on the `}<inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode>{` property.
This may not work for unexpected `}<inlineCode parentName="p">{`advertisement.typeCode`}</inlineCode>{` values.`}</p>
    <h2 {...{
      "id": "error-handling"
    }}>{`Error handling`}</h2>
    <h3 {...{
      "id": "fallback"
    }}>{`Fallback`}</h3>
    <p>{`There are errors that can prevent the panel from being shown to hirers.
When they occur, you must show a fallback field that allows a hirer to select an advertisement type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<label for="seek-advertisement-type">Ad type</label>
<select id="seek-advertisement-type">
  <option value="">Choose an option</option>
  <option>Classic</option>
  <option>StandOut</option>
  <option>Premium</option>
</select>
`}</code></pre>

    <SeekAdSelectionFallback mdxType="SeekAdSelectionFallback" />
    <p>{`The advertisement type `}<strong parentName="p">{`must`}</strong>{` be selected by the hirer.
It must not be defaulted.`}</p>
    <p>{`You will not have access to the ad product’s `}<a parentName="p" {...{
        "href": "#supporting-features"
      }}>{`features`}</a>{` when using the fallback field.
Instead, you need to hardcode the supported features within your software:`}</p>

    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Advertisement type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Supports search bullets`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Supports branding`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Classic`}</p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`StandOut`}</p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Premium`}</p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
        </tr>
      </tbody>
    </table>
    <p>{`You should also show a message informing the hirer that there was an error loading pricing and that they should contact SEEK for pricing.`}</p>
    <p>{`You could hide the fallback field after `}<inlineCode parentName="p">{`onInitialLoad`}</inlineCode>{` is called with `}<inlineCode parentName="p">{`isVisible`}</inlineCode>{` set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` or you could start by not showing anything and only show the fallback field when an error occurs or if the widget fails to load within a set time frame.`}</p>
    <h3 {...{
      "id": "script-doesnt-load-properly"
    }}>{`Script doesn’t load properly`}</h3>
    <p>{`If for some reason the hirer’s browser fails to load the script, calling the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method will fail because `}<inlineCode parentName="p">{`SEEKAdPostingWidget`}</inlineCode>{` will be `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`.
To handle this, it’s recommended to wrap the call to the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method in a try/catch block.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`try {
  SEEKAdPostingWidget.render(
    document.getElementById('seekWidget1ContainerDiv'),
    options
  );
} catch (e) {
  // Show fallback field if not already visible
}
`}</code></pre>
    <h3 {...{
      "id": "panel-fails-to-call-oninitialload-function"
    }}>{`Panel fails to call onInitialLoad function`}</h3>
    <p>{`When the panel is unable to retrieve prices from the SEEK API, it may fail to call the `}<inlineCode parentName="p">{`onInitialLoad`}</inlineCode>{` function or it may call `}<inlineCode parentName="p">{`onInitialLoad`}</inlineCode>{` with `}<inlineCode parentName="p">{`isVisible`}</inlineCode>{` set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`This can be handled 2 different ways:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`You could start by showing the `}<a parentName="p" {...{
            "href": "#fallback"
          }}>{`fallback field`}</a>{` and hide it once `}<inlineCode parentName="p">{`onInitialLoad`}</inlineCode>{` is called with `}<inlineCode parentName="p">{`isVisible`}</inlineCode>{` set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`try {
  const options = {
    onInitialLoad: (event) => {
      if (event.isVisible) {
        // Hide fallback
      }
    }
  };
  SEEKAdPostingWidget.render(container, options);
} catch (e) {}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You could set a timer and show the `}<a parentName="p" {...{
            "href": "#fallback"
          }}>{`fallback field`}</a>{` after a set time frame has elapsed and the widget container doesn’t have any children:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`try {
  const container = document.getElementById('seekWidget1ContainerDiv');
  SEEKAdPostingWidget.render(container, options);

  setTimeout(function () {
    if (!container.children[0]?.children.length > 0) {
      // Show fallback field
    }
  }, 4000);
} catch (e) {
  // Show fallback field
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <h3 {...{
      "id": "the-panel-is-unstyled"
    }}>{`The panel is unstyled`}</h3>
    <p>{`If the panel renders without a stylesheet and looks unstyled, there may be an incompatibility between your software’s front-end framework and the panel’s use of the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model"
      }}>{`CSS Object Model`}</a>{`.`}</p>
    <p>{`If this is the case you can set `}<inlineCode parentName="p">{`disableCSSOMInjection`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` within your panel `}<a parentName="p" {...{
        "href": "#options"
      }}>{`options`}</a>{`.`}</p>
    <p>{`Disabling CSS Object Model injection reduces rendering performance as it switches to a slower text node-based CSS injection system for adding styles to the DOM.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;