
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The conflict result for the `}<a parentName="p" {...{
        "href": "/schema-new/mutation/postPositionProfileForOpening",
        "title": "Asynchronously posts a job ad for an existing position opening"
      }}><inlineCode parentName="a">{`postPositionProfileForOpening`}</inlineCode></a>{` mutation.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`idempotencyId`}</inlineCode>{` provided as part of `}<a parentName="p" {...{
        "href": "/schema-new/named-type/CreatePostingInstructionInput",
        "title": "Information about how to post a job ad and where to direct its candidate applications"
      }}><inlineCode parentName="a">{`CreatePostingInstructionInput`}</inlineCode></a>{` while creating a position profile must be unique.
Providing the same `}<inlineCode parentName="p">{`idempotencyId`}</inlineCode>{` will result in a conflict.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;