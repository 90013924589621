import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const keys = ['argument', 'result', 'field', 'property'] as const;

export const useSchemaHashParams = (): {
  argumentName?: string;
  fieldName?: string;
  resultName?: string;
  propertyName?: string;
} => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);

  const { hash } = useLocation();

  // Hydration
  if (!ready) {
    return {};
  }

  const parts = hash?.replace(/^[#/]+/g, '')?.split('/');

  if (parts.length === 2 && keys.includes(parts[0] as (typeof keys)[number])) {
    return {
      [`${parts[0]}Name`]: parts[1],
    };
  }

  return {};
};
