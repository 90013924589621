import { QuestionnaireBuilder } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

export default () => (
  <BorderCard>
    <QuestionnaireBuilder
      hirerId="seekAnzPublicTest:organization:seek:93WyyF1h"
      showGraphqlOutput
    />
  </BorderCard>
);
