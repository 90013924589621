
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The interval the remuneration amounts are calculated over.`}</p>
    <p>{`Currently three interval codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Hour`}</inlineCode>{` is used to express hourly rates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Month`}</inlineCode>{` is used to express monthly salaries.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Year`}</inlineCode>{` is used to express annual salaries.`}</p>
      </li>
    </ul>
    <p>{`The specified value must correspond to `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationPackageInput#/field/basisCode",
        "title": "A code classifying the primary method of payment for a position"
      }}><inlineCode parentName="a">{`RemunerationPackageInput.basisCode`}</inlineCode></a>{`.
When `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationPackageInput#/field/basisCode",
        "title": "A code classifying the primary method of payment for a position"
      }}><inlineCode parentName="a">{`RemunerationPackageInput.basisCode`}</inlineCode></a>{` equals `}<inlineCode parentName="p">{`Hourly`}</inlineCode>{`, the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationRangeInput#/field/intervalCode",
        "title": "The interval the remuneration amounts are calculated over"
      }}><inlineCode parentName="a">{`RemunerationRangeInput.intervalCode`}</inlineCode></a>{` must be `}<inlineCode parentName="p">{`Hour`}</inlineCode>{`.
When `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationPackageInput#/field/basisCode",
        "title": "A code classifying the primary method of payment for a position"
      }}><inlineCode parentName="a">{`RemunerationPackageInput.basisCode`}</inlineCode></a>{` equals `}<inlineCode parentName="p">{`Salaried`}</inlineCode>{`, the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationRangeInput#/field/intervalCode",
        "title": "The interval the remuneration amounts are calculated over"
      }}><inlineCode parentName="a">{`RemunerationRangeInput.intervalCode`}</inlineCode></a>{` must be `}<inlineCode parentName="p">{`Month`}</inlineCode>{` or `}<inlineCode parentName="p">{`Year`}</inlineCode>{`.
For all other `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationPackageInput#/field/basisCode",
        "title": "A code classifying the primary method of payment for a position"
      }}><inlineCode parentName="a">{`RemunerationPackageInput.basisCode`}</inlineCode></a>{`s, the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationRangeInput#/field/intervalCode",
        "title": "The interval the remuneration amounts are calculated over"
      }}><inlineCode parentName="a">{`RemunerationRangeInput.intervalCode`}</inlineCode></a>{` must be `}<inlineCode parentName="p">{`Year`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;