import { Box, Secondary, TextLink } from 'braid-design-system';
import { Fragment } from 'react';
import { InlineCode } from 'scoobie';

import { hrefForWebhookSchemaDefinition } from 'src/utils/href';
import {
  type AnyType,
  type ArrayType,
  type NumberType,
  type StringType,
  type TypeRef,
  type UnionType,
  isArrayType,
  isBooleanType,
  isNullType,
  isNumberType,
  isStringType,
  isTypeRef,
  isUnionType,
} from 'src/utils/jsonSchema';

import * as styles from './TypeLabel.css';

const DEFINITION_REF_REGEXP = /^#\/definitions\/(\w+)$/;

const ArrayTypeLabel = ({ arrayType }: { arrayType: ArrayType }) => (
  <>
    <TypeLabel type={arrayType.items} />
    <InlineCode weight="weak">[]</InlineCode>
  </>
);

const StringTypeLabel = ({ stringType }: { stringType: StringType }) => {
  if (stringType.enum) {
    // This is a literal
    return (
      <InlineCode weight="weak">
        {stringType.enum
          .sort((a, b) => a.localeCompare(b))
          .map((enumValue) => `"${enumValue}"`)
          .join(' | ')}
      </InlineCode>
    );
  }

  return (
    <InlineCode weight="weak">
      string {stringType.format && <Secondary>({stringType.format})</Secondary>}{' '}
    </InlineCode>
  );
};

const NumberTypeLabel = ({ numberType }: { numberType: NumberType }) => (
  <InlineCode weight="weak">{numberType.type}</InlineCode>
);

const BooleanTypeLabel = () => <InlineCode>boolean</InlineCode>;

const NullTypeLabel = () => <InlineCode>null</InlineCode>;

const UnionTypeLabel = ({ unionType }: { unionType: UnionType }) => (
  <Box className={styles.preserveNegativeSpace} component="span">
    <InlineCode weight="weak">(</InlineCode>

    {unionType.anyOf.map((anyType, i) => (
      <Fragment key={i}>
        <InlineCode weight="weak">{'\n  | '}</InlineCode>

        <TypeLabel type={anyType} />
      </Fragment>
    ))}

    <InlineCode weight="weak">{'\n)'}</InlineCode>
  </Box>
);

const TypeRefLabel = ({ typeRef }: { typeRef: TypeRef }) => {
  const defMatches = typeRef.$ref.match(DEFINITION_REF_REGEXP);

  if (!defMatches) {
    return <InlineCode weight="weak">object</InlineCode>;
  }

  const definitionName = defMatches[1];
  return (
    <InlineCode weight="weak">
      <TextLink href={hrefForWebhookSchemaDefinition(definitionName)}>
        {definitionName}
      </TextLink>
    </InlineCode>
  );
};

export const TypeLabel = ({ type }: { type: AnyType }) => {
  if (isArrayType(type)) {
    return <ArrayTypeLabel arrayType={type} />;
  } else if (isStringType(type)) {
    return <StringTypeLabel stringType={type} />;
  } else if (isNumberType(type)) {
    return <NumberTypeLabel numberType={type} />;
  } else if (isBooleanType(type)) {
    return <BooleanTypeLabel />;
  } else if (isNullType(type)) {
    return <NullTypeLabel />;
  } else if (isUnionType(type)) {
    return <UnionTypeLabel unionType={type} />;
  } else if (isTypeRef(type)) {
    return <TypeRefLabel typeRef={type} />;
  }

  return <InlineCode>object</InlineCode>;
};
