
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The type of the component.`}</p>
    <p>{`Currently, two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PrivacyConsent`}</inlineCode>{` which corresponds to the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/ApplicationPrivacyConsent",
            "title": "A privacy policy consent component of an `ApplicationQuestionnaire`"
          }}><inlineCode parentName="a">{`ApplicationPrivacyConsent`}</inlineCode></a>{` type.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Question`}</inlineCode>{` which corresponds to the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/ApplicationQuestion",
            "title": "A question component of an `ApplicationQuestionnaire`"
          }}><inlineCode parentName="a">{`ApplicationQuestion`}</inlineCode></a>{` type.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;