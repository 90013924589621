
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The URL of the video to display.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme requires a YouTube link in one of the following formats:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`https://www.youtube.com/embed/aAgePQvHBQM`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`https://www.youtube.com/watch?v=aAgePQvHBQM`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`https://youtube.com/embed/aAgePQvHBQM`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`https://youtube.com/watch?v=aAgePQvHBQM`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`https://youtu.be/aAgePQvHBQM`}</inlineCode></p>
          </li>
        </ul>
        <p parentName="li">{`If the URL provided does not match the above criteria it will be ignored.
Examples of unsupported formats include:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Links with additional query parameters: `}<inlineCode parentName="p">{`https://www.youtube.com/watch?ab_channel=SEEKJobs&v=aAgePQvHBQM`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Mobile links: `}<inlineCode parentName="p">{`https://m.youtube.com/watch?v=aAgePQvHBQM`}</inlineCode></p>
          </li>
        </ul>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;