import { Divider, IconDownload, Stack, Text } from 'braid-design-system';
import {
  type GraphQLArgument,
  type GraphQLField,
  type GraphQLInputObjectType,
  OperationTypeNode,
  getNamedType,
  isInputObjectType,
  isNonNullType,
  isObjectType,
} from 'graphql';
import { useParams } from 'react-router-dom';
import { InlineCode } from 'scoobie';

import { NameLabel } from 'src/components/NameLabel/NameLabel';
import { SampleOperation } from 'src/components/SampleOperation/SampleOperation';
import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';
import { TypeLink } from 'src/components/TypeLink/TypeLink';
import { sortGraphqlEntities } from 'src/utils/sort';

import { ArgDetails } from '../components/ArgDetails';
import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { DeprecationWarning } from '../components/DeprecationWarning';
import { FieldDetails } from '../components/FieldDetails';
import { InputFieldDetails } from '../components/InputFieldDetails';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSnippet } from '../components/TypeSnippet';
import { schema } from '../schema';
import { useSchemaHashParams } from '../useSchemaHashParams';

import { OopsSchemaPage } from './OopsSchemaPage';

const resolveOperationByName = (
  operationName: string,
): [OperationTypeNode, GraphQLField<unknown, unknown>] | undefined => {
  const queryField = schema.getQueryType()?.getFields()[operationName];
  if (queryField) {
    return [OperationTypeNode.QUERY, queryField];
  }

  const mutationField = schema.getMutationType()?.getFields()[operationName];
  if (mutationField) {
    return [OperationTypeNode.MUTATION, mutationField];
  }

  return;
};

/**
 * Returns the input object if the operation takes a single non-null argument
 * named `input`
 */
const inputObjectForArgs = (
  args: readonly GraphQLArgument[],
): GraphQLInputObjectType | undefined => {
  if (args.length !== 1) {
    // Multiple arguments
    return;
  }

  const [arg] = args;

  if (arg.name !== 'input') {
    // Not called `input`
    return;
  }

  if (!isNonNullType(arg.type)) {
    // Not a non-null argument
    return;
  }

  const nonNullType = arg.type.ofType;
  if (!isInputObjectType(nonNullType)) {
    return;
  }

  return nonNullType;
};

export const OperationPage = () => {
  const { operationName } = useParams();
  const { argumentName, resultName } = useSchemaHashParams();

  const resolvedOperation = resolveOperationByName(operationName ?? '');
  if (!resolvedOperation || !operationName) {
    return <OopsSchemaPage />;
  }

  const [operationType, operationField] = resolvedOperation;
  const { args, type: resultType } = operationField;

  // If we accept a single input object we render it inline
  const inputObjectType = inputObjectForArgs(args);

  // If we return an operation-specific payload type we render it inline
  const namedResultType = getNamedType(resultType);
  const payloadObjectType =
    namedResultType.name.endsWith('Payload') && isObjectType(namedResultType)
      ? namedResultType
      : undefined;

  const fieldPath = `${operationType}.${operationName}`;

  return (
    <BaseSchemaPage
      titleText={operationName}
      title={<InlineCode weight="weak">{operationName}</InlineCode>}
      subHeading={
        <Text
          icon={<IconDownload />}
          size="small"
          weight="medium"
          tone="secondary"
        >
          {typeof operationField.deprecationReason === 'string'
            ? 'DEPRECATED '
            : ''}
          {operationType === OperationTypeNode.QUERY ? 'QUERY' : 'MUTATION'}
        </Text>
      }
    >
      {typeof operationField.deprecationReason === 'string' && (
        <DeprecationWarning type={`${fieldPath}.deprecation`} />
      )}

      <SchemaMarkdown type={fieldPath} />

      {args.length > 0 && (
        <>
          <Divider />
          <SchemaSection
            heading="Arguments"
            wrapper="usage-table"
            subheading={
              inputObjectType && (
                <Stack space="medium">
                  <NameLabel type={args[0].type}>{args[0].name}</NameLabel>

                  <Text weight="strong">
                    <TypeLink type={args[0].type} />
                  </Text>
                </Stack>
              )
            }
          >
            {inputObjectType
              ? Object.entries(inputObjectType.getFields())
                  .sort(sortGraphqlEntities)
                  .map(([fieldNameKey, field]) => (
                    <InputFieldDetails
                      key={fieldNameKey}
                      objectName={inputObjectType.name}
                      highlight={fieldNameKey === argumentName}
                      operationName={operationName}
                      operationType={operationType}
                      field={field}
                    />
                  ))
              : args
                  .slice()
                  .sort(sortGraphqlEntities)
                  .map((arg) => (
                    <ArgDetails
                      arg={`${fieldPath}.${arg.name}`}
                      key={arg.name}
                      operationType={operationType}
                      operationName={operationName}
                      argName={arg.name}
                      highlight={arg.name === argumentName}
                      type={arg.type}
                      deprecated={typeof arg.deprecationReason === 'string'}
                    />
                  ))}
          </SchemaSection>
        </>
      )}

      <Divider />

      <SchemaSection
        heading="Result"
        wrapper={payloadObjectType ? 'usage-table' : 'table'}
        subheading={
          payloadObjectType && (
            <Text weight="strong">
              <TypeLink type={resultType} />
            </Text>
          )
        }
      >
        {payloadObjectType ? (
          Object.entries(payloadObjectType.getFields())
            .sort(sortGraphqlEntities)
            .map(([fieldNameKey, field]) => (
              <FieldDetails
                key={fieldNameKey}
                objectName={payloadObjectType.name}
                highlight={resultName === fieldNameKey}
                operationName={operationName}
                operationType={operationType}
                field={field}
                deprecated={typeof field.deprecationReason === 'string'}
              />
            ))
        ) : (
          <TypeSnippet type={resultType} />
        )}
      </SchemaSection>

      <Divider />

      <SchemaSection heading="Sample" wrapper="none">
        <SampleOperation field={operationField} type={operationType} />
      </SchemaSection>
    </BaseSchemaPage>
  );
};
