import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Hidden,
  HiddenVisually,
  IconSearch,
  Link,
  Text,
} from 'braid-design-system';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditionalWrapper';
import { LogoLink } from 'src/components/Logo/Logo';
import { SiteSearch } from 'src/components/SiteSearch/SiteSearch';
import { useLogin } from 'src/hooks/auth';

import * as styles from './Nav.css';

const ExpandingSiteSearch = ({
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}) =>
  expanded ? (
    <Box className={styles.absoluteFullWidthInput}>
      <Box flexGrow={1} paddingX="small">
        <SiteSearch autoFocus onBlur={() => setExpanded(false)} />
      </Box>
    </Box>
  ) : (
    <Button onClick={() => setExpanded(true)} tone="neutral">
      <IconSearch />
    </Button>
  );

export const Nav = ({
  setMenuOpened,
}: {
  setMenuOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const [searchExpanded, setSearchExpanded] = useState(false);

  const location = useLocation();

  const { isAuthenticated } = useAuth0();
  const { login } = useLogin();

  return (
    <Box
      className={styles.nav}
      component="nav"
      background="brand"
      paddingX={['xxsmall', 'small']}
    >
      <Hidden below="wide">
        <Link href="/" className={styles.navLink}>
          <LogoLink />
        </Link>
      </Hidden>

      <Hidden above="desktop">
        <div
          className={styles.navLink}
          onClick={() => setMenuOpened((o) => !o)}
        >
          {searchExpanded ? (
            <HiddenVisually>
              <LogoLink showMenu />
            </HiddenVisually>
          ) : (
            <LogoLink showMenu />
          )}
        </div>
      </Hidden>

      <Hidden below="wide">
        <Box className={styles.navSection}>
          <Link href="/" className={styles.navLink}>
            <Text>
              <Box
                component="span"
                className={{
                  [styles.navLinkInner]: true,
                  [styles.navLinkActive]:
                    // TODO: Change when launching
                    !location.pathname.startsWith('/manage') &&
                    !location.pathname.startsWith('/schema-new'),
                }}
              >
                Docs
              </Box>
            </Text>
          </Link>

          <Box
            className={styles.navLink}
            onClick={() => {
              if (!isAuthenticated) {
                login('/manage');
              }
            }}
          >
            <ConditionalWrapper
              condition={isAuthenticated}
              wrapper={(children) => <Link href="/manage">{children}</Link>}
            >
              <Text>
                <Box
                  component="span"
                  className={{
                    [styles.navLinkInner]: true,
                    [styles.navLinkActive]:
                      // TODO: Fix this so that the /authorize page is not considered active or we have a way to determine the page being redirected to
                      location.pathname.startsWith('/manage') ||
                      location.pathname === '/authorize',
                  }}
                >
                  Dashboard
                </Box>
              </Text>
            </ConditionalWrapper>
          </Box>

          <Link
            // TODO: Change when launching
            href="https://developer.seek.com/schema"
            className={styles.navLink}
          >
            <Text>
              <Box
                component="span"
                className={{
                  [styles.navLinkInner]: true,
                  [styles.navLinkActive]:
                    // TODO: Change when launching
                    location.pathname.startsWith('/schema-new'),
                }}
              >
                Schema
              </Box>
            </Text>
          </Link>

          <Link href="https://status.seek.com" className={styles.navLink}>
            <Text>
              <Box component="span" className={styles.navLinkInner}>
                Status
              </Box>
            </Text>
          </Link>
        </Box>
      </Hidden>

      <Box flexGrow={1} className={styles.desktopOnlySearch}>
        <SiteSearch />
      </Box>

      <Hidden above="tablet">
        <ExpandingSiteSearch
          expanded={searchExpanded}
          setExpanded={setSearchExpanded}
        />
      </Hidden>
    </Box>
  );
};
