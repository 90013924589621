
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { InlineCode } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-performance-panel"
    }}>{`Ad Performance Panel`}</h1>
    <p>{`SEEK provides an Ad Performance Panel you can embed within your software to help hirers understand how each job ad is performing.
The widget displays job search views, job ad clicks, application starts and application completes.
It can also provide insights such as market performance, benchmarked against ads posted with similar job titles and locations.`}</p>
    <p>{`Application completes will be visible for:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Job ads using `}<a parentName="p" {...{
            "href": "/use-cases/application-export"
          }}>{`Optimised Apply`}</a>{`,
where candidates complete SEEK’s Apply Form.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Job ads using an external apply form,
if the candidate arrived from SEEK.`}</p>
        <p parentName="li">{`This is derived from `}<a parentName="p" {...{
            "href": "/use-cases/ad-performance/application-complete-signal"
          }}>{`application complete signals`}</a>{` where the candidate source is SEEK.
It does not include scenarios where the candidate arrived from another source but used Apply with SEEK.`}</p>
      </li>
    </ul>
    <p>{`Your software must send an `}<a parentName="p" {...{
        "href": "/use-cases/ad-performance/application-complete-signal"
      }}>{`application complete signal`}</a>{` along with the Ad Performance Panel if it supports posting job ads that `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link out`}</a>{` to an external apply form.`}</p>
    <h2 {...{
      "id": "user-experience"
    }}>{`User experience`}</h2>
    <p>{`The Ad Performance Panel is designed to present data for a single job ad on SEEK. To ensure relevance and context, it should be displayed in a view that directly corresponds to the specific job.
For instance:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Within the “Job Details” view for a specific position`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Alongside existing metrics for an individual job`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`As part of a job list, accessible via a button that reveals the panel in a pop-up`}</p>
      </li>
    </ul>
    <p>{`The Ad Performance Panel can be shown from when the job ad posted for as long as the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` remains available, as per the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads#job-ad-lifecycle"
      }}>{`job ad lifecycle`}</a>{`.
In the event that ad performance data for a requested job ad is unavailable, the panel will manage any errors internally and display appropriate messaging to the hirer.`}</p>
    <p>{`To maintain clarity, we recommend against displaying multiple instances of the Ad Performance Panel on the same page.`}</p>
    <h2 {...{
      "id": "browser-support"
    }}>{`Browser support`}</h2>
    <p>{`The Ad Performance Panel tracks our standard `}<a parentName="p" {...{
        "href": "/introduction/browser-support"
      }}>{`browser support policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-1-include-the-panel"
    }}>{`Step 1: Include the panel`}</h2>
    <p>{`Add the following script tag to the page where the Ad Performance Panel will be displayed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script
  type="text/javascript"
  src="https://integration.seek.com/panels/SeekApi.js"
></script>
`}</code></pre>
    <h2 {...{
      "id": "step-2-render-the-panel"
    }}>{`Step 2: Render the panel`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`SeekApi.render`}</inlineCode>{` function renders an instance of the panel in the specified DOM element, and should be called on page load or if the currently viewed job ad changes.`}</p>
    <p>{`The render function may throw errors if:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{` provided is not a valid `}<inlineCode parentName="p">{`HTMLElement`}</inlineCode></p>
      </li>
    </ul>

    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(containerNode, 'adPerformance', props);
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`containerNode`}</inlineCode>{` –
(DOM Node) The DOM element to render the panel into.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`'adPerformance'`}</inlineCode>{` -
The name of the panel to render.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`props`}</inlineCode>{` –
`}<a parentName="p" {...{
            "href": "#props"
          }}>{`Props`}</a>{` for rendering the panel.`}</p>
      </li>
    </ul>
    <Alert tone="caution" mdxType="Alert">
  <Text mdxType="Text">
    Modifying the <InlineCode mdxType="InlineCode">containerNode</InlineCode> or its children after
    the <InlineCode mdxType="InlineCode">render</InlineCode> function has been called may lead to
    unexpected behaviour.
  </Text>
    </Alert>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Prop`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`getAuthToken`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`() => Promise<string>`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Function to call to retrieve the `}<a parentName="p" {...{
                "href": "#step-3-handle-browser-token-requests"
              }}>{`browser token`}</a>{` to use when calling the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfileId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The identifier of the job ad to be displayed.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`locale`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Specifies the locale to display content in, e.g. `}<inlineCode parentName="p">{`en-AU`}</inlineCode>{`. Supported locales are outlined in the `}<a parentName="p" {...{
                "href": "/graphql/in-practice#content-localisation"
              }}>{`content localisation`}</a>{` documentation.`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "sample-code"
    }}>{`Sample code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(
  document.getElementById('seekAdPerformanceContainerDiv'),
  'adPerformance',
  {
    // Function to call to retrieve the browser token to use when calling the
    // SEEK API, see "Step 3" for more details.
    getAuthToken: () => {
      return Promise.resolve('browser token');
    },
    // Object identifier for the job ad to be displayed.
    positionProfileId: 'seekAnzPublicTest:positionProfile:jobAd:2782PZfXV'
  }
);
`}</code></pre>
    <h2 {...{
      "id": "step-3-handle-browser-token-requests"
    }}>{`Step 3: Handle browser token requests`}</h2>
    <img alt="" data-scoobie-style="none" height="492" src={require('../../../../mermaid/.5d3b8b95ba58a26e3feeb368ac84bf7e1be7bb2a.mmd.svg')} title="Panel authentication" width="905" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The panel loads and executes the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function passed to it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`If a user switches to a job ad from a different SEEK hirer account,
your software should ensure that subsequent invocations of `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` will request a token for the new hirer ID before re-rendering the panel.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorizes the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorized to access a given hirer ID and view performance data for their job ads.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend `}<a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`requests a browser token from the SEEK API`}</a>{` for the appropriate hirer ID and `}<inlineCode parentName="p">{`query:advertisement-performance query:position-profiles query:ontologies`}</inlineCode>{` scopes.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "query:advertisement-performance query:position-profiles query:ontologies",
  "userId": "317665"
}
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`Your backend responds with the browser token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend returns the browser token from the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel completes initialising and displays the relevant ad performance data.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`See our general `}<a parentName="p" {...{
        "href": "/introduction/panels#troubleshooting"
      }}>{`panel troubleshooting`}</a>{` documentation for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;