import { Divider, Heading, Stack } from 'braid-design-system';
import { useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { LocationSelectMap } from 'wingman-fe';
import { BreadCrumbsString } from 'wingman-fe/lib/components/BreadCrumbsString/BreadCrumbsString';
import type { Location } from 'wingman-fe/lib/types/seekApi.graphql';
import { flattenResourceByKey } from 'wingman-fe/lib/utils';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { useEnvironmentConfig } from 'src/hooks/environment';

const SEEK_MEL_CENTER: [number, number] = [-37.8275, 144.9902];

export const LocationSelectMapSection = () => {
  const { seekAnzSchemeId } = useEnvironmentConfig();
  const [location, setLocation] = useState<Location | undefined>();
  return (
    <Stack space="large">
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/locations#nearestlocations">
          Nearest locations
        </SmartTextLink>
      </Heading>
      <Stack space="small">
        <LocationSelectMap
          schemeId={seekAnzSchemeId}
          onLocationSelected={setLocation}
          initialLocation={SEEK_MEL_CENTER}
        />
        {location ? (
          <Stack space="large">
            <BreadCrumbsString
              segments={flattenResourceByKey(location, 'parent').map((x) => ({
                name: x.name,
                key: x.id.value,
              }))}
            />
            <Divider />
            <CopyableText inlineCode>{location.id.value}</CopyableText>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
