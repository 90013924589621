export interface TypeAnnotations {
  title?: string;
  description?: string;
}

export interface ObjectType extends TypeAnnotations {
  type: 'object';
  properties: Record<string, AnyType>;
  required?: string[];
}

export const isObjectType = (anyType: AnyType): anyType is ObjectType =>
  anyType.hasOwnProperty('type') &&
  (anyType as { type: unknown }).type === 'object';

export interface ArrayType extends TypeAnnotations {
  type: 'array';
  items: AnyType;
}

export const isArrayType = (anyType: AnyType): anyType is ArrayType =>
  anyType.hasOwnProperty('type') &&
  (anyType as { type: unknown }).type === 'array';

export interface StringType extends TypeAnnotations {
  type: 'string';
  format?: 'date-time' | 'date' | 'time';
  enum?: string[];
}

export const isStringType = (anyType: AnyType): anyType is StringType =>
  anyType.hasOwnProperty('type') &&
  (anyType as { type: unknown }).type === 'string';

export interface NumberType extends TypeAnnotations {
  type: 'number' | 'integer';
  minimum?: number;
  maximum?: number;
}

export const isNumberType = (anyType: AnyType): anyType is NumberType =>
  anyType.hasOwnProperty('type') &&
  (['number', 'integer'] as unknown[]).includes(
    (anyType as { type: unknown }).type,
  );

export interface BooleanType extends TypeAnnotations {
  type: 'boolean';
}

export const isBooleanType = (anyType: AnyType): anyType is BooleanType =>
  anyType.hasOwnProperty('type') &&
  (anyType as { type: unknown }).type === 'boolean';

export interface NullType extends TypeAnnotations {
  type: 'null';
}

export const isNullType = (anyType: AnyType): anyType is NullType =>
  anyType.hasOwnProperty('type') &&
  (anyType as { type: unknown }).type === 'null';

export interface UnionType extends TypeAnnotations {
  anyOf: AnyType[];
}

export const isUnionType = (anyType: AnyType): anyType is UnionType =>
  anyType.hasOwnProperty('anyOf');

export interface TypeRef extends TypeAnnotations {
  $ref: string;
}

export const isTypeRef = (anyType: AnyType): anyType is TypeRef =>
  anyType.hasOwnProperty('$ref');

export type AnyType =
  | ObjectType
  | ArrayType
  | StringType
  | NumberType
  | BooleanType
  | NullType
  | UnionType
  | TypeRef;

/**
 * Partial TypeScript type for a JSON Schema
 */
export type JsonSchema = AnyType & { definitions: Record<string, AnyType> };
