import {
  Badge,
  Box,
  Divider,
  RadioGroup,
  RadioItem,
  Stack,
} from 'braid-design-system';
import { useState } from 'react';
import { CodeBlock } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

import { PAY_TYPES, PAY_TYPE_MAPPING, type PayType } from './constants';

const LEGACY_PAY_TYPES = [
  'Hourly rate',
  'Annual salary',
  'Annual plus commission',
  'Commission only',
] as const;

type LegacyPayType = (typeof LEGACY_PAY_TYPES)[number];

export const CommissionOnly = () => {
  const id = 'pay-type-commission-only-input';

  const [payType, setPayType] = useState<LegacyPayType>('Annual salary');

  return (
    <BorderCard>
      <RadioGroup
        aria-label="Pay type"
        id={`${id}-pay-type`}
        onChange={(event) =>
          setPayType(event.currentTarget.value as LegacyPayType)
        }
        size="small"
        value={payType}
      >
        {LEGACY_PAY_TYPES.map((value) => (
          <RadioItem
            badge={
              value === 'Commission only' ? (
                <Badge tone="critical">Remove</Badge>
              ) : undefined
            }
            disabled={value === 'Commission only'}
            label={
              <Box component={value === 'Commission only' ? 's' : 'span'}>
                {value}
              </Box>
            }
            key={value}
            value={value}
          />
        ))}
      </RadioGroup>
    </BorderCard>
  );
};

interface PayTypeRadioGroupProps {
  id: string;
  payType: PayType;
  setPayType: (payType: PayType) => void;

  monthlyNew?: boolean;
}

export const PayTypeRadioGroup = ({
  id,
  monthlyNew,
  payType,
  setPayType,
}: PayTypeRadioGroupProps) => (
  <RadioGroup
    aria-label="Pay type"
    id={`${id}-pay-type`}
    onChange={(event) => setPayType(event.currentTarget.value as PayType)}
    size="small"
    value={payType}
  >
    {PAY_TYPES.map((value) => (
      <RadioItem
        badge={
          monthlyNew && value === 'Monthly salary' ? (
            <Badge tone="promote">New</Badge>
          ) : undefined
        }
        label={value}
        key={value}
        value={value}
      />
    ))}
  </RadioGroup>
);

interface MonthlyProps {
  hideNew?: boolean;
}

export const Monthly = ({ hideNew }: MonthlyProps) => {
  const id = 'pay-type-monthly-input';

  const [payType, setPayType] = useState<PayType>('Monthly salary');

  const { basisCode, intervalCode } = PAY_TYPE_MAPPING[payType];

  return (
    <BorderCard>
      <Stack space="large">
        <PayTypeRadioGroup
          id={id}
          monthlyNew={!hideNew}
          payType={payType}
          setPayType={setPayType}
        />

        <Divider />

        <CodeBlock label="RemunerationPackageInput" language="json">
          {JSON.stringify(
            {
              basisCode,
              ranges: [{ intervalCode }],
            },
            null,
            2,
          )}
        </CodeBlock>
      </Stack>
    </BorderCard>
  );
};
