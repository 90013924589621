
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The criteria to filter webhook requests by.`}</p>
    <p>{`These are `}<a parentName="p" {...{
        "href": "/schema-new/named-type/WebhookRequest",
        "title": "An HTTP request to a `WebhookSubscription`"
      }}><inlineCode parentName="a">{`WebhookRequest`}</inlineCode></a>{`-specific extensions on top of the standard pagination arguments `}<inlineCode parentName="p">{`before`}</inlineCode>{`, `}<inlineCode parentName="p">{`after`}</inlineCode>{`, `}<inlineCode parentName="p">{`first`}</inlineCode>{` and `}<inlineCode parentName="p">{`last`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;