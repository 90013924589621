import {
  Actions,
  Button,
  Column,
  Columns,
  Dialog,
  Heading,
  IconPersonAdd,
} from 'braid-design-system';
import { useState } from 'react';

import { NoPermissionAlert } from 'src/components/NoPermissionAlert/NoPermissionAlert';
import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { usePermissions } from 'src/hooks/auth';

import { useUserGuide } from '../userGuide/UserGuide';
import { UserGuideToggle } from '../userGuide/UserGuideToggle';

import { UsersProvider } from './UsersContext';
import { NewUserForm } from './components/NewUserForm/NewUserForm';
import { UsersList } from './components/UsersList/UsersList';

export const UsersPage = () => {
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const { permissions } = usePermissions();
  const { toggleUserGuide } = useUserGuide();

  const hasReadAccess = permissions.includes('query:users');
  const hasWriteAccess = permissions.includes('mutate:users');

  return (
    <PageWrapper
      heading={
        <Columns alignY="center" space="medium">
          <Column>
            <Heading level="2" weight="weak">
              Users
            </Heading>
          </Column>

          <Column width="content">
            <UserGuideToggle toggleUserGuide={() => toggleUserGuide('users')} />
          </Column>

          {!hasWriteAccess ? null : (
            <Column width="content">
              <Actions>
                <Button
                  size="small"
                  variant="solid"
                  onClick={() => setShowNewUserDialog(true)}
                  icon={<IconPersonAdd />}
                >
                  Add user
                </Button>
              </Actions>
            </Column>
          )}
        </Columns>
      }
    >
      {!hasReadAccess ? (
        <PageGutter>
          <NoPermissionAlert subject="view users" />
        </PageGutter>
      ) : (
        <UsersProvider>
          <UsersList />

          <Dialog
            id="newUserDialog"
            open={showNewUserDialog}
            onClose={() => setShowNewUserDialog(false)}
            title="Add new user"
          >
            <NewUserForm closeDialog={() => setShowNewUserDialog(false)} />
          </Dialog>
        </UsersProvider>
      )}
    </PageWrapper>
  );
};
