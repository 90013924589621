
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A signal that an action has been performed on the SEEK platform that may be of interest to an integration partner.`}</p>
    <p>{`Events can be delivered via:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Webhook, using the `}<a parentName="p" {...{
            "href": "/schema-new/mutation/createWebhookSubscription",
            "title": "Creates a new webhook subscription"
          }}><inlineCode parentName="a">{`createWebhookSubscription`}</inlineCode></a>{` mutation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`GraphQL polling, using the paginated `}<a parentName="p" {...{
            "href": "/schema-new/query/events",
            "title": "A page of events matching the specified criteria"
          }}><inlineCode parentName="a">{`events`}</inlineCode></a>{` query`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;