import {
  Box,
  Divider,
  IconChevron,
  Stack,
  Strong,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import { type ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { InternalLink } from 'scoobie';

import type { MdxTemplateProps } from 'src/types';

import { type TocItem, TocRenderer } from './TocRenderer';

import * as styles from './MarkdownToc.css';

const TocBox = ({
  children,
  width,
}: {
  children: ReactNode;
  width?: 'sidebar';
}) => (
  <Box
    background="body"
    borderRadius="large"
    className={[styles.tocBox, width === 'sidebar' && styles.sidebarContainer]}
    padding="gutter"
  >
    {children}
  </Box>
);

const BackToTop = () => {
  const onClick = () => window?.scrollTo({ behavior: 'smooth', top: 0 });

  return (
    <Box key="backToTop" paddingY="xxsmall">
      <TextLinkButton icon={<IconChevron direction="up" />} onClick={onClick}>
        <Box className={styles.normalWeight} component="span">
          Back to top
        </Box>
      </TextLinkButton>
    </Box>
  );
};

const CustomTocItem = ({
  children,
  id,
  level,
  pageTitle,
}: TocItem & { pageTitle?: boolean }) => {
  const { hash, pathname, search } = useLocation();
  const [rerender, setRerender] = useState(false);

  // Use `pathname` to clear the hash when clicking the page title
  const href = pageTitle ? `${pathname}${search}` : `#${id}`;

  useEffect(() => setRerender(true), []);

  const isActive = rerender && hash === (pageTitle ? '' : `#${id}`);

  return (
    <InternalLink
      className={{
        [styles.textLink]: true,
        [styles.tocItemActive]: isActive,
      }}
      href={href}
      reset={false}
    >
      <Box className={styles.tocItemActiveIndicator}>
        <Box className={styles.tocItemIndentation[level]} paddingY="xxsmall">
          {pageTitle ? <Strong>{children}</Strong> : children}
        </Box>
      </Box>
    </InternalLink>
  );
};

/**
 * Renders a Markdown page's table of contents as a sidebar
 *
 * This is used on `wide` layouts where we have an additional righthand column
 * the same width as the menu.
 */
export const MarkdownTocSidebar = ({ document }: MdxTemplateProps) => (
  <TocBox width="sidebar">
    <TocRenderer document={document}>
      {(toc) => {
        const filteredToc = toc.filter(
          (item) => item.level >= 2 && item.level <= 4,
        );

        return (
          <Text>
            <Stack space="small">
              {filteredToc.length === 0 ? undefined : (
                <>
                  <Stack space="none">
                    {filteredToc.map((props) => (
                      <CustomTocItem
                        {...props}
                        // We don't include the page title in the sidebar so shift
                        // everyone over by one level
                        level={(props.level - 1) as 1 | 2 | 3}
                        key={props.id}
                      />
                    ))}
                  </Stack>

                  <Divider />
                </>
              )}

              <BackToTop />
            </Stack>
          </Text>
        );
      }}
    </TocRenderer>
  </TocBox>
);
