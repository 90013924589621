
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A non-negative integer in the minor currency unit for the ISO currency code.`}</p>
    <p>{`For example, this is the number of cents in dollar-denominated currencies.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;