
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The URI that the candidate accesses to apply for the position.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` is available, this field can be omitted.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;