import { Divider, IconInfo, Text } from 'braid-design-system';
import type { GraphQLScalarType } from 'graphql';
import { InlineCode } from 'scoobie';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSeenIn } from '../components/TypeSeenIn';

const COMMON_SCALARS = ['Boolean', 'Float', 'ID', 'Int', 'String'];

export const ScalarSchemaPage = ({
  scalarType,
}: {
  scalarType: GraphQLScalarType;
}) => (
  <BaseSchemaPage
    titleText={scalarType.name}
    title={<InlineCode weight="weak">{scalarType.name}</InlineCode>}
    subHeading={
      <Text icon={<IconInfo />} size="small" weight="medium" tone="secondary">
        Scalar
      </Text>
    }
  >
    <SchemaMarkdown type={scalarType.name} />

    <Divider />

    {COMMON_SCALARS.includes(scalarType.name) ? undefined : (
      <TypeSeenIn name={scalarType.name} />
    )}
  </BaseSchemaPage>
);
