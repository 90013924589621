import { Alert, Heading, IconDocumentBroken, Text } from 'braid-design-system';
import { useLocation } from 'react-router-dom';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';

export const ClientErrorPage = () => {
  const location = useLocation();

  return (
    <PageWrapper
      heading={
        <Heading icon={<IconDocumentBroken />} level="2" weight="weak">
          {location.pathname}
        </Heading>
      }
    >
      <PageGutter>
        <Alert tone="caution">
          <Text>
            We encountered an error rendering this page’s content. Contact SEEK
            if the problem persists.
          </Text>
        </Alert>
      </PageGutter>
    </PageWrapper>
  );
};
