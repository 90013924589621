
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Updates the status of a position opening.`}</p>
    <p>{`This status is provided to help hirers manage position openings.
The SEEK API does not use the position opening's status itself.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;