
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether the cover image from the provided `}<a parentName="p" {...{
        "href": "/schema-new/named-type/AdvertisementBranding",
        "title": "Advertisement branding details and images"
      }}><inlineCode parentName="a">{`AdvertisementBranding`}</inlineCode></a>{` will be visible on the job ad.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;