
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The HTML content of the description.`}</p>
    <p>{`The maximum length differs by `}<inlineCode parentName="p">{`descriptionId`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AdvertisementDetails`}</inlineCode>{` has a maximum length of 15,000 characters.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SearchBulletPoint`}</inlineCode>{` has a maximum length of 80 characters per bullet point.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SearchSummary`}</inlineCode>{` has a maximum length of 150 characters.`}</p>
      </li>
    </ul>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme supports the following HTML tags in `}<inlineCode parentName="p">{`AdvertisementDetails`}</inlineCode>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`a`}</inlineCode>{` (HTTPS only. Available on a per hirer basis. Hirer must contact SEEK to enable.)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`b`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`br`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`div`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`em`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`h2`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`li`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ol`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`p`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`strong`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`ul`}</inlineCode></p>
          </li>
        </ul>
        <p parentName="li">{`Other descriptions will have all HTML tags stripped.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;