
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The high-level HTTP result of the webhook attempts to retrieve.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/WebhookRequest#/field/descriptionCode",
        "title": "The high-level description of the HTTP request's result"
      }}><inlineCode parentName="a">{`WebhookRequest.descriptionCode`}</inlineCode></a>{` documentation for a list of possible description codes.`}</p>
    <p>{`If this is not provided then all attempts will be returned regardless of their result.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;