import { Box, FieldLabel, Stack, Strong, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { BorderCard } from 'src/components/BorderCard/BorderCard';
import { TooltipField } from 'src/components/TooltipField/TooltipField';

import * as styles from './styles.css';

interface PositionProps {
  name: string;
  requester: string;
  title: ReactNode;
  location: string;
}

const POSITIONS: PositionProps[] = [
  {
    name: 'Acme Corp',
    requester: 'Gabriella H',
    title: (
      <>
        Customer Mobility <Strong>Direct</Strong>or
      </>
    ),
    location: 'Melbourne',
  },
  {
    name: 'Contoso',
    requester: 'Lucy F',
    title: (
      <>
        Customer Mobility <Strong>Direct</Strong>or
      </>
    ),
    location: 'Auckland',
  },
  {
    name: 'Vandelay Industries',
    requester: 'Gemma B',
    title: (
      <>
        Assistant to the Product <Strong>Direct</Strong>or
      </>
    ),
    location: 'Perth',
  },
];

const Position = ({ name, requester, title, location }: PositionProps) => (
  <Box className={styles.hoverListItem} paddingX="medium" paddingY="small">
    <Stack space="small">
      <Text>
        <TooltipField name="UnpostedPositionProfile.positionTitle">
          {title}
        </TooltipField>{' '}
        ({location})
      </Text>

      <Text size="small" tone="secondary">
        Reference #,{' '}
        <TooltipField name="UnpostedPositionProfile.profileName">
          {name}
        </TooltipField>
        ,{' '}
        <TooltipField name="PositionOpening.postingRequester…">
          {requester}
        </TooltipField>
      </Text>
    </Stack>
  </Box>
);

export const PositionSelector = () => (
  <Stack space="xsmall">
    <FieldLabel
      htmlFor="positionDropdown"
      label="Assign candidate to position"
    />

    <Box
      background="neutralSoft"
      borderRadius="standard"
      boxShadow="borderNeutralLight"
      padding="medium"
    >
      <Text>
        <Box
          background="neutralSoft"
          component="input"
          disabled
          id="positionDropdown"
          userSelect="none"
          value="Direct"
        />
      </Text>
    </Box>

    <BorderCard paddingX="none" paddingY="small">
      <Stack space="none">
        {POSITIONS.map((position, index) => (
          <Position key={index} {...position} />
        ))}
      </Stack>
    </BorderCard>
  </Stack>
);
