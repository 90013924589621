
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A page of hirers that have an active relationship with the requesting partner.`}</p>
    <p>{`This will not return agencies; it's not possible to have a relationship with an agency.`}</p>
    <p>{`A maximum of 100 hirers can be returned in a single page.
Additional hirers can be queried using a pagination cursor.`}</p>
    <p>{`The result list is ordered alphabetically by the hirer's name.`}</p>
    <p>{`This query accepts browser tokens that include the `}<inlineCode parentName="p">{`query:organizations`}</inlineCode>{` scope.
It will only return the single hirer that the browser token is scoped to.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;