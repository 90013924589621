
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The list of profiles associated with with the candidate.`}</p>
    <p>{`Uploaded candidates sourced from the `}<a parentName="p" {...{
        "href": "/schema-new/mutation/uploadCandidate",
        "title": "Uploads a candidate and their profile into SEEK's systems"
      }}><inlineCode parentName="a">{`uploadCandidate`}</inlineCode></a>{` mutation have a single profile.`}</p>
    <p>{`SEEK candidates have a profile per submitted application.
This field exposes up to 10 recent applications submitted by the candidate.`}</p>
    <p>{`We recommend querying specific applications by their `}<a parentName="p" {...{
        "href": "/schema-new/named-type/CandidateProfile#/field/profileId",
        "title": "The identifier for the `CandidateProfile`"
      }}><inlineCode parentName="a">{`CandidateProfile.profileId`}</inlineCode></a>{`s for the Application Export use case.`}</p>
    <p>{`This field is redacted and an empty/filtered list is returned when a candidate or job application is deleted.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;