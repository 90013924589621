import type { Stack } from 'braid-design-system';
import {
  Children,
  type ComponentProps,
  type FunctionComponent,
  type ReactNode,
} from 'react';

import { WrapperRenderer } from 'src/components/mdx/WrapperRenderer';

interface HeadingElement {
  props: {
    children: string;
    id: string;
    mdxType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  };
}

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface MdxElement {
  props: {
    mdxType: string;
  };
}

export type Toc = TocItem[];

export interface TocItem {
  children: ReactNode;
  id: string;
  level: HeadingLevel;
}

const headingToLevel = (component: HeadingElement): HeadingLevel =>
  Number(component.props.mdxType.replace(/^h/, '')) as HeadingLevel;

const isObject = (value: unknown): value is Record<PropertyKey, unknown> =>
  typeof value === 'object' && value !== null;

const isString = (value: unknown): value is string => typeof value === 'string';

const isMdxElement = (component: unknown): component is MdxElement =>
  isObject(component) &&
  isObject(component.props) &&
  isString(component.props.mdxType);

const isHeading = (component: unknown): component is HeadingElement =>
  isMdxElement(component) && /^h[1-6]$/.test(component.props.mdxType);

const wrapperToToc = ({
  children,
}: Pick<ComponentProps<typeof Stack>, 'children'>): Toc =>
  Children.toArray(children)
    .filter(isHeading)
    .map((incorrectlyTypedChild) => {
      // TypeScript isn't respecting our `isHeading` type guard here ☹️
      const child = incorrectlyTypedChild as unknown as HeadingElement;

      return {
        children: child.props.children,
        id: child.props.id,
        level: headingToLevel(child),
      };
    });

export const TocRenderer = ({
  children: render,
  document: Document,
}: {
  children: (toc: Toc) => ReturnType<FunctionComponent>;
  document: MDX.Document;
}) => (
  <WrapperRenderer document={Document}>
    {({ children }) => {
      const toc = wrapperToToc({ children });

      return render(toc);
    }}
  </WrapperRenderer>
);
