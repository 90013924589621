import "src/components/MenuItem/MenuItem.css.ts.vanilla.css!=!../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.7.4_babel-plugin-macros@3.1.0_terser@5._wox2o4hrol5mz3rhrbav7ttewi/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MenuItem/MenuItem.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8WUzXKCMBCA7z7FHqUWSsCfig/TCUmAKLBMiFbb6bt3Gm0nUbTYHrhlJvvtl2R3E9DloV3vQ3gfATSUc1nnfikynQCjJRvvqBr7/gvZrOdKqNiDCYxJEIkKJuDuRZ7nrawkSuaFTi6CVqOPUXCUEiNlWKI6jyPKDTwtohvErJOIDZGi4kKd7uVybx60WEp+M93UZOGybUp6SCAtkW3s7aTAnVBHFWWbXOG25n7nOaPGSZxkyLbtH8iZYSqqcllfLxjx4AFCm5v354jNLfpzsc099+ecJ1/25xY2R51OTlFrrM5fMnYaVWPTFXBR3W/BI5wVzzHfV8XUMLRtBNO+olpiAgSegFz3p9f8aT8/2n7Wb+6jr7kPm/3vA+70DL8j+39+FUcqhpBmQ0jzIaQ/P2th9aHToIU5Voa19l9FV76pGdhPyamqE3oGAAA=\"}!../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.7.4_babel-plugin-macros@3.1.0_terser@5._wox2o4hrol5mz3rhrbav7ttewi/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeIndicator = 'a9ysjx3';
export var activeLink = 'a9ysjx1';
export var expander = 'a9ysjxb';
export var hierarchy = {'1':'a9ysjx5','2':'a9ysjx6','3':'a9ysjx7','4':'a9ysjx8','5':'a9ysjx9'};
export var horizontalPadding = {'1':'a9ysjxc','2':'a9ysjxd','3':'a9ysjxe','4':'a9ysjxf','5':'a9ysjxg'};
export var iconSpace = 'var(--_1kj6rer2)';
export var link = 'a9ysjx4';
export var linkInner = 'a9ysjxa';
export var menuSpace = 'a9ysjx0';
export var pageIcon = 'a9ysjx2';
export var textFocus = 'a9ysjxh';