import { List, Stack, Text, TextLink } from 'braid-design-system';
import { InlineCode, Table, TableRow } from 'scoobie';

export const JobPostingAcceptanceCriteria = () => (
  <Table header={['Feature', 'Acceptance criteria']}>
    <TableRow>
      <Text>Locations</Text>
      <List>
        <Text>
          Ability to dynamically search for and select a granular location (i.e.
          suburb level) for both ANZ and Asia locations. This applies regardless
          of the markets supported by your software.
        </Text>
        <Text>
          (Optional) Ability to consume salary currency returned by SEEK API as
          part of the location query
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Job categories</Text>
      <List>
        <Text>Ability to dynamically query and select a job category</Text>
        <Text>
          <InlineCode>jobCategories</InlineCode> query successfully passes
          through the <InlineCode>positionLocation</InlineCode> variable (the
          list of job categories may vary per region, even between AU and NZ)
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Ad selection</Text>
      <Stack space="large">
        <Text>
          The ad selection revision should support the following, regardless of
          whether you support APAC markets or ANZ only
        </Text>
        <List>
          <Text>Ability to render up to 4 ad products</Text>
          <Text>
            Ability to dynamically surface ad products based on job location to
            enable the different ad types offered in ANZ and Asia
          </Text>
          <Text>
            Ability to dynamically enable features based on the selected ad
            product and what is allowed by the SEEK API (e.g. “Basic” ad
            supports brand selection of logo only, without cover image and key
            selling points)
          </Text>
          <Text>
            For job ad edits, availability of upgrade / downgrade options
            entirely based on returned ad products from SEEK API (i.e. no
            hardcoded logic)
          </Text>
          <Text>
            Ability to dynamically surface ad pricing currency based on hirer
            location (e.g. AUD for an Australian hirer, MYR for a Malaysian
            hirer)
          </Text>
        </List>
      </Stack>
    </TableRow>
    <TableRow>
      <Text>Questionnaire Panel</Text>
      <List>
        <Text>
          Ability to view and interact with the revised questionnaire panel
        </Text>
        <Text>Ability to select the hirer’s Privacy Policy</Text>
        <Text>
          Questionnaire is saved and its ID is submitted when job ad is
          previewed
        </Text>
        <Text>
          Questionnaire is saved and its ID is submitted when job ad is posted
        </Text>
        <Text>
          When editing a job ad with an existing questionnaire, the
          Questionnaire Panel displays an error noting that questions are not
          editable once the job ad is live.
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Brand Preview</Text>
      <Stack space="large">
        <Text>
          This is optional if brand preview was previously implemented or if you
          are implementing Job Ad Preview
        </Text>
        <List>
          <Text>
            Ability to preview all available branding for a hirer (refer to{' '}
            <TextLink href="/use-cases/job-posting/user-experience-guide">
              User Experience guide
            </TextLink>
            )
          </Text>
        </List>
      </Stack>
    </TableRow>
    <TableRow>
      <Text>Job Ad Preview</Text>
      <List>
        <Text>
          Ability to preview the entire job ad and job search details in desktop
          and mobile mode, for both draft and update workflows
        </Text>
        <Text>
          Ability to provide a preview of the screening questions included in
          the job ad
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Salary type</Text>
      <List>
        <Text>
          (Asia only) Ability to select <InlineCode>Monthly</InlineCode> salary
          type
        </Text>
        <Text>
          <InlineCode>CommissionOnly</InlineCode> salary type removed
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Salary currency</Text>
      <List>
        <Text>
          (Asia only) Salary currency is pre-selected or defaults to currency of
          job location (e.g. HKD if location is set to{' '}
          <InlineCode>Wanchai HK</InlineCode>)
        </Text>
        <Text>
          (Asia only) Ability to select from a list of SEEK supported currencies
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Multiple hirer accounts</Text>
      <List>
        <Text>
          Ability to select from multiple SEEK hirer accounts when posting job
          ads
        </Text>
        <Text>Unable to change the hirer account for a live job ad</Text>
      </List>
    </TableRow>
  </Table>
);
