import { isObjectType } from 'src/utils/jsonSchema';

import { webhookSchema } from '../webhookSchema';

import { OopsSchemaPage } from './OopsSchemaPage';
import { WebhookObjectSchemaPage } from './WebhookObjectSchemaPage';

export const WebhookSchemaRootPage = () => {
  if (!isObjectType(webhookSchema)) {
    return <OopsSchemaPage />;
  }

  return (
    <WebhookObjectSchemaPage
      heading={webhookSchema.title || 'Root object'}
      objectType={webhookSchema}
    />
  );
};
