
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A download URL for the attachment.`}</p>
    <p>{`This URL accepts partner tokens or browser tokens that include the `}<inlineCode parentName="p">{`download:candidate-profile-attachments`}</inlineCode>{` scope.
This is guaranteed to be an absolute URL with an origin of `}<inlineCode parentName="p">{`https://graphql.seek.com`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;