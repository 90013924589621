
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { IconTick, IconClear } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "step-6-send-application-complete-signal"
    }}>{`Step 6: Send application complete signal`}</h1>
    <p>{`The application complete signal signifies that a job application has been submitted in your recruitment software.
It applies to positions that are either advertised on SEEK or have the Apply with SEEK button enabled.
Note that it does not imply a candidate source or Apply with SEEK usage;
`}<a parentName="p" {...{
        "href": "/use-cases/ad-performance"
      }}>{`Ad Performance`}</a>{` analytics will only attribute completed applications where the candidate source is SEEK.`}</p>
    <p>{`Once a candidate has completed and submitted your apply form,
your software must send an application complete signal to SEEK to signify form submission.
The application complete signal requires a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` that your software received from the link out or Apply with SEEK flow.`}</p>
    <h2 {...{
      "id": "handling-multiple-seek-tokens"
    }}>{`Handling multiple `}<inlineCode parentName="h2">{`seek-token`}</inlineCode>{`s`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` is supplied on a query string to your software in two ways:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`When the candidate links out from SEEK to your apply form (see `}<a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/display-button"
          }}>{`Step 1: Display button`}</a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After the candidate has authorized access to their profile (see `}<a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/handle-authorization"
          }}>{`Step 2: Handle authorization`}</a>{`)`}</p>
      </li>
    </ol>
    <p>{`If your software has received both, it should retain only the second `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` from the Apply with SEEK authorization flow.`}</p>

    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Candidate source`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Using Apply with SEEK`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Send signals`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Token handling`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Use new `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` from the `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/handle-authorization"
              }}>{`Authorization flow`}</a>{` step to send signals`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Use original `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` from linking out to send signals`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Other source`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Use new `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` from the `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/handle-authorization"
              }}>{`Authorization flow`}</a>{` step to send signals`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Other source`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`No `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` will have been received by your software, do not send signals`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "sending-the-application-complete-signal"
    }}>{`Sending the application complete signal`}</h2>
    <p>{`To send the application complete signal, use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode>{` mutation`}</a>{`, passing the `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` as the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal/argument/token"
      }}><inlineCode parentName="a">{`token`}</inlineCode>{` argument`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: SendApplicationSignalInput!) {\\n  sendApplicationSignal(input: $input)\\n}\",\"position\":{\"start\":{\"line\":36,\"column\":1,\"offset\":2432},\"end\":{\"line\":40,\"column\":4,\"offset\":2536},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    // Supported values are: `global` and `globalPublicTest`\\n    \\\"schemeId\\\": \\\"globalPublicTest\\\",\\n\\n    // Details of the type of signal being sent\\n    \\\"typeCode\\\": \\\"ApplicationComplete\\\",\\n\\n    // The seek-token value received from SEEK during the authorization flow\\n    // (or initial arrival on site if no Apply with SEEK authorization occurred)\\n    \\\"token\\\": \\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n  }\\n}\",\"position\":{\"start\":{\"line\":42,\"column\":1,\"offset\":2538},\"end\":{\"line\":56,\"column\":4,\"offset\":3135},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "SendApplicationSignalInput!)": true,
        "{\\n": true,
        "": true,
        "sendApplicationSignal(input:": true,
        "$input)\\n}\",\"position\":{\"start\":{\"line\":36,\"column\":1,\"offset\":2432},\"end\":{\"line\":40,\"column\":4,\"offset\":2536},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "//": true,
        "Supported": true,
        "values": true,
        "are:": true,
        "`global`": true,
        "and": true,
        "`globalPublicTest`\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"globalPublicTest\\\",\\n\\n": true,
        "Details": true,
        "of": true,
        "the": true,
        "type": true,
        "signal": true,
        "being": true,
        "sent\\n": true,
        "\\\"typeCode\\\":": true,
        "\\\"ApplicationComplete\\\",\\n\\n": true,
        "The": true,
        "seek-token": true,
        "value": true,
        "received": true,
        "from": true,
        "SEEK": true,
        "during": true,
        "authorization": true,
        "flow\\n": true,
        "(or": true,
        "initial": true,
        "arrival": true,
        "on": true,
        "site": true,
        "if": true,
        "no": true,
        "Apply": true,
        "with": true,
        "occurred)\\n": true,
        "\\\"token\\\":": true,
        "\\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":42,\"column\":1,\"offset\":2538},\"end\":{\"line\":56,\"column\":4,\"offset\":3135},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: SendApplicationSignalInput!) {
  sendApplicationSignal(input: $input)
}
`}</code></pre>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p><a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode></a>{` will return errors consistent with our documented `}<a parentName="p" {...{
        "href": "/graphql/error-responses"
      }}>{`GraphQL error responses`}</a>{`.
If your software receives authentication or transient server errors, it should retry sending of application complete signals as described in the documentation.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "/graphql/error-responses#bad_user_input"
      }}><inlineCode parentName="a">{`BAD_USER_INPUT`}</inlineCode></a>{` error may occur if there is a structural issue with the token.
Ensure the token being sent in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode></a>{` exactly matches a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received with no additional encoding nor whitespace.`}</p>
    <p>{`We recommend that you log full requests and responses to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode></a>{` mutation during development to facilitate debugging.
Use a unique `}<a parentName="p" {...{
        "href": "/graphql/in-practice#tracing-requests"
      }}><inlineCode parentName="a">{`X-Request-Id`}</inlineCode></a>{` for each request to assist in support investigations.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;