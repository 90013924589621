
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of identifiers for the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/HiringOrganization",
        "title": "An organization hiring for an open position"
      }}><inlineCode parentName="a">{`HiringOrganization`}</inlineCode></a>{`s that have the position.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme requires exactly one element that matches the position opening's `}<a parentName="p" {...{
            "href": "/schema-new/named-type/PostingRequester#/field/id",
            "title": "The identifier for the `HiringOrganization` that owns the position opening"
          }}><inlineCode parentName="a">{`PostingRequester.id`}</inlineCode></a>{`.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;