
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The position profile that the history item relates to.`}</p>
    <p>{`This is null for interactions that are not specific to an individual position,
such as a general interview with a recruiter.
It will also be null where an unknown `}<inlineCode parentName="p">{`profileId`}</inlineCode>{` was provided or the position profile has since been deleted.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;