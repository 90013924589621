
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The type of the ad product.`}</p>
    <p>{`Currently, three codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Classic`}</inlineCode>{` indicates a Classic ad.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`StandOut`}</inlineCode>{` indicates a StandOut ad.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Premium`}</inlineCode>{` indicates a Premium ad.`}</p>
      </li>
    </ul>
    <p>{`This value should be provided as `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PostingInstruction#/field/seekAnzAdvertisementType",
        "title": "A SEEK ANZ advertisement type code"
      }}><inlineCode parentName="a">{`PostingInstruction.seekAnzAdvertisementType`}</inlineCode></a>{` when posting or updating the job ad.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;