
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile",
        "title": "A profile of a position opening"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` that was closed.`}</p>
    <p>{`This may return null if the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile",
        "title": "A profile of a position opening"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` has been closed for an extended period of time.`}</p>
    <p>{`This field is only accessible while you have an active `}<inlineCode parentName="p">{`ApplicationExport`}</inlineCode>{` or `}<inlineCode parentName="p">{`JobPosting`}</inlineCode>{` relationship with the hirer.
If these relationships have been removed, it will return null along with a `}<inlineCode parentName="p">{`FORBIDDEN`}</inlineCode>{` error.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;