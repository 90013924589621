import { Divider, Inline, Notice, Stack, Text } from 'braid-design-system';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { InfiniteScrollingList } from 'src/components/InfiniteScrollingList/InfiniteScrollingList';
import {
  PaginationDirectionPicker,
  usePaginationDirectionState,
} from 'src/components/PaginationDirectionPicker/PaginationDirectionPicker';
import { Query } from 'src/components/Query/Query';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import type {
  WebhookSubscriptionReplaysQuery,
  WebhookSubscriptionReplaysQueryVariables,
} from 'src/types/graphql';

import { StatusCodePicker, useStatusCodeState } from './StatusCodePicker';
import { SubscriptionReplay } from './SubscriptionReplay';
import { GET_WEBHOOK_SUBSCRIPTION_REPLAYS } from './queries';

interface Props {
  subscriptionId: string;
}

export const SubscriptionReplayList = ({ subscriptionId }: Props) => {
  const paginationDirectionState = usePaginationDirectionState();
  const statusCodeState = useStatusCodeState();

  const { firstOrLast, processPageInfo } = paginationDirectionState;
  const { statusCode } = statusCodeState;

  return (
    <Stack space="none">
      <GutterBox>
        <Inline align="right" alignY="center" space="gutter">
          <StatusCodePicker {...statusCodeState} />

          <PaginationDirectionPicker
            idPrefix="subscriptionReplay"
            {...paginationDirectionState}
          />
        </Inline>
      </GutterBox>

      <Divider />

      <Query<
        WebhookSubscriptionReplaysQuery,
        WebhookSubscriptionReplaysQueryVariables
      >
        errorMessage="We couldn’t load your subscription’s replays."
        query={GET_WEBHOOK_SUBSCRIPTION_REPLAYS}
        variables={{
          [firstOrLast]: 10,
          statusCode: statusCode === 'All' ? undefined : statusCode,
          subscriptionId,
        }}
        wrapperComponent={GutterBox}
      >
        {({ data, fetchMore }) => {
          if (!data.webhookSubscription) {
            return (
              <GutterBox>
                <Notice tone="critical">
                  <Text>We couldn’t find this subscription.</Text>
                </Notice>
              </GutterBox>
            );
          }

          const {
            webhookSubscriptionReplays: { edges, pageInfo },
          } = data.webhookSubscription;

          const { shouldLoadMore, variables } = processPageInfo(pageInfo);

          const loadMore = () => fetchMore({ variables });

          return (
            <InfiniteScrollingList
              loadMore={loadMore}
              shouldLoadMore={shouldLoadMore}
              isEmpty={edges.length === 0}
              subject="webhook replays"
              items={edges.map(({ node }) => ({
                key: node.id.value,
                element: (
                  <ScrollingXBox>
                    <GutterBox>
                      <SubscriptionReplay subscriptionReplay={node} />
                    </GutterBox>
                  </ScrollingXBox>
                ),
              }))}
            />
          );
        }}
      </Query>
    </Stack>
  );
};
