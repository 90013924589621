
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "managing-job-ads"
    }}>{`Managing job ads`}</h1>
    <p>{`Job ads are represented in the SEEK API by `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` objects.
The position’s profile consists of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Information on how to present the `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/position-openings"
          }}>{`position opening`}</a>{` to a candidate including its title, description and salary.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/locations"
          }}>{`location`}</a>{` and `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories"
          }}>{`job category`}</a>{` of the position.
These are displayed to the candidate and help `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection"
          }}>{`determine the job ad’s price`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Posting instructions including the `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection"
          }}>{`SEEK ad product`}</a>{` and `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/application-methods"
          }}>{`application method`}</a>{`.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "job-ad-lifecycle"
    }}>{`Job ad lifecycle`}</h2>
    <img alt="" data-scoobie-style="none" height="601" src={require('../../../../mermaid/.c70af7ce3300d791efaeb23d7650e64bebc28362.mmd.svg')} title="Job ad lifecycle" width="605" />
    <p>{`You can post a job ad by passing `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` data to one of the posting mutations.
This starts an asynchronous process to place a live job ad on the SEEK job board.`}</p>
    <p>{`The majority of job ads are automatically posted within a minute.
However, in certain circumstances SEEK will manually review a job ad before it’s posted.
In either case, a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad#events"
      }}><inlineCode parentName="a">{`PositionProfilePosted`}</inlineCode>{` event`}</a>{` will be emitted immediately after the job ad goes live.`}</p>
    <p>{`The job ad will automatically close on its scheduled `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostingInstruction/field/end"
      }}><inlineCode parentName="a">{`end`}</inlineCode>{` date`}</a>{`.
If an end date wasn’t `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CreatePostingInstructionInput/field/end"
      }}>{`specified during posting`}</a>{` it will default to the maximum period of 30 days.
Scheduled closure is not instantaneous, and typically occurs within 24 hours.`}</p>
    <p>{`You can explicitly close a job ad earlier using the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/closePostedPositionProfile"
      }}><inlineCode parentName="a">{`closePostedPositionProfile`}</inlineCode>{` mutation`}</a>{`.
Once the job ad is closed it will be removed from the SEEK job board and a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad#events"
      }}><inlineCode parentName="a">{`PositionProfileClosed`}</inlineCode>{` event`}</a>{` will be emitted.`}</p>
    <p>{`SEEK job ads are priced for a fixed time period of 30 days.
You can close a job ad earlier but the SEEK hirer will still be charged full price.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`PositionProfile`}</inlineCode>{` object and its associated candidate applications will remain for 180 days after its close date.
Your software must store any data it needs to access after the 180 day period.`}</p>
    <h2 {...{
      "id": "before-you-begin"
    }}>{`Before you begin`}</h2>
    <p>{`Before you can start with job posting queries and mutations, you will need to `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`request a partner token`}</a>{`.`}</p>
    <h2 {...{
      "id": "operations"
    }}>{`Operations`}</h2>
    <p>{`You can interact with `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` objects by:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
          }}>{`Posting a job ad`}</a>{` using a `}<inlineCode parentName="p">{`PositionProfile`}</inlineCode>{` input`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/querying-a-job-ad"
          }}>{`Querying a job ad`}</a>{` to retrieve the `}<inlineCode parentName="p">{`PositionProfile`}</inlineCode>{`’s data`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
          }}>{`Updating a job ad`}</a>{` using a `}<inlineCode parentName="p">{`PositionProfile`}</inlineCode>{` input and an existing job ad’s `}<inlineCode parentName="p">{`profileId`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad"
          }}>{`Closing a job ad`}</a></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;