
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "playground"
    }}>{`Playground`}</h1>
    <p>{`SEEK provides a read-only Playground environment designed to approximate the production SEEK API.
The Playground environment is populated with a mix of manually curated and automatically generated `}<a parentName="p" {...{
        "href": "/graphql/playground/mock-objects"
      }}>{`mock objects`}</a>{`.
This provides a number of benefits over testing against production:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`None of the mock objects contain the `}<a parentName="p" {...{
            "href": "https://www.oaic.gov.au/privacy/privacy-guidance-for-organisations-and-government-agencies/handling-personal-information/what-is-personal-information"
          }}>{`personal information`}</a>{` of real people.
This eliminates the possibility of exposing sensitive data during testing.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The mock objects are in a variety of states that can be hard to find in production data.
For example, there are mock candidate applications with every possible combination of attachments.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The curated mock objects have stable `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#object-identifiers"
          }}>{`object identifiers`}</a>{` and data.
This makes it easy to perform repeatable tests against a known set of data.`}</p>
      </li>
    </ul>
    <p>{`Due to its read-only nature, it’s not possible to execute mutations in the Playground environment.
All mutations will fail with a `}<a parentName="p" {...{
        "href": "/graphql/error-responses#forbidden"
      }}><inlineCode parentName="a">{`FORBIDDEN`}</inlineCode>{` error`}</a>{` after they validate their input.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`As in production, all Playground requests require an access token.
You can use your `}<a parentName="p" {...{
        "href": "/introduction#integration-process"
      }}>{`Playground credentials`}</a>{` to request a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{` with the `}<inlineCode parentName="p">{`https://test.graphql.seek.com`}</inlineCode>{` audience.`}</p>
    <p>{`A partner token can be requested from the command line on macOS, Linux or Windows Subsystem for Linux:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl --request POST \\
 --url https://auth.seek.com/oauth/token \\
 --header 'content-type: application/json' \\
 --data '{"client_id":"Contact SEEK","client_secret":"Contact SEEK","audience":"https://test.graphql.seek.com","grant_type":"client_credentials"}'
`}</code></pre>
    <p>{`The provided token will be valid for the number of seconds specified in the response’s `}<inlineCode parentName="p">{`expires_in`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "accessing-the-playground"
    }}>{`Accessing the Playground`}</h2>
    <p>{`The Playground environment uses the same GraphQL endpoint as the production SEEK API: `}<inlineCode parentName="p">{`https://graphql.seek.com/graphql`}</inlineCode>{`.`}</p>
    <p>{`Requests to the Playground are distinguished by their `}<a parentName="p" {...{
        "href": "#authentication"
      }}>{`access token`}</a>{` and `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#scheme-identifiers"
      }}>{`scheme ID`}</a>{`.
Instead of using the `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` & `}<inlineCode parentName="p">{`global`}</inlineCode>{` scheme IDs to refer to production, you would use `}<inlineCode parentName="p">{`seekAnzPublicTest`}</inlineCode>{` & `}<inlineCode parentName="p">{`globalPublicTest`}</inlineCode>{` in their place.`}</p>
    <h2 {...{
      "id": "browser-usage"
    }}>{`Browser usage`}</h2>
    <p>{`You can interactively query the Playground environment using the `}<a parentName="p" {...{
        "href": "/manage/graphql-explorer"
      }}>{`Developer Dashboard’s GraphQL Explorer`}</a>{`.`}</p>
    <h2 {...{
      "id": "running-example-queries"
    }}>{`Running example queries`}</h2>
    <p>{`There are examples of GraphQL queries throughout this documentation.
Once you find an interesting query you can open it in the GraphQL Explorer using the button in the top right corner of the code block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  version
}
`}</code></pre>
    <p>{`Most example queries also include `}<a parentName="p" {...{
        "href": "/graphql/in-practice#variables"
      }}>{`query variables`}</a>{` in a second tab:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  candidate(id: $id) {\\n    person {\\n      name {\\n        given\\n        family\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":59,\"column\":1,\"offset\":2527},\"end\":{\"line\":70,\"column\":4,\"offset\":2662},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:candidate:feed:5PGXAHysiXhtA9JUqhyM8hhzMuWMPA\\\"\\n}\",\"position\":{\"start\":{\"line\":72,\"column\":1,\"offset\":2664},\"end\":{\"line\":76,\"column\":4,\"offset\":2753},\"indent\":[1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "candidate(id:": true,
        "$id)": true,
        "person": true,
        "name": true,
        "given\\n": true,
        "family\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":59,\"column\":1,\"offset\":2527},\"end\":{\"line\":70,\"column\":4,\"offset\":2662},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:candidate:feed:5PGXAHysiXhtA9JUqhyM8hhzMuWMPA\\\"\\n}\",\"position\":{\"start\":{\"line\":72,\"column\":1,\"offset\":2664},\"end\":{\"line\":76,\"column\":4,\"offset\":2753},\"indent\":[1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  candidate(id: $id) {
    person {
      name {
        given
        family
      }
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "customising-example-queries"
    }}>{`Customising example queries`}</h2>
    <p>{`GraphQL allows you to write queries that combine data from multiple object types and data sources.
For example, when `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`exporting a candidate’s application`}</a>{` you also have access to all of the fields on the associated job ad.
It would not be practical or performant for the GraphQL server to return all possible fields for a given query.
Instead, your software must explicitly select the fields it requires.`}</p>
    <p>{`The examples in this documentation will only select the fields required to illustrate the use of a feature.
In most cases you’ll need to add or remove fields from the selection set to suit how it’s used in your software.
This is easy to do from your browser using the GraphQL Explorer in combination with our schema documentation.`}</p>
    <p>{`After you `}<a parentName="p" {...{
        "href": "#running-example-queries"
      }}>{`open an example in the GraphQL Explorer`}</a>{`, you’ll be able to interactively modify its selection set.
The Playground’s online editor supports context-aware autocompletion and error highlighting.
You can hover your mouse over the query’s fields to reveal their types and associated documentation.`}</p>
    <p>{`While the SEEK API schema documentation is available in the GraphQL Explorer, you may prefer our standalone `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/"
      }}>{`schema documentation`}</a>{` site.
The site’s built-in search can be used to quickly look up a GraphQL type you see referenced in the GraphQL Explorer.
Once you’ve found the appropriate type, it will show a complete list of the fields you can add to your selection set.`}</p>
    <h2 {...{
      "id": "mock-objects"
    }}>{`Mock objects`}</h2>
    <p>{`There are a number of manually curated mock objects available for testing.
Refer to the `}<a parentName="p" {...{
        "href": "/graphql/playground/mock-objects"
      }}>{`mock objects`}</a>{` documentation for a categorised list of available objects.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;