
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The product ID for the update.`}</p>
    <p>{`The ad product selected will determine the features and performance of the job ad.
This is not the identifier of the job ad itself; see `}<a parentName="p" {...{
        "href": "/schema-new/named-type/UpdatePostedPositionProfile_PositionProfileInput#/field/profileId",
        "title": "The job ad ID to update"
      }}><inlineCode parentName="a">{`UpdatePostedPositionProfile_PositionProfileInput.profileId`}</inlineCode></a>{` for that.`}</p>
    <p>{`You cannot provide the same product ID that was initially used to post the job ad.
Display ad selection to the user in your update workflow by using the Ad Selection Panel or `}<a parentName="p" {...{
        "href": "/schema-new/query/advertisementProducts",
        "title": "The list of advertisement products available to the hirer when posting or updating a job"
      }}><inlineCode parentName="a">{`advertisementProducts`}</inlineCode></a>{` query.
This will present a distinct set of update products along with associated pricing and payment information.
Once an update product has been selected, its ID can be passed in this field.`}</p>
    <p>{`In certain scenarios, you may need to update a job ad without displaying ad selection to the hirer.
You can retain the existing ad product by omitting this field entirely.`}</p>
    <p>{`Do not pass legacy ad types like `}<inlineCode parentName="p">{`Classic`}</inlineCode>{` and `}<inlineCode parentName="p">{`StandOut`}</inlineCode>{` in this field.
These values are not supported and will result in a `}<inlineCode parentName="p">{`BAD_USER_INPUT`}</inlineCode>{` error.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;