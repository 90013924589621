
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The URL of a standalone webpage to preview a job ad, with an origin of `}<inlineCode parentName="p">{`job-ad-preview.seek.com`}</inlineCode>{`.`}</p>
    <p>{`The page can be embedded in an iframe or opened in a new tab.`}</p>
    <p>{`The webpage will expire after 24 hours.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;