import { Divider, IconCopy, Text } from 'braid-design-system';
import type { GraphQLUnionType } from 'graphql';
import { InlineCode } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSnippet } from '../components/TypeSnippet';

export const UnionSchemaPage = ({
  unionType,
}: {
  unionType: GraphQLUnionType;
}) => (
  <BaseSchemaPage
    titleText={unionType.name}
    title={<InlineCode weight="weak">{unionType.name}</InlineCode>}
    subHeading={
      <Text icon={<IconCopy />} size="small" weight="medium" tone="secondary">
        Union
      </Text>
    }
  >
    <SchemaMarkdown type={unionType.name} />

    <Divider />

    <SchemaSection heading="Member types">
      {Object.entries(unionType.getTypes()).map(([typeName, type]) => (
        <TypeSnippet key={typeName} type={type} />
      ))}
    </SchemaSection>
  </BaseSchemaPage>
);
