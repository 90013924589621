import { Actions, ButtonLink, IconArrow, Tiles } from 'braid-design-system';

import { PageIcon } from 'src/components/PageIcon/PageIcon';
import { TileLink } from 'src/components/TileLink/TileLink';

export const CurrentGuides = () => (
  <Tiles columns={[1, 1, 2]} space="small">
    <TileLink
      description="Latest revision of baseline requirements for the Job Posting use case and our Asia-Pacific expansion"
      href="/migration-guides/jobstreet-and-jobsdb-uplift"
      icon={<PageIcon icon="Guide" />}
      title="2023 Job Posting revision"
    />

    <TileLink
      description="Long-lived reference for monitoring the health of your SEEK API integration"
      href="/guides/monitoring"
      icon={<PageIcon icon="Monitoring" />}
      title="Monitoring"
    />
  </Tiles>
);

export const ArchivedGuides = () => (
  <Actions>
    <ButtonLink
      href="/migration-guides/archived"
      icon={<IconArrow direction="right" />}
      variant="soft"
    >
      Jump to archive
    </ButtonLink>
  </Actions>
);
