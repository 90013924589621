import { Alert, Box, Divider, Stack, Text } from 'braid-design-system';
import React from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';

import { useNotiHistory } from '../../HistoryContext';

import { HistoricNotiRow } from './components/HistoricNotiRow/HistoricNotiRow';
import { LoadingCard } from './components/LoadingCard/LoadingCard';

import * as styles from './styles.css';

interface HistoryListProps {
  notiType: string;
}

export const HistoryList = ({ notiType }: HistoryListProps) => {
  const { notifications, loading, error } = useNotiHistory();

  if (error.failed) {
    return (
      <GutterBox>
        <Alert tone="critical">
          <Stack space="small">
            <Text>We couldn’t load your recent notifications.</Text>
            <Text size="small">{error.message}</Text>
          </Stack>
        </Alert>
      </GutterBox>
    );
  }
  if (loading) {
    return <LoadingCard />;
  }

  const notificationsFiltered =
    notiType === 'All Notifications'
      ? notifications
      : notifications.filter(({ noti }) => notiType === noti.title);

  const NotiRows = () => {
    if (notificationsFiltered.length === 0) {
      return (
        <GutterBox>
          <Text>No notifications in the last 30 days</Text>
        </GutterBox>
      );
    }
    return (
      <Box className={styles.maxHeightBox}>
        <Stack space="none">
          {notificationsFiltered.map((noti, i) => (
            <React.Fragment key={noti.scopedIdempotencyKey}>
              {i > 0 && <Divider />}
              <HistoricNotiRow noti={noti} key={noti.scopedIdempotencyKey} />
            </React.Fragment>
          ))}
        </Stack>
      </Box>
    );
  };
  return <NotiRows />;
};
