import { Badge } from 'braid-design-system';

/**
 * Badge indicating that a documented option is recommended over other options.
 */
export const Recommended = () => (
  <Badge tone="promote" weight="strong">
    Recommended
  </Badge>
);
