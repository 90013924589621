import { Box, Stack, Text } from 'braid-design-system';
import type { GraphQLField, OperationTypeNode } from 'graphql';
import { Fragment } from 'react';
import { InlineCode, TableRow } from 'scoobie';

import { NameTextLink } from 'src/components/NameTextLink/NameTextLink';
import { TypeLink } from 'src/components/TypeLink/TypeLink';
import { SchemaMarkdown } from 'src/pages/schema/components/SchemaMarkdown';
import {
  hrefForNamedTypeField,
  hrefForOperationResultField,
} from 'src/utils/href';
import { shortDescription } from 'src/utils/shortDescription';
import { sortGraphqlEntities } from 'src/utils/sort';

import { DeprecationWarning } from './DeprecationWarning';

interface Props {
  objectName: string;

  operationType?: OperationTypeNode;
  operationName?: string;

  field: GraphQLField<unknown, unknown>;
  highlight?: boolean;
  deprecated: boolean;
}

export const FieldDetails = ({
  objectName,
  operationType,
  operationName,
  field,
  highlight,
  deprecated,
}: Props) => {
  const hrefLinkTo =
    operationType && operationName
      ? hrefForOperationResultField(operationType, operationName, field)
      : hrefForNamedTypeField(objectName, field);
  const elementIdLinkTo = operationType
    ? `${operationType}-${operationName}-${field.name}`
    : `${objectName}-${field.name}`;
  return (
    <TableRow selected={highlight}>
      <Stack space="medium">
        <NameTextLink
          name={field.name}
          type={field.type}
          highlight={highlight}
          to={hrefLinkTo}
          id={elementIdLinkTo}
          deprecated={deprecated}
        >
          {field.args.length > 0 && (
            <>
              <InlineCode weight="weak">{' ('}</InlineCode>

              {field.args
                .slice()
                .sort(sortGraphqlEntities)
                .map((arg) => (
                  <Fragment key={arg.name}>
                    <Box
                      component="span"
                      title={shortDescription(arg.description)}
                    >
                      {' '}
                      <InlineCode weight="weak">{`\n  ${arg.name}: `}</InlineCode>
                    </Box>

                    <TypeLink type={arg.type} />
                  </Fragment>
                ))}

              <InlineCode weight="weak">{'\n)'}</InlineCode>
            </>
          )}
        </NameTextLink>

        <Text>
          <TypeLink type={field.type} />
        </Text>
      </Stack>
      <Stack space="medium">
        {typeof field.deprecationReason === 'string' && (
          <DeprecationWarning
            type={`${objectName}.${field.name}.deprecation`}
          />
        )}

        {typeof field.description === 'string' && (
          <SchemaMarkdown type={`${objectName}.${field.name}`} />
        )}
      </Stack>
    </TableRow>
  );
};
