
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The optional hirer associated with this webhook subscription.`}</p>
    <p>{`This will only be accessible if there is an active relationship between the partner and hirer.`}</p>
    <p>{`By default webhook subscriptions will send events from all hirers the partner has access to.
A non-null `}<inlineCode parentName="p">{`hirer`}</inlineCode>{` field indicates that this subscription is filtered to a single hirer.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;