
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier for the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/AdvertisementBranding",
        "title": "Advertisement branding details and images"
      }}><inlineCode parentName="a">{`AdvertisementBranding`}</inlineCode></a>{` to apply to the posted job ad.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For the `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme, this field's behavior is dependent on the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/AdvertisementProductFeatures",
            "title": "Additional information that is accepted when posting a job ad with this advertisement product"
          }}><inlineCode parentName="a">{`AdvertisementProductFeatures`}</inlineCode></a>{` of the product set in the `}<inlineCode parentName="p">{`seekAdvertisementProductId`}</inlineCode>{` field.`}</p>
        <p parentName="li">{`When the product's `}<a parentName="p" {...{
            "href": "/schema-new/named-type/AdvertisementProductFeatures#/field/branding",
            "title": "Additional information related to branding that is accepted when posting a job ad"
          }}><inlineCode parentName="a">{`AdvertisementProductFeatures.branding`}</inlineCode></a>{` value is null, this field will be silently ignored.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;