import { version } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { App } from './App/App';
import { DatadogProvider } from './components/Datadog/Datadog';
import { ScrollRestoration } from './components/ScrollRestoration/ScrollRestoration';
import { AuthProvider } from './components/auth/AuthProvider';
import { ApolloEnvironmentProvider } from './hooks/apollo';
import { EnvironmentProvider } from './hooks/environment';
import { StaticRenderProvider } from './hooks/staticRender';
import type { ClientContext } from './types';
import { RESET_SESSION_PATH, resetSession } from './utils/resetSession';

export default ({ environment }: ClientContext) => {
  if (window.location.pathname === RESET_SESSION_PATH) {
    // Short circuit this to skip React hydration, querying our partner name,
    // loading Statuspage, etc.
    resetSession();
    return;
  }

  const Application = () => (
    <EnvironmentProvider value={environment}>
      <StaticRenderProvider value={false}>
        <DatadogProvider env={environment} version={version}>
          <AuthProvider>
            <ApolloEnvironmentProvider>
              <App />
            </ApolloEnvironmentProvider>
          </AuthProvider>
        </DatadogProvider>
        <ScrollRestoration storageKey="developer-site-scroll-positions" />
      </StaticRenderProvider>
    </EnvironmentProvider>
  );

  const router = createBrowserRouter([
    // match everything with "*"
    {
      path: '*',
      element: <Application />,
    },
  ]);

  hydrateRoot(
    document.getElementById('app')!,
    <RouterProvider router={router} />,
  );
};
