import { Text, TextDropdown } from 'braid-design-system';
import { useState } from 'react';

import { ConfigCard } from '../ConfigSection/components/ConfigBody/components/ConfigCard/ConfigCard.tsx';

import { NotiHistoryProvider, useNotiHistory } from './HistoryContext';
import { HistoryList } from './components/HistoryList/HistoryList';

export const HistoryContent = () => {
  const [notiTypeFilter, setNotiTypeFilter] = useState('All Notifications');
  const { uniqueNotiTitles } = useNotiHistory();

  const formattedOptions = [
    { text: 'All Notifications', value: 'All Notifications' },
    ...uniqueNotiTitles.map((noti) => ({
      text: noti,
      value: noti,
    })),
  ];

  return (
    <ConfigCard
      headerAside={
        uniqueNotiTitles.length > 1 ? (
          <Text size="small">
            <TextDropdown
              id="notificationType"
              label="Notification Type"
              options={formattedOptions}
              value={notiTypeFilter}
              onChange={setNotiTypeFilter}
            />
          </Text>
        ) : undefined
      }
      title="Recent notifications"
    >
      <HistoryList notiType={notiTypeFilter} />
    </ConfigCard>
  );
};

export const HistorySection = () => (
  <NotiHistoryProvider>
    <HistoryContent />
  </NotiHistoryProvider>
);
