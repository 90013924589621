import {
  Box,
  Column,
  Columns,
  Divider,
  Hidden,
  PageBlock,
  Stack,
} from 'braid-design-system';
import React from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';

import { useLayoutSlot } from 'src/App/Layout';
import { ContentHeader } from 'src/components/ContentHeader/ContentHeader';
import { FlexPageWithFooter } from 'src/components/FlexPageWithFooter/FlexPageWithFooter';
import { PageNavigationButtonLinks } from 'src/components/PageNavigationButtonLinks/PageNavigationButtonLinks';
import { WrapperRenderer } from 'src/components/mdx/WrapperRenderer';
import type { Label } from 'src/page';
import type { PageNavigation } from 'src/site';
import type { MdxTemplateProps } from 'src/types';

import { MarkdownTocSidebar } from './components/MarkdownToc';

import * as styles from './MarkdownPage.css';

type MarkdownPageProps = MdxTemplateProps & {
  label?: Label;
  banner: JSX.Element | null;
  next?: PageNavigation;
  prev?: PageNavigation;
};

export const MarkdownPage = ({
  label,
  banner,
  document,
  next,
  prev,
}: MarkdownPageProps) => {
  const layoutSlot = useLayoutSlot();

  return (
    <WrapperRenderer document={document}>
      {({ children }) => {
        // TODO: type better
        const [title, ...body]: any = React.Children.toArray(children);
        const titleChildren = title.props.children;

        return (
          <Box background="surface" component="main">
            {label && (
              <Helmet>
                <title>
                  {typeof titleChildren === 'string' ? titleChildren : label}
                </title>
              </Helmet>
            )}

            <PageBlock width="large">
              <ContentHeader>{titleChildren}</ContentHeader>

              <Divider />

              <Columns collapseBelow="wide" space={['none', 'none', 'gutter']}>
                <Column>
                  <FlexPageWithFooter>
                    <Stack space="large">
                      {banner}

                      {body}
                    </Stack>

                    <Divider />

                    <PageNavigationButtonLinks prev={prev} next={next} />
                  </FlexPageWithFooter>
                </Column>

                <Column width="content">
                  {layoutSlot
                    ? createPortal(
                        <Box
                          className={styles.tocScroll}
                          component="nav"
                          top={0}
                        >
                          <Hidden below="wide">
                            <MarkdownTocSidebar document={document} />
                          </Hidden>
                        </Box>,
                        layoutSlot,
                      )
                    : null}
                </Column>
              </Columns>
            </PageBlock>
          </Box>
        );
      }}
    </WrapperRenderer>
  );
};
