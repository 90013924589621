
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A URL of an external apply form.`}</p>
    <p>{`When this is provided, SEEK's Apply Form will be disabled and the candidate will link out to the supplied URL.
This requires that the hirer has an `}<a parentName="p" {...{
        "href": "/schema-new/named-type/HiringOrganizationApiCapabilities#/field/applicationMethodCodes",
        "title": "The supported methods of applying to job ads posted by the hirer"
      }}><inlineCode parentName="a">{`HiringOrganizationApiCapabilities.applicationMethodCodes`}</inlineCode></a>{` that includes `}<inlineCode parentName="p">{`ApplicationUri`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;