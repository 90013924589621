
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A human-readable description of the way in which the response choice selection will apply.`}</p>
    <p>{`For example, the message can be:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`I will only accept this answer`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`I will accept any of these answers`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`I will only accept this combination of answers`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`I will accept above and including`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`I will accept up to and including`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`I will accept between this range`}</inlineCode></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;