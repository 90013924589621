
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "widgets"
    }}>{`Widgets`}</h1>
    <p><a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/integrations/talent-search-connect/"
      }}>{`Talent Search`}</a>{` widgets serve as entry points to Talent Search.
Your software must embed all provided widgets in easily discoverable places within its recruitment workflow for hirers with an active Talent Search Connect contract with SEEK.`}</p>
    <p>{`Widgets must be hidden for hirers that are not configured for Talent Search Connect.
You can use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/hiringOrganization"
      }}><inlineCode parentName="a">{`hiringOrganization`}</inlineCode>{` query`}</a>{` to check for the required `}<inlineCode parentName="p">{`ProactiveSourcing`}</inlineCode>{` relationship with a SEEK hirer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  hiringOrganization(id: $id) {\\n    seekApiCapabilities {\\n      relationshipTypeCodes\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":9,\"column\":1,\"offset\":461},\"end\":{\"line\":17,\"column\":4,\"offset\":596},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n}\",\"position\":{\"start\":{\"line\":19,\"column\":1,\"offset\":598},\"end\":{\"line\":23,\"column\":4,\"offset\":668},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"hiringOrganization\\\": {\\n    \\\"seekApiCapabilities\\\": {\\n      \\\"relationshipTypeCodes\\\": [\\n        \\\"ApplicationExport\\\",\\n        \\\"ApplicationPrefill\\\",\\n        \\\"JobPosting\\\",\\n        \\\"ProactiveSourcing\\\"\\n      ]\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":25,\"column\":1,\"offset\":670},\"end\":{\"line\":38,\"column\":4,\"offset\":900},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "hiringOrganization(id:": true,
        "$id)": true,
        "seekApiCapabilities": true,
        "relationshipTypeCodes\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":9,\"column\":1,\"offset\":461},\"end\":{\"line\":17,\"column\":4,\"offset\":596},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n}\",\"position\":{\"start\":{\"line\":19,\"column\":1,\"offset\":598},\"end\":{\"line\":23,\"column\":4,\"offset\":668},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"hiringOrganization\\\":": true,
        "\\\"seekApiCapabilities\\\":": true,
        "\\\"relationshipTypeCodes\\\":": true,
        "[\\n": true,
        "\\\"ApplicationExport\\\",\\n": true,
        "\\\"ApplicationPrefill\\\",\\n": true,
        "\\\"JobPosting\\\",\\n": true,
        "\\\"ProactiveSourcing\\\"\\n": true,
        "]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":25,\"column\":1,\"offset\":670},\"end\":{\"line\":38,\"column\":4,\"offset\":900},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  hiringOrganization(id: $id) {
    seekApiCapabilities {
      relationshipTypeCodes
    }
  }
}
`}</code></pre>
    <p>{`Talent Search widgets are `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Web_components"
      }}>{`Web Components`}</a>{` that are packaged and delivered as a JavaScript bundle.
Web Components are technology agnostic and will work in conjunction with popular frontend frameworks such as Angular or React.`}</p>
    <h2 {...{
      "id": "widget-types"
    }}>{`Widget types`}</h2>
    <p>{`Each widget can be interactively previewed in `}<a parentName="p" {...{
        "href": "https://connected.talentsearch.seek.com.au/docs/index.html"
      }}>{`Storybook`}</a>{`.
Knobs allow you to try out different input options and receive immediate feedback on how a given widget will respond,
while the Notes section contains detailed specifications and implementation guidance.`}</p>

    <Alert tone="info" mdxType="Alert">
  <Text mdxType="Text">
    A SEEK hirer account with Talent Search Connect access is required to view
    logged-in widget states in Storybook.
  </Text>
    </Alert>
    <h3 {...{
      "id": "talent-search-global-entry-point"
    }}>{`Talent Search Global Entry Point`}</h3>
    <p><img alt="Global entry menu button" data-scoobie-style="default" height="37" src={require('./assets/TSCGlobalEntry.png')} title="Global entry point" width="171" /></p>
    <p>{`Provides a link to Talent Search.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Storybook:`}</strong>{` `}<a parentName="p" {...{
            "href": "https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/talent-search-global-entry-point--docs"
          }}>{`https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/talent-search-global-entry-point--docs`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Suggested placement:`}</strong>{` Main menu item`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "view-seek-profile"
    }}>{`View SEEK Profile`}</h3>
    <p><img alt="Candidate has SEEK Profile" data-scoobie-style="none" height="31" src={require('./assets/ViewSeekProfile.png')} title="View SEEK Profile button" width="149" /></p>
    <p>{`Takes the email address of the candidate profile being viewed in your software and searches against Talent Search’s database for a corresponding SEEK Profile.
This widget will be blank if no matching SEEK Profile is found.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Storybook:`}</strong>{` `}<a parentName="p" {...{
            "href": "https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/view-seek-profile-button--docs"
          }}>{`https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/view-seek-profile-button--docs`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Suggested placement:`}</strong>{` Candidate profile`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "similar-candidates"
    }}>{`Similar Candidates`}</h3>
    <p><img alt="Candidates that are similar to the one being viewed" data-scoobie-style="default" height="903" src={require('./assets/SimilarCandidates.png')} title="Similar Candidates widget" width="921" /></p>
    <p>{`Takes the email address of the candidate profile being viewed in your software and searches against Talent Search’s database for a corresponding SEEK Profile.
If a SEEK Profile is found, it is used to find and display similar SEEK Profiles.
There are multiple sizes and orientations available for this widget.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Storybook:`}</strong>{` `}<a parentName="p" {...{
            "href": "https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/similar-candidates--docs"
          }}>{`https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/similar-candidates--docs`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Suggested placement:`}</strong>{` Candidate profile`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Recommended settings:`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`layoutSize="large"`}</inlineCode>{` (up to 8 results shown)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`seekAndCombined="true"`}</inlineCode></p>
          </li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "matching-candidates"
    }}>{`Matching Candidates`}</h3>
    <p><img alt="Candidate has SEEK Profile" data-scoobie-style="default" height="98" src={require('./assets/MatchingCandidates.png')} title="Matching Candidates widget" width="849" /></p>
    <p>{`Takes the position details being viewed and runs a search against Talent Search’s database for SEEK Profiles that match the position details.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Storybook:`}</strong>{` `}<a parentName="p" {...{
            "href": "https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/matching-candidates--docs"
          }}>{`https://connected.talentsearch.seek.com.au/docs/index.html?path=/docs/matching-candidates--docs`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Suggested placement:`}</strong>{` Position details, position management, position creation pages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Recommended settings:`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`seekAndCombined="true"`}</inlineCode>{` shows numbers for both shared profiles and SEEK-only profiles.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`approachableOnly="true"`}</inlineCode>{` filters by candidates whose activity on SEEK indicates they may be looking for a new role at the moment.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`location`}</inlineCode>{` accepts free text which is sent to a normalisation and resolution service to determine a location with which to filter the search.
This works with a variety of inputs.
It requires a country at a minimum (e.g. Australia), but works best when a suburb, state and country are supplied.`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "how-to-embed-widgets"
    }}>{`How to embed widgets`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Include a `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` element to enable Talent Search Connect widgets:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<script
  async
  type="text/javascript"
  src="https://connected.talentsearch.seek.com.au/sdk/latest/bundle.js"
></script>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Include a Talent Search component at the desired location, per the Notes section of its Storybook entry.
For example, to include Matching Candidates:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<ts-matching-candidates
  jobTitle="Developer"
  location="Melbourne"
  approachableOnly="true"
  seekAndCombined="true"
  layoutOrientation="horizontal"
/>
`}</code></pre>
      </li>
    </ol>
    <p>{`An example implementation with pure HTML/JavaScript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <script
    async
    type="text/javascript"
    src="https://connected.talentsearch.seek.com.au/sdk/latest/bundle.js"
  ></script>

  <ts-matching-candidates
    jobTitle="Developer"
    location="Melbourne"
    approachableOnly="true"
    seekAndCombined="true"
    layoutOrientation="horizontal"
  />
</html>
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;