
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "step-2-handle-authorization"
    }}>{`Step 2: Handle authorization`}</h1>
    <p>{`When a candidate clicks the Apply with SEEK button,
your software must redirect them to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplyWithSeekButtonPayload/field/url"
      }}><inlineCode parentName="a">{`url`}</inlineCode></a>{` from the `}<a parentName="p" {...{
        "href": "/use-cases/apply-with-seek/display-button"
      }}><inlineCode parentName="a">{`applyWithSeekButton`}</inlineCode>{` query`}</a>{` to complete authorization.`}</p>
    <p>{`The candidate will then see the Apply with SEEK authorization page.
If the candidate is logged in, they will see the image below, otherwise they will see our login screen first.`}</p>
    <p><img alt="Apply with SEEK authorization page" data-scoobie-style="default" height="588" src={require('./awsk-auth-page.png')} title="Apply with SEEK authorization page" width="698" /></p>
    <h2 {...{
      "id": "scenarios"
    }}>{`Scenarios`}</h2>
    <h3 {...{
      "id": "allow-access"
    }}>{`Allow access`}</h3>
    <p>{`If the candidate clicks `}<inlineCode parentName="p">{`Allow access`}</inlineCode>{`,
they will be redirected to your supplied `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` with additional parameters in the query string.`}</p>
    <p>{`For example, if your `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` was `}<inlineCode parentName="p">{`https://www.example.com/job/123445/`}</inlineCode>{`, then after authorization the candidate would be redirected to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`https://www.example.com/job/123445/apply?seek-prefill-id=globalPublicTest:candidateProfile:prefill:1111111111111111&seek-token=eyJqb2JJZCI6MCwiY2FuZGlkYXRlSWQiOjAsInNlc3Npb25JZCI6IiJ9
`}</code></pre>
    <p>{`The values returned in the redirect are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Parameter`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seek-prefill-id`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`A unique identifier that can be used to retrieve SEEK Profile information.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seek-token`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`A unique token for the candidate session that your software should store and use to send subsequent `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/send-application-complete-signal"
              }}>{`signals`}</a>{`. This should override any `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received from the query string when the candidate initially arrived from SEEK.`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "not-now"
    }}>{`Not now`}</h3>
    <p>{`If the candidate clicks `}<inlineCode parentName="p">{`Not now`}</inlineCode>{`,
they will be redirected to your supplied `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` with only the `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` parameter added to the query string.`}</p>
    <p>{`For example, if your `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` was `}<inlineCode parentName="p">{`https://www.example.com/job/123445/`}</inlineCode>{`, then after authorization the candidate would be redirected to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`https://www.example.com/job/123445/apply?seek-token=eyJqb2JJZCI6MCwiY2FuZGlkYXRlSWQiOjAsInNlc3Npb25JZCI6IiJ9
`}</code></pre>
    <h2 {...{
      "id": "edge-cases"
    }}>{`Edge cases`}</h2>
    <p>{`A candidate landing on the Apply with SEEK authorization page may run into the following edge cases:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The authorization request may be invalid or expired.`}</p>
        <p parentName="li">{`In this case, we instruct the candidate to navigate back in their browser history.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The authorization request may have been previously fulfilled by a different candidate.`}</p>
        <p parentName="li">{`This may occur if a candidate shares the URL of their authorization page with someone else.
In this case, we instruct them to navigate back in their browser history.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The authorization request may have been previously fulfilled by themselves.`}</p>
        <p parentName="li">{`This may occur if a candidate navigates back to their Apply with SEEK authorization page.
In this case, we automatically redirect them to the `}<a parentName="p" {...{
            "href": "#not-now"
          }}>{`Not now`}</a>{` path.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;