
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A page of position openings for the given `}<inlineCode parentName="p">{`hirerId`}</inlineCode>{`.`}</p>
    <p>{`Currently, only position openings in the `}<inlineCode parentName="p">{`global`}</inlineCode>{` and `}<inlineCode parentName="p">{`globalPublicTest`}</inlineCode>{` schemes are returned.`}</p>
    <p>{`A maximum of 20 position openings can be returned in a single page.
Additional position openings can be queried using a pagination cursor.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;