
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "step-4-download-resume"
    }}>{`Step 4: Download resume`}</h1>
    <p>{`When a candidate applies using Apply with SEEK they may have a resume attached to their profile
Partners can download attachment files associated with a particular candidate profile to pre-fill the resume field.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/use-cases/apply-with-seek/retrieve-seek-profile"
      }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query from Step 3`}</a>{` should be used to retrieve the URL of any profile attachments.
If a resume is available, the attachments object should be populated with the URL of the resume as shown below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc"
      }}>{`{
  "candidateProfile": {
    // ... other SEEK Profile information

    "attachments": [
      {
        "descriptions": ["Candidate resume"],
        "seekRoleCode": "Resume",
        "url": "https://graphql.seek.com/anzPublicTest/applications/4QM5fWQbdekL9gPtPZrzex/attachments/7wS9uaWkwNrz4L5EUjNoBpVHCo61gaTSTdqiEeqxPVAk"
      }
    ]
  }
}
`}</code></pre>
    <h2 {...{
      "id": "downloading-attachment-files"
    }}>{`Downloading attachment files`}</h2>
    <p>{`Because GraphQL does not efficiently support binary data, the attachment itself is provided over HTTPS.
Each attachment has a unique download URL provided in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/Attachment/field/url"
      }}><inlineCode parentName="a">{`url`}</inlineCode>{` field`}</a>{` of the GraphQL response.`}</p>
    <p>{`During the Apply with SEEK flow your software should download attachments via your server using a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{`.`}</p>
    <h2 {...{
      "id": "attachment-file-metadata"
    }}>{`Attachment file metadata`}</h2>
    <p>{`Attachment files have additional metadata returned in standard HTTP headers.
These headers can be parsed by your software or passed to the hirer’s browser when they download the attachment.`}</p>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD"
      }}>{`HTTP `}<inlineCode parentName="a">{`HEAD`}</inlineCode>{` method`}</a>{` to retrieve an attachment’s metadata without downloading its contents.
This is useful to display the attachment’s metadata before downloading it.`}</p>
    <h3 {...{
      "id": "content-disposition"
    }}><inlineCode parentName="h3">{`Content-Disposition`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition"
      }}><inlineCode parentName="a">{`Content-Disposition`}</inlineCode></a>{` header contains the attachment’s original filename encoded using `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc6266"
      }}>{`RFC 6266`}</a>{`.`}</p>
    <p>{`We recommend parsing the filename using a library or framework that supports `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc6266"
      }}>{`RFC 6266`}</a>{`.
This ensures your software is robust against filenames containing spaces, quotes or other special characters.`}</p>
    <h3 {...{
      "id": "content-length"
    }}><inlineCode parentName="h3">{`Content-Length`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Length"
      }}><inlineCode parentName="a">{`Content-Length`}</inlineCode></a>{` header indicates the size of the attachment in bytes.
This can be retrieved using the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD"
      }}>{`HTTP `}<inlineCode parentName="a">{`HEAD`}</inlineCode>{` method`}</a>{` to display the attachment’s size before downloading it.`}</p>
    <h3 {...{
      "id": "content-type"
    }}><inlineCode parentName="h3">{`Content-Type`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type"
      }}><inlineCode parentName="a">{`Content-Type`}</inlineCode></a>{` header indicates the file format of the attachment.
Alternatively, you can extract the attachment’s file extension from its `}<a parentName="p" {...{
        "href": "#content-disposition"
      }}><inlineCode parentName="a">{`Content-Disposition`}</inlineCode></a>{`.`}</p>
    <p>{`The currently supported file formats are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p"><inlineCode parentName="strong">{`Content-Type`}</inlineCode></strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`File format`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Extension`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/msword`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Microsoft Word 97-2004`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.doc`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/vnd.openxmlformats-officedocument.wordprocessingml.document`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Microsoft Word (OpenXML)`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.docx`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/rtf`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Rich Text Format (RTF)`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.rtf`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/pdf`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Portable Document Format (PDF)`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.pdf`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`text/plain`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Plain text`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.txt`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "last-modified"
    }}><inlineCode parentName="h3">{`Last-Modified`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified"
      }}><inlineCode parentName="a">{`Last-Modified`}</inlineCode></a>{` header contains the date the attachment was uploaded or generated.`}</p>
    <h2 {...{
      "id": "security"
    }}>{`Security`}</h2>
    <p>{`SEEK processes attachments to reduce the risk to hirers reviewing candidate applications:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Attachments are scanned for viruses before the candidate application is made visible to partners.
Any files containing viruses are removed from the candidate application.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Attachments may be modified to remove dangerous content such as macros or phishing links.`}</p>
      </li>
    </ul>
    <p>{`The precise details of SEEK’s attachment processing are proprietary and subject to change.
SEEK continuously improves its processes in response to new threats.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;