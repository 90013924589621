import { Inline, Stack } from 'braid-design-system';

import {
  DateRangePicker,
  useDateRangeState,
} from 'src/components/DateRangePicker/DateRangePicker';
import {
  EventTypePicker,
  useEventTypeState,
} from 'src/components/EventTypePicker/EventTypePicker';
import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { InfiniteScrollingList } from 'src/components/InfiniteScrollingList/InfiniteScrollingList';
import { PageGutter } from 'src/components/PageGutter/PageGutter';
import {
  PaginationDirectionPicker,
  usePaginationDirectionState,
} from 'src/components/PaginationDirectionPicker/PaginationDirectionPicker';
import { Query } from 'src/components/Query/Query';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { useEnvironmentConfig } from 'src/hooks/environment';
import type {
  PollEventsQuery,
  PollEventsQueryVariables,
} from 'src/types/graphql';

import { Event } from './Event';
import { POLL_EVENTS } from './queries';

export const EventList = () => {
  const dateRangeState = useDateRangeState();
  const eventTypeState = useEventTypeState();
  const paginationDirectionState = usePaginationDirectionState();

  const { seekAnzSchemeId } = useEnvironmentConfig();

  const { afterDateTime, beforeDateTime } = dateRangeState;
  const { eventTypeCodes } = eventTypeState;
  const { firstOrLast, processPageInfo } = paginationDirectionState;

  return (
    <Stack space="gutter">
      <PageGutter>
        <Inline
          align="right"
          alignY="center"
          collapseBelow="tablet"
          space="large"
        >
          <DateRangePicker {...dateRangeState} idPrefix="event" />

          <EventTypePicker {...eventTypeState} align="right" />

          <PaginationDirectionPicker
            {...paginationDirectionState}
            idPrefix="event"
          />
        </Inline>
      </PageGutter>

      <Query<PollEventsQuery, PollEventsQueryVariables>
        errorMessage="We couldn’t load your events."
        query={POLL_EVENTS}
        variables={{
          [firstOrLast]: 10,
          filter: {
            afterDateTime: afterDateTime?.toISOString(),
            beforeDateTime: beforeDateTime?.toISOString(),
            eventTypeCodes,
          },
          schemeId: seekAnzSchemeId,
        }}
        wrapperComponent={PageGutter}
      >
        {({ data, fetchMore }) => {
          const { edges, pageInfo } = data.events;

          const { shouldLoadMore, variables } = processPageInfo(pageInfo);

          const loadMore = () => fetchMore({ variables });

          return (
            <SectionCard>
              <InfiniteScrollingList
                loadMore={loadMore}
                shouldLoadMore={shouldLoadMore}
                isEmpty={edges.length === 0}
                subject="events"
                items={edges.map(({ node }) => ({
                  key: node.id.value,
                  element: (
                    <ScrollingXBox>
                      <GutterBox>
                        <Event event={node} />
                      </GutterBox>
                    </ScrollingXBox>
                  ),
                }))}
              />
            </SectionCard>
          );
        }}
      </Query>
    </Stack>
  );
};
