import type { ApolloClient } from '@apollo/client';
import type { SeekApiPayload } from '@seek/indie-api-types';

import type { EnvironmentConfig } from 'src/hooks/environment';
import type {
  HiringOrganizationFieldsFragment,
  RelatedHirersQuery,
  RelatedHirersQueryVariables,
} from 'src/types/graphql';

import { RELATED_HIRERS } from './queries';

const escapeCsvCell = (data: string | number | boolean | null) => {
  if (typeof data !== 'string') {
    // Use the simple stringified form of the JavaScript value
    return String(data);
  }

  if (!/[",]/.test(data)) {
    // Doesn't need escaping
    return data;
  }

  return `"${data.replace(/"/g, '""')}"`;
};

/**
 * Triggers the user's browser to download a file with the given string content
 */
const downloadFile = ({
  filename,
  content,
}: {
  filename: string;
  content: string;
}) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`,
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

/**
 * Returns all hirers matching the provided filter
 *
 * This queries `hiringOrganizations` in a loop until the last page is reached.
 */
const paginateAllHirers = async <TCacheShape>(
  apolloClient: ApolloClient<TCacheShape>,
  filter: RelatedHirersQueryVariables['filter'],
  seekAnzSchemeId: EnvironmentConfig['seekAnzSchemeId'],
): Promise<HiringOrganizationFieldsFragment[]> => {
  let after: string | null = null;
  const hirers: HiringOrganizationFieldsFragment[] = [];

  while (true) {
    const result = await apolloClient.query<
      RelatedHirersQuery,
      RelatedHirersQueryVariables
    >({
      query: RELATED_HIRERS,
      variables: { after, first: 100, filter, schemeId: seekAnzSchemeId },
      // We'll loop forever if we use our infinite scroll type policy.
      // Skip the cache entirely to avoid interference.
      fetchPolicy: 'no-cache',
    });

    const {
      hiringOrganizations: { edges, pageInfo },
    } = result.data;

    hirers.push(...edges.map(({ node }) => node));

    if (!pageInfo.endCursor || !pageInfo.hasNextPage) {
      return hirers;
    }

    // microsoft/TypeScript#36687 requires this type annotation
    after = pageInfo.endCursor as string;
  }
};

/**
 * Paginates all hirers from the SEEK API and downloads it as a CSV
 *
 * The SEEK API doesn't have built-in CSV support so the CSV is built on the
 * client-side. This isn't as efficient but still finishes in a reasonable
 * amount of time.
 */
export const downloadCsv = async <TCacheShape>(
  apolloClient: ApolloClient<TCacheShape>,
  filter: RelatedHirersQueryVariables['filter'],
  seekAnzSchemeId: EnvironmentConfig['seekAnzSchemeId'],
) => {
  const allHirers = await paginateAllHirers(
    apolloClient,
    filter,
    seekAnzSchemeId,
  );

  const csvRows = [
    'Name,OID,Advertiser ID,Job Posting,Application Export,Application Prefill,Proactive Sourcing,Link Out',
    ...allHirers.map((hirer) => {
      const hasRelationship = (type: SeekApiPayload.RelationshipTypeCode) =>
        Boolean(
          hirer.seekApiCapabilities?.relationshipTypeCodes.includes(type),
        );

      return [
        hirer.name,
        hirer.id.value,
        hirer.seekAnzAdvertiserId ?? null,
        hasRelationship('JobPosting'),
        hasRelationship('ApplicationExport'),
        hasRelationship('ApplicationPrefill'),
        hasRelationship('ProactiveSourcing'),
        hirer.seekApiCapabilities?.applicationMethodCodes.includes(
          'ApplicationUri',
        ) ?? null,
      ]
        .map(escapeCsvCell)
        .join(',');
    }),
  ];

  // By default the browser will rewrite `/` to `_`.
  // Hyphens are a bit more readable when rendering dates.
  const filenameSafeDate = new Date().toLocaleDateString().replace(/\//g, '-');

  downloadFile({
    filename: `SEEK hirers (${filenameSafeDate}).csv`,
    content: csvRows.join('\r\n'),
  });
};
