/**
 * Check whether a path is a generated documentation path
 */
export const isGeneratedPath = (path?: string): boolean =>
  !(
    path?.startsWith('/manage') ||
    path?.startsWith('/schema') ||
    path?.startsWith('/authorize') ||
    path === '/reset-session'
  );
