import { Divider, Heading, Stack, Text, TextField } from 'braid-design-system';
import { useEffect, useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { JobCategorySelect, LocationSuggest } from 'wingman-fe';

import { useEnvironmentConfig } from 'src/hooks/environment';
import type {
  HiringOrganizationIdFieldsFragment,
  JobDetailsQuery,
} from 'src/types/graphql';

import { QuestionnairePanel } from './components/QuestionnairePanel';

type PostedPositionProfile = Extract<
  Required<JobDetailsQuery['positionProfile']>,
  { __typename: 'PostedPositionProfile' }
>;

type JobCategory = PostedPositionProfile['jobCategories'][number];

type Location = PostedPositionProfile['positionLocation'][number];

interface Props {
  hirer?: HiringOrganizationIdFieldsFragment;
}

export const QuestionnairePanelSection = ({ hirer }: Props) => {
  const { seekAnzSchemeId } = useEnvironmentConfig();
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [jobCategory, setJobCategory] = useState<JobCategory>();
  const [location, setLocation] = useState<Location>();
  const [questionnaireId, setQuestionnaireId] = useState<string>('');

  useEffect(() => {
    if (!hirer) {
      // Reset form when selected hirer is cleared
      setPositionTitle('');
      setLocation(undefined);
      setJobCategory(undefined);
    }
  }, [hirer]);

  return (
    <Stack space="large">
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/questionnaires/v2/panel">
          Questionnaire Panel
        </SmartTextLink>
      </Heading>

      <Divider />

      {!hirer ? (
        <Text tone="secondary">
          Select a hirer to view the Questionnaire Panel.
        </Text>
      ) : (
        <Stack space="large">
          <Stack space="large">
            <TextField
              id="questionnairePanelPositionTitle"
              label="Job title"
              message="e.g. Business Analyst"
              onClear={() => {
                setPositionTitle('');
              }}
              value={positionTitle}
              onChange={(event) => setPositionTitle(event.currentTarget.value)}
            />
            <LocationSuggest
              id="questionnairePanelLocationSuggest"
              label="Location"
              schemeId={seekAnzSchemeId}
              onSelect={setLocation}
              onClear={() => {
                setLocation(undefined);
              }}
            />
            <JobCategorySelect
              id="questionnairePanelJobCategorySelect"
              onSelect={(category, type) => {
                if (type === 'child') {
                  setJobCategory(category);
                  return;
                }
                setJobCategory(undefined);
              }}
              schemeId={seekAnzSchemeId}
            />
            <TextField
              id="prefillQuestionnaireId"
              label="Questionnaire ID"
              secondaryLabel="optional"
              message={`e.g., ${seekAnzSchemeId}:applicationQuestionnaire:rrv2:GhbDXNSzpjKHD93oN3u7aF`}
              onClear={() => setQuestionnaireId('')}
              value={questionnaireId}
              onChange={(event) =>
                setQuestionnaireId(event.currentTarget.value)
              }
              name="questionnaireId"
            />
          </Stack>

          <Divider />

          <QuestionnairePanel
            positionTitle={positionTitle}
            positionOrganizations={hirer?.id.value ? [hirer.id.value] : []}
            positionLocation={location?.id.value ? [location?.id.value] : []}
            jobCategories={jobCategory?.id.value ? [jobCategory?.id.value] : []}
            questionnaireId={questionnaireId}
          />
        </Stack>
      )}
    </Stack>
  );
};
