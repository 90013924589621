import { Box, Hidden, Stack } from 'braid-design-system';
import {
  type ComponentProps,
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditionalWrapper';
import { MenuItem } from 'src/components/MenuItem/MenuItem';
import { MenuSection } from 'src/components/MenuSection/MenuSection';
import { site as dashboardSite } from 'src/dashboard.config';
import { site as documentationSite } from 'src/documentation.config';
import { usePermissions } from 'src/hooks/auth';
import type { Page } from 'src/page';
import { SchemaMenu } from 'src/pages/schema/SchemaMenu/SchemaMenu';
import { type ListablePage, isListablePage } from 'src/site';

import * as styles from './Menu.css';

interface OnClickProps {
  onClick?: () => void;
}

type NavigationLevel = NonNullable<
  Pick<ComponentProps<typeof MenuItem>, 'level'>['level']
>;

const isNavigationLevel = (num: unknown): num is NavigationLevel =>
  typeof num === 'number' && Number.isSafeInteger(num) && num > 0 && num <= 5;

const subtreeIsActive = (root: Page, activePath: string): boolean =>
  Boolean(root.path && matchPath(activePath, root.path)) ||
  Boolean(root.children?.some((child) => subtreeIsActive(child, activePath)));

const renderNavigationForPages = (
  pages: Page[],
  activePath: string,
  ref: ForwardedRef<HTMLAnchorElement>,
  onClick: OnClickProps['onClick'],
  permissions: string[] = [],
  level: number = 0,
) => {
  if (level === 0) {
    return pages.map((page, i) => (
      <MenuSection key={page.path ?? i} heading={page.label}>
        {page.children === undefined
          ? undefined
          : renderNavigationForPages(
              page.children,
              activePath,
              ref,
              onClick,
              permissions,
              1,
            )}
      </MenuSection>
    ));
  }

  if (!isNavigationLevel(level)) {
    throw new Error(`${level} is too deep a navigation level`);
  }

  return pages.filter(isListablePage).map((page, index) =>
    page.mobileOnly ? (
      <Hidden above="desktop" key={`${page.path}-${index}`}>
        <ItemHierarchy
          activePath={activePath}
          level={level}
          onClick={onClick}
          page={page}
          permissions={permissions}
          ref={ref}
        />
      </Hidden>
    ) : (
      <ItemHierarchy
        activePath={activePath}
        key={`${page.path}-${index}`}
        level={level}
        onClick={onClick}
        page={page}
        permissions={permissions}
        ref={ref}
      />
    ),
  );
};

type ItemHierarchyProps = {
  activePath: string;
  level: NavigationLevel;
  onClick: OnClickProps['onClick'];
  page: ListablePage;
  permissions: string[];
};

const ItemHierarchy = forwardRef<HTMLAnchorElement, ItemHierarchyProps>(
  ({ activePath, level, onClick, page, permissions }, ref) => {
    const [isUserExpanded, setUserExpanded] = useState<boolean | undefined>(
      page.menuItemStartExpanded,
    );
    const isActive = subtreeIsActive(page, activePath);

    // Default to tracking the active page without explicit user action
    const isExpanded = isUserExpanded ?? isActive;

    const matchingRef =
      page.path && matchPath(activePath, page.path) ? ref : null;

    const itemProps = {
      icon: page.icon,
      isExpanded,
      level,
      onClick,
      setUserExpanded,
      to: page.path,
    };

    if (!page.children?.length) {
      return (
        <ConditionalWrapper
          wrapper={page.menuItemWrapper}
          condition={page.menuItemWrapper !== undefined}
        >
          <MenuItem
            {...itemProps}
            collapsible={false}
            // Scroll the leaf item into view
            ref={matchingRef}
          >
            {page.label}
          </MenuItem>
        </ConditionalWrapper>
      );
    }

    return (
      <>
        <MenuItem {...itemProps} collapsible={true}>
          {page.label}
        </MenuItem>

        <Box
          className={styles.menuSection[`${isExpanded}`]}
          // Scroll the expanded children of the item into view
          ref={matchingRef}
        >
          <Box className={styles.menuSectionInner}>
            {renderNavigationForPages(
              page.children,
              activePath,
              ref,
              onClick,
              permissions,
              level + 1,
            )}
          </Box>
        </Box>
      </>
    );
  },
);

const SiteNavigation = ({ onClick }: OnClickProps) => {
  const { pathname } = useLocation();
  const { permissions } = usePermissions();

  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });

      return () => clearTimeout(timeout);
    },

    // Re-scroll on client-side navigation. This may be useful when following an
    // internal link to a different page that is no longer in view.
    [pathname],
  );

  return (
    <Box paddingY="medium">
      <Stack space="medium">
        {/* TODO: Fix the /authorize route to either show a loading menu or use the redirect URL to determine the correct menu */}
        {pathname.startsWith('/manage') || pathname === '/authorize'
          ? renderNavigationForPages(
              dashboardSite,
              pathname,
              ref,
              onClick,
              permissions,
            )
          : renderNavigationForPages(
              documentationSite,
              pathname,
              ref,
              onClick,
              permissions,
            )}
      </Stack>
    </Box>
  );
};

export const Menu = ({
  usedHeight,
  isOpen,
  close,
}: {
  usedHeight: string;
  isOpen: boolean;
  close: () => void;
}) => {
  const scrollingParentRef = useRef<HTMLElement>(null);

  return (
    <>
      <Hidden above="desktop">
        {isOpen && (
          <Box
            className={styles.overlay}
            onClick={close}
            zIndex="dropdownBackdrop"
          />
        )}
      </Hidden>
      <Box
        className={styles.menu[`${isOpen}`]}
        style={{ height: `calc(100vh - ${usedHeight})`, top: usedHeight }}
        component="nav"
        zIndex="dropdown"
        ref={scrollingParentRef}
      >
        <Box
          display={[
            isOpen ? 'block' : 'none',
            isOpen ? 'block' : 'none',
            isOpen ? 'block' : 'none',
            'block',
          ]}
        >
          {/* TODO: Change when launching */}
          {useLocation().pathname.startsWith('/schema-new') ? (
            <SchemaMenu
              onClick={close}
              scrollingParentRef={scrollingParentRef}
            />
          ) : (
            <SiteNavigation onClick={close} />
          )}
        </Box>
      </Box>
    </>
  );
};
