import type { ComponentProps } from 'react';
import { MockBrandSelect } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

type Props = Pick<ComponentProps<typeof MockBrandSelect>, 'variant'>;

export const SeekBrandSelect = ({ variant }: Props) => (
  <BorderCard>
    <MockBrandSelect
      hirerId="seekAnzPublicTest:organization:seek:93WyyF1h"
      pageSize={2}
      showStorybookAction
      variant={variant}
    />
  </BorderCard>
);
