
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier for the HTTP request.`}</p>
    <p>{`This is included in the request as an `}<inlineCode parentName="p">{`X-Request-Id`}</inlineCode>{` custom header.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;