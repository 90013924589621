import {
  Actions,
  Button,
  Dialog,
  Divider,
  IconMobile,
  Stack,
  Text,
  useToast,
} from 'braid-design-system';
import { useState } from 'react';

import { useApi } from 'src/api';

export const ResetSelfMfa = () => {
  const api = useApi();

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const showToast = useToast();

  const resetSelfMfa = async () => {
    setLoading(true);
    try {
      await api.self.resetMfa();
      setShowDialog(false);
      showToast({
        key: 'resetSelfMfa',
        tone: 'positive',
        message: 'Your MFA has been reset.',
        description:
          'We’ll prompt you to register a new MFA device on your next login.',
      });
    } catch (err) {
      showToast({
        key: 'resetSelfMfa',
        tone: 'critical',
        message: 'We couldn’t reset your MFA.',
        description: String(err),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        icon={<IconMobile />}
        onClick={() => setShowDialog(true)}
        variant="soft"
      >
        Reset MFA
      </Button>

      <Dialog
        id="resetSelfMFA"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        title="Reset MFA"
      >
        <Stack space="medium">
          <Text>
            Are you sure you want to reset your MFA? You will be required to
            register a new MFA device on your next login.
          </Text>

          <Divider />

          <Actions>
            <Button onClick={resetSelfMfa} loading={loading}>
              {loading ? 'Resetting' : 'Reset'} MFA
            </Button>
            <Button variant="transparent" onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
          </Actions>
        </Stack>
      </Dialog>
    </>
  );
};
