
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-1-authentication"
    }}>{`Phase 1: Authentication`}</h1>
    <h2 {...{
      "id": "access-tokens"
    }}>{`Access tokens`}</h2>
    <p>{`While the retired Job Posting API and the SEEK API both use `}<a parentName="p" {...{
        "href": "https://oauth.net/2/"
      }}>{`OAuth 2.0`}</a>{`,
they have different sets of endpoints & client credentials.`}</p>
    <p>{`SEEK will provide you with access to the `}<a parentName="p" {...{
        "href": "/manage/"
      }}>{`Developer Dashboard`}</a>{` as part of your `}<a parentName="p" {...{
        "href": "/introduction#integration-process"
      }}>{`integration process`}</a>{`,
which supports self-service provisioning and rotation of SEEK API client credentials.
As with any sensitive credentials, you will need to store these securely in a system such as `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/secrets-manager/"
      }}>{`AWS Secrets Manager`}</a>{`, `}<a parentName="p" {...{
        "href": "https://azure.microsoft.com/en-au/products/key-vault/"
      }}>{`Azure Key Vault`}</a>{`, or an encrypted local filesystem.`}</p>
    <p>{`Your software exchanges the client credentials for a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens#requesting-a-partner-token"
      }}>{`partner token`}</a>{`.
A partner token grants access to the data of any SEEK hirer you have a `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`relationship`}</a>{` with.
Your software can also exchange the partner token for a `}<a parentName="p" {...{
        "href": "/auth/browser-tokens#requesting-a-browser-token"
      }}>{`browser token`}</a>{` to query the SEEK API directly from a hirer’s browser or mobile app.`}</p>
    <p>{`For more information on how authentication works and how to generate an access token, see the `}<a parentName="p" {...{
        "href": "/auth"
      }}>{`auth documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "authorization"
    }}>{`Authorization`}</h2>
    <p>{`The SEEK API authorizes operations based on a set of `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`relationships`}</a>{` between you and the SEEK hirers that use your software.`}</p>
    <p>{`Internally, both the Job Posting API and the SEEK API use the same `}<inlineCode parentName="p">{`JobPosting`}</inlineCode>{` hirer relationships.
Once you authenticate to the SEEK API you’ll be able to post jobs on behalf of your existing SEEK hirers.`}</p>
    <h2 {...{
      "id": "exit-criteria"
    }}>{`Exit criteria`}</h2>
    <p>{`The following criteria need to be met before moving to the next phase:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Criteria`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Credentials received`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`The SEEK support team has sent you an encrypted ZIP file with your client credentials (client ID & secret)`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Credentials stored securely`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`The credentials have been stored in a secure cloud-based system or encrypted local filesystem`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Credentials ready for consumption`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Your software backend has the correct permissions & capability to securely retrieve the credentials at run time`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Partner token obtained`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Your software backend can successfully retrieve and cache a partner token from `}<inlineCode parentName="p">{`auth.seek.com`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Browser token obtained`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Your frontend can successfully retrieve a browser token via your software’s backend`}</p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;