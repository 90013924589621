
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether the position profile was created by the requesting partner.`}</p>
    <p>{`If your software cannot ingest objects that are created by another source,
this can be used to filter out such position profiles.`}</p>
    <p>{`This indicator is never `}<inlineCode parentName="p">{`null`}</inlineCode>{` for unposted position profiles.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;