import 'braid-design-system/reset';

import { BraidProvider, ToastProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { Helmet } from 'react-helmet';
import { ScoobieLink } from 'scoobie';

import { IncidentToaster } from 'src/components/IncidentToaster/IncidentToaster';
import { PageErrorBoundary } from 'src/components/PageErrorBoundary/PageErrorBoundary';
import { MdxProvider } from 'src/components/mdx/MdxProvider';
import { UserProvider } from 'src/hooks/auth';
import { StatuspageProvider } from 'src/hooks/statuspage';
import { ClientErrorPage } from 'src/pages/ClientErrorPage';

import { Layout } from './Layout';
import { Router } from './Router';

export const App = () => (
  <BraidProvider linkComponent={ScoobieLink} theme={seekJobs}>
    <MdxProvider graphqlPlayground="https://developer.seek.com/manage/graphql-explorer">
      <Helmet
        defaultTitle="SEEK Developer"
        titleTemplate="%s | SEEK Developer"
      />
      <UserProvider>
        <StatuspageProvider pageUrl="https://status.seek.com">
          <ToastProvider>
            <IncidentToaster />
            <Layout>
              <PageErrorBoundary errorPage={<ClientErrorPage />}>
                <Router />
              </PageErrorBoundary>
            </Layout>
          </ToastProvider>
        </StatuspageProvider>
      </UserProvider>
    </MdxProvider>
  </BraidProvider>
);
