import {
  Box,
  Column,
  Columns,
  Dropdown,
  Stack,
  Text,
  TextField,
} from 'braid-design-system';
import { useState } from 'react';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

import { PayTypeRadioGroup } from './PayType';
import {
  ACTIVE_CURRENCY_CODES,
  PAY_TYPE_MAPPING,
  type PayType,
} from './constants';

import * as styles from './SalaryRange.css';

const salaryMinError = (s: string): string | undefined => {
  const trimmed = s.trim();

  const n = Number(trimmed);

  // The HTML `<input type="number">` field may not provide us with the raw text
  // input if it is not numeric, so we can't tell these scenarios apart.
  if (!trimmed || Number.isNaN(n)) {
    return 'Enter a numeric value';
  }

  if (n <= 0) {
    return 'Must be positive';
  }

  return;
};

const salaryMaxError = (
  props: Record<'min' | 'max', string>,
): string | undefined => {
  const minError = salaryMinError(props.max);
  if (minError) {
    return minError;
  }

  if (!salaryMinError(props.min) && Number(props.max) < Number(props.min)) {
    return 'Must be greater than minimum';
  }

  return;
};

export const SalaryRange = () => {
  const id = 'salary-range-input';

  const [currency, setCurrency] = useState('SGD');

  const [min, setMin] = useState('70000');
  const [max, setMax] = useState('80000');

  const minError = salaryMinError(min);
  const maxError = salaryMaxError({ min, max });

  const [payType, setPayType] = useState<PayType>('Monthly salary');

  const { intervalCode } = PAY_TYPE_MAPPING[payType];

  return (
    <BorderCard>
      <Stack space="large">
        <Stack space="small">
          <Text weight="strong">Pay type</Text>

          <PayTypeRadioGroup
            id={id}
            payType={payType}
            setPayType={setPayType}
          />
        </Stack>

        <Stack space="small">
          <Text weight="strong">Pay range</Text>

          <Text size="small" tone="secondary">
            Enter a pay range to offer candidates (this will not show on your
            ad).
          </Text>

          <Columns collapseBelow="desktop" space="small">
            <Column width="content">
              <Dropdown
                id={`${id}-currency`}
                aria-label="Currency"
                onChange={(event) => setCurrency(event.currentTarget.value)}
                value={currency}
              >
                {ACTIVE_CURRENCY_CODES.map((code) => (
                  <option key={code}>{code}</option>
                ))}
              </Dropdown>
            </Column>

            <Column>
              <TextField
                aria-label="Minimum amount"
                id={`${id}-min`}
                onChange={(event) => setMin(event.currentTarget.value)}
                onClear={() => setMin('')}
                message={minError ?? 'Valid'}
                placeholder="Minimum"
                tone={minError ? 'critical' : 'positive'}
                type="number"
                value={min}
              />
            </Column>

            <Column width="content">
              <Box
                alignItems="center"
                className={styles.fieldHeight}
                display="flex"
              >
                <Text size="small">to</Text>
              </Box>
            </Column>

            <Column>
              <TextField
                aria-label="Maximum amount"
                id={`${id}-max`}
                onChange={(event) => setMax(event.currentTarget.value)}
                onClear={() => setMax('')}
                message={maxError ?? 'Valid'}
                placeholder="Maximum"
                tone={maxError ? 'critical' : 'positive'}
                type="number"
                value={max}
              />
            </Column>

            <Column width="content">
              <Box
                alignItems="center"
                className={styles.fieldHeight}
                display="flex"
              >
                <Text size="small">per {intervalCode.toLowerCase()}</Text>
              </Box>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </BorderCard>
  );
};
