
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A profile for posting a job ad against an existing position opening.`}</p>
    <p>{`This contains information of how a position opening is presented on a given channel or job board.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;