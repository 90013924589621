
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-performance"
    }}>{`Ad Performance`}</h1>
    <p>{`This use case covers measurement and reporting of conversion rates for job ads posted through the SEEK API.`}</p>
    <p>{`It is implemented via the `}<a parentName="p" {...{
        "href": "/use-cases/ad-performance/panel"
      }}>{`Ad Performance Panel`}</a>{`,
which can be `}<a parentName="p" {...{
        "href": "/introduction/panels"
      }}>{`embedded in web-based software`}</a>{` to show detailed performance data to your hirers.`}</p>
    <p>{`Your software must send an `}<a parentName="p" {...{
        "href": "/use-cases/ad-performance/application-complete-signal"
      }}>{`application complete signal`}</a>{` as a prerequisite if it supports posting job ads that `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link out`}</a>{` to an external apply form.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;