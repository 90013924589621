
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The date the degree was granted or is expected to be granted.`}</p>
    <p>{`This may only contain a year and optional month, e.g. `}<inlineCode parentName="p">{`2020`}</inlineCode>{` or `}<inlineCode parentName="p">{`2020-06`}</inlineCode>{`.
If the date isn't known this will be `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;