import { Box, Heading, IconLink, Text } from 'braid-design-system';
import type { FunctionComponent, ReactNode } from 'react';
import { SmartTextLink } from 'scoobie';

import * as styles from './SpacedHeading.css';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

const headingForLevel: Record<
  HeadingLevel,
  FunctionComponent<{ children: ReactNode }>
> = {
  1: ({ children }) => (
    <Heading component="h1" level="2">
      {children}
    </Heading>
  ),
  2: ({ children }) => (
    <Heading component="h2" level="3">
      {children}
    </Heading>
  ),
  3: ({ children }) => (
    <Heading component="h3" level="4">
      {children}
    </Heading>
  ),
  4: ({ children }) => (
    <Text component="h4" weight="strong">
      {children}
    </Text>
  ),
  5: ({ children }) => (
    <Text component="h5" weight="strong">
      <span>| </span>
      {children}
    </Text>
  ),
  6: ({ children }) => (
    <Text component="h6" weight="strong">
      <span>‖ </span>
      {children}
    </Text>
  ),
};

interface Props {
  children: ReactNode;
  id: string;
}

export const createSpacedHeading = (level: HeadingLevel) => {
  const LevelHeading = headingForLevel[level];

  return ({ children, id }: Props) => (
    <Box
      className={styles.headingSpacer}
      id={id}
      marginTop="small"
      tabIndex={0}
    >
      <LevelHeading>
        {children}{' '}
        <SmartTextLink href={`#${id}`}>
          <Box className={styles.headingAnchor} component="span">
            <IconLink alignY="lowercase" />
          </Box>
        </SmartTextLink>
      </LevelHeading>
    </Box>
  );
};
