import {
  type ArgumentNode,
  type GraphQLArgument,
  type GraphQLField,
  type GraphQLOutputType,
  Kind,
  type NameNode,
  type OperationDefinitionNode,
  type OperationTypeNode,
  type SelectionNode,
  type SelectionSetNode,
  type VariableDefinitionNode,
  getNamedType,
  isInterfaceType,
  isNonNullType,
  isObjectType,
  isScalarType,
  print,
} from 'graphql';
import { CodeBlock } from 'scoobie';

const createNameNode = (value: string): NameNode => ({
  kind: Kind.NAME,
  value,
});

/**
 * Naively defines variables with names that match their arguments.
 */
const argsToVariableDefinitions = (
  args: GraphQLArgument[],
): VariableDefinitionNode[] =>
  args.map<VariableDefinitionNode>((arg) => ({
    kind: Kind.VARIABLE_DEFINITION,
    type: {
      kind: Kind.NAMED_TYPE,
      name: createNameNode(arg.type.toString()),
    },
    variable: {
      kind: Kind.VARIABLE,
      // TODO: handle variable naming conflicts?
      name: createNameNode(arg.name),
    },
  }));

/**
 * Selects a simple subset of fields resulting from an operation.
 *
 * This preferences scalars and fields with an identifier-like name. If no such
 * fields can be found, it takes a gamble on the first field to try to ensure a
 * valid operation is rendered. Dangerous cycles should be handled separately.
 */
const selectFieldSubset = (fields: Array<GraphQLField<unknown, unknown>>) => {
  const simpleFields = fields.filter(
    (field) =>
      isScalarType(field.type) ||
      field.name === '_id' ||
      field.name === 'id' ||
      field.name.endsWith('Id'),
  );

  return simpleFields.length > 0 ? simpleFields : fields.slice(0, 1);
};

interface Traversal {
  selectionSet: SelectionSetNode;
  variableDefinitions: VariableDefinitionNode[];
}

/**
 * Traverses the output type of a field, selecting a minimal set of subfields
 * from interfaces and objects in an effort to form a valid selection set for an
 * operation.
 *
 * This enforces a hard depth limit to prevent infinite recursion on cycles.
 */
const traverseOutputType = (
  type: GraphQLOutputType,
  maxDepth: number,
  currentDepth: number,
): Traversal | undefined => {
  const namedType = getNamedType(type);

  if (currentDepth > maxDepth) {
    return;
  }

  if (!isInterfaceType(namedType) && !isObjectType(namedType)) {
    return;
  }

  const fields = Object.values(namedType.getFields());

  // We're mutually recursive with `traverseFields`
  // eslint-disable-next-line no-use-before-define
  return traverseFields(fields, maxDepth, currentDepth);
};

/**
 * Traverses and selects a minimal subset of fields in an effort to form a valid
 * selection set for an operation.
 *
 * This enforces a hard depth limit to prevent infinite recursion on cycles.
 */
const traverseFields = (
  fields: Array<GraphQLField<unknown, unknown>>,
  maxDepth = 4,
  currentDepth = 1,
): Traversal => {
  const variableDefinitions: VariableDefinitionNode[] = [];

  const selectionSet: SelectionSetNode = {
    kind: Kind.SELECTION_SET,
    selections: selectFieldSubset(fields).map<SelectionNode>((field) => {
      const nonNullArgs = field.args?.filter((arg) => isNonNullType(arg.type));
      variableDefinitions.push(...argsToVariableDefinitions(nonNullArgs ?? []));

      const result = traverseOutputType(field.type, maxDepth, currentDepth + 1);
      variableDefinitions.push(...(result?.variableDefinitions ?? []));

      return {
        arguments: nonNullArgs?.map<ArgumentNode>((arg) => ({
          kind: Kind.ARGUMENT,
          name: createNameNode(arg.name),
          value: {
            kind: Kind.VARIABLE,
            name: createNameNode(arg.name),
          },
        })),
        kind: Kind.FIELD,
        name: createNameNode(field.name),
        selectionSet: result?.selectionSet,
      };
    }),
  };

  return {
    selectionSet,
    variableDefinitions,
  };
};

export const SampleOperation = ({
  field,
  type,
}: {
  field: GraphQLField<unknown, unknown>;
  type: OperationTypeNode;
}) => {
  const node: OperationDefinitionNode = {
    kind: Kind.OPERATION_DEFINITION,
    operation: type,
    ...traverseFields([field]),
  };

  const sample = print(node);

  return (
    <CodeBlock
      graphqlPlayground="https://developer.seek.com/manage/graphql-explorer"
      language="graphql"
    >
      {sample}
    </CodeBlock>
  );
};
