
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The list of `}<a parentName="p" {...{
        "href": "/schema-new/named-type/Event#/field/id",
        "title": "The identifier for the `Event`"
      }}><inlineCode parentName="a">{`Event.id`}</inlineCode></a>{` values to replay.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;