import { Box, IconChevron, Stack } from 'braid-design-system';
import {
  type GraphQLField,
  type GraphQLNamedType,
  OperationTypeNode,
  isEnumType,
  isInputObjectType,
  isInterfaceType,
  isObjectType,
  isScalarType,
  isUnionType,
} from 'graphql';
import {
  type ComponentProps,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { MenuItem } from 'src/components/MenuItem/MenuItem';
import { MenuSection } from 'src/components/MenuSection/MenuSection';
import {
  hrefForNamedType,
  hrefForOperation,
  hrefForWebhookSchemaDefinition,
  hrefForWebhookSchemaRootType,
} from 'src/utils/href';

import { schema } from '../schema';
import { webhookSchema } from '../webhookSchema';

import { type NestedTypes, nestSubordinateTypes } from './nestSubordinateTypes';

const SchemaMenuItem = forwardRef<
  HTMLAnchorElement,
  Omit<ComponentProps<typeof MenuItem>, 'isExpanded' | 'setUserExpanded'> & {
    activePath: string;
  }
>(({ activePath, to, ...props }, ref) => (
  <MenuItem
    {...props}
    to={to}
    ref={to && matchPath(activePath, to) ? ref : null}
    isExpanded={false}
    setUserExpanded={() => {}}
  />
));

const webhookSchemaDefNames = Object.keys(webhookSchema?.definitions || {});

const RootType = ({
  type,
  itemRef,
  activePath,
  nestedTypes,
  onClick,
}: {
  type: GraphQLNamedType;
  itemRef: React.RefObject<HTMLAnchorElement>;
  activePath: string;
  nestedTypes: NestedTypes;
  onClick: () => void;
}) => (
  <>
    <SchemaMenuItem
      ref={itemRef}
      key={type.name}
      to={hrefForNamedType(type)}
      activePath={activePath}
      onClick={onClick}
    >
      {type.name}
    </SchemaMenuItem>

    {nestedTypes.subordinateTypes
      .get(type.name)
      ?.map(({ type: subordinateType, label }) => (
        <SchemaMenuItem
          ref={itemRef}
          key={subordinateType.name}
          to={hrefForNamedType(subordinateType)}
          activePath={activePath}
          onClick={onClick}
        >
          <Box paddingLeft="small">{label}</Box>
        </SchemaMenuItem>
      ))}
  </>
);

const NamedTypesSection = ({
  rootTypes,
  heading,
  itemRef,
  activePath,
  nestedTypes,
  onClick,
}: {
  rootTypes: GraphQLNamedType[];
  heading: string;
  itemRef: React.RefObject<HTMLAnchorElement>;
  activePath: string;
  nestedTypes: NestedTypes;
  onClick: () => void;
}) => (
  <MenuSection heading={heading}>
    {rootTypes.map((type) => (
      <RootType
        key={`${type.name}-group`}
        type={type}
        itemRef={itemRef}
        activePath={activePath}
        nestedTypes={nestedTypes}
        onClick={onClick}
      />
    ))}
  </MenuSection>
);

const OperationFieldsSection = ({
  heading,
  fields,
  operationType,
  itemRef,
  activePath,
  onClick,
}: {
  operationType: OperationTypeNode;
  heading: string;
  fields: Array<GraphQLField<unknown, unknown, unknown>>;
  itemRef: React.RefObject<HTMLAnchorElement>;
  activePath: string;
  onClick: () => void;
}) => (
  <MenuSection heading={heading}>
    {fields.map((field) => (
      <SchemaMenuItem
        ref={itemRef}
        key={field.name}
        to={hrefForOperation(operationType, field)}
        activePath={activePath}
        onClick={onClick}
      >
        {field.name}
      </SchemaMenuItem>
    ))}
  </MenuSection>
);

const WebhookSchemaSection = ({
  defNames,
  heading,
  itemRef,
  activePath,
  onClick,
}: {
  defNames: string[];
  heading: string;
  itemRef: React.RefObject<HTMLAnchorElement>;
  activePath: string;
  onClick: () => void;
}) => (
  <MenuSection heading={heading}>
    {typeof webhookSchema.title === 'string' && (
      <SchemaMenuItem
        to={hrefForWebhookSchemaRootType()}
        ref={itemRef}
        activePath={activePath}
        onClick={onClick}
      >
        {webhookSchema.title}
      </SchemaMenuItem>
    )}

    {defNames.map((defName) => (
      <SchemaMenuItem
        key={defName}
        to={hrefForWebhookSchemaDefinition(defName)}
        ref={itemRef}
        activePath={activePath}
        onClick={onClick}
      >
        {defName}
      </SchemaMenuItem>
    ))}
  </MenuSection>
);

export const SchemaMenu = ({
  scrollingParentRef,
  onClick,
}: {
  scrollingParentRef: React.RefObject<HTMLElement>;
  onClick: () => void;
}) => {
  const { pathname } = useLocation();
  const itemRef = useRef<HTMLAnchorElement>(null);

  const types = useMemo(() => {
    const nestedTypes = nestSubordinateTypes(schema.getTypeMap());

    const includeOperation = (field: GraphQLField<unknown, unknown>) =>
      !field.name.startsWith('_') &&
      typeof field.deprecationReason !== 'string';

    return {
      nestedTypes,
      enumTypes: nestedTypes.rootTypes.filter(isEnumType),
      interfaceTypes: nestedTypes.rootTypes.filter(isInterfaceType),
      inputObjectTypes: nestedTypes.rootTypes.filter(isInputObjectType),
      unionTypes: nestedTypes.rootTypes.filter(isUnionType),
      scalarTypes: nestedTypes.rootTypes.filter(isScalarType),
      objectTypes: nestedTypes.rootTypes.filter(isObjectType),
      queries: Object.values(schema.getQueryType()?.getFields() ?? {}).filter(
        includeOperation,
      ),
      mutations: Object.values(
        schema.getMutationType()?.getFields() ?? {},
      ).filter(includeOperation),
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // TODO: Change when launching
      if (pathname === '/schema-new') {
        scrollingParentRef.current?.scrollTo({
          top: 0,
          behavior: 'instant',
        });
        // TODO: Change when launching
      } else if (pathname.startsWith('/schema-new')) {
        itemRef.current?.scrollIntoView({
          behavior: 'instant',
          block: 'nearest',
        });
      }
    });

    return () => clearTimeout(timeout);
  }, [pathname, scrollingParentRef]);

  return (
    <Box style={{ overflowX: 'scroll' }}>
      <Box
        paddingY="medium"
        style={{ width: 'max-content', overflowX: 'scroll' }}
      >
        <Stack space="small">
          <SchemaMenuItem
            to="/"
            ref={itemRef}
            activePath={pathname}
            onClick={onClick}
          >
            <IconChevron direction="left" /> Back to main documentation
          </SchemaMenuItem>

          <SchemaMenuItem
            // TODO: Change when launching
            to="/schema-new/definition"
            ref={itemRef}
            activePath={pathname}
            onClick={onClick}
          >
            Schema definition
          </SchemaMenuItem>

          <Box paddingTop="small">
            <Stack space="large">
              {types.queries.length > 0 && (
                <OperationFieldsSection
                  operationType={OperationTypeNode.QUERY}
                  heading="Queries"
                  fields={types.queries}
                  itemRef={itemRef}
                  activePath={pathname}
                  onClick={onClick}
                />
              )}

              {types.mutations.length > 0 && (
                <OperationFieldsSection
                  operationType={OperationTypeNode.MUTATION}
                  heading="Mutations"
                  fields={types.mutations}
                  itemRef={itemRef}
                  activePath={pathname}
                  onClick={onClick}
                />
              )}

              {types.objectTypes.length > 0 && (
                <NamedTypesSection
                  rootTypes={types.objectTypes}
                  heading="Objects"
                  itemRef={itemRef}
                  activePath={pathname}
                  nestedTypes={types.nestedTypes}
                  onClick={onClick}
                />
              )}

              {types.interfaceTypes.length > 0 && (
                <NamedTypesSection
                  rootTypes={types.interfaceTypes}
                  heading="Interfaces"
                  itemRef={itemRef}
                  activePath={pathname}
                  nestedTypes={types.nestedTypes}
                  onClick={onClick}
                />
              )}

              {types.unionTypes.length > 0 && (
                <NamedTypesSection
                  rootTypes={types.unionTypes}
                  heading="Unions"
                  itemRef={itemRef}
                  activePath={pathname}
                  nestedTypes={types.nestedTypes}
                  onClick={onClick}
                />
              )}

              {types.inputObjectTypes.length > 0 && (
                <NamedTypesSection
                  rootTypes={types.inputObjectTypes}
                  heading="Input objects"
                  itemRef={itemRef}
                  activePath={pathname}
                  nestedTypes={types.nestedTypes}
                  onClick={onClick}
                />
              )}

              {types.enumTypes.length > 0 && (
                <NamedTypesSection
                  rootTypes={types.enumTypes}
                  heading="Enums"
                  itemRef={itemRef}
                  activePath={pathname}
                  nestedTypes={types.nestedTypes}
                  onClick={onClick}
                />
              )}

              {types.scalarTypes.length > 0 && (
                <NamedTypesSection
                  rootTypes={types.scalarTypes}
                  heading="Scalars"
                  itemRef={itemRef}
                  activePath={pathname}
                  nestedTypes={types.nestedTypes}
                  onClick={onClick}
                />
              )}

              {webhookSchemaDefNames.length > 0 && (
                <WebhookSchemaSection
                  defNames={webhookSchemaDefNames}
                  heading="Webhook JSON schema"
                  itemRef={itemRef}
                  activePath={pathname}
                  onClick={onClick}
                />
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
