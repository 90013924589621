import { useParams } from 'react-router-dom';

import { isObjectType } from 'src/utils/jsonSchema';

import { webhookSchema } from '../webhookSchema';

import { OopsSchemaPage } from './OopsSchemaPage';
import { WebhookObjectSchemaPage } from './WebhookObjectSchemaPage';

export const WebhookSchemaPage = () => {
  const { definitionName, propertyName } = useParams();

  const type = webhookSchema.definitions[definitionName ?? ''];
  if (!type || !isObjectType(type) || !definitionName) {
    return <OopsSchemaPage />;
  }

  return (
    <WebhookObjectSchemaPage
      heading={definitionName}
      objectName={definitionName}
      objectType={type}
      propertyName={propertyName}
    />
  );
};
