
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of identifiers for the position's `}<a parentName="p" {...{
        "href": "/schema-new/named-type/Location",
        "title": "A physical location with a persistent identifier"
      }}><inlineCode parentName="a">{`Location`}</inlineCode></a>{`s.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;