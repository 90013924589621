
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<a parentName="p" {...{
        "href": "/schema-new/named-type/Float",
        "title": "The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point)"
      }}><inlineCode parentName="a">{`Float`}</inlineCode></a>{` scalar type represents signed double-precision fractional values as specified by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/IEEE_floating_point"
      }}>{`IEEE 754`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;