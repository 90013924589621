
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "2023-job-posting-revision"
    }}>{`2023 Job Posting revision`}</h1>
    <p>{`The SEEK API was initially launched in our Australia & New Zealand markets.
It has since been enabled in `}<a parentName="p" {...{
        "href": "/introduction#multi-market-support"
      }}>{`additional markets`}</a>{` across the Asia Pacific (APAC):`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Code`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Brand`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`HK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Hong Kong`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.jobsdb.com/"
              }}>{`Jobsdb`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`ID`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Indonesia`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.jobstreet.com/"
              }}>{`Jobstreet`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`MY`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Malaysia`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`PH`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The Philippines`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`SG`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Singapore`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`TH`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Thailand`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobsdb`}</p></td>
        </tr>
      </tbody>
    </table>
    <p>{`This guide details the process of revising an existing SEEK API integration built for the Australia & New Zealand markets for our expansion across the Asia Pacific.`}</p>
    <h2 {...{
      "id": "seek-api-only"
    }}>{`SEEK API only`}</h2>
    <p>{`This section is applicable to partners that are only integrated with the SEEK API for the Australia & New Zealand markets,
and did not previously support posting to our Jobstreet & Jobsdb job boards.`}</p>
    <p>{`You will be able to expand your offering across the Asia Pacific upon completion of the revision.
The revision is required even if you do not offer your recruitment software in these additional markets,
as it includes changes to marketplace features that affect Australia & New Zealand hirers.`}</p>
    <img alt="" data-scoobie-style="none" height="315" src={require('../../../mermaid/.78fd07c3aeae78c0c216d54dccb3207f6f3e3752.mmd.svg')} title="" width="784" />
    <p>{`Partners that are only integrated with the SEEK API have two development phases to complete:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-1-developer-dashboard"
          }}>{`Developer Dashboard`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting"
          }}>{`Job Posting`}</a></p>
      </li>
    </ol>
    <h2 {...{
      "id": "seek-api-with-existing-jobstreet--jobsdb-integrations"
    }}>{`SEEK API with existing Jobstreet & Jobsdb integrations`}</h2>
    <p>{`This section is applicable to partners that are integrated with the SEEK API for the Australia & New Zealand markets,
but also previously supported posting to our Jobstreet & Jobsdb job boards via existing integrations with APOS or Adloader.`}</p>
    <p>{`You will be able to consolidate your onboarding processes, support model and technology upon completion of the revision.`}</p>
    <img alt="" data-scoobie-style="none" height="525" src={require('../../../mermaid/.d870b69b8e4179582577cc13056436bf396f3e10.mmd.svg')} title="" width="784" />
    <p>{`Partners with existing Jobstreet & Jobsdb integrations have four development phases to complete:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-1-developer-dashboard"
          }}>{`Developer Dashboard`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting"
          }}>{`Job Posting`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-3-apply-and-proactive-sourcing"
          }}>{`Apply & Talent Search Connect`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-4-transitioning-hirers"
          }}>{`Transitioning hirers`}</a></p>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;