import { Box, Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { Footer } from '../Footer/Footer';

interface Props {
  children: ComponentProps<typeof Stack>['children'];
  footer?: {
    paddingX?: ComponentProps<typeof Box>['paddingX'];
  };
}

export const FlexPageWithFooter = ({ children, footer }: Props) => (
  <Box display="flex" flexDirection="column" justifyContent="spaceBetween">
    <Box component="article" paddingY="xlarge">
      <Stack space="xlarge">{children}</Stack>
    </Box>

    <Footer {...footer} />
  </Box>
);
