
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A completed candidate submission for an `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationQuestionnaire",
        "title": "A set of questions presented to a candidate during an application"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;