
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A salary or compensation range for a position to be previewed prior to posting.`}</p>
    <p>{`Salary ranges are used to refine candidate job searches.
While the monetary values in `}<inlineCode parentName="p">{`minimumAmount`}</inlineCode>{` and `}<inlineCode parentName="p">{`maximumAmount`}</inlineCode>{` are not visible on job ads,
the currency and interval may be displayed alongside the `}<inlineCode parentName="p">{`descriptions`}</inlineCode>{` of the remuneration package for clarity.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;