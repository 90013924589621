import { Box, Heading, Stack } from 'braid-design-system';
import type { ComponentProps, ReactElement, ReactNode } from 'react';

import * as styles from './ContentHeader.css';

export const ContentHeader = ({
  children,
  icon,
  subHeading,
  titleText,
}: {
  children?: ReactNode;
  icon?: ComponentProps<typeof Heading>['icon'];
  subHeading?: ReactElement;
  titleText?: string;
}) => (
  <Box paddingY="large">
    {children === undefined ? null : (
      <Heading icon={icon} level="2">
        <Stack space="small">
          <Box className={styles.header} title={titleText}>
            {children}
          </Box>
          {subHeading}
        </Stack>
      </Heading>
    )}
  </Box>
);
