
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The role of the owner of the position opening.`}</p>
    <p>{`Currently, two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Agency`}</inlineCode>{` indicates a recruitment agency hiring on behalf of another company.`}</p>
        <p parentName="li">{`This only applies to position openings created outside of the SEEK API.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Company`}</inlineCode>{` indicates a company hiring on behalf of themselves.`}</p>
        <p parentName="li">{`This applies to all position openings created through the SEEK API.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;