import {
  Bleed,
  Box,
  IconChevron,
  IconNewWindow,
  Link,
  Text,
} from 'braid-design-system';
import {
  type ComponentProps,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  forwardRef,
} from 'react';
import { InternalLink } from 'scoobie';

import type { PageIconId } from 'src/page';

import { PageIcon } from '../PageIcon/PageIcon';

import * as styles from './MenuItem.css';

export const MenuItem = forwardRef<
  HTMLAnchorElement,
  {
    children: ReactNode;
    collapsible?: boolean;
    icon?: PageIconId;
    isExpanded: boolean;
    level?: 1 | 2 | 3 | 4;
    onClick?: ComponentProps<typeof InternalLink>['onClick'];
    setUserExpanded: Dispatch<SetStateAction<boolean | undefined>>;
    to?: string;
  }
>(
  (
    {
      children,
      collapsible = false,
      icon,
      isExpanded,
      level = 1,
      onClick,
      setUserExpanded,
      to,
    },
    ref,
  ) => {
    const link = to?.startsWith('/') ? 'internal' : 'external';

    if (to && link === 'external') {
      return (
        <Link href={to} onClick={onClick}>
          <Box className={[styles.link, styles.horizontalPadding[level]]}>
            <Box className={[styles.hierarchy[level]]}>
              <Box className={styles.linkInner}>
                <Text
                  icon={icon ? <PageIcon icon={icon} /> : undefined}
                  size="small"
                >
                  <Box className={styles.textFocus} component="span">
                    {children} <IconNewWindow />
                  </Box>
                </Text>
              </Box>
            </Box>
          </Box>
        </Link>
      );
    }

    const item = (
      <>
        <Box
          bottom={0}
          className={styles.activeIndicator}
          left={0}
          position="absolute"
          top={0}
        />

        <Box className={[styles.horizontalPadding[level]]}>
          <Box className={styles.hierarchy[level]}>
            <Box
              alignItems="center"
              className={styles.linkInner}
              display="flex"
              justifyContent="spaceBetween"
            >
              <Text
                icon={
                  icon ? (
                    <Box className={styles.pageIcon} component="span">
                      <PageIcon icon={icon} />
                    </Box>
                  ) : undefined
                }
                size="small"
              >
                <Box className={styles.textFocus} component="span">
                  {children}
                </Box>
              </Text>

              {collapsible ? (
                <Bleed vertical="xsmall">
                  <Box
                    alignItems="center"
                    borderRadius="large"
                    className={styles.expander}
                    display="flex"
                    justifyContent="center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setUserExpanded(!isExpanded);
                    }}
                    padding="xxsmall"
                    aria-expanded={isExpanded}
                  >
                    <Text size="xsmall">
                      <Box className={styles.textFocus} component="span">
                        <IconChevron direction={isExpanded ? 'up' : 'down'} />
                      </Box>
                    </Text>
                  </Box>
                </Bleed>
              ) : null}
            </Box>
          </Box>
        </Box>
      </>
    );

    return (
      <Box
        className={{
          [styles.link]: true,
        }}
        onClick={
          to
            ? undefined
            : (e) => {
                e.preventDefault();
                setUserExpanded(!isExpanded);
              }
        }
        position="relative"
      >
        {to ? (
          <InternalLink
            className={(isActive) => ({
              [styles.activeLink]: isActive,
            })}
            end
            href={to}
            onClick={onClick}
            ref={ref}
          >
            {item}
          </InternalLink>
        ) : (
          item
        )}
      </Box>
    );
  },
);
