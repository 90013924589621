
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The hirer ID to replay events for.`}</p>
    <p>{`If you omit this field, behaviour differs based on the scope of the webhook subscription:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A partner-scoped subscription defaults to replaying all related hirers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A hirer-scoped subscription defaults to its corresponding `}<a parentName="p" {...{
            "href": "/schema-new/named-type/WebhookSubscription#/field/hirerId",
            "title": "The optional hirer ID to receive events from"
          }}><inlineCode parentName="a">{`WebhookSubscription.hirerId`}</inlineCode></a>{`.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;