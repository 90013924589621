import { Divider, IconList, Stack, Text } from 'braid-design-system';
import type { GraphQLEnumType, GraphQLEnumValue } from 'graphql';
import { InlineCode, TableRow } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { DeprecationWarning } from '../components/DeprecationWarning';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { Strikethrough } from '../components/Strikethrough';

const EnumValueDetails = ({
  enumName,
  value,
}: {
  enumName: string;
  value: GraphQLEnumValue;
}) => (
  <TableRow>
    <Text
      tone={
        typeof value.deprecationReason === 'string' ? 'secondary' : undefined
      }
      weight="strong"
    >
      <Strikethrough strike={typeof value.deprecationReason === 'string'}>
        <InlineCode weight="weak">{value.name}</InlineCode>
      </Strikethrough>
    </Text>

    <Stack space="medium">
      {typeof value.deprecationReason === 'string' && (
        <DeprecationWarning type={`${enumName}.${value.name}.deprecation`} />
      )}

      <SchemaMarkdown type={`${enumName}.${value.name}`} />
    </Stack>
  </TableRow>
);

export const EnumSchemaPage = ({ enumType }: { enumType: GraphQLEnumType }) => (
  <BaseSchemaPage
    titleText={enumType.name}
    title={<InlineCode weight="weak">{enumType.name}</InlineCode>}
    subHeading={
      <Text icon={<IconList />} size="small" weight="medium" tone="secondary">
        Enum
      </Text>
    }
  >
    <SchemaMarkdown type={enumType.name} />

    <Divider />

    <SchemaSection heading="Enum values">
      {enumType.getValues().map((value) => (
        <EnumValueDetails
          key={value.name}
          enumName={enumType.name}
          value={value}
        />
      ))}
    </SchemaSection>
  </BaseSchemaPage>
);
