
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A date string compliant with the ISO 8601 "year", "year and month" or "complete date" formats.`}</p>
    <p>{`For example, `}<inlineCode parentName="p">{`"2020"`}</inlineCode>{`, `}<inlineCode parentName="p">{`"2020-05"`}</inlineCode>{` and `}<inlineCode parentName="p">{`"2020-05-27"`}</inlineCode>{` are all valid for `}<a parentName="p" {...{
        "href": "/schema-new/named-type/HistoryDate",
        "title": "A date string compliant with the ISO 8601 \"year\", \"year and month\" or \"complete date\" formats"
      }}><inlineCode parentName="a">{`HistoryDate`}</inlineCode></a>{`.`}</p>
    <p>{`This is used for dates in a candidate's position & employment histories where the precise month or day may not have been provided.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;