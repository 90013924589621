import { List, type Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { useOrderedListContext } from './OrderedListContext';
import { SIZE_TO_SPACE, type Size } from './size';

type Props = Pick<ComponentProps<typeof Stack>, 'children'> & {
  size: Size;
  start?: number;
};

export const MdxOrderedList = ({ children, size, start }: Props) => {
  const { NextOrderedListProvider, type } = useOrderedListContext();

  const space = SIZE_TO_SPACE[size];

  return (
    <NextOrderedListProvider>
      <List size={size} space={space} start={start} type={type}>
        {children}
      </List>
    </NextOrderedListProvider>
  );
};
