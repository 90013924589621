
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The candidate for the given `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
    <p>{`This will include the candidate's personal details along with all application profiles for a single hirer.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;