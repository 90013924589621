import { useMutation } from '@apollo/client';
import {
  Alert,
  Box,
  ButtonIcon,
  Column,
  Columns,
  Divider,
  IconClear,
  IconSend,
  Inline,
  Loader,
  Stack,
  Text,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { InfiniteScrollingList } from 'src/components/InfiniteScrollingList/InfiniteScrollingList';
import {
  PaginationDirectionPicker,
  usePaginationDirectionState,
} from 'src/components/PaginationDirectionPicker/PaginationDirectionPicker';
import { Query } from 'src/components/Query/Query';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import { usePermissions } from 'src/hooks/auth';
import type {
  ReplayWebhookSubscriptionByIdMutation,
  ReplayWebhookSubscriptionByIdMutationVariables,
  SubscriptionEventsQuery,
  SubscriptionEventsQueryVariables,
} from 'src/types/graphql';

import {
  type DeliveredIndicator,
  DeliveredIndicatorPicker,
  useDeliveredIndicatorState,
} from './DeliveredIndicatorPicker';
import { Event } from './Event';
import { REPLAY_WEBHOOK } from './mutations';
import { SUBSCRIPTION_EVENTS } from './queries';

import * as styles from './styles.css';

const MAX_SELECTED_EVENTS = 100;

interface Props {
  subscriptionId: string;
}

const eventPhraseForDeliveredIndicator = (
  deliveredIndicator: DeliveredIndicator,
): string => {
  switch (deliveredIndicator) {
    case true:
      return 'delivered events';
    case false:
      return 'undelivered events';
    case undefined:
      return 'events';
  }
};

export const EventList = ({ subscriptionId }: Props) => {
  const { permissions } = usePermissions();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [replayedEventCount, setReplayedEventCount] = useState<number>(0);

  const paginationDirectionState = usePaginationDirectionState();
  const deliveredIndicatorState = useDeliveredIndicatorState();

  const { firstOrLast, processPageInfo } = paginationDirectionState;
  const { deliveredIndicator } = deliveredIndicatorState;

  const [replay, replayResult] = useMutation<
    ReplayWebhookSubscriptionByIdMutation,
    ReplayWebhookSubscriptionByIdMutationVariables
  >(REPLAY_WEBHOOK);

  // TODO: this is a bit crap. Consider relaxing the SEEK API `events` query to
  // allow omission of `schemeId` when `filter.subscriptionId` is defined.
  const schemeId = subscriptionId.split(':')[0];

  const [selectedEventsSet, setSelectedEventsSet] = useState<Set<string>>(
    new Set(),
  );

  const setCheckedCallback = (eventOid: string) => {
    const selectedSet = selectedEventsSet;
    if (selectedSet.has(eventOid)) {
      selectedSet.delete(eventOid);
    } else {
      selectedSet.add(eventOid);
    }
    setSelectedEventsSet(new Set(selectedEventsSet));
  };

  const clearSelectedEvents = () => setSelectedEventsSet(new Set());

  useEffect(() => {
    if (!replayResult.called || replayResult.loading || replayResult.error) {
      return;
    }

    if (replayResult.data?.replayWebhookSubscription) {
      setShowSuccess(true);
    }
  }, [replayResult]);

  const selectedEventOids = Array.from(selectedEventsSet.values());
  const selectedEventCount = selectedEventOids.length;

  const PostReplaySuccessAlert = () => (
    <Alert
      tone="positive"
      closeLabel="Dismiss"
      onClose={() => {
        replayResult.reset();
        setShowSuccess(false);
      }}
    >
      <Text>
        {replayedEventCount}{' '}
        {replayedEventCount === 1 ? 'event is' : 'events are'} being replayed.
        Allow some time for these events to be received.
      </Text>
    </Alert>
  );

  const PostFailureAlert = () => (
    <Alert tone="critical">
      <Stack space="small">
        <Text>We could not replay your events.</Text>
        <Text size="small">{replayResult.error?.message}</Text>
      </Stack>
    </Alert>
  );

  return (
    <Stack space="none">
      <GutterBox>
        <Stack space="medium">
          <Columns space="none" alignY="center">
            <Column>
              {permissions.includes('mutate:webhooks') ? (
                <Box height="full">
                  {selectedEventCount > 0 ? (
                    <Inline space="xsmall" alignY="center">
                      <Box paddingY="small" borderRadius="full">
                        <Text
                          weight="strong"
                          size="small"
                        >{`${selectedEventCount} ${
                          selectedEventCount === 1 ? 'event' : 'events'
                        } selected`}</Text>
                      </Box>
                      <ButtonIcon
                        size="standard"
                        icon={<IconSend />}
                        onClick={() => {
                          const variables: ReplayWebhookSubscriptionByIdMutationVariables =
                            {
                              webhookSubscription: {
                                id: subscriptionId,
                              },
                              eventIds: selectedEventOids,
                            };
                          replay({
                            variables,
                          });
                          setReplayedEventCount(selectedEventOids.length);
                          clearSelectedEvents();
                        }}
                        id="replay-selected-events-button"
                        label="Replay selected events"
                      />
                      <ButtonIcon
                        size="standard"
                        icon={<IconClear />}
                        onClick={clearSelectedEvents}
                        id="clear-selected-events-button"
                        label="Clear selected events"
                      />
                    </Inline>
                  ) : (
                    <Box paddingY="small">
                      <Text tone="secondary" size="small">
                        Select events to replay them
                      </Text>
                    </Box>
                  )}
                </Box>
              ) : null}
            </Column>
            <Column width="content">
              <Inline align="right" alignY="center" space="gutter">
                <DeliveredIndicatorPicker {...deliveredIndicatorState} />
                <PaginationDirectionPicker
                  idPrefix="subscriptionEvent"
                  {...paginationDirectionState}
                />
              </Inline>
            </Column>
          </Columns>
          {replayResult.loading && <Loader size="small" />}
          {showSuccess && <PostReplaySuccessAlert />}
          {replayResult.error && <PostFailureAlert />}
        </Stack>
      </GutterBox>

      <Divider />

      <Query<SubscriptionEventsQuery, SubscriptionEventsQueryVariables>
        errorMessage="We couldn’t load your events."
        query={SUBSCRIPTION_EVENTS}
        variables={{
          [firstOrLast]: 10,
          deliveredIndicator,
          subscriptionId,
          schemeId,
        }}
        wrapperComponent={GutterBox}
      >
        {({ data, fetchMore }) => {
          const { edges, pageInfo } = data.events;

          const { shouldLoadMore, variables } = processPageInfo(pageInfo);

          const loadMore = () => fetchMore({ variables });

          return (
            <InfiniteScrollingList
              loadMore={loadMore}
              shouldLoadMore={shouldLoadMore}
              isEmpty={edges.length === 0}
              subject={eventPhraseForDeliveredIndicator(deliveredIndicator)}
              items={edges.map(({ node }) => ({
                key: node.id.value,
                element: (
                  <ScrollingXBox>
                    <Box
                      padding="gutter"
                      className={
                        selectedEventsSet.has(node.id.value)
                          ? styles.selectedEvent
                          : undefined
                      }
                    >
                      <Event
                        selectable={selectedEventCount < MAX_SELECTED_EVENTS}
                        checked={selectedEventsSet.has(node.id.value)}
                        setCheckedCallback={setCheckedCallback}
                        event={node}
                        context="to be replayed"
                      />
                    </Box>
                  </ScrollingXBox>
                ),
              }))}
            />
          );
        }}
      </Query>
    </Stack>
  );
};
