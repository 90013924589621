
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hirer-provided text used for the location suggestions.`}</p>
    <p>{`This currently expects a substring of a location's name (e.g. a suburb or city name).
Postcodes and street addresses are not supported.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;