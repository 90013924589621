
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The text value of the answer.`}</p>
    <p>{`For `}<inlineCode parentName="p">{`FreeText`}</inlineCode>{` questions this may contain whitespace such as the `}<inlineCode parentName="p">{`\\n`}</inlineCode>{` newline escape sequence.
For readability, you should display `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/white-space"
      }}>{`whitespace characters`}</a>{` instead of collapsing them.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;