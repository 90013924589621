import { Box } from 'braid-design-system';
import React, { type ComponentProps, useContext } from 'react';

import { TableContext } from './TableContext';

import * as styles from './TableRow.css';

interface Props extends Pick<ComponentProps<typeof Box>, 'children'> {
  selected?: boolean;
}

export const BaseTableRow = ({ children, selected }: Props) => {
  const { type } = useContext(TableContext);

  return (
    <Box
      className={{
        [styles.tableRow[type]]: true,
        [styles.tableRowSelection]: selected,
      }}
      component="tr"
    >
      {children}
    </Box>
  );
};
