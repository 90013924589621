
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier for the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/Event",
        "title": "A signal that an action has been performed on the SEEK platform that may be of interest to an integration partner"
      }}><inlineCode parentName="a">{`Event`}</inlineCode></a>{` that generated the attempts.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;