
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The product ID that was previously selected.`}</p>
    <p>{`The selected product ID may be temporarily stored in a job ad draft.
When the hirer resumes their posting workflow from the draft at a later date,
this previous product ID should not be directly used to post the job ad.
Instead, pass the previous product ID in this field.
The query will then pre-select an equivalent ad product and return new product IDs that can be used to post the job ad.`}</p>
    <p>{`Omit this field when you are newly creating or updating a job ad.
This is not the identifier of the job ad itself;
when you are updating a job ad,
supply the `}<inlineCode parentName="p">{`positionProfile.profileId`}</inlineCode>{` instead.`}</p>
    <p>{`Do not pass legacy ad types like `}<inlineCode parentName="p">{`Classic`}</inlineCode>{` and `}<inlineCode parentName="p">{`StandOut`}</inlineCode>{` in this field.
These values are not supported and will result in a `}<inlineCode parentName="p">{`BAD_USER_INPUT`}</inlineCode>{` error.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;