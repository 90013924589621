
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier itself.`}</p>
    <p>{`This has a maximum length of 255 characters,
and will always be representable with 255 bytes in UTF-8 encoding.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;