import { Box, Stack, Text } from 'braid-design-system';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SmartTextLink } from 'scoobie';

import { BaseSchemaPage } from './components/BaseSchemaPage';
import { NamedTypePage } from './pages/NamedTypePage';
import { OopsSchemaPage } from './pages/OopsSchemaPage';
import { OperationPage } from './pages/OperationPage';
import { SchemaDefinitionPage } from './pages/SchemaDefinitionPage';
import { WebhookSchemaPage } from './pages/WebhookSchemaPage';
import { WebhookSchemaRootPage } from './pages/WebhookSchemaRootPage';

const Home = () => (
  <BaseSchemaPage title="SEEK API Schema" divider={true}>
    <Box paddingTop="large">
      <Stack space="medium">
        <Text component="span">
          Automatically generated documentation for the SEEK API’s{' '}
          <SmartTextLink href="https://graphql.org/learn/schema/">
            GraphQL schema
          </SmartTextLink>
          . This can be used as a reference for the low-level details of the
          SEEK API’s operations & types.
        </Text>
        <Text>
          Head back to{' '}
          <SmartTextLink href="/">the main documentation</SmartTextLink> for
          examples and higher level documentation.
        </Text>
      </Stack>
    </Box>
  </BaseSchemaPage>
);

export const SchemaPage = () => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect from indoctrinate routes
    if (pathname.replace(/\/$/, '') === '/schema' && hash.startsWith('#/')) {
      const parts = hash.replaceAll(/(^#\/|\/$)/g, '').split('/');

      if (
        ['definition', 'named-type', 'query', 'mutation', 'webhooks'].includes(
          parts[0],
        )
      ) {
        if (parts.length === 2) {
          navigate(`/schema/${parts.join('/')}`);
        } else if (parts.length === 4) {
          navigate(
            `/schema/${parts.slice(0, 2).join('/')}#/${parts
              .slice(2)
              .join('/')}`,
          );
        }
      }
    }
  }, [pathname, hash, navigate]);

  // TODO: Figure out where the field/ hyperlinking/scrolling went

  return (
    <Routes>
      <Route path="/" Component={Home} />

      <Route path="/query/:operationName" Component={OperationPage} />
      <Route path="/mutation/:operationName" Component={OperationPage} />
      <Route path="/named-type/:typeName" Component={NamedTypePage} />
      <Route path="/definition" Component={SchemaDefinitionPage} />
      <Route path="/webhooks" Component={WebhookSchemaRootPage} />
      <Route path="/webhooks/:definitionName" Component={WebhookSchemaPage} />

      <Route path="*" Component={OopsSchemaPage} />
    </Routes>
  );
};
