
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The hirer's job reference.`}</p>
    <p>{`This field is used for tracing & debugging.
It does not impact the available ad products or their pricing.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;