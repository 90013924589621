import { Box, Text } from 'braid-design-system';
import { type GraphQLType, isNonNullType } from 'graphql';
import { InlineCode } from 'scoobie';

import * as styles from './NameLabel.css';

interface Props {
  children: string;
  inline?: boolean;
  required?: boolean;
  type?: GraphQLType;
}

export const NameLabel = ({ children, inline, required, type }: Props) => {
  const nameLabel = (
    <>
      <InlineCode weight="weak">{children}</InlineCode>

      {required || isNonNullType(type) ? (
        <Box className={styles.critical} component="span">
          *
        </Box>
      ) : undefined}
    </>
  );

  return inline ? nameLabel : <Text weight="strong">{nameLabel}</Text>;
};
