
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A resource identifier for the position.`}</p>
    <p>{`This identifies the relevant position profile within the position opening.
It can be matched with the position profile `}<inlineCode parentName="p">{`positionUri`}</inlineCode>{` field.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For candidate application profiles from the Application Export use case,
this is the public web URL of the posted job ad.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For purchased candidate profiles from the Proactive Sourcing use case,
this is the object identifier of the relevant unposted position profile.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;