import { Badge } from 'braid-design-system';

import { isValidStatusCode } from './StatusCodes';

const statusBadgeTones = {
  Running: 'neutral',
  Completed: 'positive',
  Submitted: 'promote',
  Cancelled: 'caution',
} as const;

interface StatusCodeBadgeProps {
  statusCode: string;
}

export const StatusCodeBadge = ({ statusCode }: StatusCodeBadgeProps) => {
  if (!isValidStatusCode(statusCode)) {
    return <Badge tone="neutral">Unknown status</Badge>;
  }

  return <Badge tone={statusBadgeTones[statusCode]}>{statusCode}</Badge>;
};
