
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "monitoring-your-integration"
    }}>{`Monitoring your integration`}</h1>
    <p>{`SEEK is responsible for the availability of the SEEK API,
however there are important aspects to monitor with your integration to ensure it is working as expected.`}</p>
    <h2 {...{
      "id": "seek-api-status"
    }}>{`SEEK API status`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://status.seek.com"
      }}>{`SEEK API status page`}</a>{` provides real-time information on the availability of the SEEK API, such as outages or degraded performance.
Planned maintenance is also communicated in advance to minimise disruption to your integration.`}</p>
    <p>{`We recommend you subscribe to the status page to receive notifications of any issues that may impact your integration.`}</p>
    <h2 {...{
      "id": "release-notes"
    }}>{`Release notes`}</h2>
    <p>{`We regularly update the SEEK API with new features, improvements, and bug fixes.
To stay informed of these changes, subscribe to our `}<a parentName="p" {...{
        "href": "/introduction/release-notes"
      }}>{`release notes`}</a>{`.
This ensures that you are aware of any upcoming changes that may impact your integration and can plan accordingly.`}</p>
    <h2 {...{
      "id": "developer-dashboard"
    }}>{`Developer Dashboard`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "/manage/"
      }}>{`Developer Dashboard`}</a>{` provides a range of tools to help you monitor your integration.
You should subscribe to alerts to be notified of issues with your webhooks, such as delivery failures.`}</p>
    <p>{`There are a number of tools available in the dashboard for debugging and administration tasks.
These include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Viewing your webhook endpoint performance and delivery status, and resending any failed events`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Viewing your configured hirer relationships`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Managing your credentials`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Viewing job posting features such as branding and locations`}</p>
      </li>
    </ul>
    <p>{`We highly recommend you familiarise yourself with the Developer Dashboard and use it to monitor your integration.`}</p>
    <h2 {...{
      "id": "monitoring-your-software-directly"
    }}>{`Monitoring your software directly`}</h2>
    <p>{`While SEEK provides a number of tools to assist, ultimately your own software will also need to be monitored.
We recommend you set up logging and alerting for `}<a parentName="p" {...{
        "href": "/graphql/error-responses"
      }}>{`error responses from the SEEK API`}</a>{`.
Your logging should include `}<a parentName="p" {...{
        "href": "/graphql/in-practice#tracing-requests"
      }}>{`tracing information`}</a>{`; pass these details on to `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/contactus"
      }}>{`SEEK’s support team`}</a>{` when asking for assistance.`}</p>
    <h2 {...{
      "id": "ask-for-help"
    }}>{`Ask for help`}</h2>
    <p>{`If you encounter any issues with your integration, `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/contactus"
      }}>{`reach out to SEEK’s support team`}</a>{`.
If you embed panels in your software,
review our documentation on `}<a parentName="p" {...{
        "href": "/introduction/panels#troubleshooting"
      }}>{`panel troubleshooting`}</a>{` and include logs in your support request.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;