
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The type of the signal to be sent.`}</p>
    <p>{`Currently, only `}<inlineCode parentName="p">{`ApplicationComplete`}</inlineCode>{` is supported.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;