import { Alert, Text } from 'braid-design-system';
import React, { type ReactNode } from 'react';
import { SmartTextLink } from 'scoobie';

import { PageGutter } from 'src/components/PageGutter/PageGutter';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * Catches errors thrown by our system status components
 *
 * This allows the home page to still render if we get an unexpected response
 * from the Atlassian Statuspage API.
 */
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageGutter>
          <Alert tone="caution">
            <Text>
              Unable to render the SEEK API’s status. Visit the{' '}
              <SmartTextLink href="https://status.seek.com">
                SEEK API status page
              </SmartTextLink>{' '}
              instead.
            </Text>
          </Alert>
        </PageGutter>
      );
    }

    return this.props.children;
  }
}
