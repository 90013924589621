
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The replay status to filter by.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/WebhookSubscriptionReplay#/field/statusCode",
        "title": "The current status of the replay"
      }}><inlineCode parentName="a">{`WebhookSubscriptionReplay.statusCode`}</inlineCode></a>{` documentation for a list of possible status codes.`}</p>
    <p>{`If this is not provided then replays of all statuses will be returned.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;