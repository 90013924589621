import { Box, Link, Stack, Text } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import * as styles from './TileLink.css';

type Align = 'center' | 'left' | 'right';

const Overlay = ({
  className,
}: Pick<ComponentProps<typeof Box>, 'className'>) => (
  <Box
    borderRadius="standard"
    className={className}
    component="span"
    inset={0}
    opacity={0}
    pointerEvents="none"
    position="absolute"
    transition="fast"
  />
);

export const TileLink = ({
  align = ['left', 'left'],
  description,
  href,
  icon,
  reverse,
  title,
  badge,
}: {
  align?: [Align, Align];
  description?: ReactNode;
  href: string;
  icon: ComponentProps<typeof Text>['icon'];
  reverse?: boolean;
  title: string;
  badge?: ComponentProps<typeof Stack>['children'];
}) => (
  <Link className={styles.tileLink} href={href} role="button">
    <Box
      borderRadius="standard"
      boxShadow="borderNeutralLight"
      className={styles.tile}
      height="full"
      paddingX="gutter"
      paddingY="medium"
      position="relative"
    >
      <Overlay className={styles.hoverOverlay} />
      <Overlay className={styles.activeOverlay} />

      <Box position="relative">
        <Stack align={align} space="medium">
          <Box
            className={styles.iconGap}
            display="flex"
            flexDirection={reverse ? 'rowReverse' : 'row'}
          >
            <Text>{icon}</Text>

            <Text weight="strong">
              {title}
              {badge ? <> {badge}</> : null}
            </Text>
          </Box>

          {description ? <Text size="small">{description}</Text> : null}
        </Stack>
      </Box>
    </Box>
  </Link>
);
