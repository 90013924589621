import { MockJobCategorySelect, MockJobCategorySuggest } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

export const SeekJobCategorySelect = () => (
  <BorderCard>
    <MockJobCategorySelect
      id="seek-job-category-select"
      label="Category"
      schemeId="seekAnz"
      showStorybookAction
    />
  </BorderCard>
);

export const SeekJobCategorySuggest = () => (
  <BorderCard>
    <MockJobCategorySuggest
      positionProfile={{
        // Our mock isn't clever enough to vary itself on these inputs (yet).
        positionLocation: [],
        positionTitle: '',
        positionFormattedDescriptions: [],
        positionOrganizations: [],
      }}
      onSelect={() => undefined}
      schemeId="seekAnz"
      showStorybookAction
    />
  </BorderCard>
);
