import { Divider, IconDocument, Text, TextLink } from 'braid-design-system';
import type { GraphQLObjectType } from 'graphql';
import { InlineCode, TableRow } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';
import { hrefForWebhookSchemaDefinition } from 'src/utils/href';
import { type ObjectType, isObjectType } from 'src/utils/jsonSchema';
import { sortGraphqlEntities } from 'src/utils/sort';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { FieldDetails } from '../components/FieldDetails';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSeenIn } from '../components/TypeSeenIn';
import { TypeSnippet } from '../components/TypeSnippet';
import { webhookSchema } from '../webhookSchema';

const WebhookPayloadSection = ({
  name,
  definition,
}: {
  name: string;
  definition: ObjectType;
}) => (
  <SchemaSection heading="Webhook event payload">
    <TableRow>
      <Text>
        <InlineCode weight="weak">
          <TextLink href={hrefForWebhookSchemaDefinition(name)}>
            {name}
          </TextLink>
        </InlineCode>
      </Text>

      {typeof definition.description === 'string' && (
        <SchemaMarkdown type={`webhook.${name}`} />
      )}
    </TableRow>
  </SchemaSection>
);

export const ObjectSchemaPage = ({
  objectType,
  fieldName,
}: {
  objectType: GraphQLObjectType;
  fieldName?: string;
}) => {
  const interfaces = objectType.getInterfaces();

  const isEventImplementation =
    interfaces.length === 1 && interfaces[0].name === 'Event';

  const webhookDefinition = webhookSchema.definitions[objectType.name];

  return (
    <BaseSchemaPage
      titleText={objectType.name}
      title={<InlineCode weight="weak">{objectType.name}</InlineCode>}
      subHeading={
        <Text
          icon={<IconDocument />}
          size="small"
          weight="medium"
          tone="secondary"
        >
          Object
        </Text>
      }
    >
      <SchemaMarkdown type={objectType.name} />

      {interfaces.length > 0 && (
        <>
          <Divider />
          <SchemaSection heading="Implements">
            {interfaces.map((interfaceType) => (
              <TypeSnippet key={interfaceType.name} type={interfaceType} />
            ))}
          </SchemaSection>
        </>
      )}

      <Divider />

      <SchemaSection heading="Fields" wrapper="usage-table">
        {Object.entries(objectType.getFields())
          .filter(([_, field]) => !field.name.startsWith('_unstable_'))
          .sort(sortGraphqlEntities)
          .map(([fieldNameKey, field]) => (
            <FieldDetails
              key={fieldNameKey}
              objectName={objectType.name}
              highlight={fieldName === fieldNameKey}
              field={field}
              deprecated={typeof field.deprecationReason === 'string'}
            />
          ))}
      </SchemaSection>

      <Divider />

      {isEventImplementation &&
      webhookDefinition &&
      isObjectType(webhookDefinition) ? (
        <WebhookPayloadSection
          name={objectType.name}
          definition={webhookDefinition}
        />
      ) : (
        <TypeSeenIn name={objectType.name} />
      )}
    </BaseSchemaPage>
  );
};
