
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of suggested job categories for the provided partial `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile",
        "title": "A profile of a position opening"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` in decreasing order of relevance.`}</p>
    <p>{`A maximum of 10 job categories can be recommended.`}</p>
    <p>{`This query accepts browser tokens that include the `}<inlineCode parentName="p">{`query:ontologies`}</inlineCode>{` scope.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;