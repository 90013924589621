
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The description type.`}</p>
    <p>{`Currently, three identifiers are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AdvertisementDetails`}</inlineCode>{` is the detailed description of the position that appears on the job ad.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SearchBulletPoint`}</inlineCode>{` is a highlight or selling point of the position that appears in search results.
This will not appear on the job ad details page.
The number of bullet points that a job can provide is based on the `}<a parentName="p" {...{
            "href": "/schema-new/named-type/AdvertisementProductFeatures",
            "title": "Additional information that is accepted when posting a job ad with this advertisement product"
          }}><inlineCode parentName="a">{`AdvertisementProductFeatures`}</inlineCode></a>{`'s `}<inlineCode parentName="p">{`searchBulletPoints.limit`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SearchSummary`}</inlineCode>{` is a short description that appears in search results.
This will not appear on the job ad details page.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;