
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text, Tiles } from 'braid-design-system';
import { TileLink } from '../components/TileLink/TileLink';
import { PageIcon } from '../components/PageIcon/PageIcon';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`The SEEK API provides access to SEEK’s employment marketplace.
This documentation is targeted at developers integrating their recruitment software with SEEK.`}</p>
    <h2 {...{
      "id": "integration-process"
    }}>{`Integration process`}</h2>
    <p>{`SEEK uses a seven stage process for developing integrations with the SEEK API to ensure a high quality experience for our mutual hirers:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Review the documentation`}</strong></p>
        <p parentName="li">{`Read through the documentation available on this website, and the `}<a parentName="p" {...{
            "href": "https://talent.seek.com.au/partners/terms-of-use/"
          }}>{`SEEK API Terms of Use`}</a>{`,
to understand the concepts of the SEEK API and the scope of integrating it with your software.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Request to integrate`}</strong></p>
        <p parentName="li">{`Fill in our `}<a parentName="p" {...{
            "href": "https://talent.seek.com.au/partners/integration-request/"
          }}>{`integration request form`}</a>{`, or reach out to your dedicated Partner Manager, to get started.
We’ll be in contact to guide you through the process and set up your access to the `}<a parentName="p" {...{
            "href": "/manage/"
          }}>{`Developer Dashboard`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Build and test with the Playground`}</strong></p>
        <p parentName="li">{`Once you can access the Developer Dashboard, provision `}<a parentName="p" {...{
            "href": "/graphql/playground"
          }}>{`Playground`}</a>{` credentials on the `}<a parentName="p" {...{
            "href": "/manage/credentials"
          }}>{`Developer Dashboard’s credentials page`}</a>{`.
These credentials grant you limited permissions to the SEEK API’s read-only mock data;
use them to test accessing the SEEK API and mapping its data structures.
While you can test the input validation of mutations with these credentials,
the SEEK API will ultimately forbid requests to mutate our read-only mock data.`}</p>
        <p parentName="li">{`The Developer Dashboard also provides a view of your live `}<a parentName="p" {...{
            "href": "/auth/hirer-relationships"
          }}>{`hirer relationships`}</a>{` and `}<a parentName="p" {...{
            "href": "/events"
          }}>{`events`}</a>{`.
This will give you visibility on your production SEEK API access before requesting your partner credentials.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Request live credentials`}</strong></p>
        <p parentName="li">{`Once you’ve reached the limits of what you can do with the Playground, contact SEEK to request access to manage live `}<a parentName="p" {...{
            "href": "/auth#client-credentials"
          }}>{`partner credentials`}</a>{`.`}</p>
        <p parentName="li">{`Once approved, provision your live credentials using the `}<a parentName="p" {...{
            "href": "/manage/credentials"
          }}>{`Developer Dashboard’s credentials page`}</a>{`.
Live credentials grant you access to live production data.`}</p>
        <p parentName="li">{`At the same time, SEEK will add a relationship between your partner account and an unsearchable SEEK test hirer.
Test hirers can export candidate applications and post job ads free of charge, but their job ads don’t appear in SEEK’s search results.
This should be sufficient to test your software end-to-end against the SEEK API.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Test your integration`}</strong></p>
        <p parentName="li">{`SEEK may share a test plan with you to ensure your integration meets our quality standards.
In addition, we may require access to your sandbox/test environment as applicable, to do our own validation.
This is an important milestone for your integration,
ensuring it meets user experience expectations and appropriately represents SEEK’s value proposition.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Release your integration`}</strong></p>
        <p parentName="li">{`When you’re ready to go live, contact SEEK to discuss your release plans.
For initial integrations, SEEK will work with you to ensure a smooth transition for you and our mutual hirers.
In some cases, this may involve a limited pilot release to a subset of your customers.`}</p>
        <p parentName="li">{`For ongoing changes to your integration, SEEK asks to remain informed of your release plans.
This allows us to communicate accurate information to our mutual hirers during Sales and Support interactions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Maintain your integration`}</strong></p>
        <p parentName="li">{`Subscribe to our `}<a parentName="p" {...{
            "href": "/introduction/release-notes"
          }}>{`release notes`}</a>{` to stay informed of changes as they reach
general availability and evolve your integration accordingly.`}</p>
        <p parentName="li">{`Review our `}<a parentName="p" {...{
            "href": "/guides/monitoring"
          }}>{`Monitoring`}</a>{` documentation to understand how to monitor your integration’s performance and troubleshoot issues, including use of the `}<a parentName="p" {...{
            "href": "/manage/"
          }}>{`Developer Dashboard`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://talent.seek.com.au/contactus"
          }}>{`Contact SEEK’s support team`}</a>{` for ongoing technical support.`}</p>
      </li>
    </ol>
    <p>{`We recommend to repeat these steps for any new development, not just for your initial integration.
In particular, continue to use your `}<a parentName="p" {...{
        "href": "/graphql/playground"
      }}>{`Playground`}</a>{` credentials for exploratory testing, as your partner credentials are highly sensitive.`}</p>
    <h2 {...{
      "id": "technology"
    }}>{`Technology`}</h2>
    <p>{`The SEEK API leverages existing industry standards whenever possible.
The core of the API is built on:`}</p>

    <Tiles columns={[1, 1, 2]} space="small" mdxType="Tiles">
  <TileLink description="Securely connect to SEEK with OAuth 2.0 flows" href="/auth" icon={<PageIcon icon="Auth" mdxType="PageIcon" />} title="Auth" mdxType="TileLink" />
  <TileLink description="Access our API endpoint and follow best practices" href="/graphql" icon={<PageIcon icon="GraphQL" mdxType="PageIcon" />} title="GraphQL" mdxType="TileLink" />
  <TileLink description="Embed pre-built components to reduce integration effort" href="/introduction/web-components" icon={<PageIcon icon="Panel" mdxType="PageIcon" />} title="Web components" mdxType="TileLink" />
  <TileLink description="Be notified when an action occurs on our marketplace with our webhooks" href="/events" icon={<PageIcon icon="Webhook" mdxType="PageIcon" />} title="Events" mdxType="TileLink" />
    </Tiles>
    <h2 {...{
      "id": "multi-market-support"
    }}>{`Multi-market support`}</h2>
    <p>{`While the SEEK API was initially launched in Australia & New Zealand (ANZ),
it was built to be extended to other markets within the `}<a parentName="p" {...{
        "href": "https://www.seek.com.au/about"
      }}>{`SEEK Group`}</a>{`.`}</p>
    <p>{`The SEEK API is now enabled across our Asia-Pacific (APAC) markets:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Code`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Brand`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`HK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Hong Kong`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.jobsdb.com/"
              }}>{`Jobsdb`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`ID`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Indonesia`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.jobstreet.com/"
              }}>{`Jobstreet`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`MY`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Malaysia`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`PH`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The Philippines`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`SG`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Singapore`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`TH`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Thailand`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Jobsdb`}</p></td>
        </tr>
      </tbody>
    </table>
    <p>{`Our documentation has been revised with guidance on regional features for new integrations.`}</p>
    <p>{`If you previously built an integration for the Australia & New Zealand markets,
refer to the `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift"
      }}>{`2023 Job Posting revision`}</a>{` to accommodate changes to the SEEK marketplace as we have expanded across the Asia Pacific.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;