
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The subscription stream to retrieve events from.`}</p>
    <p>{`This can be used in combination with `}<inlineCode parentName="p">{`deliveredIndicator`}</inlineCode>{` to identify events that were not successfully delivered through a particular webhook subscription.`}</p>
    <p>{`Omit this field to consume events solely through GraphQL polling.
This will retrieve events from a persistent stream that is not associated with a webhook subscription.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;