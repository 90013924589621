import { Bleed, Box } from 'braid-design-system';

import { SchemaMarkdown } from './SchemaMarkdown';

export const DeprecationWarning = ({ type }: { type: string }) => (
  <Bleed space="small">
    <Box background="cautionLight" padding="small">
      <SchemaMarkdown type={type} />
    </Box>
  </Bleed>
);
