
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The status of the question.`}</p>
    <p>{`Currently, two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Active`}</inlineCode>{` indicates that a question is active and can be used as input for the `}<a parentName="p" {...{
            "href": "/schema-new/mutation/createApplicationQuestionnaire",
            "title": "Creates a new questionnaire"
          }}><inlineCode parentName="a">{`createApplicationQuestionnaire`}</inlineCode></a>{` mutation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Retired`}</inlineCode>{` indicates that a question has been retired and cannot be used as input for the `}<a parentName="p" {...{
            "href": "/schema-new/mutation/createApplicationQuestionnaire",
            "title": "Creates a new questionnaire"
          }}><inlineCode parentName="a">{`createApplicationQuestionnaire`}</inlineCode></a>{` mutation.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;