
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An alternate version of `}<inlineCode parentName="p">{`summary`}</inlineCode>{` that includes HTML markup.`}</p>
    <p>{`This is intended to be parsed and rendered by a web browser for displaying in a user interface.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;