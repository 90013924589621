import { Stack, Text } from 'braid-design-system';
import type { GraphQLInputField, OperationTypeNode } from 'graphql';
import { TableRow } from 'scoobie';

import { NameTextLink } from 'src/components/NameTextLink/NameTextLink';
import { TypeLink } from 'src/components/TypeLink/TypeLink';
import { SchemaMarkdown } from 'src/pages/schema/components/SchemaMarkdown';
import {
  hrefForNamedTypeField,
  hrefForOperationArgument,
} from 'src/utils/href';

import { DeprecationWarning } from './DeprecationWarning';

interface Props {
  objectName: string;

  operationType?: OperationTypeNode;
  operationName?: string;

  field: GraphQLInputField;
  highlight?: boolean;
}

export const InputFieldDetails = ({
  objectName,
  operationType,
  operationName,
  field,
  highlight,
}: Props) => {
  const hrefLinkTo =
    operationType && operationName
      ? hrefForOperationArgument(operationType, operationName, field.name)
      : hrefForNamedTypeField(objectName, field);

  const elementIdLinkTo = operationType
    ? `${operationType}-${operationName}-${field.name}`
    : `${objectName}-${field.name}`;
  return (
    <TableRow selected={highlight}>
      <Stack space="medium">
        <NameTextLink
          name={field.name}
          type={field.type}
          highlight={highlight}
          to={hrefLinkTo}
          id={elementIdLinkTo}
          deprecated={typeof field.deprecationReason === 'string'}
        />

        <Text>
          <TypeLink type={field.type} />
        </Text>
      </Stack>

      <Stack space="medium">
        {typeof field.deprecationReason === 'string' && (
          <DeprecationWarning
            type={`${objectName}.${field.name}.deprecation`}
          />
        )}

        <SchemaMarkdown type={`${objectName}.${field.name}`} />
      </Stack>
    </TableRow>
  );
};
