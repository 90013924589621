
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of human readable descriptions of the certification.`}</p>
    <p>{`This can contain multiple descriptions from two different sources:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Free text descriptions provided by the candidate.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Human readable descriptions automatically generated from SEEK's internal structured data.`}</p>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;