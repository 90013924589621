
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The way to present the response choice selection.`}</p>
    <p>{`Currently, three codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SingleChoice`}</inlineCode>{` indicates a question that expects a single preferred response choice.`}</p>
        <p parentName="li">{`A radio group or dropdown may be used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`MultiChoice`}</inlineCode>{` indicates a question that expects multiple preferred response choices.`}</p>
        <p parentName="li">{`A checkbox group may be used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Range`}</inlineCode>{` indicates a question that expects two response choices representing the minimum and maximum preferred values.`}</p>
        <p parentName="li">{`A set of two dropdowns may be used.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;