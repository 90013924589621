import {
  Divider,
  IconSearch,
  Inline,
  Notice,
  Stack,
  Text,
  TextField,
  TextLink,
} from 'braid-design-system';
import { useState } from 'react';
import { InlineCode } from 'scoobie';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { InfiniteScrollingList } from 'src/components/InfiniteScrollingList/InfiniteScrollingList';
import {
  PaginationDirectionPicker,
  type PaginationDirectionState,
  usePaginationDirectionState,
} from 'src/components/PaginationDirectionPicker/PaginationDirectionPicker';
import { Query } from 'src/components/Query/Query';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import type {
  WebhookRequestByIdQuery,
  WebhookRequestByIdQueryVariables,
  WebhookRequestsForSubscriptionQuery,
  WebhookRequestsForSubscriptionQueryVariables,
} from 'src/types/graphql';

import type { DescriptionCode } from './DescriptionCode';
import {
  DescriptionCodePicker,
  useDescriptionCodeState,
} from './DescriptionCodePicker';
import { WebhookRequest } from './WebhookRequest';
import {
  GET_WEBHOOK_REQUESTS_FOR_SUBSCRIPTION,
  GET_WEBHOOK_REQUEST_BY_ID,
} from './queries';

const REQUEST_ID_REGEXP =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

const FilteredWebhookRequestList = ({
  subscriptionId,
  paginationDirectionState: { firstOrLast, processPageInfo },
  descriptionCodes,
}: {
  subscriptionId: string;
  paginationDirectionState: PaginationDirectionState;
  descriptionCodes: DescriptionCode[];
}) => (
  <Query<
    WebhookRequestsForSubscriptionQuery,
    WebhookRequestsForSubscriptionQueryVariables
  >
    errorMessage="We couldn’t load your subscription’s requests."
    query={GET_WEBHOOK_REQUESTS_FOR_SUBSCRIPTION}
    variables={{
      [firstOrLast]: 10,
      subscriptionId,
      filter: {
        descriptionCodes,
      },
    }}
    wrapperComponent={GutterBox}
  >
    {({ data, fetchMore }) => {
      const { edges, pageInfo } = data.webhookRequestsForSubscription;

      const { shouldLoadMore, variables } = processPageInfo(pageInfo);

      const loadMore = () => fetchMore({ variables });

      return (
        <InfiniteScrollingList
          loadMore={loadMore}
          shouldLoadMore={shouldLoadMore}
          isEmpty={edges.length === 0}
          subject="requests"
          items={edges.map(({ node }) => ({
            key: node.requestId,
            element: (
              <ScrollingXBox>
                <GutterBox>
                  <WebhookRequest request={node} />
                </GutterBox>
              </ScrollingXBox>
            ),
          }))}
        />
      );
    }}
  </Query>
);

const WebhookRequestByIdResult = ({
  subscriptionId,
  requestId,
  result: { webhookRequest },
}: {
  subscriptionId: string;
  requestId: string;
  result: WebhookRequestByIdQuery;
}) => {
  // Check that both the request & subscription exist.
  // If the subscription has been deleted there's not much we can do in our
  // subscription-specific UI.
  if (!webhookRequest) {
    return <Text tone="secondary">Request not found.</Text>;
  }

  const { webhookSubscription } = webhookRequest;

  if (!webhookSubscription) {
    return (
      <Notice tone="critical">
        <Text>
          Request <InlineCode>{requestId}</InlineCode> was made by a deleted
          subscription.
        </Text>
      </Notice>
    );
  }

  // Make sure the request belongs to this subscription
  if (webhookSubscription.id.value !== subscriptionId) {
    const subscriptionLink = `/manage/webhooks/${encodeURIComponent(
      webhookSubscription.id.value,
    )}`;

    return (
      <Notice tone="info">
        <Text>
          Request <InlineCode>{requestId}</InlineCode> was made by{' '}
          <TextLink href={subscriptionLink}>another subscription</TextLink>.
        </Text>
      </Notice>
    );
  }

  return <WebhookRequest request={webhookRequest} />;
};

const WebhookRequestById = ({
  subscriptionId,
  requestId,
}: {
  subscriptionId: string;
  requestId: string;
}) => (
  <Query<WebhookRequestByIdQuery, WebhookRequestByIdQueryVariables>
    errorMessage="We couldn’t load the request."
    query={GET_WEBHOOK_REQUEST_BY_ID}
    variables={{
      schemeId: subscriptionId.split(':')[0],
      requestId,
    }}
    wrapperComponent={GutterBox}
  >
    {({ data }) => (
      <ScrollingXBox>
        <GutterBox>
          <WebhookRequestByIdResult
            subscriptionId={subscriptionId}
            requestId={requestId}
            result={data}
          />
        </GutterBox>
      </ScrollingXBox>
    )}
  </Query>
);

interface Props {
  subscriptionId: string;
}

export const WebhookRequestList = ({ subscriptionId }: Props) => {
  const descriptionCodeState = useDescriptionCodeState();
  const paginationDirectionState = usePaginationDirectionState();
  const [requestId, setRequestId] = useState<string>('');

  const { descriptionCodes } = descriptionCodeState;

  const normalisedRequestId = requestId.trim().toLocaleLowerCase();

  const invalidRequestId =
    normalisedRequestId && !REQUEST_ID_REGEXP.test(normalisedRequestId);

  return (
    <Stack space="none">
      <GutterBox>
        <Stack space="gutter">
          <Inline align="right" alignY="center" space="gutter">
            <DescriptionCodePicker {...descriptionCodeState} />

            <PaginationDirectionPicker
              idPrefix="webhookRequest"
              {...paginationDirectionState}
            />
          </Inline>

          <TextField
            id="request-id-search"
            icon={<IconSearch />}
            type="search"
            value={requestId}
            onChange={(event) => setRequestId(event.currentTarget.value)}
            onClear={() => setRequestId('')}
            placeholder="Look up request by ID"
            aria-label="Look up request by ID"
            message={invalidRequestId ? 'Request ID must be a UUID' : undefined}
            tone={invalidRequestId ? 'critical' : 'neutral'}
          />
        </Stack>
      </GutterBox>

      <Divider />

      {normalisedRequestId && !invalidRequestId ? (
        <WebhookRequestById
          subscriptionId={subscriptionId}
          requestId={normalisedRequestId}
        />
      ) : (
        <FilteredWebhookRequestList
          subscriptionId={subscriptionId}
          paginationDirectionState={paginationDirectionState}
          descriptionCodes={descriptionCodes}
        />
      )}
    </Stack>
  );
};
