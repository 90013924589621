
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, List, Stack, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "events"
    }}>{`Events`}</h1>
    <h2 {...{
      "id": "whats-an-event"
    }}>{`What’s an event?`}</h2>
    <p>{`Events are emitted when certain actions occur on SEEK.
For example, an event may be emitted when:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A posted job ad has gone live on the SEEK job board`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A candidate has applied for a job ad`}</p>
      </li>
    </ul>
    <p>{`A complete list of event types is available in the `}<a parentName="p" {...{
        "href": "#event-types"
      }}>{`event types`}</a>{` section of this page and in our `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/webhooks"
      }}>{`webhook schema`}</a>{`.`}</p>
    <p>{`You can use events to trigger workflows in your software.
SEEK can deliver events in two ways:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Pushed into your software via `}<a parentName="p" {...{
            "href": "/events/webhooks"
          }}>{`webhooks`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pulled from your software via `}<a parentName="p" {...{
            "href": "/events/polling"
          }}>{`polling`}</a>{` the GraphQL `}<inlineCode parentName="p">{`events`}</inlineCode>{` query`}</p>
      </li>
    </ul>
    <p>{`The SEEK API does not use GraphQL subscriptions as these are not a good fit for machine-to-machine integrations.`}</p>
    <p>{`You can view a list of your historical events on the `}<a parentName="p" {...{
        "href": "/manage/events"
      }}>{`Developer Dashboard’s events page`}</a>{`.
This lets you observe the events that are being emitted for all of your SEEK hirers.`}</p>
    <h2 {...{
      "id": "event-types"
    }}>{`Event types`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/webhooks/CandidateApplicationCreatedEvent"
          }}>{`CandidateApplicationCreatedEvent`}</a></p>
        <p parentName="li">{`These events are emitted when a candidate submits an application for a position.`}</p>
        <p parentName="li">{`Consuming these events will allow you to retrieve applications submitted using SEEK’s Apply Form; see `}<a parentName="p" {...{
            "href": "/use-cases/application-export"
          }}>{`Optimised Apply`}</a>{` for more information.`}</p>
      </li>
    </ul>

    <Alert tone="caution" mdxType="Alert">
  <Stack space="medium" mdxType="Stack">
    <Text weight="strong" mdxType="Text">
      It is essential that you consume these events for{' '}
      <SmartTextLink href="https://developer.seek.com/use-cases/application-export" mdxType="SmartTextLink">
        Optimised Apply
      </SmartTextLink>{' '}
      integrations. Failure to consume these events will mean:
    </Text>
    <List space="small" mdxType="List">
      <Text mdxType="Text">
        Hirers will be unable to view job applications via your platform. This
        wrongs hirers since they will not receive the full value of the
        advertisement they paid for.
      </Text>
      <Text mdxType="Text">
        Candidates will not have their application delivered to the hirer. This
        wrongs candidates since they miss out on the value that SEEK offers
        them.
      </Text>
    </List>
  </Stack>
    </Alert>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/webhooks/CandidateProfilePurchasedEvent"
          }}>{`CandidateProfilePurchasedEvent`}</a></p>
        <p parentName="li">{`These events are emitted when a candidate profile is purchased in `}<a parentName="p" {...{
            "href": "https://talent.seek.com.au/products/talentsearch"
          }}>{`Talent Search`}</a>{`.`}</p>
        <p parentName="li">{`It is essential to consume these events if you wish to import purchased candidate profiles into your recruitment software.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/webhooks/HirerRelationshipChangedEvent"
          }}>{`HirerRelationshipChangedEvent`}</a></p>
        <p parentName="li">{`These events are emitted whenever a relationship between your platform and a hirer is added or removed.`}</p>
        <p parentName="li">{`The hirer relationships in your software should be kept in sync with SEEK using these events.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/webhooks/PositionProfilePostedEvent"
          }}>{`PositionProfilePostedEvent`}</a></p>
        <p parentName="li">{`These events are emitted when a job ad is posted.`}</p>
        <p parentName="li">{`An advertisement’s status in your software should be kept in sync with SEEK using these events.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/webhooks/PositionProfileClosedEvent"
          }}>{`PositionProfileClosedEvent`}</a></p>
        <p parentName="li">{`These events are emitted when a job ad is closed.`}</p>
        <p parentName="li">{`An advertisement’s status in your software should be kept in sync with SEEK using these events.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "why-webhooks"
    }}>{`Why webhooks?`}</h2>
    <p>{`Webhooks are preferred for a few key reasons:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Efficiency`}</strong></p>
        <p parentName="li">{`With webhooks, you are only notified when an event actually occurs.
You can place incoming events onto a queue, and leverage autoscaling and event-driven services to adjust to load.`}</p>
        <p parentName="li">{`With polling, you need to query our GraphQL endpoint for new events on an interval.
This leads to a tradeoff between latency and cost:
polling infrequently means that your software will be slower to react to events,
while polling too frequently wastes compute resources.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Scalability`}</strong></p>
        <p parentName="li">{`With webhooks, you can distribute incoming events across multiple servers and process them in any order.`}</p>
        <p parentName="li">{`With polling, you need to keep track of pagination cursors and retrieve pages in order.
This makes it difficult to distribute your workload across multiple servers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Simplicity`}</strong></p>
        <p parentName="li">{`With webhooks, you can have a stateless service that simply processes the events that it receives.
SEEK takes care of delivering the events to your endpoint;
if your service is unreachable or returns an error,
SEEK automatically re-queues the events for later.`}</p>
        <p parentName="li">{`Webhooks also provide a mechanism for SEEK to resend historical events to you on request.
This enables disaster recovery without adding any complexity to your integration.`}</p>
        <p parentName="li">{`With polling, you need to manage state and the complexity of pagination.
It can be easy to accidentally skip events while paging through results,
and you will probably need to build the capability to retrieve and reprocess historical events for disaster recovery.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;