
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { InlineCode } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-selection-panel"
    }}>{`Ad Selection Panel`}</h1>
    <h2 {...{
      "id": "browser-support"
    }}>{`Browser support`}</h2>
    <p>{`The Ad Selection Panel tracks our standard `}<a parentName="p" {...{
        "href": "/introduction/browser-support"
      }}>{`browser support policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-1-include-the-panel"
    }}>{`Step 1: Include the panel`}</h2>
    <p>{`Add the following script tag to the page where you want to insert the Ad Selection Panel:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script
  type="text/javascript"
  src="https://integration.seek.com/panels/SeekApi.js"
></script>
`}</code></pre>
    <p>{`This will expose a `}<inlineCode parentName="p">{`SeekApi.render`}</inlineCode>{` function which renders an instance of a panel within a specified DOM element.`}</p>
    <h2 {...{
      "id": "step-2-render-the-panel"
    }}>{`Step 2: Render the panel`}</h2>
    <p>{`The render function must be called on page load and whenever the properties of the `}<a parentName="p" {...{
        "href": "#props"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode>{` object`}</a>{` change.
For example, if the hirer selects a new location, you must re-render the panel to reflect updated products and pricing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(containerNode, 'adSelection', props);
`}</code></pre>
    <p>{`The Ad Selection Panel will render to fit the width of the supplied `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{`.
We recommend your `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{` spans the full width of your UI for the best user experience, as up to four ad products may be displayed to hirers.`}</p>

    <Alert tone="caution" mdxType="Alert">
  <Text mdxType="Text">
    Modifying the <InlineCode mdxType="InlineCode">containerNode</InlineCode> or its children after
    the <InlineCode mdxType="InlineCode">render</InlineCode> function has been called may lead to
    unexpected behaviour.
  </Text>
    </Alert>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Prop`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`getAuthToken`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`() => Promise<string>`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Function to retrieve a `}<a parentName="p" {...{
                "href": "#step-3-handle-browser-token-requests"
              }}>{`browser token`}</a>{` for the SEEK API`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`onChange`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`(event) => void`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`See `}<a parentName="p" {...{
                "href": "#step-4-handle-ad-product-selection"
              }}>{`handling ad product selection`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`sellingPointsDisplay`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`bullet`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`tooltip`}</inlineCode></p><p><small>{`default: `}<inlineCode parentName="td">{`bullet`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`How the panel should display selling points of each product`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProducts_PositionProfileInput"
              }}><inlineCode parentName="a">{`AdvertisementProducts_PositionProfileInput`}</inlineCode></a>{` When editing an existing job ad, this must include the `}<inlineCode parentName="p">{`profileId`}</inlineCode>{` of the job being updated`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedAdvertisementProductId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The product ID stored in a job ad draft`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`showFormValidationError`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`boolean`}</inlineCode></p><p><small>{`default: `}<inlineCode parentName="td">{`false`}</inlineCode></small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Whether the panel should display a validation error when there is no selected product`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`locale`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Specifies the locale to display content in, e.g. `}<inlineCode parentName="p">{`en-AU`}</inlineCode>{`. Set this prop to override the default localisation behaviour based on users browser preferences. Supported locales are outlined in the `}<a parentName="p" {...{
                "href": "/graphql/in-practice#content-localisation"
              }}>{`content localisation`}</a>{` documentation.`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(document.getElementById('seekPanelContainer'), 'adSelection', {
  getAuthToken: async () => {
    // Do not implement caching in your \`getAuthToken\` implementation.
    // The panel will internally memoise the response.
    const token = await fetchAuthToken();

    return token;
  },

  onChange: (event) => {
    const { selectedProduct } = event;

    // Persist \`selectedProduct.id\` in memory for use in job posting mutations.

    // Display form inputs for features supported by the selected product.

    if (selectedProduct?.features.branding) {
      // Show brand selection.
    } else {
      // Hide brand selection.
    }

    if (selectedProduct?.features.searchBulletPoints) {
      // Show the number of bullet point input fields indicated by \`limit\`.
    } else {
      // Hide all bullet point input fields.
    }
  },

  sellingPointsDisplay: 'bullet',

  positionProfile: {
    // Ad products and their pricing vary based on these classifiers.
    jobCategories: 'seekAnzPublicTest:jobCategory:seek:27HXTkNXh',
    positionLocation: 'seekAnzPublicTest:location:seek:W7NggeKH',
    positionOrganizations: 'seekAnzPublicTest:organization:seek:93WyyF1h',
    positionTitle: 'Software Engineer',

    // Salary details let us indicate how each ad product is likely to perform.
    offeredRemunerationPackage: {
      basisCode: 'Salaried',
      descriptions: ['Up to $70,000 per year + bonus'],
      ranges: [
        {
          intervalCode: 'Year',
          minimumAmount: { currency: 'AUD', value: 40000 },
          maximumAmount: { currency: 'AUD', value: 70000 }
        }
      ]
    },
    seekAnzWorkTypeCode: 'FullTime',

    // The position profile ID must be provided when editing a job ad.
    profileId: undefined
  },

  // The product ID stored in a job ad draft.
  selectedAdvertisementProductId: undefined,

  // Set this to true if the hirer tries to post the job ad before they have
  // selected an ad product (i.e. no \`selectedProduct.id\` persisted in memory).
  showFormValidationError: false
});
`}</code></pre>
    <h2 {...{
      "id": "step-3-handle-browser-token-requests"
    }}>{`Step 3: Handle browser token requests`}</h2>
    <img alt="" data-scoobie-style="none" height="540" src={require('../../../../../../mermaid/.aaaaf88d36867e4aa60b047f3e4b05879b1919a4.mmd.svg')} title="Panel authentication" width="1092" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The panel loads and invokes the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function passed to it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function should request a new token for the hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`.
If a user switches to a different SEEK hirer account in your posting form,
your software should re-render the panel with the new hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`,
and ensure that subsequent invocations of `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` will request a token for the new hirer ID.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorizes the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorized to access a given hirer ID.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend `}<a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`requests a browser token from the SEEK API`}</a>{` for the appropriate hirer ID and `}<inlineCode parentName="p">{`query:ad-products query:organizations`}</inlineCode>{` scope.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend responds with the browser token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend returns the browser token from the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel can now make requests to the GraphQL endpoint.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "query:ad-products query:organizations",
  "userId": "317665"
}
`}</code></pre>
    <h2 {...{
      "id": "step-4-handle-ad-product-selection"
    }}>{`Step 4: Handle ad product selection`}</h2>
    <p>{`When a hirer selects an ad product, the `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` callback you provided within your `}<a parentName="p" {...{
        "href": "#props"
      }}>{`props`}</a>{` will be invoked with the below parameters.`}</p>
    <h3 {...{
      "id": "onchange-callback"
    }}><inlineCode parentName="h3">{`onChange`}</inlineCode>{` callback`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Property`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.id`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The selected product ID to persist in memory for posting, previewing or updating the job ad`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.label`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The selected ad product label, this should only be used for display purposes`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.features`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The features available for the selected ad product`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.features.branding`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Whether the ad product supports branding`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.features.branding.logoIndicator`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`boolean`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Whether the logo of the brand will be displayed on the job ad`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.features.branding.coverImageIndicator`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`boolean`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Whether the cover image of the brand will be displayed on the job ad`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.features.searchBulletPoints`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Whether the ad product supports search bullet points`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.features.searchBulletPoints.limit`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`number`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The number of bullet point input fields to display in your posting workflow`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.price`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The price of the ad product, this should only be used if your software supports budgeting`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.price.summary`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The human readable pricing summary of the ad product`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.price.currency`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The three-letter ISO 4217 currency code of the ad product price`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`selectedProduct.price.value`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The ad product price in the minor unit of the currency, excluding tax`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "managing-variable-job-ads"
    }}>{`Managing variable job ads`}</h3>
    <p>{`Due to the variable nature of SEEK’s product ladder,
the panel returns an ephemeral `}<inlineCode parentName="p">{`selectedProduct.id`}</inlineCode>{` that references a product quoted at that point in time.
When a hirer selects a product and your software propagates its identifier to post or update a job ad,
SEEK will scan recent ad selection contexts for a match and attempt to lock in the exact quoted product features and pricing.`}</p>
    <p>{`Ad products presented to a hirer are only valid within the context of the workflow that they were executing at the time.
Product IDs cannot be persisted long term;
they should not be stored alongside a posted job ad for `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
      }}>{`subsequent update workflows`}</a>{` or to otherwise build a hardcoded mapping.`}</p>
    <h4 {...{
      "id": "handling-drafts"
    }}>{`Handling drafts`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`selectedProduct.id`}</inlineCode>{` may be temporarily stored in a job ad draft.
When the hirer resumes their posting workflow from the draft at a later date,
this previous product ID must not be directly used to post the job ad.
Instead, pass the previous product ID to the Ad Selection Panel render function as the `}<inlineCode parentName="p">{`selectedAdvertisementProductId`}</inlineCode>{`.
The panel will then pre-select an equivalent ad product and return new product IDs that can be used to post the job ad.`}</p>
    <h4 {...{
      "id": "handling-edits"
    }}>{`Handling edits`}</h4>
    <p>{`When a hirer edits an existing job ad, the `}<inlineCode parentName="p">{`profileId`}</inlineCode>{` of the job being updated must be provided to the panel to show the correct ad products and pricing.
The panel will default to selecting the appropriate ad product based on the existing job ad details.`}</p>
    <p>{`Additionally, the product ID used to initially post the job ad must not be used again when calling `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updatePostedPositionProfile"
      }}><inlineCode parentName="a">{`updatePostedPositionProfile`}</inlineCode></a>{`.
Instead, always use the most recent `}<inlineCode parentName="p">{`selectedProduct.id`}</inlineCode>{` emitted through the `}<a parentName="p" {...{
        "href": "#onchange-callback"
      }}><inlineCode parentName="a">{`onChange`}</inlineCode>{` callback`}</a>{` to update the job ad.`}</p>
    <p>{`When a job ad is expired, the panel will display the selected product and note that further edits are no longer possible.`}</p>
    <h4 {...{
      "id": "stale-product-id-errors"
    }}>{`Stale product ID errors`}</h4>
    <p>{`When executing mutations such as `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updatePostedPositionProfile"
      }}><inlineCode parentName="a">{`updatePostedPositionProfile`}</inlineCode></a>{`, you might encounter errors similar to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "errors": [
    {
      "message": "Invalid posting instructions",
      "extensions": {
        "code": "BAD_USER_INPUT",
        "invalidFields": {
          "/input/positionProfile/postingInstructions/0/seekAdvertisementProductId": "No longer valid; refresh the available ad products for this job ad"
        }
      }
    }
  ]
}
`}</code></pre>
    <p>{`This can indicate an underlying implementation issue, for instance:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`A product ID from a draft has been stored for too long and was used to post the job ad.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A product ID used to initially post a job ad has been used again to update the ad.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel was rendered without a `}<inlineCode parentName="p">{`profileId`}</inlineCode>{` when editing an existing job ad, resulting in the selected product ID being invalid for an update.`}</p>
      </li>
    </ol>
    <p>{`To resolve these scenarios, check that all job posting flows refresh the product ID correctly prior to posting.
This can be achieved by ensuring the panel is rendered before posting with the full `}<a parentName="p" {...{
        "href": "#props"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode>{` object`}</a>{` passed in (including the `}<inlineCode parentName="p">{`profileId`}</inlineCode>{` for existing ads) and using the latest `}<inlineCode parentName="p">{`selectedProduct.id`}</inlineCode>{` in the mutation to post or update the job ad.`}</p>
    <h3 {...{
      "id": "supporting-features"
    }}>{`Supporting features`}</h3>
    <p>{`When a hirer selects an ad product, your software should conditionally display form inputs for the additional job ad features it enables.
The available features are passed to the `}<a parentName="p" {...{
        "href": "#onchange-callback"
      }}><inlineCode parentName="a">{`onChange`}</inlineCode>{` callback`}</a>{`.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/features"
      }}>{`features for ad products`}</a>{` for a detailed walkthrough.`}</p>
    <h2 {...{
      "id": "step-5-post-the-job-ad"
    }}>{`Step 5: Post the job ad`}</h2>
    <p>{`When the hirer is ready to post or update the job ad,
provide the `}<inlineCode parentName="p">{`selectedProduct.id`}</inlineCode>{` persisted above in the posting instruction `}<inlineCode parentName="p">{`seekAdvertisementProductId`}</inlineCode>{` field on the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/postPosition"
      }}><inlineCode parentName="a">{`postPosition`}</inlineCode></a>{`, `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/postPositionProfileForOpening"
      }}><inlineCode parentName="a">{`postPositionProfileForOpening`}</inlineCode></a>{` or `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updatePostedPositionProfile"
      }}><inlineCode parentName="a">{`updatePostedPositionProfile`}</inlineCode></a>{` job posting mutations.`}</p>
    <p>{`When a hirer submits a job posting form in your software,
toggle the `}<inlineCode parentName="p">{`showFormValidationError`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "#props"
      }}>{`prop`}</a>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.
The panel will display a validation error if an ad product has not yet been selected.`}</p>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`See our general `}<a parentName="p" {...{
        "href": "/introduction/panels#troubleshooting"
      }}>{`panel troubleshooting`}</a>{` documentation for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;