
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The pagination metadata for this page of webhook subscription replays.`}</p>
    <p>{`Disclaimer:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`hasPreviousPage`}</inlineCode>{` field will always be false when paginating by `}<inlineCode parentName="p">{`first`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`hasNextPage`}</inlineCode>{` field will always be false when paginating by `}<inlineCode parentName="p">{`last`}</inlineCode>{`.`}</p>
      </li>
    </ul>
    <p>{`To discern whether a next/previous page exists in these conditions, an additional request will need to be made to retrieve the next/previous page.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;