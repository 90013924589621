
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The conflict result for the `}<a parentName="p" {...{
        "href": "/schema-new/mutation/createWebhookSubscription",
        "title": "Creates a new webhook subscription"
      }}><inlineCode parentName="a">{`createWebhookSubscription`}</inlineCode></a>{` mutation.`}</p>
    <p>{`Webhook subscriptions must have a unique combination of `}<inlineCode parentName="p">{`eventTypeCode`}</inlineCode>{`, `}<inlineCode parentName="p">{`schemeId`}</inlineCode>{`, `}<inlineCode parentName="p">{`url`}</inlineCode>{` & `}<inlineCode parentName="p">{`hirerId`}</inlineCode>{` fields.
Attempting to create a duplicate webhook subscription will result in a conflict.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;