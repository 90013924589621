import "src/styles.css.ts.vanilla.css!=!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.7.4_babel-plugin-macros@3.1.0_terser@5._wox2o4hrol5mz3rhrbav7ttewi/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles.css.ts.vanilla.css\",\"source\":\"KiB7CiAgc2Nyb2xsLW1hcmdpbi10b3A6IGNhbGMoKHZhcigtLV8xa2o2cmVyYSkgKiAxNSkgKyB2YXIoLS1fMWtqNnJlcjMpKTsKfQpib2R5IHsKICBtaW4td2lkdGg6IDMyMHB4Owp9\"}!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.7.4_babel-plugin-macros@3.1.0_terser@5._wox2o4hrol5mz3rhrbav7ttewi/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/pages/manage/userGuide/styles.css.ts.vanilla.css!=!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.7.4_babel-plugin-macros@3.1.0_terser@5._wox2o4hrol5mz3rhrbav7ttewi/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/manage/userGuide/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VU246bMBB9z1eMVqoEWznFJNm2zkv/pDIwCd44NjImJFvl3ysM7NoBbdPbo8dnjuec8cyyfUZs2wR+LAAqXQsrtGKwE2cstguAEsW+tAxyLvOIJsmpBALRiZuIkO/08Pxk0PAYHoFu4rhLsLoa0POgDmM8zugGR2kMH2ESTV10Cn0EQuMYPkEaD4cpzj1N1n19rShsOVvhX7zshBuuRvucQEiWqxqQ10h0Y7eL62LZm02d2UehyOiuM9ZDrBzCZzxysxeKzBJDcMsgqc4e19pxFaKuJL8wUFqhd7txtxnPD3ujG1UwCJWmTeyjWalPaG5ySK6lNpPMo5/5mWW40wZdaq6VRWUZPDxs/doyqfNDF5G4G3W8/pbhNFoWPnZxLci0KdAQwwvR1LeQvIfcU7U/CTyrtWwsdtEXIlSBZwbUU/bFSeps2UndkgsD3ljd6zKYDx/CSj/FN6PXSjdB074OTXslkNb41z7BLyU1cVB82hF9O2IhONS5QVTAVQFR9yGH6aBpklTn2NEHn3Z2R/zBlrhvT7z1vj/9t9m9Y3oBrm9mpO+ZcZ8yb/Q/BOrCpJcB7k+o7DL3Bi83Va2Hqqbz9Du75Hab/Hu3vZKfJkYalNyKEwb9n6lh0z0WhlYj/fUnX5eeY9YGAAA=\"}!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.7.4_babel-plugin-macros@3.1.0_terser@5._wox2o4hrol5mz3rhrbav7ttewi/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var CloseUserGuideButtonStyling = 'wjeeww6';
export var CloseUserGuideIconStyling = 'wjeeww5';
export var ClosedUserGuideSidebar = 'wjeeww0';
export var ContentInnerContainer = 'wjeeww9 wjeeww7';
export var ContentOuterContainer = 'wjeeww8 wjeeww7';
export var MainConstricted = 'wjeeww4';
export var MainRelaxed = 'wjeeww3';
export var OpenUserGuideSidebar = 'wjeeww1';
export var UserGuideVerticalDivider = 'wjeeww2';