export const RESET_SESSION_PATH = '/reset-session';

/**
 *  Clear out our local state, including:
 *
 * - Our cached Auth0 token & state
 * - Our Raygun integration partner UUID
 * - Previously viewed Statuspage incidents
 *
 * This is mostly used for impersonation, but it can be useful for testing
 * `IncidentToaster` as well. An integration partner should never have to use
 * this.
 */
export const resetSession = () => {
  localStorage.clear();
  sessionStorage.clear();

  window.location.replace('/manage');
};
