import { Tiles } from 'braid-design-system';

import { PageIcon } from 'src/components/PageIcon/PageIcon';
import { TileLink } from 'src/components/TileLink/TileLink';

export const ArchivedGuides = () => (
  <Tiles columns={[1, 1, 2]} space="small">
    <TileLink
      description="Initial migration from legacy Job Posting & Application Export integrations in Australia & New Zealand to the SEEK API"
      href="/migration-guides/overview"
      icon={<PageIcon icon="GuideFileArchive" />}
      title="2022 SEEK API migration"
    />

    <TileLink
      description="Historical reference for converting from SEEK advertiser IDs and working with hirer IDs in the SEEK API"
      href="/migration-guides/seek-advertiser-ids"
      icon={<PageIcon icon="GuideFileArchive" />}
      title="Hirer identifiers"
    />
  </Tiles>
);
