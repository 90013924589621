import {
  type GraphQLObjectType,
  buildSchema,
  getNamedType,
  isInputObjectType,
  isInterfaceType,
  isObjectType,
  isUnionType,
  lexicographicSortSchema,
} from 'graphql';

// @ts-ignore
import raw from './schema.graphql';

export const schema = lexicographicSortSchema(buildSchema(raw));

export type TypeOccurrence =
  | {
      type: 'Argument';
      argName: string;
      fieldName: undefined;
      parentName: string;
      parentType: OperationType;
    }
  | {
      type: 'Argument';
      argName: string;
      fieldName: string;
      parentName: string;
      parentType: 'Interface' | 'Object';
    }
  | {
      type: 'Field';
      fieldName: undefined;
      parentName: string;
      parentType: OperationType;
    }
  | {
      type: 'Field';
      fieldName: string;
      parentName: string;
      parentType: 'Input Object' | 'Interface' | 'Object';
    }
  | {
      type: 'UnionMember';
      parentName: string;
      parentType: 'Union';
    };

export type TypeIndex = Record<string, TypeOccurrence[]>;

const operationTypes = ['Mutation', 'Query', 'Subscription'] as const;

type OperationType = (typeof operationTypes)[number];

const operationTypeSet = new Set<string>(operationTypes);

const isOperationType = (name: string): name is OperationType =>
  operationTypeSet.has(name);

const propsForField = ({ name }: GraphQLObjectType, fieldName: string) =>
  isOperationType(name)
    ? {
        fieldName: undefined,
        parentName: fieldName,
        parentType: name,
      }
    : {
        fieldName,
        parentName: name,
        parentType: 'Object' as const,
      };

const buildTypeIndex = (): TypeIndex => {
  const typeIndex: Record<string, TypeOccurrence[]> = {};

  const storeTypeOccurrence = (name: string, occurrence: TypeOccurrence) => {
    if (!typeIndex[name]) {
      typeIndex[name] = [];
    }

    typeIndex[name].push(occurrence);
  };

  Object.values(schema.getTypeMap()).forEach((type) => {
    if (type.name.startsWith('_')) {
      return;
    }

    if (isInputObjectType(type)) {
      return Object.values(type.getFields()).forEach((field) =>
        storeTypeOccurrence(getNamedType(field.type).name, {
          type: 'Field',
          fieldName: field.name,
          parentName: type.name,
          parentType: 'Input Object',
        }),
      );
    }

    if (isInterfaceType(type)) {
      return Object.values(type.getFields()).forEach((field) => {
        field.args.forEach((arg) =>
          storeTypeOccurrence(getNamedType(arg.type).name, {
            type: 'Argument',
            argName: arg.name,
            fieldName: field.name,
            parentName: type.name,
            parentType: 'Interface',
          }),
        );

        storeTypeOccurrence(getNamedType(field.type).name, {
          type: 'Field',
          fieldName: field.name,
          parentName: type.name,
          parentType: 'Interface',
        });
      });
    }

    if (isObjectType(type)) {
      return Object.values(type.getFields()).forEach((field) => {
        const fieldProps = propsForField(type, field.name);

        field.args.forEach((arg) =>
          storeTypeOccurrence(getNamedType(arg.type).name, {
            ...fieldProps,
            type: 'Argument',
            argName: arg.name,
          }),
        );

        storeTypeOccurrence(getNamedType(field.type).name, {
          ...fieldProps,
          type: 'Field',
        });
      });
    }

    if (isUnionType(type)) {
      return Object.values(type.getTypes()).map((member) =>
        storeTypeOccurrence(member.name, {
          type: 'UnionMember',
          parentName: type.name,
          parentType: 'Union',
        }),
      );
    }
  });

  return typeIndex;
};

export const typeIndex = buildTypeIndex();
