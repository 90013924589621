
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A position profile with the given `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
    <p>{`This query accepts browser tokens that include the `}<inlineCode parentName="p">{`query:position-profiles`}</inlineCode>{` scope for posted position profiles.
Note that this scope does not grant access to the containing `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile#/field/positionOpening",
        "title": "The `PositionOpening` that this profile was created under"
      }}><inlineCode parentName="a">{`PositionProfile.positionOpening`}</inlineCode></a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;