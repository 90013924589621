
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The state of the live job ad on SEEK's job board.`}</p>
    <p>{`This depends on your software persisting & updating the state of the live job ad.
The `}<a parentName="p" {...{
        "href": "/schema-new/query/seekAnzHirerAdvertisementModificationProducts",
        "title": "Ad products available when updating a live job ad"
      }}><inlineCode parentName="a">{`seekAnzHirerAdvertisementModificationProducts`}</inlineCode></a>{` query is recommended if you have a `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile#/field/profileId",
        "title": "The identifier for the `PositionProfile`"
      }}><inlineCode parentName="a">{`PositionProfile.profileId`}</inlineCode></a>{` for the live job ad.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;