import { Heading, Loader } from 'braid-design-system';
import { useEffect } from 'react';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { resetSession } from 'src/utils/resetSession';

export const ResetSessionPage = () => {
  // This only exists for client-side navigation.
  // We short circuit this in `client.tsx` to skip React hydration.
  useEffect(resetSession);

  return (
    <PageWrapper
      heading={
        <Heading level="2" weight="weak">
          Resetting session
        </Heading>
      }
    >
      <PageGutter>
        <Loader />
      </PageGutter>
    </PageWrapper>
  );
};
