import {
  type GraphQLNamedType,
  isEnumType,
  isInputObjectType,
  isInterfaceType,
  isObjectType,
  isScalarType,
  isUnionType,
} from 'graphql';
import { useParams } from 'react-router';

import { schema } from '../schema';

import { EnumSchemaPage } from './EnumSchemaPage';
import { InputObjectSchemaPage } from './InputObjectSchemaPage';
import { InterfaceSchemaPage } from './InterfaceSchemaPage';
import { ObjectSchemaPage } from './ObjectSchemaPage';
import { OopsSchemaPage } from './OopsSchemaPage';
import { ScalarSchemaPage } from './ScalarSchemaPage';
import { UnionSchemaPage } from './UnionSchemaPage';

const TypeSchemaPage = ({
  namedType,
  fieldName,
}: {
  namedType: GraphQLNamedType;
  fieldName?: string;
}) => {
  if (isObjectType(namedType)) {
    return <ObjectSchemaPage objectType={namedType} fieldName={fieldName} />;
  } else if (isInputObjectType(namedType)) {
    return (
      <InputObjectSchemaPage
        inputObjectType={namedType}
        fieldName={fieldName}
      />
    );
  } else if (isInterfaceType(namedType)) {
    return (
      <InterfaceSchemaPage interfaceType={namedType} fieldName={fieldName} />
    );
  } else if (isScalarType(namedType)) {
    return <ScalarSchemaPage scalarType={namedType} />;
  } else if (isEnumType(namedType)) {
    return <EnumSchemaPage enumType={namedType} />;
  } else if (isUnionType(namedType)) {
    return <UnionSchemaPage unionType={namedType} />;
  }

  return namedType satisfies never;
};

export const NamedTypePage = () => {
  const { typeName, fieldName } = useParams();

  const namedType = schema.getType(typeName ?? '');
  if (!namedType) {
    return <OopsSchemaPage />;
  }

  return (
    <TypeSchemaPage
      key={namedType.name}
      namedType={namedType}
      fieldName={fieldName}
    />
  );
};
