
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The details of the process history items uploaded alongside the candidate.`}</p>
    <p>{`The upload operation is atomic;
if you receive an `}<a parentName="p" {...{
        "href": "/schema-new/named-type/UploadCandidatePayload_Success",
        "title": "The success result for the `uploadCandidate` mutation"
      }}><inlineCode parentName="a">{`UploadCandidatePayload_Success`}</inlineCode></a>{` payload,
all process history items were successfully uploaded.
Input order is preserved to allow your software to record the `}<inlineCode parentName="p">{`id`}</inlineCode>{` assigned to each item.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;