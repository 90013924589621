import { Heading, Stack, Text } from 'braid-design-system';
import { InlineCode, SmartTextLink } from 'scoobie';

import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';

import { EventList } from './components/EventList/EventList';

export const EventsPage = () => (
  <PageWrapper
    heading={
      <Heading level="2" weight="weak">
        Event polling
      </Heading>
    }
    explainer={
      <Stack space="medium">
        <Text tone="secondary">
          View all events available for ingestion, beyond the lifetime of an
          individual webhook subscription.
        </Text>

        <Text tone="secondary">
          You can use the{' '}
          <SmartTextLink href="https://developer.seek.com/schema/#/query/events">
            <InlineCode>events</InlineCode> query
          </SmartTextLink>{' '}
          in this manner to{' '}
          <SmartTextLink href="https://developer.seek.com/events/polling#backfilling--reprocessing-flow">
            backfill and reprocess events
          </SmartTextLink>{' '}
          for disaster recovery or when remediating a webhook configuration
          issue.
        </Text>
      </Stack>
    }
  >
    <EventList />
  </PageWrapper>
);
