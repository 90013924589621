
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Human readable descriptions of the remuneration package.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme is limited to a single element with a maximum length of 50 characters.`}</p>
      </li>
    </ul>
    <p>{`An empty array must be provided to signify the absence of salary descriptions.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;