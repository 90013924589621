
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Recommended } from '../../../components/Recommended/Recommended';
import { Alert, Text } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-3-ad-selection"
    }}>{`Phase 3: Ad selection`}</h1>
    <p>{`SEEK hirers must be shown all available SEEK ad products with pricing when they create or update an ad in your software.`}</p>
    <p>{`Available ad products and pricing are different for every SEEK hirer.
As a result, partners are no longer able to hardcode SEEK ad products.`}</p>
    <p>{`SEEK offers two solutions to enable partners to show ad products with pricing:`}</p>

    <ol>
      <li parentName="ol">
        <p parentName="li">{`Ad Selection Panel `}<Recommended mdxType="Recommended" /></p>
        <p parentName="li">{`SEEK offers a themeable Ad Selection Panel that can be embedded into your posting flow.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GraphQL`}</p>
        <p parentName="li">{`Available ad products can be programmatically retrieved by your software when `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
          }}>{`posting`}</a>{` or `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
          }}>{`updating`}</a>{` a job ad.
This allows partners to build custom user interfaces for ad product selection.`}</p>
      </li>
    </ol>

    <Alert tone="promote" mdxType="Alert">
  <Text mdxType="Text">
    SEEK strongly recommends using the Ad Selection Panel for your integration.
    It will significantly reduce development time, ongoing maintenance, as well
    as provide an up-to-date user experience for SEEK hirers. Implementation of
    a custom Ad Selection Panel will require you to support and maintain various
    ad type requirements such as what ad types can be upgraded, price and price
    variation visibility, etc.
  </Text>
    </Alert>
    <p>{`For more information on implementing ad selection, see the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection"
      }}>{`Ad Selection documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "exit-criteria"
    }}>{`Exit criteria`}</h2>
    <p>{`The following criteria need to be met before moving to the next phase:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Criteria`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Ad selection built`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`SEEK hirers can select from all available SEEK ad products and see product pricing when creating or editing a job ad in your software`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`User experience signed off`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`SEEK has signed off on your ad selection user experience`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Job ad posted successfully`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can successfully post a SEEK job ad with their chosen SEEK ad product`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Job ad updated successfully`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can successfully update a SEEK job ad with their chosen SEEK ad product`}</p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;