
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "apply-with-seek"
    }}>{`Apply with SEEK`}</h1>
    <p>{`This use case provides technical implementation details for the Apply with SEEK product.
More information can be found on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/whats-possible/apply-with-seek/"
      }}>{`Apply with SEEK product page`}</a>{`.`}</p>
    <p>{`Apply with SEEK allows candidates to pre-fill an external apply form hosted by your recruitment software using information from their SEEK Profile.
Candidates can also attach their CV already stored on SEEK, making it easier to apply on desktop or mobile.`}</p>
    <h2 {...{
      "id": "candidate-experience"
    }}>{`Candidate experience`}</h2>
    <p>{`A candidate can use Apply with SEEK when they:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
          }}>{`Link out`}</a>{` from a SEEK job ad to your apply form`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Arrive on your apply form directly`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Arrive on your apply form through another channel`}</p>
      </li>
    </ul>
    <p>{`The diagram below outlines the Apply with SEEK experience for a candidate starting on SEEK:`}</p>
    <img alt="" data-scoobie-style="none" height="774" src={require('../../../mermaid/.8896989750aefa78e269b16156f54845a1e4e488.mmd.svg')} title="Candidate Overview" width="759" />
    <p>{`The diagram details two Apply with SEEK flows.
Your software must support both to be certified.`}</p>
    <h3 {...{
      "id": "manual-button-flow"
    }}>{`Manual button flow`}</h3>
    <img alt="" data-scoobie-style="none" height="195" src={require('../../../mermaid/.36712a743532265ee98cedbada3aaea240b2ce77.mmd.svg')} title="" width="699" />
    <p>{`The candidate is first redirected to the external apply form.
If they click the Apply with SEEK button on the form,
they are prompted to temporarily authorize access to their SEEK Profile.
Your software can pre-fill the form after this exchange.`}</p>
    <p>{`The previous version of Apply with SEEK worked in this way,
and it remains the default interaction model.`}</p>
    <h3 {...{
      "id": "pre-authorization-flow"
    }}>{`Pre-authorization flow`}</h3>
    <img alt="" data-scoobie-style="none" height="33" src={require('../../../mermaid/.34ac13253c31ccda50749e5c5c59342a8124275e.mmd.svg')} title="" width="506" />
    <p>{`The authorization page is presented to the candidate upfront,
before they are redirected to the external apply form.
Your software can pre-fill the form upon redirect.`}</p>
    <p>{`This streamlines the candidate’s experience when they start on SEEK,
and is planned to be selectively enabled on suitable job ads over time.`}</p>
    <h2 {...{
      "id": "before-you-begin"
    }}>{`Before you begin`}</h2>
    <p>{`Before you start accessing the GraphQL queries for Apply with SEEK,
you will need to `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`request a partner token`}</a>{`.`}</p>
    <h2 {...{
      "id": "implementation-steps"
    }}>{`Implementation steps`}</h2>
    <p>{`To enable Apply with SEEK on your apply form, the following steps are required:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/display-button"
          }}>{`Display button`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/handle-authorization"
          }}>{`Handle authorization`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/retrieve-seek-profile"
          }}>{`Retrieve SEEK Profile`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/download-resume"
          }}>{`Download resume`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/populate-form"
          }}>{`Populate form`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/send-application-complete-signal"
          }}>{`Send application complete signal`}</a></p>
      </li>
    </ol>
    <p>{`The diagram below shows an expanded sequence of a candidate applying via Apply with SEEK.
It details the interactions between your software and SEEK to pre-fill an apply form,
grouped by each step in the integration process.`}</p>
    <img alt="" data-scoobie-style="none" height="1657" src={require('../../../mermaid/.a3347b88e605f60667a88f5fc0a1bdb59cc9d91c.mmd.svg')} title="Full sequence" width="1145" />
    <h2 {...{
      "id": "migration-considerations"
    }}>{`Migration considerations`}</h2>
    <p>{`The new Apply with SEEK offers a similar user experience to `}<a parentName="p" {...{
        "href": "https://devportal.seek.com.au/apply-with-seek/overview"
      }}>{`its predecessor`}</a>{`,
though candidates will benefit from the following enhancements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Richer SEEK Profile information available for pre-fill`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A familiar, localised experience across our SEEK, Jobstreet, and Jobsdb brands`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Forward compatibility with a streamlined pre-authorization flow`}</p>
      </li>
    </ul>
    <p>{`From an integration partner perspective,
the new Apply with SEEK brings:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Common `}<a parentName="p" {...{
            "href": "/introduction#technology"
          }}>{`technology`}</a>{` that enables your software to consolidate its `}<a parentName="p" {...{
            "href": "/auth/partner-tokens"
          }}>{`auth`}</a>{` and access patterns`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Richer SEEK Profile information via the same GraphQL `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/query/candidateProfile"
          }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query`}</a>{` as `}<a parentName="p" {...{
            "href": "/use-cases/application-export"
          }}>{`Optimised Apply`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Support and localisation across our `}<a parentName="p" {...{
            "href": "/introduction#multi-market-support"
          }}>{`Asia-Pacific markets`}</a></p>
      </li>
    </ul>
    <p>{`If you currently integrate with the `}<a parentName="p" {...{
        "href": "https://devportal.seek.com.au/apply-with-seek/overview"
      }}>{`previous version of Apply with SEEK`}</a>{`, you will need to follow the same steps above to update your integration.`}</p>
    <p>{`The table below outlines the key differences between the previous and current versions of Apply with SEEK:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Step`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Previous version`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`New version`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/auth/partner-tokens"
              }}>{`Auth`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Legacy client credentials`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK API `}<a parentName="p" {...{
                "href": "/auth/partner-tokens"
              }}>{`partner access tokens`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/display-button#scenario-1-candidate-arrives-from-seek-without-pre-authorization"
              }}>{`Track application state`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`No support`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`seek-token`}</inlineCode>{` parameter included in link-out redirect`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`1. `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/display-button"
              }}>{`Display button`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Link generated by partner`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GraphQL `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/query/applyWithSeekButton"
              }}><inlineCode parentName="a">{`applyWithSeekButton`}</inlineCode>{` query`}</a>{` returns localised button image, label, and link`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`2. `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/handle-authorization"
              }}>{`Handle authorization`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`OAuth 2.0 with authorization code exchanged for access token`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Revised authorization flow returns `}<inlineCode parentName="p">{`seek-prefill-id`}</inlineCode>{` to retrieve SEEK Profile and `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` to track application state`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`3. `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/retrieve-seek-profile"
              }}>{`Retrieve SEEK Profile`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`POST endpoint`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GraphQL `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/query/candidateProfile"
              }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query`}</a>{` returns richer information`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`4. `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/download-resume"
              }}>{`Download resume`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GET based on returned `}<inlineCode parentName="p">{`url`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GET based on returned `}<inlineCode parentName="p">{`url`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`5. `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/populate-form"
              }}>{`Populate form`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Partner pre-fills fields based on returned data`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Partner pre-fills fields based on returned data`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`6. `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/send-application-complete-signal"
              }}>{`Send application complete signal`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`POST endpoint`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GraphQL `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
              }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode>{` mutation`}</a></p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;