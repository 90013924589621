import { MockSalaryDetails } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

export const SeekSalaryDetails = () => (
  <BorderCard>
    <MockSalaryDetails
      onBlur={() => undefined}
      schemeId="seekAnzPublicTest"
      showStorybookAction
    />
  </BorderCard>
);
