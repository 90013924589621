
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A list of currencies used within this location.`}</p>
    <p>{`Locations with unsupported currencies will default to a SEEK supported currency.`}</p>
    <p>{`At least one currency will be provided.`}</p>
    <p>{`As most countries only use a single currency, the first item in the array can be used to preselect the default currency in a job posting flow.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;