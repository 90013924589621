import { Heading, Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';
import { Table } from 'scoobie';

interface Props {
  children: ComponentProps<typeof Stack>['children'];
  heading: string | null;

  subheading?: ComponentProps<typeof Stack>['children'];
  wrapper?: 'none' | 'table' | 'usage-table';
}

export const SchemaSection = ({
  children,
  heading,
  subheading,
  wrapper = 'table',
}: Props) => (
  <Stack space="xlarge">
    {typeof heading === 'string' && <Heading level="3">{heading}</Heading>}

    {subheading}

    {wrapper !== 'none' ? (
      <Table
        align={['left', 'left', 'center']}
        header={['Name', 'Description']}
        type="subtle"
        overflowX="scroll"
        width="full"
      >
        {children}
      </Table>
    ) : (
      children
    )}
  </Stack>
);
