
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The date which the associated object is no longer effective.`}</p>
    <p>{`For example, this corresponds to the expiry date of certifications.
This will be `}<inlineCode parentName="p">{`null`}</inlineCode>{` if the associated object does not expire.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;