
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The maximum amount an organization is willing to pay for a position.`}</p>
    <p>{`The value must be greater than or equal to the value of `}<inlineCode parentName="p">{`minimumAmount`}</inlineCode>{` and the currency must match `}<inlineCode parentName="p">{`minimumAmount`}</inlineCode>{`.`}</p>
    <p>{`The associated `}<a parentName="p" {...{
        "href": "/schema-new/named-type/RemunerationRangeInput#/field/maximumAmount",
        "title": "The maximum amount an organization is willing to pay for a position"
      }}><inlineCode parentName="a">{`RemunerationRangeInput.maximumAmount`}</inlineCode></a>{` field will be required in our schema in future.
Currently, omitting the field will default it to `}<inlineCode parentName="p">{`minimumAmount`}</inlineCode>{` and harm the performance of the job ad.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;