
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of offered salary ranges.`}</p>
    <p>{`Scheme requirements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme is limited to a single element containing the amount for the `}<inlineCode parentName="p">{`basisCode`}</inlineCode>{`.`}</p>
      </li>
    </ul>
    <p>{`Salary ranges are used to refine candidate job searches.
While the monetary values in `}<inlineCode parentName="p">{`minimumAmount`}</inlineCode>{` and `}<inlineCode parentName="p">{`maximumAmount`}</inlineCode>{` are not visible on job ads,
the currency and interval may be displayed alongside the `}<inlineCode parentName="p">{`descriptions`}</inlineCode>{` of the remuneration package for clarity.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;