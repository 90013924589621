
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The personal details of the uploaded candidate to be updated.`}</p>
    <p>{`At least one email address is required in `}<inlineCode parentName="p">{`communication`}</inlineCode>{`,
and one of the provided values must match `}<inlineCode parentName="p">{`seekPrimaryEmailAddress`}</inlineCode>{`.`}</p>
    <p>{`If no value is provided for physical addresses in `}<inlineCode parentName="p">{`communication`}</inlineCode>{` it will be treated as an empty list and will remove any previously uploaded addresses.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;