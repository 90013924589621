
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The algorithm for signing webhooks.`}</p>
    <p>{`Currently, two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`None`}</inlineCode>{` indicates no signature will be attached.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SeekHmacSha512`}</inlineCode>{` indicates a HMAC SHA-512 hex digest will be attached to each request as a `}<inlineCode parentName="p">{`Seek-Signature`}</inlineCode>{` header.`}</p>
      </li>
    </ul>
    <p>{`A webhook's signature can be used to validate that the request originated from SEEK.`}</p>
    <p>{`Use a constant-time algorithm to validate the signature.
Regular comparison methods like the `}<inlineCode parentName="p">{`==`}</inlineCode>{` operator are susceptible to timing attacks.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;