
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "application-complete-signal"
    }}>{`Application complete signal`}</h1>
    <p>{`The application complete signal signifies that a job application has been submitted in your recruitment software.
It applies to positions that are either advertised on SEEK or have the Apply with SEEK button enabled.
Note that it does not imply a candidate source or Apply with SEEK usage;
`}<a parentName="p" {...{
        "href": "/use-cases/ad-performance"
      }}>{`Ad Performance`}</a>{` analytics will only attribute completed applications where the candidate source is SEEK.`}</p>

    <Alert tone="info" mdxType="Alert">
  <Text mdxType="Text">
    If you are implementing Apply with SEEK, refer to{' '}
    <SmartTextLink href="/use-cases/apply-with-seek" mdxType="SmartTextLink">
      its documentation
    </SmartTextLink>{' '}
    as your implementation of the application complete signal will differ from
    the below.
  </Text>
    </Alert>
    <p>{`Once a candidate has completed and submitted your apply form,
your software must send an application complete signal to SEEK to signify form submission.
The application complete signal requires a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` that your software received from the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link out`}</a>{` flow.`}</p>
    <p>{`The application complete signal is only required if your software supports posting job ads that `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link out`}</a>{` to an external apply form.`}</p>
    <img alt="" data-scoobie-style="none" height="366" src={require('../../../../mermaid/.2a5255a59e6c0429364d687eaaa96e0a3411f398.mmd.svg')} title="Panel authentication" width="930" />
    <h2 {...{
      "id": "receiving-the-token"
    }}>{`Receiving the token`}</h2>
    <p>{`You will receive the token in a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` parameter appended to the query string of the posted `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationMethod/field/applicationUri"
      }}><inlineCode parentName="a">{`applicationUri`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`https://www.example.com/job/123445/?seek-token=93WyyF1h6eaeb58ccc384b0e8353daa3011f1ece
`}</code></pre>
    <p>{`The token is unique to that specific candidate, job ad and session, and should be stored for the duration of the application process.`}</p>
    <h3 {...{
      "id": "token-lifecycle"
    }}>{`Token lifecycle`}</h3>
    <p>{`Tokens remain valid for 30 days from the time of generation, and can be stored while a candidate drafts their application.`}</p>
    <p>{`Tokens do not need to be stored for more than 30 days.
Should an expired token be provided to the `}<inlineCode parentName="p">{`sendApplicationSignal`}</inlineCode>{` mutation, the SEEK API will internally handle the error and no error message will be returned.`}</p>
    <p>{`If a candidate returns to an apply form via the same SEEK link-out URL,
you will receive a new `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{`.
This should override any previous token for the job application when sending subsequent signals to SEEK.`}</p>
    <h2 {...{
      "id": "sending-the-application-complete-signal"
    }}>{`Sending the application complete signal`}</h2>
    <p>{`When an application is submitted,
supply the corresponding token to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode>{` mutation`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: SendApplicationSignalInput!) {\\n  sendApplicationSignal(input: $input)\\n}\",\"position\":{\"start\":{\"line\":72,\"column\":1,\"offset\":2942},\"end\":{\"line\":76,\"column\":4,\"offset\":3046},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    // Supported values are: `global` and `globalPublicTest`\\n    \\\"schemeId\\\": \\\"globalPublicTest\\\",\\n\\n    // Details of the type of signal being sent\\n    \\\"typeCode\\\": \\\"ApplicationComplete\\\",\\n\\n    // The token received from SEEK as part of the link-out URL\\n    \\\"token\\\": \\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n  }\\n}\",\"position\":{\"start\":{\"line\":78,\"column\":1,\"offset\":3048},\"end\":{\"line\":91,\"column\":4,\"offset\":3551},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "SendApplicationSignalInput!)": true,
        "{\\n": true,
        "": true,
        "sendApplicationSignal(input:": true,
        "$input)\\n}\",\"position\":{\"start\":{\"line\":72,\"column\":1,\"offset\":2942},\"end\":{\"line\":76,\"column\":4,\"offset\":3046},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "//": true,
        "Supported": true,
        "values": true,
        "are:": true,
        "`global`": true,
        "and": true,
        "`globalPublicTest`\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"globalPublicTest\\\",\\n\\n": true,
        "Details": true,
        "of": true,
        "the": true,
        "type": true,
        "signal": true,
        "being": true,
        "sent\\n": true,
        "\\\"typeCode\\\":": true,
        "\\\"ApplicationComplete\\\",\\n\\n": true,
        "The": true,
        "token": true,
        "received": true,
        "from": true,
        "SEEK": true,
        "as": true,
        "part": true,
        "link-out": true,
        "URL\\n": true,
        "\\\"token\\\":": true,
        "\\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":78,\"column\":1,\"offset\":3048},\"end\":{\"line\":91,\"column\":4,\"offset\":3551},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: SendApplicationSignalInput!) {
  sendApplicationSignal(input: $input)
}
`}</code></pre>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p><a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode></a>{` will return errors consistent with our documented `}<a parentName="p" {...{
        "href": "/graphql/error-responses"
      }}>{`GraphQL error responses`}</a>{`.
If your software receives authentication or transient server errors, it should retry sending of application complete signals as described in the documentation.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "/graphql/error-responses#bad_user_input"
      }}><inlineCode parentName="a">{`BAD_USER_INPUT`}</inlineCode></a>{` error may occur if there is a structural issue with the token.
Ensure the token being sent in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode></a>{` exactly matches a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received with no additional encoding nor whitespace.`}</p>
    <p>{`We recommend that you log full requests and responses to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode></a>{` mutation during development to facilitate debugging.
Use a unique `}<a parentName="p" {...{
        "href": "/graphql/in-practice#tracing-requests"
      }}><inlineCode parentName="a">{`X-Request-Id`}</inlineCode></a>{` for each request to assist in support investigations.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;