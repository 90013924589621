import { Box, Divider, Loader, Notice, Stack, Text } from 'braid-design-system';
import React, {
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';

import { ScrollingYBox } from '../ScrollingBox/ScrollingBox';

interface LoadMoreProps {
  shouldLoadMore: boolean;
  errorMessage?: string;
  subject?: string;
}

const LoadMore = forwardRef(
  (props: LoadMoreProps, ref: ForwardedRef<HTMLElement>) => {
    const { errorMessage, shouldLoadMore, subject } = props;
    const content = subject ?? 'items';

    if (errorMessage) {
      return (
        <Notice tone="critical">
          <Stack space="small">
            <Text>We couldn’t load more {content}.</Text>
            <Text size="small">{errorMessage}</Text>
          </Stack>
        </Notice>
      );
    }

    if (shouldLoadMore) {
      return (
        <Box display="flex" justifyContent="center" ref={ref} width="full">
          <Loader />
        </Box>
      );
    }

    return <Text tone="secondary">No more {content} to show.</Text>;
  },
);

interface InfiniteScrollingListProps {
  items: Array<{ element: JSX.Element; key: string }>;
  loadMore: () => Promise<unknown>;
  shouldLoadMore: boolean;
  isEmpty: boolean;
  /**
   * The pluralised name of items in the list, e.g. `events`.
   */
  subject?: string;
}

export const InfiniteScrollingList = (props: InfiniteScrollingListProps) => {
  const { items, loadMore, shouldLoadMore, isEmpty, subject } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const rootRef = useRef<HTMLElement>(null);
  const loadMoreRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const options = {
      root: rootRef.current,
      rootMargin: '0px',
      threshold: [0.1],
    };

    const observer = new IntersectionObserver(async (entries) => {
      const { isIntersecting } = entries[0];

      if (isIntersecting && !isLoading) {
        setIsLoading(true);

        try {
          await loadMore();
        } catch (err) {
          setErrorMessage(String(err));
        }

        setIsLoading(false);
      }
    }, options);

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [loadMore, isLoading]);

  if (isEmpty) {
    return (
      <GutterBox>
        <Text tone="secondary">No {subject} found.</Text>
      </GutterBox>
    );
  }

  return (
    <ScrollingYBox ref={rootRef}>
      <Stack space="none">
        {items.map(({ element, key }) => (
          <React.Fragment key={key}>
            {element}
            <Divider />
          </React.Fragment>
        ))}

        <GutterBox>
          <LoadMore
            ref={loadMoreRef}
            errorMessage={errorMessage}
            shouldLoadMore={shouldLoadMore}
            subject={subject}
          />
        </GutterBox>
      </Stack>
    </ScrollingYBox>
  );
};
