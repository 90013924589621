import { MockAdSelectionFallback } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

export const SeekAdSelectionFallback = () => (
  <BorderCard>
    <MockAdSelectionFallback
      id="seek-advertisement-type"
      onSelect={() => undefined}
      showStorybookAction
    />
  </BorderCard>
);
