import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { HomePage } from 'src/App/HomePage';
import { OopsPage } from 'src/App/OopsPage';
import { ExternalRedirect } from 'src/components/ExternalRedirect/ExternalRedirect';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';
import { AuthorizePage } from 'src/pages/authorization/AuthorizePage';
import { ResetSessionPage } from 'src/pages/authorization/ResetSessionPage';
import { CredentialsPage } from 'src/pages/manage/credentials/CredentialsPage';
import { EventsPage } from 'src/pages/manage/events/EventsPage';
import { GraphqlExplorerPage } from 'src/pages/manage/graphqlExplorer';
import { HirersPage } from 'src/pages/manage/hirers/HirersPage';
import { DashboardHomePage } from 'src/pages/manage/home/DashboardHome';
import { JobAdsPage } from 'src/pages/manage/jobAds/JobAdsPage';
import { JobPostingPage } from 'src/pages/manage/jobPosting/JobPostingPage';
import { UserGuide } from 'src/pages/manage/userGuide/UserGuide';
import { UserProfilePage } from 'src/pages/manage/userProfile/UserProfilePage';
import { UsersPage } from 'src/pages/manage/users/UsersPage';
import { WebhooksPage } from 'src/pages/manage/webhooks/WebhooksPage';
import { WebhooksPlaygroundPage } from 'src/pages/manage/webhooksPlayground/WebhookPlaygroundPage';
import { NotificationsPage } from 'src/pages/notifications/NotificationsPage';
import { SchemaPage } from 'src/pages/schema';
import { RENDERABLE_PAGES, renderPage } from 'src/site';
import { RESET_SESSION_PATH } from 'src/utils/resetSession';

export const Router = () => (
  <Routes>
    <Route
      element={<Navigate replace to="/graphql/seek-api-conventions" />}
      path="/graphql/in-the-seek-api"
    />

    <Route
      element={<Navigate replace to="/introduction#integration-process" />}
      path="/introduction/development-and-debugging"
    />

    <Route element={<HomePage />} path="/" />

    <Route
      element={
        <ExternalRedirect to="https://talent.seek.com.au/partners/terms-of-use/" />
      }
      path="/introduction/terms-of-use"
    />

    <Route
      element={<ExternalRedirect to="https://developer.seek.com/schema" />}
      path="/schema"
    />

    <Route
      element={<SchemaPage />}
      // TODO: Change when launching
      path="/schema-new/*"
    />

    {RENDERABLE_PAGES.map((page) => (
      <Route element={renderPage(page)} key={page.path} path={page.path} />
    ))}

    <Route element={<ResetSessionPage />} path={RESET_SESSION_PATH} />

    <Route
      element={
        <UserGuide>
          <AuthVerifier>
            <Outlet />
          </AuthVerifier>
        </UserGuide>
      }
    >
      <Route element={<AuthorizePage />} path="/authorize" />
      <Route element={<DashboardHomePage />} path="/manage/" />
      <Route element={<CredentialsPage />} path="/manage/credentials" />
      <Route element={<EventsPage />} path="/manage/events" />
      <Route
        element={<GraphqlExplorerPage />}
        path="/manage/graphql-explorer/*"
      />
      <Route element={<HirersPage />} path="/manage/hirers" />
      <Route element={<JobAdsPage />} path="/manage/job-ads" />
      <Route element={<NotificationsPage />} path="/manage/notifications/*" />
      <Route element={<JobPostingPage />} path="/manage/job-posting" />
      <Route element={<UsersPage />} path="/manage/users" />
      <Route element={<UserProfilePage />} path="/manage/user-profile/*" />
      <Route
        element={<WebhooksPlaygroundPage />}
        path="/manage/webhook-playground"
      />
      <Route element={<WebhooksPage />} path="/manage/webhooks">
        <Route element={<WebhooksPage />} path=":subscriptionId" />
      </Route>
    </Route>

    <Route path="*" element={<OopsPage />} />
  </Routes>
);
