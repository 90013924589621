import { Divider, IconSend, Text } from 'braid-design-system';
import * as GraphQL from 'graphql';
import { InlineCode } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';
import { definitionNameToEventType } from 'src/utils/definitionNameToEventType';
import type { ObjectType } from 'src/utils/jsonSchema';
import { getSortedJsonSchemaProperties } from 'src/utils/sort';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { PropertyDetails } from '../components/PropertyDetails';
import { SchemaMarkdown } from '../components/SchemaMarkdown';
import { TypeSnippet } from '../components/TypeSnippet';
import { schema } from '../schema';

export const WebhookObjectSchemaPage = ({
  heading,
  objectName,
  objectType,
  propertyName,
}: {
  heading: string;
  objectName?: string;
  objectType: ObjectType;
  propertyName?: string;
}) => {
  const eventType = objectName && definitionNameToEventType(objectName);

  // Try to find a matching GraphQL event instance
  const graphqlEventObject =
    eventType && objectName && schema.getType(objectName);

  const path = objectName ? `webhook.${objectName}` : 'webhook';

  return (
    <BaseSchemaPage
      titleText={heading}
      title={<InlineCode weight="weak">{heading}</InlineCode>}
      subHeading={
        <Text icon={<IconSend />} size="small" weight="medium" tone="secondary">
          {eventType ? 'Webhook event payload' : 'Webhook JSON schema object'}
        </Text>
      }
    >
      <SchemaMarkdown type={path} />

      <Divider />

      <SchemaSection heading="Properties">
        {getSortedJsonSchemaProperties(objectType).map(([name, type]) => (
          <PropertyDetails
            objectName={objectName}
            key={name}
            name={name}
            type={type}
            highlight={propertyName === name}
            required={objectType.required?.includes(name)}
            markdownPath={`${path}.${name}`}
          />
        ))}
      </SchemaSection>

      {GraphQL.isObjectType(graphqlEventObject) && (
        <SchemaSection heading="GraphQL event object">
          <TypeSnippet type={graphqlEventObject} />
        </SchemaSection>
      )}
    </BaseSchemaPage>
  );
};
