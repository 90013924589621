
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The optional hiring organization for whom the relationship was changed.`}</p>
    <p>{`This field is only accessible while you have an active relationship with the hirer.
If all relationships have been removed, it will return null along with a `}<inlineCode parentName="p">{`FORBIDDEN`}</inlineCode>{` error.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;