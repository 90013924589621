import {
  Box,
  Divider,
  type Heading,
  PageBlock,
  Stack,
} from 'braid-design-system';
import type React from 'react';
import { Helmet } from 'react-helmet';

import { ContentHeader } from 'src/components/ContentHeader/ContentHeader';

export const BaseSchemaPage = ({
  title,
  icon,
  titleText,
  children,
  divider,
  subHeading,
}: {
  icon?: React.ComponentProps<typeof Heading>['icon'];
  children: React.ComponentProps<typeof Stack>['children'];
  divider?: boolean;
  subHeading?: React.ReactElement;
} & (
  | { title: string; titleText?: never }
  | { title?: React.ReactNode; titleText: string }
)) => (
  <Box component="main">
    <Helmet>
      <title>{typeof title === 'string' ? title : titleText}</title>
    </Helmet>

    <PageBlock width="large">
      <ContentHeader
        icon={icon}
        subHeading={subHeading}
        titleText={typeof title === 'string' ? title : titleText}
      >
        {title}
      </ContentHeader>

      {divider ? <Divider /> : null}

      <Box display="flex" flexDirection="column" justifyContent="spaceBetween">
        <Box component="article" paddingTop="small" paddingBottom="large">
          <Stack space="xlarge">{children}</Stack>
        </Box>
      </Box>
    </PageBlock>
  </Box>
);
