
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An array of attachments related to the candidate's profile.`}</p>
    <p>{`This field is redacted and an empty list is returned when a candidate or job application is deleted.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;