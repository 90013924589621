
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Pagination metadata for a result list.`}</p>
    <p>{`This is compliant with the `}<em parentName="p">{`Relay Cursor Connections Specification`}</em>{`:`}</p>
    <p><a parentName="p" {...{
        "href": "https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo"
      }}>{`https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;