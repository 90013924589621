import { Stack, Text } from 'braid-design-system';
import type { GraphQLType, OperationTypeNode } from 'graphql';
import { TableRow } from 'scoobie';

import { NameTextLink } from 'src/components/NameTextLink/NameTextLink';
import { TypeLink } from 'src/components/TypeLink/TypeLink';
import { hrefForOperationArgument } from 'src/utils/href';

import { SchemaMarkdown } from './SchemaMarkdown';

interface ArgPathProps {
  operationType: OperationTypeNode;
  operationName: string;
  argName: string;
}

interface Props extends ArgPathProps {
  arg: string;
  type: GraphQLType;
  highlight?: boolean;
  deprecated: boolean;
}

export const ArgDetails = ({
  arg,
  operationType,
  operationName,

  argName,
  type,
  highlight,
  deprecated,
}: Props) => {
  const hrefLinkTo = hrefForOperationArgument(
    operationType,
    operationName,
    argName,
  );
  const elementIdLinkTo = `${operationName}-${argName}`;
  return (
    <TableRow selected={highlight}>
      <Stack space="medium">
        <NameTextLink
          name={argName}
          type={type}
          highlight={highlight}
          to={hrefLinkTo}
          id={elementIdLinkTo}
          deprecated={deprecated}
        />
        <Text>
          <TypeLink type={type} />
        </Text>
      </Stack>

      <SchemaMarkdown type={arg} />
    </TableRow>
  );
};
