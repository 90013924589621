
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The latency of the HTTP request in milliseconds.`}</p>
    <p>{`This will be `}<inlineCode parentName="p">{`null`}</inlineCode>{` if the request wasn't made (i.e. an `}<inlineCode parentName="p">{`InvalidUrl`}</inlineCode>{` error occurred).`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;