import { Box, FieldLabel, Stack, Text, TextField } from 'braid-design-system';
import { useId, useState } from 'react';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

interface Props {
  limit: number;
}

export const SearchBulletPoints = ({ limit }: Props) => {
  const id = useId();

  const [bullets, setBullets] = useState<string[]>([]);

  return (
    <BorderCard>
      <Stack space="medium">
        <FieldLabel
          htmlFor={id}
          label="Key selling points"
          secondaryLabel="optional"
        />

        <Text tone="secondary">
          Enter {limit} key selling point{limit === 1 ? '' : 's'} to attract
          candidates to view your role.
        </Text>
        <Box id={id}>
          <Stack space="medium">
            {new Array(limit).fill(null).map((_, index) => (
              <TextField
                aria-label={`Key selling point ${index + 1}`}
                characterLimit={80}
                id={`${id}-${index + 1}`}
                key={index}
                onChange={(event) => {
                  const { value } = event.currentTarget;

                  setBullets((prev) => [
                    ...prev.slice(0, index),
                    value,
                    ...prev.slice(index + 1),
                  ]);
                }}
                value={bullets[index] ?? ''}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </BorderCard>
  );
};
