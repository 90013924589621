import {
  Checkbox,
  Column,
  Columns,
  Dropdown,
  Heading,
  Hidden,
  RadioGroup,
  RadioItem,
  Stack,
  Text,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

import { BorderCard } from 'src/components/BorderCard/BorderCard';
import { DividerColumn } from 'src/components/DividerColumn/DividerColumn';
import { Fade } from 'src/components/Fade/Fade';

export const SingleChoiceSingleSelectionQuestion = () => {
  const [candidateViewOption, setCandidateViewOption] = useState('Yes');
  const [hirerViewOption, setHirerViewOption] = useState('Yes');

  return (
    <Columns collapseBelow="desktop" space="large">
      <Column>
        <Stack space="medium">
          <Heading level="4" weight="weak">
            Hirer view
          </Heading>

          <BorderCard>
            <RadioGroup
              id="experience-question-hirer-view"
              value={candidateViewOption}
              onChange={({ currentTarget: { value } }) =>
                setCandidateViewOption(value)
              }
              label="Do you have customer service experience?"
              description="I will only accept this answer"
            >
              <RadioItem label="Yes" value="Yes" />
              <RadioItem label="No" value="No" />
            </RadioGroup>
          </BorderCard>
        </Stack>
      </Column>

      <DividerColumn />

      <Column>
        <Fade>
          <Stack space="medium">
            <Heading level="4" weight="weak">
              Candidate view
            </Heading>

            <BorderCard>
              <RadioGroup
                id="experience-question-candidate-view"
                value={hirerViewOption}
                onChange={({ currentTarget: { value } }) =>
                  setHirerViewOption(value)
                }
                label="Do you have customer service experience?"
              >
                <RadioItem label="Yes" value="Yes" />
                <RadioItem label="No" value="No" />
              </RadioGroup>
            </BorderCard>
          </Stack>
        </Fade>
      </Column>
    </Columns>
  );
};

const RIGHT_TO_WORK_TYPES = [
  "I'm an Australian citizen",
  "I'm a permanent resident and/or NZ citizen",
  'I have a family/partner visa with no restrictions',
  'I have a graduate temporary work visa',
  'I have a holiday temporary work visa',
  'I have a temporary visa with restrictions',
  'I require sponsorship to work for a new employer (e.g. 482, 457)',
];

export const MultiChoiceSingleSelectionQuestion = () => {
  const [candidateViewOption, setCandidateViewOption] = useState('');
  const [hirerViewOption, setHirerViewOption] = useState<boolean[]>(
    new Array(RIGHT_TO_WORK_TYPES.length).fill(false),
  );

  return (
    <Columns collapseBelow="desktop" space="large">
      <Column>
        <Stack space="medium">
          <Heading level="4" weight="weak">
            Hirer view
          </Heading>

          <BorderCard>
            <Stack space="medium">
              <Stack space="small">
                <Text size="standard" weight="strong">
                  Which of the following statements best describes your right to
                  work in Australia?
                </Text>

                <Text size="standard" tone="secondary">
                  I will accept any of these answers
                </Text>
              </Stack>

              {RIGHT_TO_WORK_TYPES.map((type, index) => (
                <Checkbox
                  label={type}
                  key={type}
                  id={`right-to-work-question-${index}-hirer-view`}
                  checked={hirerViewOption[index]}
                  onChange={(event) => {
                    const { checked } = event.currentTarget;

                    setHirerViewOption((prev) => {
                      const next = prev.slice();
                      next[index] = checked;
                      return next;
                    });
                  }}
                />
              ))}
            </Stack>
          </BorderCard>
        </Stack>
      </Column>

      <DividerColumn />

      <Column>
        <Fade>
          <Stack space="medium">
            <Heading level="4" weight="weak">
              Candidate view
            </Heading>

            <BorderCard>
              <Dropdown
                id="right-to-work-question-candidate-view"
                label="Which of the following statements best describes your right to work in Australia?"
                onChange={(event) =>
                  setCandidateViewOption(event.currentTarget.value)
                }
                placeholder="Select..."
                value={candidateViewOption}
              >
                {RIGHT_TO_WORK_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Dropdown>
            </BorderCard>
          </Stack>
        </Fade>
      </Column>
    </Columns>
  );
};

const MS_PRODUCT_TYPES = [
  'Word',
  'Excel',
  'PowerPoint',
  'OneNote',
  'Outlook',
  'Access',
  'None of these',
];

export const MultiChoiceMultiSelectionQuestion = () => {
  const [candidateViewOption, setCandidateViewOption] = useState<boolean[]>(
    new Array(MS_PRODUCT_TYPES.length).fill(false),
  );
  const [hirerViewOption, setHirerViewOption] = useState<boolean[]>(
    new Array(MS_PRODUCT_TYPES.length).fill(false),
  );

  return (
    <Columns collapseBelow="desktop" space="large">
      <Column>
        <Stack space="medium">
          <Heading level="4" weight="weak">
            Hirer view
          </Heading>

          <BorderCard>
            <Stack space="medium">
              <Stack space="small">
                <Text size="standard" weight="strong">
                  Which of the following Microsoft Office products are you
                  experienced with?
                </Text>

                <Text size="standard" tone="secondary">
                  I will only accept this combination of answers
                </Text>
              </Stack>

              {MS_PRODUCT_TYPES.map((type, index) => (
                <Checkbox
                  label={type}
                  key={type}
                  id={`mc-question-${index}-hirer-view`}
                  checked={hirerViewOption[index]}
                  onChange={(event) => {
                    const { checked } = event.currentTarget;

                    setHirerViewOption((prev) => {
                      const next = prev.slice();
                      next[index] = checked;
                      return next;
                    });
                  }}
                />
              ))}
            </Stack>
          </BorderCard>
        </Stack>
      </Column>

      <DividerColumn />

      <Column>
        <Fade>
          <Stack space="medium">
            <Heading level="4" weight="weak">
              Candidate view
            </Heading>

            <BorderCard>
              <Stack space="medium">
                <Text size="standard" weight="strong">
                  Which of the following Microsoft Office products are you
                  experienced with?
                </Text>

                {MS_PRODUCT_TYPES.map((type, index) => (
                  <Checkbox
                    label={type}
                    key={type}
                    id={`mc-question-${index}-candidate-view`}
                    checked={candidateViewOption[index]}
                    onChange={(event) => {
                      const { checked } = event.currentTarget;

                      setCandidateViewOption((prev) => {
                        const next = prev.slice();
                        next[index] = checked;
                        return next;
                      });
                    }}
                  />
                ))}
              </Stack>
            </BorderCard>
          </Stack>
        </Fade>
      </Column>
    </Columns>
  );
};

const SALARY_RANGE_OPTIONS = [
  '30K',
  '40K',
  '50K',
  '60K',
  '70K',
  '80K',
  '90K',
  '100K',
];

export const RangeSingleSelectionQuestion = () => {
  const [maximum, setMaximum] = useState('');
  const [minimum, setMinimum] = useState('');
  const [candidateViewOption, setCandidateViewOption] = useState('');

  const minimumIndex = SALARY_RANGE_OPTIONS.indexOf(minimum);

  useEffect(
    () =>
      setMaximum((prev) =>
        SALARY_RANGE_OPTIONS.indexOf(prev) <= minimumIndex ? '' : prev,
      ),
    [minimumIndex],
  );

  return (
    <Columns collapseBelow="desktop" space="large">
      <Column>
        <Stack space="medium">
          <Heading level="4" weight="weak">
            Hirer view
          </Heading>

          <BorderCard>
            <Stack space="medium">
              <Stack space="small">
                <Text weight="strong">
                  What is your expected annual base salary?
                </Text>

                <Text tone="secondary">I will accept between this range</Text>
              </Stack>

              <Columns collapseBelow="desktop" space="small" alignY="center">
                <Column width="content">
                  <Dropdown
                    aria-label="Minimum salary"
                    id="salary-range-question-hirer-view-minimum"
                    onChange={(event) => setMinimum(event.currentTarget.value)}
                    value={minimum}
                    placeholder="Minimum"
                  >
                    {SALARY_RANGE_OPTIONS.map((value) => {
                      const index = SALARY_RANGE_OPTIONS.indexOf(value);

                      return (
                        <option
                          disabled={index === SALARY_RANGE_OPTIONS.length - 1}
                          key={value}
                          value={value}
                        >
                          {value}
                        </option>
                      );
                    })}
                  </Dropdown>
                </Column>

                <Hidden below="desktop">
                  <Column width="content">
                    <Text>to</Text>
                  </Column>
                </Hidden>

                <Column width="content">
                  <Dropdown
                    aria-label="Maximum salary"
                    id="salary-range-question-hirer-view-maximum"
                    onChange={(event) => setMaximum(event.currentTarget.value)}
                    value={maximum}
                    placeholder="Maximum"
                  >
                    {SALARY_RANGE_OPTIONS.map((value) => {
                      const index = SALARY_RANGE_OPTIONS.indexOf(value);

                      return (
                        <option
                          disabled={index === 0 || index <= minimumIndex}
                          key={value}
                          value={value}
                        >
                          {value}
                        </option>
                      );
                    })}
                  </Dropdown>
                </Column>
              </Columns>
            </Stack>
          </BorderCard>
        </Stack>
      </Column>

      <DividerColumn />

      <Column>
        <Fade>
          <Stack space="medium">
            <Heading level="4" weight="weak">
              Candidate view
            </Heading>
            <BorderCard>
              <Dropdown
                id="salary-range-question-candidate-view"
                label="What is your expected annual base salary?"
                onChange={(event) =>
                  setCandidateViewOption(event.currentTarget.value)
                }
                placeholder="Select..."
                value={candidateViewOption}
              >
                {SALARY_RANGE_OPTIONS.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Dropdown>
            </BorderCard>
          </Stack>
        </Fade>
      </Column>
    </Columns>
  );
};
