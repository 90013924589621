
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An identifier to ensure that multiple ads are not created on retries.`}</p>
    <p>{`A unique identifier needs to be generated by your software for each position profile.
The same identifier must be provided when retrying after create failures.
Your identifiers are isolated from and will not conflict with those generated by other recruitment software providers.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;