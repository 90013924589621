import {
  Actions,
  Alert,
  Badge,
  Bleed,
  ButtonLink,
  Divider,
  Heading,
  IconNewWindow,
  PageBlock,
  Stack,
  Text,
  Tiles,
} from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { ContentHeader } from 'src/components/ContentHeader/ContentHeader';
import { FlexPageWithFooter } from 'src/components/FlexPageWithFooter/FlexPageWithFooter';
import { PageIcon } from 'src/components/PageIcon/PageIcon';
import { TileLink } from 'src/components/TileLink/TileLink';

const Foreword = () => (
  <Stack space="gutter">
    <Text>
      Welcome to our developer site, home of technical documentation for the{' '}
      <SmartTextLink href="https://www.seek.com.au/about/">
        SEEK Group
      </SmartTextLink>
      .
    </Text>

    <Text>
      Our Developer Site enables providers like you to integrate your software
      with SEEK’s employment marketplace across the Asia Pacific. By leveraging
      our API, you can now easily integrate SEEK’s solutions into your software:
    </Text>

    <UseCases />

    <Text>
      Visit the{' '}
      <SmartTextLink href="https://talent.seek.com.au/partners/partner-with-seek">
        Partner Hub
      </SmartTextLink>{' '}
      for a high-level overview of the hirer product suite.
    </Text>
  </Stack>
);

const GettingStarted = () => (
  <Stack space="gutter">
    <Heading level="4">New to the SEEK API?</Heading>

    <Text>
      Our introductory content outlines the SEEK API and our integration
      process, then details each technology component in the appropriate order.
      You can navigate through the pages in order or jump to the section you are
      looking for in the ”Getting Started” section of our sidebar. From there,
      you can progress to build or manage your integration.
    </Text>

    <Actions>
      <ButtonLink
        href="/introduction"
        icon={<PageIcon icon="Introduction" />}
        variant="soft"
      >
        Getting started
      </ButtonLink>
    </Actions>

    <Divider />
  </Stack>
);

const UseCases = () => (
  <Tiles columns={[1, 2]} space="small">
    <TileLink
      description="Advertise positions on SEEK with custom questions, granular
              locations, and variable pricing"
      href="/use-cases/job-posting"
      icon={<PageIcon icon="JobPosting" />}
      title="Job Posting"
    />

    <TileLink
      description="Export candidate applications from SEEK into your recruitment software"
      href="/use-cases/application-export"
      icon={<PageIcon icon="OptimisedApply" />}
      title="Optimised Apply"
    />

    <TileLink
      description="Allow candidates to pre-fill an external apply form hosted by your recruitment software using their SEEK Profile"
      href="/use-cases/apply-with-seek"
      icon={<PageIcon icon="ApplyWithSeek" />}
      title="Apply with SEEK"
      badge={<Badge tone="promote">Beta</Badge>}
    />

    <TileLink
      description="Understand how each job ad is performing compared to the market"
      href="/use-cases/ad-performance"
      icon={<PageIcon icon="AdPerformance" />}
      title="Ad Performance Panel"
      badge={<Badge tone="promote">Beta</Badge>}
    />
  </Tiles>
);

const Guides = () => (
  <Stack space="gutter">
    <Heading level="4">Already integrated with the SEEK API?</Heading>

    <Text>
      Keep your integration up to date with the latest revisions. The SEEK API
      is always under development and as such integrated providers are expected
      to deliver the latest and most valuable experiences to our mutual hirers.
      Subscribe to our release notes to stay informed of changes as they reach
      general availability and evolve your integration accordingly.
    </Text>

    <Actions>
      <ButtonLink
        href="/guides"
        icon={<PageIcon icon="GuideIndex" />}
        variant="soft"
      >
        How-to guides
      </ButtonLink>

      <ButtonLink
        href="/introduction/release-notes"
        icon={<PageIcon icon="ReleaseNotes" />}
        variant="soft"
      >
        Release notes
      </ButtonLink>
    </Actions>

    <Divider />

    <Heading level="4">Developer tools</Heading>

    <Text>
      Familiarise yourself with our suite of developer tools for building and
      managing your integration with SEEK:
    </Text>

    <Tiles columns={[1, 1, 3]} space="small">
      <TileLink
        description={
          <>
            Log in to configure and manage your integration in an admin UI{' '}
            <IconNewWindow />
          </>
        }
        href="/manage"
        icon={<PageIcon icon="DeveloperDashboard" />}
        title="Dashboard"
      />

      <TileLink
        description={
          <>
            Inspect low-level details of our GraphQL operations & types{' '}
            <IconNewWindow />
          </>
        }
        href="https://developer.seek.com/schema"
        icon={<PageIcon icon="Schema" />}
        title="Schema"
      />

      <TileLink
        description={
          <>
            Check for incidents and upcoming maintenance <IconNewWindow />
          </>
        }
        href="https://status.seek.com"
        icon={<PageIcon icon="StatusPage" />}
        title="Status page"
      />
    </Tiles>
  </Stack>
);

export const HomePage = () => (
  <PageBlock width="large">
    <ContentHeader>SEEK API</ContentHeader>

    <Bleed bottom="small">
      <Alert tone="info">
        <Text>
          Access to the SEEK API requires approval from SEEK. If you are
          interested in using this API, apply for access by filling out our{' '}
          <SmartTextLink href="https://talent.seek.com.au/partners/integration-request/">
            Integration Request form.
          </SmartTextLink>
        </Text>
      </Alert>
    </Bleed>

    <FlexPageWithFooter>
      <Stack space="xlarge">
        <Foreword />

        <Divider />

        <GettingStarted />

        <Guides />
      </Stack>
    </FlexPageWithFooter>
  </PageBlock>
);
