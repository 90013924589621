
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The details of the uploaded candidate profile that was updated.`}</p>
    <p>{`The uploaded candidate is available in the `}<a parentName="p" {...{
        "href": "/schema-new/query/candidate",
        "title": "The candidate for the given `id`"
      }}><inlineCode parentName="a">{`candidate`}</inlineCode></a>{` field.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;