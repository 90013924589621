
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether previously delivered events should be included in the request.`}</p>
    <p>{`This also includes events that were not delivered because the relevant hirer relationship or webhook subscription was not in place at time of occurrence.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;