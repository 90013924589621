import { MDXProvider } from '@mdx-js/react';
import type { ComponentProps } from 'react';
import { useLocation } from 'react-router-dom';
import { SmartTextLink } from 'scoobie';

import { content } from '../content';

const LocationAwareSmartTextLink = (
  props: ComponentProps<typeof SmartTextLink>,
) => {
  const { pathname } = useLocation();

  if (props.href === pathname) {
    return props.children;
  }

  return <SmartTextLink {...props} />;
};

export const SchemaMarkdown = ({ type }: { type: string }) => {
  const nameWithFirstUpper = type.charAt(0).toUpperCase() + type.slice(1);

  const Component =
    content[type as keyof typeof content] ||
    content[nameWithFirstUpper as keyof typeof content];

  if (!Component) {
    // eslint-disable-next-line no-console
    console.error(`No content for ${type}`);
    return null;
  }

  return (
    <MDXProvider components={{ a: LocationAwareSmartTextLink }}>
      <Component />
    </MDXProvider>
  );
};
