
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received as part of the query string when the candidate arrived from SEEK.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` is not available (i.e. the candidate did not arrive from SEEK), this field can be omitted.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;