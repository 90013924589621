
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The descriptions for the position profile.`}</p>
    <p>{`Currently, only the `}<inlineCode parentName="p">{`AdvertisementDetails`}</inlineCode>{` description is used.
Other descriptions will be accepted but are ignored when determining the relevance of suggestion.`}</p>
    <p>{`The job category suggestion algorithm is designed to work with the complete advertisement details as they would appear on the final job ad.
Providing incomplete or placeholder text in this field may result in irrelevant suggestions.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;