
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "polling"
    }}>{`Polling`}</h1>
    <p>{`Event polling has two primary uses:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Paging through historical events.`}</p>
        <p parentName="li">{`If you need to backfill a webhook subscription or reprocess missed events,
we strongly recommend using our event `}<a parentName="p" {...{
            "href": "/events/webhooks#replaying-a-subscriptions-events"
          }}>{`replay`}</a>{` functionality instead.
This reduces the complexity of your integration;
the SEEK API will take care of requeuing each event in scope,
and your webhook endpoint will process a replayed event as it would any other event.`}</p>
        <p parentName="li">{`If you want to interactively explore historical events you can use the `}<a parentName="p" {...{
            "href": "/manage/events"
          }}>{`Developer Dashboard’s events page`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Event delivery for partners without the capability to consume webhooks.`}</p>
      </li>
    </ol>
    <p>{`Both flows use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/events"
      }}><inlineCode parentName="a">{`events`}</inlineCode>{` query`}</a>{` for `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#cursor-based-pagination"
      }}>{`cursor-based pagination`}</a>{` through events.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`events`}</inlineCode>{` query supports filtering on `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#scheme-identifiers"
      }}>{`scheme ID`}</a>{`, event types and an optional date range.
You can implement more sophisticated event categorisation or filtering within your polling software.
For example, you might select the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateProfile/field/associatedPositionProfile"
      }}><inlineCode parentName="a">{`CandidateProfile.associatedPositionProfile`}</inlineCode></a>{` from a `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateApplicationCreatedEvent"
      }}><inlineCode parentName="a">{`CandidateApplicationCreated`}</inlineCode>{` event`}</a>{` to identify the associated position and hirer.`}</p>
    <p>{`To avoid wasted resources from repeatedly polling each hirer, filtering events by hirer ID is intentionally unsupported.
As an alternative you can use `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhooks`}</a>{` to deliver events to hirer-specific HTTPS endpoints.`}</p>
    <h2 {...{
      "id": "backfilling--reprocessing-flow"
    }}>{`Backfilling & reprocessing flow`}</h2>
    <p>{`If you use `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` subscriptions,
we strongly recommend using our event `}<a parentName="p" {...{
        "href": "/events/webhooks#replaying-a-subscriptions-events"
      }}>{`replay`}</a>{` functionality instead.`}</p>
    <p>{`This section remains as a reference for integrations that use polling-based event delivery.`}</p>
    <img alt="" data-scoobie-style="none" height="529" src={require('../../../mermaid/.7d7046013878784de70014287a49ee28e7415267.mmd.svg')} title="Backfilling interaction" width="583" />
    <p>{`This flow is based on paging through events within a specific time range:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For processing missed or failed events you can specify the `}<inlineCode parentName="p">{`afterDateTime`}</inlineCode>{` and `}<inlineCode parentName="p">{`beforeDateTime`}</inlineCode>{` of the affected period.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For backfilling events for a webhook subscription you can specify just the `}<inlineCode parentName="p">{`beforeDateTime`}</inlineCode>{` of when the subscription was created.`}</p>
      </li>
    </ul>
    <p>{`You then repeatedly use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/events"
      }}><inlineCode parentName="a">{`events`}</inlineCode>{` query`}</a>{` with the determined `}<inlineCode parentName="p">{`afterDateTime`}</inlineCode>{` and `}<inlineCode parentName="p">{`beforeDateTime`}</inlineCode>{` arguments.
The initial query should pass `}<inlineCode parentName="p">{`after: null`}</inlineCode>{` to get the first page.`}</p>
    <p>{`For example, this queries the first page of 25 `}<inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode>{` events between April 10th and 20th 2020:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query (\\n  $after: String\\n  $filter: EventsFilterInput!\\n  $first: Int!\\n  $schemeId: String!\\n) {\\n  events(after: $after, filter: $filter, first: $first, schemeId: $schemeId) {\\n    edges {\\n      node {\\n        typeCode\\n        ... on CandidateApplicationCreatedEvent {\\n          candidate {\\n            person {\\n              name {\\n                formattedName\\n              }\\n            }\\n          }\\n          candidateApplicationProfile {\\n            associatedPositionProfile {\\n              positionUri\\n            }\\n          }\\n        }\\n      }\\n    }\\n    pageInfo {\\n      hasNextPage\\n      endCursor\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":62,\"column\":1,\"offset\":2786},\"end\":{\"line\":95,\"column\":4,\"offset\":3419},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"after\\\": null,\\n  \\\"filter\\\": {\\n    \\\"eventTypeCodes\\\": \\\"CandidateApplicationCreated\\\",\\n    \\\"afterDateTime\\\": \\\"2020-04-10T00:00:00Z\\\",\\n    \\\"beforeDateTime\\\": \\\"2020-04-21T00:00:00Z\\\"\\n  },\\n  \\\"first\\\": 25,\\n  \\\"schemeId\\\": \\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":97,\"column\":1,\"offset\":3421},\"end\":{\"line\":108,\"column\":4,\"offset\":3664},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "(\\n": true,
        "": true,
        "$after:": true,
        "String\\n": true,
        "$filter:": true,
        "EventsFilterInput!\\n": true,
        "$first:": true,
        "Int!\\n": true,
        "$schemeId:": true,
        "String!\\n)": true,
        "{\\n": true,
        "events(after:": true,
        "$after,": true,
        "filter:": true,
        "$filter,": true,
        "first:": true,
        "$first,": true,
        "schemeId:": true,
        "$schemeId)": true,
        "edges": true,
        "node": true,
        "typeCode\\n": true,
        "...": true,
        "on": true,
        "CandidateApplicationCreatedEvent": true,
        "candidate": true,
        "person": true,
        "name": true,
        "formattedName\\n": true,
        "}\\n": true,
        "candidateApplicationProfile": true,
        "associatedPositionProfile": true,
        "positionUri\\n": true,
        "pageInfo": true,
        "hasNextPage\\n": true,
        "endCursor\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":62,\"column\":1,\"offset\":2786},\"end\":{\"line\":95,\"column\":4,\"offset\":3419},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"after\\\":": true,
        "null,\\n": true,
        "\\\"filter\\\":": true,
        "\\\"eventTypeCodes\\\":": true,
        "\\\"CandidateApplicationCreated\\\",\\n": true,
        "\\\"afterDateTime\\\":": true,
        "\\\"2020-04-10T00:00:00Z\\\",\\n": true,
        "\\\"beforeDateTime\\\":": true,
        "\\\"2020-04-21T00:00:00Z\\\"\\n": true,
        "},\\n": true,
        "\\\"first\\\":": true,
        "25,\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":97,\"column\":1,\"offset\":3421},\"end\":{\"line\":108,\"column\":4,\"offset\":3664},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query (
  $after: String
  $filter: EventsFilterInput!
  $first: Int!
  $schemeId: String!
) {
  events(after: $after, filter: $filter, first: $first, schemeId: $schemeId) {
    edges {
      node {
        typeCode
        ... on CandidateApplicationCreatedEvent {
          candidate {
            person {
              name {
                formattedName
              }
            }
          }
          candidateApplicationProfile {
            associatedPositionProfile {
              positionUri
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`}</code></pre>
    <p>{`For subsequent queries you pass the previous page’s `}<inlineCode parentName="p">{`endCursor`}</inlineCode>{` as the `}<inlineCode parentName="p">{`after`}</inlineCode>{` argument.
Once `}<inlineCode parentName="p">{`hasNextPage`}</inlineCode>{` is `}<inlineCode parentName="p">{`false`}</inlineCode>{` there are no more events in the time range and the process is complete.`}</p>
    <p>{`Events will remain for 90 days after they occur.
Your software must store any data that it needs to access after the 90 day period.`}</p>
    <h2 {...{
      "id": "event-delivery-flow"
    }}>{`Event delivery flow`}</h2>
    <img alt="" data-scoobie-style="none" height="433" src={require('../../../mermaid/.8e1e6ac548c7e7ddb0ac4ce74c0747eee0fca363.mmd.svg')} title="Event delivery interaction" width="583" />
    <p>{`In this flow the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/events"
      }}><inlineCode parentName="a">{`events`}</inlineCode>{` query`}</a>{` is used with the previous poll’s `}<inlineCode parentName="p">{`endCursor`}</inlineCode>{` as its `}<inlineCode parentName="p">{`after`}</inlineCode>{` argument.
The cursor is persisted by your software to resume event polling from the last processed event.
If it’s difficult to persist the cursor you can instead use `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhooks`}</a>{` to receive events statelessly.`}</p>
    <p>{`For accurate paging only the `}<inlineCode parentName="p">{`after`}</inlineCode>{` argument should be used;
you should not specify an `}<inlineCode parentName="p">{`afterDateTime`}</inlineCode>{` or `}<inlineCode parentName="p">{`beforeDateTime`}</inlineCode>{`.
This ensures that polling will resume from the last successful poll in the event of a failure.`}</p>
    <p>{`For example, this queries for a page of 25 `}<inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode>{` events since the previous cursor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query (\\n  $after: String!\\n  $filter: EventsFilterInput!\\n  $first: Int!\\n  $schemeId: String!\\n) {\\n  events(after: $after, filter: $filter, first: $first, schemeId: $schemeId) {\\n    edges {\\n      node {\\n        typeCode\\n        ... on CandidateApplicationCreatedEvent {\\n          candidate {\\n            person {\\n              name {\\n                formattedName\\n              }\\n            }\\n          }\\n          candidateApplicationProfile {\\n            associatedPositionProfile {\\n              positionUri\\n            }\\n          }\\n        }\\n      }\\n    }\\n    pageInfo {\\n      hasNextPage\\n      endCursor\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":141,\"column\":1,\"offset\":5005},\"end\":{\"line\":174,\"column\":4,\"offset\":5639},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"after\\\": \\\"seekAnzPublicTest:eventCursor:events:3zQw2pr4e9HHhsv8CJUhxMJPMA7mM4L13\\\",\\n  \\\"filter\\\": {\\n    \\\"eventTypeCodes\\\": \\\"CandidateApplicationCreated\\\"\\n  },\\n  \\\"first\\\": 25,\\n  \\\"schemeId\\\": \\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":176,\"column\":1,\"offset\":5641},\"end\":{\"line\":185,\"column\":4,\"offset\":5861},\"indent\":[1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "(\\n": true,
        "": true,
        "$after:": true,
        "String!\\n": true,
        "$filter:": true,
        "EventsFilterInput!\\n": true,
        "$first:": true,
        "Int!\\n": true,
        "$schemeId:": true,
        "String!\\n)": true,
        "{\\n": true,
        "events(after:": true,
        "$after,": true,
        "filter:": true,
        "$filter,": true,
        "first:": true,
        "$first,": true,
        "schemeId:": true,
        "$schemeId)": true,
        "edges": true,
        "node": true,
        "typeCode\\n": true,
        "...": true,
        "on": true,
        "CandidateApplicationCreatedEvent": true,
        "candidate": true,
        "person": true,
        "name": true,
        "formattedName\\n": true,
        "}\\n": true,
        "candidateApplicationProfile": true,
        "associatedPositionProfile": true,
        "positionUri\\n": true,
        "pageInfo": true,
        "hasNextPage\\n": true,
        "endCursor\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":141,\"column\":1,\"offset\":5005},\"end\":{\"line\":174,\"column\":4,\"offset\":5639},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"after\\\":": true,
        "\\\"seekAnzPublicTest:eventCursor:events:3zQw2pr4e9HHhsv8CJUhxMJPMA7mM4L13\\\",\\n": true,
        "\\\"filter\\\":": true,
        "\\\"eventTypeCodes\\\":": true,
        "\\\"CandidateApplicationCreated\\\"\\n": true,
        "},\\n": true,
        "\\\"first\\\":": true,
        "25,\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":176,\"column\":1,\"offset\":5641},\"end\":{\"line\":185,\"column\":4,\"offset\":5861},\"indent\":[1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query (
  $after: String!
  $filter: EventsFilterInput!
  $first: Int!
  $schemeId: String!
) {
  events(after: $after, filter: $filter, first: $first, schemeId: $schemeId) {
    edges {
      node {
        typeCode
        ... on CandidateApplicationCreatedEvent {
          candidate {
            person {
              name {
                formattedName
              }
            }
          }
          candidateApplicationProfile {
            associatedPositionProfile {
              positionUri
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`}</code></pre>
    <p>{`To process all new events the `}<inlineCode parentName="p">{`events`}</inlineCode>{` query should be repeated until `}<inlineCode parentName="p">{`hasNextPage`}</inlineCode>{` is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "limits"
    }}>{`Limits`}</h2>
    <h3 {...{
      "id": "polling-interval"
    }}>{`Polling interval`}</h3>
    <p>{`Once the last page of events has been reached you should not poll again for at least 15 minutes.
If you’re polling multiple event streams you must not exceed one `}<inlineCode parentName="p">{`events`}</inlineCode>{` query per minute across your entire SEEK API integration.
This is a global limit applied across all of your servers, hirers and webhook subscriptions.`}</p>
    <p>{`If near real-time event delivery is required then `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhooks`}</a>{` can be used instead.
See `}<a parentName="p" {...{
        "href": "/events#why-webhooks"
      }}>{`“why webhooks”`}</a>{` for an explanation of why frequent polling is harmful.`}</p>
    <h3 {...{
      "id": "subscription-streams"
    }}>{`Subscription streams`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/events"
      }}><inlineCode parentName="a">{`events`}</inlineCode>{` query`}</a>{` can be used in tandem with `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/EventsFilterInput/field/subscriptionId"
      }}><inlineCode parentName="a">{`filter.subscriptionId`}</inlineCode></a>{` to obtain an ad-hoc historical view of the events associated with a particular subscription.
This can help you to discover that historical events are missing or unprocessed,
which you can subsequently remediate by triggering a `}<a parentName="p" {...{
        "href": "/events/webhooks#replaying-a-subscriptions-events"
      }}>{`replay`}</a>{`.
We recommend the `}<a parentName="p" {...{
        "href": "/manage/webhooks"
      }}>{`Developer Dashboard’s webhooks page`}</a>{` for ad-hoc troubleshooting of this nature.`}</p>
    <p>{`Polling implementations are approved on a case-by-case basis and are mandated to operate at a partner level.
Your production environment must not implement event delivery by polling subscription-scoped event streams.
This limitation ensures that the SEEK API can maintain its quality of service and operating costs as partner usage and webhook subscriptions scale over time.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;