import { Notice, Text, TextLink } from 'braid-design-system';
import { OperationTypeNode } from 'graphql';
import { InlineCode, Table, TableRow } from 'scoobie';

import { SchemaSection } from 'src/components/SchemaSection/SchemaSection';
import { type TypeOccurrence, typeIndex } from 'src/pages/schema/schema';
import {
  hrefForNamedType,
  hrefForNamedTypeField,
  hrefForOperation,
  hrefForOperationArgument,
} from 'src/utils/href';

interface Props {
  name: string;
}

export const TypeSeenIn = ({ name }: Props) => (
  <SchemaSection heading="Seen in" wrapper="none">
    <TypeSeenInChild name={name} />
  </SchemaSection>
);

const hrefForTypeOccurrence = (occurrence: TypeOccurrence): string => {
  switch (occurrence.parentType) {
    case 'Input Object':
    case 'Interface':
    case 'Object':
      return hrefForNamedTypeField(occurrence.parentName, occurrence.fieldName);

    case 'Mutation':
      // If we're seen in a mutation we're either the input object or result
      // type itself. The mutation page's deep links are actually to the
      // flattened input object or result type's individual fields.
      return hrefForOperation(
        OperationTypeNode.MUTATION,
        occurrence.parentName,
      );

    case 'Query':
      return occurrence.type === 'Argument'
        ? hrefForOperationArgument(
            OperationTypeNode.QUERY,
            occurrence.parentName,
            occurrence.argName,
          )
        : hrefForOperation(OperationTypeNode.QUERY, occurrence.parentName);

    case 'Subscription':
      throw new Error('Subscriptions are not supported');

    case 'Union':
      return hrefForNamedType(occurrence.parentName);
  }
};

const TypeSeenInChild = ({ name }: Props) => {
  const occurrences = typeIndex[name] ?? [];

  if (!occurrences.length) {
    return (
      <Notice>
        <Text>This type is not used elsewhere in the schema.</Text>
      </Notice>
    );
  }

  return (
    <Table
      align={['left', 'left', 'left']}
      header={['Parent name', 'Parent type', 'Description']}
    >
      {occurrences
        .sort(
          (a, b) =>
            a.parentType.localeCompare(b.parentType) ||
            a.parentName.localeCompare(b.parentName),
        )
        .map((occurrence, index) => (
          <TableRow key={index}>
            <Text>
              <TextLink href={hrefForTypeOccurrence(occurrence)}>
                {occurrence.parentName}
              </TextLink>
            </Text>
            <Text>{occurrence.parentType}</Text>
            <Text>
              {(() => {
                switch (occurrence.type) {
                  case 'Argument':
                    return (
                      <>
                        <InlineCode>
                          {occurrence.fieldName
                            ? `${occurrence.fieldName}.${occurrence.argName}`
                            : occurrence.argName}
                        </InlineCode>{' '}
                        argument
                      </>
                    );
                  case 'Field':
                    return occurrence.fieldName ? (
                      <>
                        <InlineCode>{occurrence.fieldName}</InlineCode> field
                      </>
                    ) : (
                      'Result type'
                    );
                  case 'UnionMember':
                    return 'Member type';
                }
              })()}
            </Text>
          </TableRow>
        ))}
    </Table>
  );
};
