import type {
  GraphQLField,
  GraphQLInputField,
  GraphQLNamedType,
  OperationTypeNode,
} from 'graphql';

type FieldLikeType = GraphQLField<unknown, unknown> | GraphQLInputField;

// TODO: Change when launching
const baseSchema = '/schema-new';

export const hrefForNamedType = (
  namedType: GraphQLNamedType | string,
): string => {
  const typeName = typeof namedType === 'string' ? namedType : namedType.name;
  return `${baseSchema}/named-type/${encodeURIComponent(typeName)}`;
};

export const hrefForNamedTypeField = (
  namedType: GraphQLNamedType | string,
  field: FieldLikeType | string,
): string => {
  const typeName = typeof namedType === 'string' ? namedType : namedType.name;
  const fieldName = typeof field === 'string' ? field : field.name;
  return `${baseSchema}/named-type/${encodeURIComponent(
    typeName,
  )}#/field/${encodeURIComponent(fieldName)}`;
};

export const hrefForOperation = (
  operationType: OperationTypeNode,
  field: GraphQLField<unknown, unknown> | string,
): string => {
  const fieldName = typeof field === 'string' ? field : field.name;
  return `${baseSchema}/${encodeURIComponent(
    operationType,
  )}/${encodeURIComponent(fieldName)}`;
};

export const hrefForOperationArgument = (
  operationType: OperationTypeNode,
  operation: GraphQLField<unknown, unknown> | string,
  argument: GraphQLField<unknown, unknown> | string,
): string => {
  const operationName =
    typeof operation === 'string' ? operation : operation.name;
  const argumentName = typeof argument === 'string' ? argument : argument.name;
  return `${baseSchema}/${encodeURIComponent(
    operationType,
  )}/${encodeURIComponent(operationName)}#/argument/${encodeURIComponent(
    argumentName,
  )}`;
};

export const hrefForOperationResultField = (
  operationType: OperationTypeNode,
  operation: GraphQLField<unknown, unknown> | string,
  resultField: GraphQLField<unknown, unknown> | string,
): string => {
  const operationName =
    typeof operation === 'string' ? operation : operation.name;
  const resultFieldName =
    typeof resultField === 'string' ? resultField : resultField.name;
  return `${baseSchema}/${encodeURIComponent(
    operationType,
  )}/${encodeURIComponent(operationName)}#/result/${encodeURIComponent(
    resultFieldName,
  )}`;
};

export const hrefForWebhookSchemaRootType = () => `${baseSchema}/webhooks/`;

export const hrefForWebhookSchemaDefinition = (defName: string) =>
  `${baseSchema}/webhooks/${encodeURIComponent(defName)}`;

export const hrefForWebhookSchemaProperty = (
  defName: string,
  propertyName: string,
) =>
  `${baseSchema}/webhooks/${encodeURIComponent(
    defName,
  )}#/property/${encodeURIComponent(propertyName)}`;
