import Query from './Query.mdx';
import String from './String.mdx';
import Int from './Int.mdx';
import SeekAnzAdProduct from './SeekAnzAdProduct.mdx';
import Boolean from './Boolean.mdx';
import SeekAnzAdProductFeatures from './SeekAnzAdProductFeatures.mdx';
import SeekAnzAdProductPrice from './SeekAnzAdProductPrice.mdx';
import SeekAnzAdProductCheckoutEstimate from './SeekAnzAdProductCheckoutEstimate.mdx';
import SeekAnzAdProductContractConsumption from './SeekAnzAdProductContractConsumption.mdx';
import SeekAnzAdProductAdvertisementInput from './SeekAnzAdProductAdvertisementInput.mdx';
import SeekAnzAdProductAdvertisementDraftInput from './SeekAnzAdProductAdvertisementDraftInput.mdx';
import SeekAnzAdProductMessage from './SeekAnzAdProductMessage.mdx';
import CurrencyMinorUnit from './CurrencyMinorUnit.mdx';
import AdvertisementProducts from './AdvertisementProducts.mdx';
import AdvertisementProduct from './AdvertisementProduct.mdx';
import AdvertisementProductPriceDetails_Tax from './AdvertisementProductPriceDetails_Tax.mdx';
import AdvertisementProductPriceDetails from './AdvertisementProductPriceDetails.mdx';
import AdvertisementProductPaymentDetails from './AdvertisementProductPaymentDetails.mdx';
import AdvertisementProductFeatures from './AdvertisementProductFeatures.mdx';
import AdvertisementProductSellingPoint from './AdvertisementProductSellingPoint.mdx';
import AdvertisementProductFeatures_Branding from './AdvertisementProductFeatures_Branding.mdx';
import AdvertisementProductFeatures_SearchBulletPoints from './AdvertisementProductFeatures_SearchBulletPoints.mdx';
import AdvertisementProducts_PositionProfileInput from './AdvertisementProducts_PositionProfileInput.mdx';
import ApplyWithSeekButtonInput from './ApplyWithSeekButtonInput.mdx';
import ApplyWithSeekButtonPayload from './ApplyWithSeekButtonPayload.mdx';
import ApplyWithSeekButtonImages from './ApplyWithSeekButtonImages.mdx';
import Mutation from './Mutation.mdx';
import AddressComponent from './AddressComponent.mdx';
import AddressComponentInput from './AddressComponentInput.mdx';
import Address from './Address.mdx';
import AddressInput from './AddressInput.mdx';
import WebUrl from './WebUrl.mdx';
import Email from './Email.mdx';
import EmailInput from './EmailInput.mdx';
import Phone from './Phone.mdx';
import PhoneInput from './PhoneInput.mdx';
import Communication from './Communication.mdx';
import CommunicationInput from './CommunicationInput.mdx';
import WebUrlInput from './WebUrlInput.mdx';
import DateTime from './DateTime.mdx';
import Date from './Date.mdx';
import HistoryDate from './HistoryDate.mdx';
import EffectiveTimePeriod from './EffectiveTimePeriod.mdx';
import ObjectIdentifier from './ObjectIdentifier.mdx';
import JobCategory from './JobCategory.mdx';
import JobCategorySuggestionChoice from './JobCategorySuggestionChoice.mdx';
import Float from './Float.mdx';
import JobCategorySuggestionPositionProfileInput from './JobCategorySuggestionPositionProfileInput.mdx';
import JobCategories_PositionProfileInput from './JobCategories_PositionProfileInput.mdx';
import Location from './Location.mdx';
import LocationSuggestion from './LocationSuggestion.mdx';
import GeoLocation from './GeoLocation.mdx';
import GeoLocationInput from './GeoLocationInput.mdx';
import PageInfo from './PageInfo.mdx';
import PersonName from './PersonName.mdx';
import SpecifiedPerson from './SpecifiedPerson.mdx';
import PersonNameInput from './PersonNameInput.mdx';
import SpecifiedPersonInput from './SpecifiedPersonInput.mdx';
import Currency from './Currency.mdx';
import PayType from './PayType.mdx';
import SendApplicationSignalInput from './SendApplicationSignalInput.mdx';
import AdvertisementBranding from './AdvertisementBranding.mdx';
import AdvertisementBrandingEdge from './AdvertisementBrandingEdge.mdx';
import AdvertisementBrandingImage from './AdvertisementBrandingImage.mdx';
import AdvertisementBrandingsConnection from './AdvertisementBrandingsConnection.mdx';
import Candidate from './Candidate.mdx';
import CandidatePerson from './CandidatePerson.mdx';
import CandidatePersonInput from './CandidatePersonInput.mdx';
import SeekAttachmentRole from './SeekAttachmentRole.mdx';
import AssociatedPositionOpening from './AssociatedPositionOpening.mdx';
import Attachment from './Attachment.mdx';
import PositionHistory from './PositionHistory.mdx';
import EmployerHistory from './EmployerHistory.mdx';
import EducationDegree from './EducationDegree.mdx';
import EducationAttendance from './EducationAttendance.mdx';
import PersonCompetency from './PersonCompetency.mdx';
import Certification from './Certification.mdx';
import CandidateSource from './CandidateSource.mdx';
import CandidateProfile from './CandidateProfile.mdx';
import PersonAvailability from './PersonAvailability.mdx';
import PersonAvailability_NoticePeriodMeasure from './PersonAvailability_NoticePeriodMeasure.mdx';
import CreateCandidateProcessHistoryItem_CandidateProfileInput from './CreateCandidateProcessHistoryItem_CandidateProfileInput.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.mdx';
import CreateCandidateProcessHistoryItemInput from './CreateCandidateProcessHistoryItemInput.mdx';
import CreateCandidateProcessHistoryItemPayload_Conflict from './CreateCandidateProcessHistoryItemPayload_Conflict.mdx';
import CreateCandidateProcessHistoryItemPayload_Success from './CreateCandidateProcessHistoryItemPayload_Success.mdx';
import CreateCandidateProcessHistoryItemPayload from './CreateCandidateProcessHistoryItemPayload.mdx';
import DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput from './DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.mdx';
import DeleteCandidateProcessHistoryItemInput from './DeleteCandidateProcessHistoryItemInput.mdx';
import DeleteCandidateProcessHistoryItemPayload from './DeleteCandidateProcessHistoryItemPayload.mdx';
import DeleteUploadedCandidate_CandidateInput from './DeleteUploadedCandidate_CandidateInput.mdx';
import DeleteUploadedCandidateInput from './DeleteUploadedCandidateInput.mdx';
import DeleteUploadedCandidatePayload from './DeleteUploadedCandidatePayload.mdx';
import DistributionGuidelines from './DistributionGuidelines.mdx';
import DistributionGuidelinesInput from './DistributionGuidelinesInput.mdx';
import PositionPreference from './PositionPreference.mdx';
import SeekSalaryExpectation from './SeekSalaryExpectation.mdx';
import PositionPreferenceInput from './PositionPreferenceInput.mdx';
import PreferredLocation from './PreferredLocation.mdx';
import PreferredLocationInput from './PreferredLocationInput.mdx';
import CandidateProcessAction from './CandidateProcessAction.mdx';
import CandidateProcessActionInput from './CandidateProcessActionInput.mdx';
import CandidateProcessStatus from './CandidateProcessStatus.mdx';
import CandidateProcessStatusInput from './CandidateProcessStatusInput.mdx';
import CandidateProcessParty from './CandidateProcessParty.mdx';
import CandidateProcessPartyInput from './CandidateProcessPartyInput.mdx';
import SeekProcessHistoryItemSource from './SeekProcessHistoryItemSource.mdx';
import SeekProcessHistoryItemSourceInput from './SeekProcessHistoryItemSourceInput.mdx';
import CandidateProcessHistoryItem from './CandidateProcessHistoryItem.mdx';
import CandidateProcessHistoryItemInput from './CandidateProcessHistoryItemInput.mdx';
import CandidateProcessHistoryItem_PositionProfileInput from './CandidateProcessHistoryItem_PositionProfileInput.mdx';
import CandidateProcessHistoryItemEdge from './CandidateProcessHistoryItemEdge.mdx';
import CandidateProcessHistoryItemConnection from './CandidateProcessHistoryItemConnection.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.mdx';
import UpdateCandidateProcessHistoryItemInput from './UpdateCandidateProcessHistoryItemInput.mdx';
import UpdateCandidateProcessHistoryItemPayload from './UpdateCandidateProcessHistoryItemPayload.mdx';
import UpdateUploadedCandidatePerson_CandidateInput from './UpdateUploadedCandidatePerson_CandidateInput.mdx';
import UpdateUploadedCandidatePersonInput from './UpdateUploadedCandidatePersonInput.mdx';
import UpdateUploadedCandidatePersonPayload_Conflict from './UpdateUploadedCandidatePersonPayload_Conflict.mdx';
import UpdateUploadedCandidatePersonPayload_Success from './UpdateUploadedCandidatePersonPayload_Success.mdx';
import UpdateUploadedCandidatePersonPayload from './UpdateUploadedCandidatePersonPayload.mdx';
import UpdateUploadedCandidateProfileActions_CandidateProfileInput from './UpdateUploadedCandidateProfileActions_CandidateProfileInput.mdx';
import UpdateUploadedCandidateProfileActionsInput from './UpdateUploadedCandidateProfileActionsInput.mdx';
import UpdateUploadedCandidateProfileActionsPayload from './UpdateUploadedCandidateProfileActionsPayload.mdx';
import UpdateUploadedCandidateProfileDates_CandidateProfileInput from './UpdateUploadedCandidateProfileDates_CandidateProfileInput.mdx';
import UpdateUploadedCandidateProfileDatesInput from './UpdateUploadedCandidateProfileDatesInput.mdx';
import UpdateUploadedCandidateProfileDatesPayload from './UpdateUploadedCandidateProfileDatesPayload.mdx';
import UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput from './UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput.mdx';
import UpdateUploadedCandidateProfilePositionPreferencesInput from './UpdateUploadedCandidateProfilePositionPreferencesInput.mdx';
import UpdateUploadedCandidateProfilePositionPreferencesPayload from './UpdateUploadedCandidateProfilePositionPreferencesPayload.mdx';
import UploadCandidate_CandidateInput from './UploadCandidate_CandidateInput.mdx';
import UploadCandidate_CandidateProfileInput from './UploadCandidate_CandidateProfileInput.mdx';
import UploadCandidate_HiringOrganizationInput from './UploadCandidate_HiringOrganizationInput.mdx';
import UploadCandidateInput from './UploadCandidateInput.mdx';
import UploadCandidatePayload_Conflict from './UploadCandidatePayload_Conflict.mdx';
import UploadCandidatePayload_Success from './UploadCandidatePayload_Success.mdx';
import UploadCandidatePayload from './UploadCandidatePayload.mdx';
import Event from './Event.mdx';
import CandidateApplicationCreatedEvent from './CandidateApplicationCreatedEvent.mdx';
import PositionProfileClosedEvent from './PositionProfileClosedEvent.mdx';
import PositionProfilePostedEvent from './PositionProfilePostedEvent.mdx';
import CandidateProfilePurchasedEvent from './CandidateProfilePurchasedEvent.mdx';
import HirerRelationshipChangedEvent from './HirerRelationshipChangedEvent.mdx';
import EventsConnection from './EventsConnection.mdx';
import EventEdge from './EventEdge.mdx';
import EventsFilterInput from './EventsFilterInput.mdx';
import CreateWebhookSubscriptionInput from './CreateWebhookSubscriptionInput.mdx';
import CreateWebhookSubscription_SubscriptionInput from './CreateWebhookSubscription_SubscriptionInput.mdx';
import CreateWebhookSubscriptionPayload from './CreateWebhookSubscriptionPayload.mdx';
import CreateWebhookSubscriptionPayload_Conflict from './CreateWebhookSubscriptionPayload_Conflict.mdx';
import CreateWebhookSubscriptionPayload_Success from './CreateWebhookSubscriptionPayload_Success.mdx';
import UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput from './UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationInput from './UpdateWebhookSubscriptionDeliveryConfigurationInput.mdx';
import UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput from './UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.mdx';
import UpdateWebhookSubscriptionSigningConfigurationInput from './UpdateWebhookSubscriptionSigningConfigurationInput.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationPayload from './UpdateWebhookSubscriptionDeliveryConfigurationPayload.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success from './UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict from './UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict.mdx';
import UpdateWebhookSubscriptionSigningConfigurationPayload from './UpdateWebhookSubscriptionSigningConfigurationPayload.mdx';
import DeleteWebhookSubscriptionInput from './DeleteWebhookSubscriptionInput.mdx';
import DeleteWebhookSubscription_SubscriptionInput from './DeleteWebhookSubscription_SubscriptionInput.mdx';
import ReplayWebhookSubscriptionInput from './ReplayWebhookSubscriptionInput.mdx';
import ReplayWebhookSubscription_SubscriptionInput from './ReplayWebhookSubscription_SubscriptionInput.mdx';
import ReplayWebhookSubscription_FilterInput from './ReplayWebhookSubscription_FilterInput.mdx';
import DeleteWebhookSubscriptionPayload from './DeleteWebhookSubscriptionPayload.mdx';
import ReplayWebhookSubscriptionPayload from './ReplayWebhookSubscriptionPayload.mdx';
import WebhookAttempt from './WebhookAttempt.mdx';
import WebhookAttemptsConnection from './WebhookAttemptsConnection.mdx';
import WebhookAttemptEdge from './WebhookAttemptEdge.mdx';
import WebhookAttemptsFilterInput from './WebhookAttemptsFilterInput.mdx';
import WebhookRequest from './WebhookRequest.mdx';
import WebhookRequestFilterInput from './WebhookRequestFilterInput.mdx';
import WebhookRequestsConnection from './WebhookRequestsConnection.mdx';
import WebhookRequestEdge from './WebhookRequestEdge.mdx';
import WebhookSubscription from './WebhookSubscription.mdx';
import WebhookSubscriptionReplay from './WebhookSubscriptionReplay.mdx';
import WebhookSubscriptionReplayRequest from './WebhookSubscriptionReplayRequest.mdx';
import WebhookSubscriptionReplayByRangeRequest from './WebhookSubscriptionReplayByRangeRequest.mdx';
import WebhookSubscriptionReplayByIdRequest from './WebhookSubscriptionReplayByIdRequest.mdx';
import WebhookSubscriptionReplaysConnection from './WebhookSubscriptionReplaysConnection.mdx';
import WebhookSubscriptionReplayEdge from './WebhookSubscriptionReplayEdge.mdx';
import WebhookSubscriptionReplaysFilterInput from './WebhookSubscriptionReplaysFilterInput.mdx';
import WebhookSubscriptionsConnection from './WebhookSubscriptionsConnection.mdx';
import WebhookSubscriptionEdge from './WebhookSubscriptionEdge.mdx';
import WebhookSubscriptionsFilterInput from './WebhookSubscriptionsFilterInput.mdx';
import HiringOrganization from './HiringOrganization.mdx';
import HiringOrganizationApiCapabilities from './HiringOrganizationApiCapabilities.mdx';
import HiringOrganizationsConnection from './HiringOrganizationsConnection.mdx';
import HiringOrganizationEdge from './HiringOrganizationEdge.mdx';
import HiringOrganizationsFilterInput from './HiringOrganizationsFilterInput.mdx';
import Organization from './Organization.mdx';
import OrganizationInput from './OrganizationInput.mdx';
import PartnerOrganization from './PartnerOrganization.mdx';
import SelfOrganizations from './SelfOrganizations.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion from './_unstable_AdvertisementPerformance_DetailsWithConversion_Conversion.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion from './_unstable_AdvertisementPerformance_DetailsWithConversion.mdx';
import _unstable_AdvertisementPerformance_Details from './_unstable_AdvertisementPerformance_Details.mdx';
import _unstable_AdvertisementPerformance from './_unstable_AdvertisementPerformance.mdx';
import ClosePostedPositionProfilePayload from './ClosePostedPositionProfilePayload.mdx';
import ClosePostedPositionProfile_PositionProfilePayload from './ClosePostedPositionProfile_PositionProfilePayload.mdx';
import CreatePositionOpeningPayload from './CreatePositionOpeningPayload.mdx';
import CreateUnpostedPositionProfileForOpeningPayload from './CreateUnpostedPositionProfileForOpeningPayload.mdx';
import DeletePositionOpeningPayload from './DeletePositionOpeningPayload.mdx';
import DeleteUnpostedPositionProfilePayload from './DeleteUnpostedPositionProfilePayload.mdx';
import PositionOpening from './PositionOpening.mdx';
import PostingRequester from './PostingRequester.mdx';
import PositionOpeningEdge from './PositionOpeningEdge.mdx';
import PositionOpeningConnection from './PositionOpeningConnection.mdx';
import PositionProfile from './PositionProfile.mdx';
import PostedPositionProfile from './PostedPositionProfile.mdx';
import PositionFormattedDescriptionIdentifier from './PositionFormattedDescriptionIdentifier.mdx';
import UnpostedPositionProfile from './UnpostedPositionProfile.mdx';
import PositionFormattedDescription from './PositionFormattedDescription.mdx';
import RemunerationPackage from './RemunerationPackage.mdx';
import RemunerationRange from './RemunerationRange.mdx';
import RemunerationAmount from './RemunerationAmount.mdx';
import PostingInstruction from './PostingInstruction.mdx';
import ApplicationMethod from './ApplicationMethod.mdx';
import PostedPositionProfile_AdvertisementProduct from './PostedPositionProfile_AdvertisementProduct.mdx';
import SeekVideo from './SeekVideo.mdx';
import PositionProfileEdge from './PositionProfileEdge.mdx';
import PositionProfileConnection from './PositionProfileConnection.mdx';
import PostPositionPayload from './PostPositionPayload.mdx';
import PostPositionPayload_Success from './PostPositionPayload_Success.mdx';
import PostPositionPayload_Conflict from './PostPositionPayload_Conflict.mdx';
import PostPosition_PositionOpeningPayload from './PostPosition_PositionOpeningPayload.mdx';
import PostPosition_PositionProfilePayload from './PostPosition_PositionProfilePayload.mdx';
import PostPositionProfileForOpeningPayload from './PostPositionProfileForOpeningPayload.mdx';
import PostPositionProfileForOpeningPayload_Success from './PostPositionProfileForOpeningPayload_Success.mdx';
import PostPositionProfileForOpeningPayload_Conflict from './PostPositionProfileForOpeningPayload_Conflict.mdx';
import PostPositionProfileForOpening_PositionProfilePayload from './PostPositionProfileForOpening_PositionProfilePayload.mdx';
import PositionOpeningsFilterInput from './PositionOpeningsFilterInput.mdx';
import UpdatePositionOpeningPersonContactsPayload from './UpdatePositionOpeningPersonContactsPayload.mdx';
import UpdatePositionOpeningStatusPayload from './UpdatePositionOpeningStatusPayload.mdx';
import UpdatePostedPositionProfilePayload from './UpdatePostedPositionProfilePayload.mdx';
import UpdatePostedPositionProfile_PositionProfilePayload from './UpdatePostedPositionProfile_PositionProfilePayload.mdx';
import UpdateUnpostedPositionProfilePayload from './UpdateUnpostedPositionProfilePayload.mdx';
import ApplicationMethodInput from './ApplicationMethodInput.mdx';
import ClosePostedPositionProfileInput from './ClosePostedPositionProfileInput.mdx';
import ClosePostedPositionProfile_PositionProfileInput from './ClosePostedPositionProfile_PositionProfileInput.mdx';
import CreatePostingInstructionInput from './CreatePostingInstructionInput.mdx';
import PositionFormattedDescriptionInput from './PositionFormattedDescriptionInput.mdx';
import CreatePositionOpeningInput from './CreatePositionOpeningInput.mdx';
import CreatePositionOpening_PositionOpeningInput from './CreatePositionOpening_PositionOpeningInput.mdx';
import UpdatePositionOpeningPersonContactsInput from './UpdatePositionOpeningPersonContactsInput.mdx';
import UpdatePositionOpeningPersonContactsPositionOpeningInput from './UpdatePositionOpeningPersonContactsPositionOpeningInput.mdx';
import UpdatePositionOpeningStatusInput from './UpdatePositionOpeningStatusInput.mdx';
import UpdatePositionOpeningStatusPositionOpeningInput from './UpdatePositionOpeningStatusPositionOpeningInput.mdx';
import DeletePositionOpeningInput from './DeletePositionOpeningInput.mdx';
import DeletePositionOpening_PositionOpeningInput from './DeletePositionOpening_PositionOpeningInput.mdx';
import PostingRequesterInput from './PostingRequesterInput.mdx';
import PostPositionInput from './PostPositionInput.mdx';
import PostPosition_PositionProfileInput from './PostPosition_PositionProfileInput.mdx';
import PostPositionProfileForOpeningInput from './PostPositionProfileForOpeningInput.mdx';
import PostPositionProfileForOpeningPositionProfileInput from './PostPositionProfileForOpeningPositionProfileInput.mdx';
import RemunerationPackageInput from './RemunerationPackageInput.mdx';
import RemunerationRangeInput from './RemunerationRangeInput.mdx';
import RemunerationAmountInput from './RemunerationAmountInput.mdx';
import SeekPositionAddressInput from './SeekPositionAddressInput.mdx';
import SeekVideoInput from './SeekVideoInput.mdx';
import CreateUnpostedPositionProfileForOpeningInput from './CreateUnpostedPositionProfileForOpeningInput.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.mdx';
import UpdateUnpostedPositionProfileInput from './UpdateUnpostedPositionProfileInput.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput from './UpdateUnpostedPositionProfile_PositionProfileInput.mdx';
import DeleteUnpostedPositionProfileInput from './DeleteUnpostedPositionProfileInput.mdx';
import DeleteUnpostedPositionProfile_PositionProfileInput from './DeleteUnpostedPositionProfile_PositionProfileInput.mdx';
import UpdatePostedPositionProfileInput from './UpdatePostedPositionProfileInput.mdx';
import UpdatePostedPositionProfile_PositionProfileInput from './UpdatePostedPositionProfile_PositionProfileInput.mdx';
import UpdatePostingInstructionInput from './UpdatePostingInstructionInput.mdx';
import PostedPositionProfilePreview_PositionProfileInput from './PostedPositionProfilePreview_PositionProfileInput.mdx';
import PostedPositionProfilePreview from './PostedPositionProfilePreview.mdx';
import PostedPositionProfilePreview_PostingInstructionInput from './PostedPositionProfilePreview_PostingInstructionInput.mdx';
import PostedPositionProfilePreview_RemunerationAmountInput from './PostedPositionProfilePreview_RemunerationAmountInput.mdx';
import PostedPositionProfilePreview_RemunerationPackageInput from './PostedPositionProfilePreview_RemunerationPackageInput.mdx';
import PostedPositionProfilePreview_RemunerationRangeInput from './PostedPositionProfilePreview_RemunerationRangeInput.mdx';
import ApplicationLibraryQuestionSuggestions_PositionProfileInput from './ApplicationLibraryQuestionSuggestions_PositionProfileInput.mdx';
import ApplicationLibraryQuestionPreferenceSelection from './ApplicationLibraryQuestionPreferenceSelection.mdx';
import ApplicationLibraryQuestionChoice from './ApplicationLibraryQuestionChoice.mdx';
import ApplicationLibraryQuestion from './ApplicationLibraryQuestion.mdx';
import ApplicationLibraryQuestionSuggestion from './ApplicationLibraryQuestionSuggestion.mdx';
import ApplicationLibraryQuestionSearchResult from './ApplicationLibraryQuestionSearchResult.mdx';
import ApplicationQuestionnaire from './ApplicationQuestionnaire.mdx';
import ApplicationQuestionnaireComponent from './ApplicationQuestionnaireComponent.mdx';
import ApplicationQuestion from './ApplicationQuestion.mdx';
import ApplicationPrivacyConsent from './ApplicationPrivacyConsent.mdx';
import ApplicationQuestionChoice from './ApplicationQuestionChoice.mdx';
import ApplicationQuestionnaireComponentResponse from './ApplicationQuestionnaireComponentResponse.mdx';
import ApplicationQuestionAnswer from './ApplicationQuestionAnswer.mdx';
import ApplicationQuestionResponse from './ApplicationQuestionResponse.mdx';
import ApplicationPrivacyConsentResponse from './ApplicationPrivacyConsentResponse.mdx';
import ApplicationQuestionnaireSubmission from './ApplicationQuestionnaireSubmission.mdx';
import ApplicationPrivacyConsentInput from './ApplicationPrivacyConsentInput.mdx';
import ApplicationQuestionInput from './ApplicationQuestionInput.mdx';
import ApplicationQuestionChoiceInput from './ApplicationQuestionChoiceInput.mdx';
import ApplicationLibraryQuestionInput from './ApplicationLibraryQuestionInput.mdx';
import ApplicationQuestionnaireComponentInput from './ApplicationQuestionnaireComponentInput.mdx';
import CreateApplicationQuestionnaire_ApplicationQuestionnaireInput from './CreateApplicationQuestionnaire_ApplicationQuestionnaireInput.mdx';
import CreateApplicationQuestionnaireInput from './CreateApplicationQuestionnaireInput.mdx';
import CreateApplicationQuestionnairePayload from './CreateApplicationQuestionnairePayload.mdx';
import webhook from './webhook.mdx';
import Query_seekAnzHirerAdvertisementCreationProducts_deprecation from './Query.seekAnzHirerAdvertisementCreationProducts.deprecation.mdx';
import Query_seekAnzHirerAdvertisementModificationProducts_deprecation from './Query.seekAnzHirerAdvertisementModificationProducts.deprecation.mdx';
import Query_seekAnzHirerAdvertisementModificationProductsAlt_deprecation from './Query.seekAnzHirerAdvertisementModificationProductsAlt.deprecation.mdx';
import Mutation__empty_deprecation from './Mutation._empty.deprecation.mdx';
import Location_children_deprecation from './Location.children.deprecation.mdx';
import SeekAttachmentRole_RESUME_deprecation from './SeekAttachmentRole.RESUME.deprecation.mdx';
import SeekAttachmentRole_COVER_LETTER_deprecation from './SeekAttachmentRole.COVER_LETTER.deprecation.mdx';
import SeekAttachmentRole_SELECTION_CRITERIA_deprecation from './SeekAttachmentRole.SELECTION_CRITERIA.deprecation.mdx';
import Attachment_seekRole_deprecation from './Attachment.seekRole.deprecation.mdx';
import Certification_issueDate_deprecation from './Certification.issueDate.deprecation.mdx';
import CandidateProfile_associatedPositionOpenings_deprecation from './CandidateProfile.associatedPositionOpenings.deprecation.mdx';
import PositionOpening_positionProfiles_deprecation from './PositionOpening.positionProfiles.deprecation.mdx';
import PostingInstruction_seekAnzAdvertisementType_deprecation from './PostingInstruction.seekAnzAdvertisementType.deprecation.mdx';
import ApplicationMethod_applicationEmail_deprecation from './ApplicationMethod.applicationEmail.deprecation.mdx';
import CreatePostingInstructionInput_seekAnzAdvertisementType_deprecation from './CreatePostingInstructionInput.seekAnzAdvertisementType.deprecation.mdx';
import UpdatePostingInstructionInput_seekAnzAdvertisementType_deprecation from './UpdatePostingInstructionInput.seekAnzAdvertisementType.deprecation.mdx';
import PostedPositionProfilePreview_PostingInstructionInput_seekAnzAdvertisementType_deprecation from './PostedPositionProfilePreview_PostingInstructionInput.seekAnzAdvertisementType.deprecation.mdx';
import Query_seekAnzHirerAdvertisementCreationProducts from './Query.seekAnzHirerAdvertisementCreationProducts.mdx';
import Query_seekAnzHirerAdvertisementModificationProducts from './Query.seekAnzHirerAdvertisementModificationProducts.mdx';
import Query_seekAnzHirerAdvertisementModificationProductsAlt from './Query.seekAnzHirerAdvertisementModificationProductsAlt.mdx';
import Query_advertisementProducts from './Query.advertisementProducts.mdx';
import Query_applyWithSeekButton from './Query.applyWithSeekButton.mdx';
import Query_version from './Query.version.mdx';
import Query_jobCategory from './Query.jobCategory.mdx';
import Query_jobCategories from './Query.jobCategories.mdx';
import Query_jobCategorySuggestions from './Query.jobCategorySuggestions.mdx';
import Query_location from './Query.location.mdx';
import Query_locationSuggestions from './Query.locationSuggestions.mdx';
import Query_nearestLocations from './Query.nearestLocations.mdx';
import Query_inferLocation from './Query.inferLocation.mdx';
import Query_currencies from './Query.currencies.mdx';
import Query_payTypes from './Query.payTypes.mdx';
import Query_advertisementBrandings from './Query.advertisementBrandings.mdx';
import Query_advertisementBranding from './Query.advertisementBranding.mdx';
import Query_candidateProfile from './Query.candidateProfile.mdx';
import Query_candidate from './Query.candidate.mdx';
import Query_candidateProcessHistoryItem from './Query.candidateProcessHistoryItem.mdx';
import Query_event from './Query.event.mdx';
import Query_events from './Query.events.mdx';
import Query_webhookAttemptsForEvent from './Query.webhookAttemptsForEvent.mdx';
import Query_webhookSubscription from './Query.webhookSubscription.mdx';
import Query_webhookSubscriptions from './Query.webhookSubscriptions.mdx';
import Query_webhookSubscriptionReplay from './Query.webhookSubscriptionReplay.mdx';
import Query_webhookRequestsForSubscription from './Query.webhookRequestsForSubscription.mdx';
import Query_webhookRequest from './Query.webhookRequest.mdx';
import Query_hiringOrganization from './Query.hiringOrganization.mdx';
import Query_hiringOrganizations from './Query.hiringOrganizations.mdx';
import Query_seekAnzAdvertiser from './Query.seekAnzAdvertiser.mdx';
import Query_self from './Query.self.mdx';
import Query_positionOpening from './Query.positionOpening.mdx';
import Query_positionProfile from './Query.positionProfile.mdx';
import Query_positionOpenings from './Query.positionOpenings.mdx';
import Query_postedPositionProfilePreview from './Query.postedPositionProfilePreview.mdx';
import Query_applicationLibraryQuestionSuggestions from './Query.applicationLibraryQuestionSuggestions.mdx';
import Query_applicationLibraryQuestionSearch from './Query.applicationLibraryQuestionSearch.mdx';
import Query_applicationQuestionnaire from './Query.applicationQuestionnaire.mdx';
import SeekAnzAdProduct_advertisementTypeCode from './SeekAnzAdProduct.advertisementTypeCode.mdx';
import SeekAnzAdProduct_name from './SeekAnzAdProduct.name.mdx';
import SeekAnzAdProduct_description from './SeekAnzAdProduct.description.mdx';
import SeekAnzAdProduct_price from './SeekAnzAdProduct.price.mdx';
import SeekAnzAdProduct_enabledIndicator from './SeekAnzAdProduct.enabledIndicator.mdx';
import SeekAnzAdProduct_checkoutEstimate from './SeekAnzAdProduct.checkoutEstimate.mdx';
import SeekAnzAdProduct_messages from './SeekAnzAdProduct.messages.mdx';
import SeekAnzAdProduct_features from './SeekAnzAdProduct.features.mdx';
import SeekAnzAdProductFeatures_brandingIndicator from './SeekAnzAdProductFeatures.brandingIndicator.mdx';
import SeekAnzAdProductFeatures_searchBulletPointsIndicator from './SeekAnzAdProductFeatures.searchBulletPointsIndicator.mdx';
import SeekAnzAdProductPrice_amountExcludingTax from './SeekAnzAdProductPrice.amountExcludingTax.mdx';
import SeekAnzAdProductPrice_summary from './SeekAnzAdProductPrice.summary.mdx';
import SeekAnzAdProductPrice_visibleForHirerIndicator from './SeekAnzAdProductPrice.visibleForHirerIndicator.mdx';
import SeekAnzAdProductPrice_taxedIndicator from './SeekAnzAdProductPrice.taxedIndicator.mdx';
import SeekAnzAdProductCheckoutEstimate_paymentDueExcludingTax from './SeekAnzAdProductCheckoutEstimate.paymentDueExcludingTax.mdx';
import SeekAnzAdProductCheckoutEstimate_summary from './SeekAnzAdProductCheckoutEstimate.summary.mdx';
import SeekAnzAdProductCheckoutEstimate_contractConsumption from './SeekAnzAdProductCheckoutEstimate.contractConsumption.mdx';
import SeekAnzAdProductContractConsumption_summary from './SeekAnzAdProductContractConsumption.summary.mdx';
import SeekAnzAdProductContractConsumption_typeCode from './SeekAnzAdProductContractConsumption.typeCode.mdx';
import SeekAnzAdProductAdvertisementInput_typeCode from './SeekAnzAdProductAdvertisementInput.typeCode.mdx';
import SeekAnzAdProductAdvertisementInput_id from './SeekAnzAdProductAdvertisementInput.id.mdx';
import SeekAnzAdProductAdvertisementInput_hirerJobReference from './SeekAnzAdProductAdvertisementInput.hirerJobReference.mdx';
import SeekAnzAdProductAdvertisementInput_positionTitle from './SeekAnzAdProductAdvertisementInput.positionTitle.mdx';
import SeekAnzAdProductAdvertisementInput_jobCategoryId from './SeekAnzAdProductAdvertisementInput.jobCategoryId.mdx';
import SeekAnzAdProductAdvertisementInput_positionLocationId from './SeekAnzAdProductAdvertisementInput.positionLocationId.mdx';
import SeekAnzAdProductAdvertisementDraftInput_typeCode from './SeekAnzAdProductAdvertisementDraftInput.typeCode.mdx';
import SeekAnzAdProductAdvertisementDraftInput_hirerJobReference from './SeekAnzAdProductAdvertisementDraftInput.hirerJobReference.mdx';
import SeekAnzAdProductAdvertisementDraftInput_positionTitle from './SeekAnzAdProductAdvertisementDraftInput.positionTitle.mdx';
import SeekAnzAdProductAdvertisementDraftInput_jobCategoryId from './SeekAnzAdProductAdvertisementDraftInput.jobCategoryId.mdx';
import SeekAnzAdProductAdvertisementDraftInput_positionLocationId from './SeekAnzAdProductAdvertisementDraftInput.positionLocationId.mdx';
import SeekAnzAdProductMessage_typeCode from './SeekAnzAdProductMessage.typeCode.mdx';
import SeekAnzAdProductMessage_severityCode from './SeekAnzAdProductMessage.severityCode.mdx';
import SeekAnzAdProductMessage_visibilityCode from './SeekAnzAdProductMessage.visibilityCode.mdx';
import SeekAnzAdProductMessage_content from './SeekAnzAdProductMessage.content.mdx';
import CurrencyMinorUnit_value from './CurrencyMinorUnit.value.mdx';
import CurrencyMinorUnit_currency from './CurrencyMinorUnit.currency.mdx';
import AdvertisementProducts_products from './AdvertisementProducts.products.mdx';
import AdvertisementProducts_information from './AdvertisementProducts.information.mdx';
import AdvertisementProduct_id from './AdvertisementProduct.id.mdx';
import AdvertisementProduct_label from './AdvertisementProduct.label.mdx';
import AdvertisementProduct_selected from './AdvertisementProduct.selected.mdx';
import AdvertisementProduct_description from './AdvertisementProduct.description.mdx';
import AdvertisementProduct_payment from './AdvertisementProduct.payment.mdx';
import AdvertisementProduct_price from './AdvertisementProduct.price.mdx';
import AdvertisementProduct_features from './AdvertisementProduct.features.mdx';
import AdvertisementProduct_sellingPoints from './AdvertisementProduct.sellingPoints.mdx';
import AdvertisementProductPriceDetails_Tax_code from './AdvertisementProductPriceDetails_Tax.code.mdx';
import AdvertisementProductPriceDetails_Tax_value from './AdvertisementProductPriceDetails_Tax.value.mdx';
import AdvertisementProductPriceDetails_summary from './AdvertisementProductPriceDetails.summary.mdx';
import AdvertisementProductPriceDetails_value from './AdvertisementProductPriceDetails.value.mdx';
import AdvertisementProductPriceDetails_currency from './AdvertisementProductPriceDetails.currency.mdx';
import AdvertisementProductPriceDetails_tax from './AdvertisementProductPriceDetails.tax.mdx';
import AdvertisementProductPaymentDetails_summary from './AdvertisementProductPaymentDetails.summary.mdx';
import AdvertisementProductPaymentDetails_summaryHtml from './AdvertisementProductPaymentDetails.summaryHtml.mdx';
import AdvertisementProductFeatures_branding from './AdvertisementProductFeatures.branding.mdx';
import AdvertisementProductFeatures_searchBulletPoints from './AdvertisementProductFeatures.searchBulletPoints.mdx';
import AdvertisementProductSellingPoint_text from './AdvertisementProductSellingPoint.text.mdx';
import AdvertisementProductFeatures_Branding_logoIndicator from './AdvertisementProductFeatures_Branding.logoIndicator.mdx';
import AdvertisementProductFeatures_Branding_coverImageIndicator from './AdvertisementProductFeatures_Branding.coverImageIndicator.mdx';
import AdvertisementProductFeatures_SearchBulletPoints_limit from './AdvertisementProductFeatures_SearchBulletPoints.limit.mdx';
import AdvertisementProducts_PositionProfileInput_jobCategories from './AdvertisementProducts_PositionProfileInput.jobCategories.mdx';
import AdvertisementProducts_PositionProfileInput_offeredRemunerationPackage from './AdvertisementProducts_PositionProfileInput.offeredRemunerationPackage.mdx';
import AdvertisementProducts_PositionProfileInput_positionLocation from './AdvertisementProducts_PositionProfileInput.positionLocation.mdx';
import AdvertisementProducts_PositionProfileInput_positionOrganizations from './AdvertisementProducts_PositionProfileInput.positionOrganizations.mdx';
import AdvertisementProducts_PositionProfileInput_positionTitle from './AdvertisementProducts_PositionProfileInput.positionTitle.mdx';
import AdvertisementProducts_PositionProfileInput_profileId from './AdvertisementProducts_PositionProfileInput.profileId.mdx';
import AdvertisementProducts_PositionProfileInput_seekAnzWorkTypeCode from './AdvertisementProducts_PositionProfileInput.seekAnzWorkTypeCode.mdx';
import ApplyWithSeekButtonInput_redirectUri from './ApplyWithSeekButtonInput.redirectUri.mdx';
import ApplyWithSeekButtonInput_hirerId from './ApplyWithSeekButtonInput.hirerId.mdx';
import ApplyWithSeekButtonInput_token from './ApplyWithSeekButtonInput.token.mdx';
import ApplyWithSeekButtonInput_applicationUri from './ApplyWithSeekButtonInput.applicationUri.mdx';
import ApplyWithSeekButtonInput_seekHirerJobReference from './ApplyWithSeekButtonInput.seekHirerJobReference.mdx';
import ApplyWithSeekButtonPayload_url from './ApplyWithSeekButtonPayload.url.mdx';
import ApplyWithSeekButtonPayload_buttonLabel from './ApplyWithSeekButtonPayload.buttonLabel.mdx';
import ApplyWithSeekButtonPayload_images from './ApplyWithSeekButtonPayload.images.mdx';
import ApplyWithSeekButtonImages_accent from './ApplyWithSeekButtonImages.accent.mdx';
import ApplyWithSeekButtonImages_dark from './ApplyWithSeekButtonImages.dark.mdx';
import ApplyWithSeekButtonImages_light from './ApplyWithSeekButtonImages.light.mdx';
import Mutation__empty from './Mutation._empty.mdx';
import Mutation_sendApplicationSignal from './Mutation.sendApplicationSignal.mdx';
import Mutation_deleteUploadedCandidate from './Mutation.deleteUploadedCandidate.mdx';
import Mutation_uploadCandidate from './Mutation.uploadCandidate.mdx';
import Mutation_updateUploadedCandidatePerson from './Mutation.updateUploadedCandidatePerson.mdx';
import Mutation_updateUploadedCandidateProfileActions from './Mutation.updateUploadedCandidateProfileActions.mdx';
import Mutation_updateUploadedCandidateProfileDates from './Mutation.updateUploadedCandidateProfileDates.mdx';
import Mutation_updateUploadedCandidateProfilePositionPreferences from './Mutation.updateUploadedCandidateProfilePositionPreferences.mdx';
import Mutation_createCandidateProcessHistoryItem from './Mutation.createCandidateProcessHistoryItem.mdx';
import Mutation_deleteCandidateProcessHistoryItem from './Mutation.deleteCandidateProcessHistoryItem.mdx';
import Mutation_updateCandidateProcessHistoryItem from './Mutation.updateCandidateProcessHistoryItem.mdx';
import Mutation_createWebhookSubscription from './Mutation.createWebhookSubscription.mdx';
import Mutation_updateWebhookSubscriptionDeliveryConfiguration from './Mutation.updateWebhookSubscriptionDeliveryConfiguration.mdx';
import Mutation_updateWebhookSubscriptionSigningConfiguration from './Mutation.updateWebhookSubscriptionSigningConfiguration.mdx';
import Mutation_deleteWebhookSubscription from './Mutation.deleteWebhookSubscription.mdx';
import Mutation_replayWebhookSubscription from './Mutation.replayWebhookSubscription.mdx';
import Mutation_createPositionOpening from './Mutation.createPositionOpening.mdx';
import Mutation_updatePositionOpeningPersonContacts from './Mutation.updatePositionOpeningPersonContacts.mdx';
import Mutation_updatePositionOpeningStatus from './Mutation.updatePositionOpeningStatus.mdx';
import Mutation_deletePositionOpening from './Mutation.deletePositionOpening.mdx';
import Mutation_postPositionProfileForOpening from './Mutation.postPositionProfileForOpening.mdx';
import Mutation_updatePostedPositionProfile from './Mutation.updatePostedPositionProfile.mdx';
import Mutation_closePostedPositionProfile from './Mutation.closePostedPositionProfile.mdx';
import Mutation_postPosition from './Mutation.postPosition.mdx';
import Mutation_createUnpostedPositionProfileForOpening from './Mutation.createUnpostedPositionProfileForOpening.mdx';
import Mutation_updateUnpostedPositionProfile from './Mutation.updateUnpostedPositionProfile.mdx';
import Mutation_deleteUnpostedPositionProfile from './Mutation.deleteUnpostedPositionProfile.mdx';
import Mutation_createApplicationQuestionnaire from './Mutation.createApplicationQuestionnaire.mdx';
import AddressComponent_type from './AddressComponent.type.mdx';
import AddressComponent_value from './AddressComponent.value.mdx';
import AddressComponentInput_type from './AddressComponentInput.type.mdx';
import AddressComponentInput_value from './AddressComponentInput.value.mdx';
import Address_line from './Address.line.mdx';
import Address_extendedLines from './Address.extendedLines.mdx';
import Address_countryCode from './Address.countryCode.mdx';
import Address_countrySubDivisions from './Address.countrySubDivisions.mdx';
import Address_city from './Address.city.mdx';
import Address_postalCode from './Address.postalCode.mdx';
import Address_geoLocation from './Address.geoLocation.mdx';
import Address_formattedAddress from './Address.formattedAddress.mdx';
import AddressInput_line from './AddressInput.line.mdx';
import AddressInput_extendedLines from './AddressInput.extendedLines.mdx';
import AddressInput_countryCode from './AddressInput.countryCode.mdx';
import AddressInput_countrySubDivisions from './AddressInput.countrySubDivisions.mdx';
import AddressInput_city from './AddressInput.city.mdx';
import AddressInput_postalCode from './AddressInput.postalCode.mdx';
import AddressInput_geoLocation from './AddressInput.geoLocation.mdx';
import AddressInput_formattedAddress from './AddressInput.formattedAddress.mdx';
import WebUrl_url from './WebUrl.url.mdx';
import Email_address from './Email.address.mdx';
import EmailInput_address from './EmailInput.address.mdx';
import Phone_formattedNumber from './Phone.formattedNumber.mdx';
import PhoneInput_formattedNumber from './PhoneInput.formattedNumber.mdx';
import Communication_phone from './Communication.phone.mdx';
import Communication_email from './Communication.email.mdx';
import Communication_address from './Communication.address.mdx';
import Communication_seekDoNotContactIndicator from './Communication.seekDoNotContactIndicator.mdx';
import CommunicationInput_phone from './CommunicationInput.phone.mdx';
import CommunicationInput_email from './CommunicationInput.email.mdx';
import CommunicationInput_address from './CommunicationInput.address.mdx';
import CommunicationInput_seekDoNotContactIndicator from './CommunicationInput.seekDoNotContactIndicator.mdx';
import WebUrlInput_url from './WebUrlInput.url.mdx';
import EffectiveTimePeriod_validTo from './EffectiveTimePeriod.validTo.mdx';
import ObjectIdentifier_value from './ObjectIdentifier.value.mdx';
import JobCategory_id from './JobCategory.id.mdx';
import JobCategory_parent from './JobCategory.parent.mdx';
import JobCategory_children from './JobCategory.children.mdx';
import JobCategory_name from './JobCategory.name.mdx';
import JobCategorySuggestionChoice_jobCategory from './JobCategorySuggestionChoice.jobCategory.mdx';
import JobCategorySuggestionChoice_confidence from './JobCategorySuggestionChoice.confidence.mdx';
import JobCategorySuggestionPositionProfileInput_positionTitle from './JobCategorySuggestionPositionProfileInput.positionTitle.mdx';
import JobCategorySuggestionPositionProfileInput_positionLocation from './JobCategorySuggestionPositionProfileInput.positionLocation.mdx';
import JobCategorySuggestionPositionProfileInput_positionOrganizations from './JobCategorySuggestionPositionProfileInput.positionOrganizations.mdx';
import JobCategorySuggestionPositionProfileInput_positionFormattedDescriptions from './JobCategorySuggestionPositionProfileInput.positionFormattedDescriptions.mdx';
import JobCategories_PositionProfileInput_positionLocation from './JobCategories_PositionProfileInput.positionLocation.mdx';
import Location_id from './Location.id.mdx';
import Location_parent from './Location.parent.mdx';
import Location_children from './Location.children.mdx';
import Location_name from './Location.name.mdx';
import Location_contextualName from './Location.contextualName.mdx';
import Location_countryCode from './Location.countryCode.mdx';
import Location_currencies from './Location.currencies.mdx';
import LocationSuggestion_location from './LocationSuggestion.location.mdx';
import GeoLocation_latitude from './GeoLocation.latitude.mdx';
import GeoLocation_longitude from './GeoLocation.longitude.mdx';
import GeoLocationInput_latitude from './GeoLocationInput.latitude.mdx';
import GeoLocationInput_longitude from './GeoLocationInput.longitude.mdx';
import PageInfo_hasPreviousPage from './PageInfo.hasPreviousPage.mdx';
import PageInfo_hasNextPage from './PageInfo.hasNextPage.mdx';
import PageInfo_startCursor from './PageInfo.startCursor.mdx';
import PageInfo_endCursor from './PageInfo.endCursor.mdx';
import PersonName_formattedName from './PersonName.formattedName.mdx';
import PersonName_given from './PersonName.given.mdx';
import PersonName_family from './PersonName.family.mdx';
import SpecifiedPerson_name from './SpecifiedPerson.name.mdx';
import SpecifiedPerson_communication from './SpecifiedPerson.communication.mdx';
import SpecifiedPerson_roleCode from './SpecifiedPerson.roleCode.mdx';
import PersonNameInput_formattedName from './PersonNameInput.formattedName.mdx';
import PersonNameInput_given from './PersonNameInput.given.mdx';
import PersonNameInput_family from './PersonNameInput.family.mdx';
import SpecifiedPersonInput_name from './SpecifiedPersonInput.name.mdx';
import SpecifiedPersonInput_communication from './SpecifiedPersonInput.communication.mdx';
import SpecifiedPersonInput_roleCode from './SpecifiedPersonInput.roleCode.mdx';
import Currency_code from './Currency.code.mdx';
import PayType_basisCode from './PayType.basisCode.mdx';
import PayType_intervalCode from './PayType.intervalCode.mdx';
import PayType_label from './PayType.label.mdx';
import SendApplicationSignalInput_typeCode from './SendApplicationSignalInput.typeCode.mdx';
import SendApplicationSignalInput_token from './SendApplicationSignalInput.token.mdx';
import SendApplicationSignalInput_schemeId from './SendApplicationSignalInput.schemeId.mdx';
import AdvertisementBranding_id from './AdvertisementBranding.id.mdx';
import AdvertisementBranding_name from './AdvertisementBranding.name.mdx';
import AdvertisementBranding_images from './AdvertisementBranding.images.mdx';
import AdvertisementBranding_hirer from './AdvertisementBranding.hirer.mdx';
import AdvertisementBrandingEdge_cursor from './AdvertisementBrandingEdge.cursor.mdx';
import AdvertisementBrandingEdge_node from './AdvertisementBrandingEdge.node.mdx';
import AdvertisementBrandingImage_typeCode from './AdvertisementBrandingImage.typeCode.mdx';
import AdvertisementBrandingImage_url from './AdvertisementBrandingImage.url.mdx';
import AdvertisementBrandingsConnection_edges from './AdvertisementBrandingsConnection.edges.mdx';
import AdvertisementBrandingsConnection_pageInfo from './AdvertisementBrandingsConnection.pageInfo.mdx';
import AdvertisementBrandingsConnection_brandManagementUrl from './AdvertisementBrandingsConnection.brandManagementUrl.mdx';
import Candidate_documentId from './Candidate.documentId.mdx';
import Candidate_seekPrimaryEmailAddress from './Candidate.seekPrimaryEmailAddress.mdx';
import Candidate_distributionGuidelines from './Candidate.distributionGuidelines.mdx';
import Candidate_person from './Candidate.person.mdx';
import Candidate_profiles from './Candidate.profiles.mdx';
import CandidatePerson_name from './CandidatePerson.name.mdx';
import CandidatePerson_communication from './CandidatePerson.communication.mdx';
import CandidatePersonInput_name from './CandidatePersonInput.name.mdx';
import CandidatePersonInput_communication from './CandidatePersonInput.communication.mdx';
import SeekAttachmentRole_RESUME from './SeekAttachmentRole.RESUME.mdx';
import SeekAttachmentRole_COVER_LETTER from './SeekAttachmentRole.COVER_LETTER.mdx';
import SeekAttachmentRole_SELECTION_CRITERIA from './SeekAttachmentRole.SELECTION_CRITERIA.mdx';
import AssociatedPositionOpening_positionOpeningId from './AssociatedPositionOpening.positionOpeningId.mdx';
import AssociatedPositionOpening_positionOpening from './AssociatedPositionOpening.positionOpening.mdx';
import AssociatedPositionOpening_positionUri from './AssociatedPositionOpening.positionUri.mdx';
import AssociatedPositionOpening_candidateAppliedIndicator from './AssociatedPositionOpening.candidateAppliedIndicator.mdx';
import Attachment_id from './Attachment.id.mdx';
import Attachment_url from './Attachment.url.mdx';
import Attachment_descriptions from './Attachment.descriptions.mdx';
import Attachment_seekRoleCode from './Attachment.seekRoleCode.mdx';
import Attachment_seekRole from './Attachment.seekRole.mdx';
import PositionHistory_start from './PositionHistory.start.mdx';
import PositionHistory_end from './PositionHistory.end.mdx';
import PositionHistory_current from './PositionHistory.current.mdx';
import PositionHistory_title from './PositionHistory.title.mdx';
import PositionHistory_descriptions from './PositionHistory.descriptions.mdx';
import EmployerHistory_organization from './EmployerHistory.organization.mdx';
import EmployerHistory_positionHistories from './EmployerHistory.positionHistories.mdx';
import EducationDegree_name from './EducationDegree.name.mdx';
import EducationDegree_degreeGrantedStatus from './EducationDegree.degreeGrantedStatus.mdx';
import EducationDegree_date from './EducationDegree.date.mdx';
import EducationAttendance_institution from './EducationAttendance.institution.mdx';
import EducationAttendance_educationDegrees from './EducationAttendance.educationDegrees.mdx';
import EducationAttendance_descriptions from './EducationAttendance.descriptions.mdx';
import PersonCompetency_competencyName from './PersonCompetency.competencyName.mdx';
import Certification_name from './Certification.name.mdx';
import Certification_effectiveTimePeriod from './Certification.effectiveTimePeriod.mdx';
import Certification_issued from './Certification.issued.mdx';
import Certification_issueDate from './Certification.issueDate.mdx';
import Certification_issuingAuthority from './Certification.issuingAuthority.mdx';
import Certification_descriptions from './Certification.descriptions.mdx';
import CandidateSource_name from './CandidateSource.name.mdx';
import CandidateSource_type from './CandidateSource.type.mdx';
import CandidateProfile_candidate from './CandidateProfile.candidate.mdx';
import CandidateProfile_profileId from './CandidateProfile.profileId.mdx';
import CandidateProfile_createDateTime from './CandidateProfile.createDateTime.mdx';
import CandidateProfile_associatedPositionOpenings from './CandidateProfile.associatedPositionOpenings.mdx';
import CandidateProfile_associatedPositionProfile from './CandidateProfile.associatedPositionProfile.mdx';
import CandidateProfile_attachments from './CandidateProfile.attachments.mdx';
import CandidateProfile_employment from './CandidateProfile.employment.mdx';
import CandidateProfile_education from './CandidateProfile.education.mdx';
import CandidateProfile_qualifications from './CandidateProfile.qualifications.mdx';
import CandidateProfile_certifications from './CandidateProfile.certifications.mdx';
import CandidateProfile_candidateSources from './CandidateProfile.candidateSources.mdx';
import CandidateProfile_positionPreferences from './CandidateProfile.positionPreferences.mdx';
import CandidateProfile_updateDateTime from './CandidateProfile.updateDateTime.mdx';
import CandidateProfile_seekActions from './CandidateProfile.seekActions.mdx';
import CandidateProfile_seekProcessHistory from './CandidateProfile.seekProcessHistory.mdx';
import CandidateProfile_seekQuestionnaireSubmission from './CandidateProfile.seekQuestionnaireSubmission.mdx';
import CandidateProfile_personAvailability from './CandidateProfile.personAvailability.mdx';
import CandidateProfile_executiveSummary from './CandidateProfile.executiveSummary.mdx';
import PersonAvailability_noticePeriodMeasure from './PersonAvailability.noticePeriodMeasure.mdx';
import PersonAvailability_immediateStartIndicator from './PersonAvailability.immediateStartIndicator.mdx';
import PersonAvailability_NoticePeriodMeasure_value from './PersonAvailability_NoticePeriodMeasure.value.mdx';
import PersonAvailability_NoticePeriodMeasure_unitCode from './PersonAvailability_NoticePeriodMeasure.unitCode.mdx';
import CreateCandidateProcessHistoryItem_CandidateProfileInput_profileId from './CreateCandidateProcessHistoryItem_CandidateProfileInput.profileId.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_idempotencyId from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.idempotencyId.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_actionDate from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.actionDate.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_action from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.action.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_status from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.status.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_positionProfile from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.positionProfile.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_seekSource from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.seekSource.mdx';
import CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_primaryParties from './CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.primaryParties.mdx';
import CreateCandidateProcessHistoryItemInput_candidateProfile from './CreateCandidateProcessHistoryItemInput.candidateProfile.mdx';
import CreateCandidateProcessHistoryItemInput_candidateProcessHistoryItem from './CreateCandidateProcessHistoryItemInput.candidateProcessHistoryItem.mdx';
import CreateCandidateProcessHistoryItemPayload_Conflict_conflictingCandidateProcessHistoryItem from './CreateCandidateProcessHistoryItemPayload_Conflict.conflictingCandidateProcessHistoryItem.mdx';
import CreateCandidateProcessHistoryItemPayload_Conflict_candidateProfile from './CreateCandidateProcessHistoryItemPayload_Conflict.candidateProfile.mdx';
import CreateCandidateProcessHistoryItemPayload_Success_candidateProcessHistoryItem from './CreateCandidateProcessHistoryItemPayload_Success.candidateProcessHistoryItem.mdx';
import CreateCandidateProcessHistoryItemPayload_Success_candidateProfile from './CreateCandidateProcessHistoryItemPayload_Success.candidateProfile.mdx';
import DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_id from './DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.id.mdx';
import DeleteCandidateProcessHistoryItemInput_candidateProcessHistoryItem from './DeleteCandidateProcessHistoryItemInput.candidateProcessHistoryItem.mdx';
import DeleteCandidateProcessHistoryItemPayload_candidateProcessHistoryItem from './DeleteCandidateProcessHistoryItemPayload.candidateProcessHistoryItem.mdx';
import DeleteUploadedCandidate_CandidateInput_documentId from './DeleteUploadedCandidate_CandidateInput.documentId.mdx';
import DeleteUploadedCandidateInput_candidate from './DeleteUploadedCandidateInput.candidate.mdx';
import DeleteUploadedCandidatePayload_candidate from './DeleteUploadedCandidatePayload.candidate.mdx';
import DistributionGuidelines_seekProductCodes from './DistributionGuidelines.seekProductCodes.mdx';
import DistributionGuidelinesInput_seekProductCodes from './DistributionGuidelinesInput.seekProductCodes.mdx';
import PositionPreference_locations from './PositionPreference.locations.mdx';
import PositionPreference_seekAnzWorkTypeCodes from './PositionPreference.seekAnzWorkTypeCodes.mdx';
import PositionPreference_seekSalaryExpectations from './PositionPreference.seekSalaryExpectations.mdx';
import SeekSalaryExpectation_countryCode from './SeekSalaryExpectation.countryCode.mdx';
import SeekSalaryExpectation_amount from './SeekSalaryExpectation.amount.mdx';
import SeekSalaryExpectation_intervalCode from './SeekSalaryExpectation.intervalCode.mdx';
import PositionPreferenceInput_locations from './PositionPreferenceInput.locations.mdx';
import PreferredLocation_referenceLocation from './PreferredLocation.referenceLocation.mdx';
import PreferredLocationInput_referenceLocation from './PreferredLocationInput.referenceLocation.mdx';
import CandidateProcessAction_code from './CandidateProcessAction.code.mdx';
import CandidateProcessAction_description from './CandidateProcessAction.description.mdx';
import CandidateProcessAction_name from './CandidateProcessAction.name.mdx';
import CandidateProcessAction_seekUrl from './CandidateProcessAction.seekUrl.mdx';
import CandidateProcessActionInput_code from './CandidateProcessActionInput.code.mdx';
import CandidateProcessActionInput_description from './CandidateProcessActionInput.description.mdx';
import CandidateProcessActionInput_name from './CandidateProcessActionInput.name.mdx';
import CandidateProcessActionInput_seekUrl from './CandidateProcessActionInput.seekUrl.mdx';
import CandidateProcessStatus_code from './CandidateProcessStatus.code.mdx';
import CandidateProcessStatusInput_code from './CandidateProcessStatusInput.code.mdx';
import CandidateProcessParty_person from './CandidateProcessParty.person.mdx';
import CandidateProcessParty_organization from './CandidateProcessParty.organization.mdx';
import CandidateProcessPartyInput_person from './CandidateProcessPartyInput.person.mdx';
import CandidateProcessPartyInput_organization from './CandidateProcessPartyInput.organization.mdx';
import SeekProcessHistoryItemSource_name from './SeekProcessHistoryItemSource.name.mdx';
import SeekProcessHistoryItemSourceInput_name from './SeekProcessHistoryItemSourceInput.name.mdx';
import CandidateProcessHistoryItem_id from './CandidateProcessHistoryItem.id.mdx';
import CandidateProcessHistoryItem_candidateProfile from './CandidateProcessHistoryItem.candidateProfile.mdx';
import CandidateProcessHistoryItem_actionDate from './CandidateProcessHistoryItem.actionDate.mdx';
import CandidateProcessHistoryItem_action from './CandidateProcessHistoryItem.action.mdx';
import CandidateProcessHistoryItem_status from './CandidateProcessHistoryItem.status.mdx';
import CandidateProcessHistoryItem_positionProfile from './CandidateProcessHistoryItem.positionProfile.mdx';
import CandidateProcessHistoryItem_seekSource from './CandidateProcessHistoryItem.seekSource.mdx';
import CandidateProcessHistoryItem_primaryParties from './CandidateProcessHistoryItem.primaryParties.mdx';
import CandidateProcessHistoryItemInput_actionDate from './CandidateProcessHistoryItemInput.actionDate.mdx';
import CandidateProcessHistoryItemInput_action from './CandidateProcessHistoryItemInput.action.mdx';
import CandidateProcessHistoryItemInput_status from './CandidateProcessHistoryItemInput.status.mdx';
import CandidateProcessHistoryItemInput_positionProfile from './CandidateProcessHistoryItemInput.positionProfile.mdx';
import CandidateProcessHistoryItemInput_seekSource from './CandidateProcessHistoryItemInput.seekSource.mdx';
import CandidateProcessHistoryItemInput_primaryParties from './CandidateProcessHistoryItemInput.primaryParties.mdx';
import CandidateProcessHistoryItem_PositionProfileInput_profileId from './CandidateProcessHistoryItem_PositionProfileInput.profileId.mdx';
import CandidateProcessHistoryItemEdge_cursor from './CandidateProcessHistoryItemEdge.cursor.mdx';
import CandidateProcessHistoryItemEdge_node from './CandidateProcessHistoryItemEdge.node.mdx';
import CandidateProcessHistoryItemConnection_edges from './CandidateProcessHistoryItemConnection.edges.mdx';
import CandidateProcessHistoryItemConnection_pageInfo from './CandidateProcessHistoryItemConnection.pageInfo.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_id from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.id.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_actionDate from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.actionDate.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_action from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.action.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_status from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.status.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_positionProfile from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.positionProfile.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_seekSource from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.seekSource.mdx';
import UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_primaryParties from './UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.primaryParties.mdx';
import UpdateCandidateProcessHistoryItemInput_candidateProcessHistoryItem from './UpdateCandidateProcessHistoryItemInput.candidateProcessHistoryItem.mdx';
import UpdateCandidateProcessHistoryItemPayload_candidateProcessHistoryItem from './UpdateCandidateProcessHistoryItemPayload.candidateProcessHistoryItem.mdx';
import UpdateUploadedCandidatePerson_CandidateInput_documentId from './UpdateUploadedCandidatePerson_CandidateInput.documentId.mdx';
import UpdateUploadedCandidatePerson_CandidateInput_person from './UpdateUploadedCandidatePerson_CandidateInput.person.mdx';
import UpdateUploadedCandidatePerson_CandidateInput_seekPrimaryEmailAddress from './UpdateUploadedCandidatePerson_CandidateInput.seekPrimaryEmailAddress.mdx';
import UpdateUploadedCandidatePersonInput_candidate from './UpdateUploadedCandidatePersonInput.candidate.mdx';
import UpdateUploadedCandidatePersonPayload_Conflict_conflictingCandidate from './UpdateUploadedCandidatePersonPayload_Conflict.conflictingCandidate.mdx';
import UpdateUploadedCandidatePersonPayload_Success_candidate from './UpdateUploadedCandidatePersonPayload_Success.candidate.mdx';
import UpdateUploadedCandidateProfileActions_CandidateProfileInput_profileId from './UpdateUploadedCandidateProfileActions_CandidateProfileInput.profileId.mdx';
import UpdateUploadedCandidateProfileActions_CandidateProfileInput_seekActions from './UpdateUploadedCandidateProfileActions_CandidateProfileInput.seekActions.mdx';
import UpdateUploadedCandidateProfileActionsInput_candidateProfile from './UpdateUploadedCandidateProfileActionsInput.candidateProfile.mdx';
import UpdateUploadedCandidateProfileActionsPayload_candidateProfile from './UpdateUploadedCandidateProfileActionsPayload.candidateProfile.mdx';
import UpdateUploadedCandidateProfileDates_CandidateProfileInput_profileId from './UpdateUploadedCandidateProfileDates_CandidateProfileInput.profileId.mdx';
import UpdateUploadedCandidateProfileDates_CandidateProfileInput_createDateTime from './UpdateUploadedCandidateProfileDates_CandidateProfileInput.createDateTime.mdx';
import UpdateUploadedCandidateProfileDates_CandidateProfileInput_updateDateTime from './UpdateUploadedCandidateProfileDates_CandidateProfileInput.updateDateTime.mdx';
import UpdateUploadedCandidateProfileDatesInput_candidateProfile from './UpdateUploadedCandidateProfileDatesInput.candidateProfile.mdx';
import UpdateUploadedCandidateProfileDatesPayload_candidateProfile from './UpdateUploadedCandidateProfileDatesPayload.candidateProfile.mdx';
import UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput_profileId from './UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput.profileId.mdx';
import UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput_positionPreferences from './UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput.positionPreferences.mdx';
import UpdateUploadedCandidateProfilePositionPreferencesInput_candidateProfile from './UpdateUploadedCandidateProfilePositionPreferencesInput.candidateProfile.mdx';
import UpdateUploadedCandidateProfilePositionPreferencesPayload_candidateProfile from './UpdateUploadedCandidateProfilePositionPreferencesPayload.candidateProfile.mdx';
import UploadCandidate_CandidateInput_seekPrimaryEmailAddress from './UploadCandidate_CandidateInput.seekPrimaryEmailAddress.mdx';
import UploadCandidate_CandidateInput_distributionGuidelines from './UploadCandidate_CandidateInput.distributionGuidelines.mdx';
import UploadCandidate_CandidateInput_person from './UploadCandidate_CandidateInput.person.mdx';
import UploadCandidate_CandidateProfileInput_createDateTime from './UploadCandidate_CandidateProfileInput.createDateTime.mdx';
import UploadCandidate_CandidateProfileInput_updateDateTime from './UploadCandidate_CandidateProfileInput.updateDateTime.mdx';
import UploadCandidate_CandidateProfileInput_seekProcessHistory from './UploadCandidate_CandidateProfileInput.seekProcessHistory.mdx';
import UploadCandidate_CandidateProfileInput_positionPreferences from './UploadCandidate_CandidateProfileInput.positionPreferences.mdx';
import UploadCandidate_CandidateProfileInput_seekActions from './UploadCandidate_CandidateProfileInput.seekActions.mdx';
import UploadCandidate_HiringOrganizationInput_id from './UploadCandidate_HiringOrganizationInput.id.mdx';
import UploadCandidateInput_candidate from './UploadCandidateInput.candidate.mdx';
import UploadCandidateInput_candidateProfile from './UploadCandidateInput.candidateProfile.mdx';
import UploadCandidateInput_hiringOrganization from './UploadCandidateInput.hiringOrganization.mdx';
import UploadCandidatePayload_Conflict_conflictingCandidate from './UploadCandidatePayload_Conflict.conflictingCandidate.mdx';
import UploadCandidatePayload_Conflict_hiringOrganization from './UploadCandidatePayload_Conflict.hiringOrganization.mdx';
import UploadCandidatePayload_Success_candidate from './UploadCandidatePayload_Success.candidate.mdx';
import UploadCandidatePayload_Success_candidateProcessHistoryItems from './UploadCandidatePayload_Success.candidateProcessHistoryItems.mdx';
import UploadCandidatePayload_Success_hiringOrganization from './UploadCandidatePayload_Success.hiringOrganization.mdx';
import Event_id from './Event.id.mdx';
import Event_schemeId from './Event.schemeId.mdx';
import Event_typeCode from './Event.typeCode.mdx';
import Event_createDateTime from './Event.createDateTime.mdx';
import Event_webhookAttempts from './Event.webhookAttempts.mdx';
import CandidateApplicationCreatedEvent_id from './CandidateApplicationCreatedEvent.id.mdx';
import CandidateApplicationCreatedEvent_schemeId from './CandidateApplicationCreatedEvent.schemeId.mdx';
import CandidateApplicationCreatedEvent_typeCode from './CandidateApplicationCreatedEvent.typeCode.mdx';
import CandidateApplicationCreatedEvent_createDateTime from './CandidateApplicationCreatedEvent.createDateTime.mdx';
import CandidateApplicationCreatedEvent_candidateApplicationProfileId from './CandidateApplicationCreatedEvent.candidateApplicationProfileId.mdx';
import CandidateApplicationCreatedEvent_candidateId from './CandidateApplicationCreatedEvent.candidateId.mdx';
import CandidateApplicationCreatedEvent_candidate from './CandidateApplicationCreatedEvent.candidate.mdx';
import CandidateApplicationCreatedEvent_candidateApplicationProfile from './CandidateApplicationCreatedEvent.candidateApplicationProfile.mdx';
import CandidateApplicationCreatedEvent_webhookAttempts from './CandidateApplicationCreatedEvent.webhookAttempts.mdx';
import PositionProfileClosedEvent_id from './PositionProfileClosedEvent.id.mdx';
import PositionProfileClosedEvent_schemeId from './PositionProfileClosedEvent.schemeId.mdx';
import PositionProfileClosedEvent_typeCode from './PositionProfileClosedEvent.typeCode.mdx';
import PositionProfileClosedEvent_createDateTime from './PositionProfileClosedEvent.createDateTime.mdx';
import PositionProfileClosedEvent_positionProfileId from './PositionProfileClosedEvent.positionProfileId.mdx';
import PositionProfileClosedEvent_positionProfile from './PositionProfileClosedEvent.positionProfile.mdx';
import PositionProfileClosedEvent_webhookAttempts from './PositionProfileClosedEvent.webhookAttempts.mdx';
import PositionProfilePostedEvent_id from './PositionProfilePostedEvent.id.mdx';
import PositionProfilePostedEvent_schemeId from './PositionProfilePostedEvent.schemeId.mdx';
import PositionProfilePostedEvent_typeCode from './PositionProfilePostedEvent.typeCode.mdx';
import PositionProfilePostedEvent_createDateTime from './PositionProfilePostedEvent.createDateTime.mdx';
import PositionProfilePostedEvent_positionProfileId from './PositionProfilePostedEvent.positionProfileId.mdx';
import PositionProfilePostedEvent_positionProfile from './PositionProfilePostedEvent.positionProfile.mdx';
import PositionProfilePostedEvent_webhookAttempts from './PositionProfilePostedEvent.webhookAttempts.mdx';
import CandidateProfilePurchasedEvent_id from './CandidateProfilePurchasedEvent.id.mdx';
import CandidateProfilePurchasedEvent_schemeId from './CandidateProfilePurchasedEvent.schemeId.mdx';
import CandidateProfilePurchasedEvent_typeCode from './CandidateProfilePurchasedEvent.typeCode.mdx';
import CandidateProfilePurchasedEvent_createDateTime from './CandidateProfilePurchasedEvent.createDateTime.mdx';
import CandidateProfilePurchasedEvent_candidateProfileId from './CandidateProfilePurchasedEvent.candidateProfileId.mdx';
import CandidateProfilePurchasedEvent_candidateProfile from './CandidateProfilePurchasedEvent.candidateProfile.mdx';
import CandidateProfilePurchasedEvent_webhookAttempts from './CandidateProfilePurchasedEvent.webhookAttempts.mdx';
import HirerRelationshipChangedEvent_id from './HirerRelationshipChangedEvent.id.mdx';
import HirerRelationshipChangedEvent_schemeId from './HirerRelationshipChangedEvent.schemeId.mdx';
import HirerRelationshipChangedEvent_typeCode from './HirerRelationshipChangedEvent.typeCode.mdx';
import HirerRelationshipChangedEvent_createDateTime from './HirerRelationshipChangedEvent.createDateTime.mdx';
import HirerRelationshipChangedEvent_hirerId from './HirerRelationshipChangedEvent.hirerId.mdx';
import HirerRelationshipChangedEvent_hirer from './HirerRelationshipChangedEvent.hirer.mdx';
import HirerRelationshipChangedEvent_webhookAttempts from './HirerRelationshipChangedEvent.webhookAttempts.mdx';
import EventsConnection_edges from './EventsConnection.edges.mdx';
import EventsConnection_pageInfo from './EventsConnection.pageInfo.mdx';
import EventEdge_cursor from './EventEdge.cursor.mdx';
import EventEdge_node from './EventEdge.node.mdx';
import EventEdge_streamDateTime from './EventEdge.streamDateTime.mdx';
import EventsFilterInput_afterDateTime from './EventsFilterInput.afterDateTime.mdx';
import EventsFilterInput_beforeDateTime from './EventsFilterInput.beforeDateTime.mdx';
import EventsFilterInput_deliveredIndicator from './EventsFilterInput.deliveredIndicator.mdx';
import EventsFilterInput_eventTypeCodes from './EventsFilterInput.eventTypeCodes.mdx';
import EventsFilterInput_subscriptionId from './EventsFilterInput.subscriptionId.mdx';
import CreateWebhookSubscriptionInput_webhookSubscription from './CreateWebhookSubscriptionInput.webhookSubscription.mdx';
import CreateWebhookSubscription_SubscriptionInput_eventTypeCode from './CreateWebhookSubscription_SubscriptionInput.eventTypeCode.mdx';
import CreateWebhookSubscription_SubscriptionInput_schemeId from './CreateWebhookSubscription_SubscriptionInput.schemeId.mdx';
import CreateWebhookSubscription_SubscriptionInput_hirerId from './CreateWebhookSubscription_SubscriptionInput.hirerId.mdx';
import CreateWebhookSubscription_SubscriptionInput_url from './CreateWebhookSubscription_SubscriptionInput.url.mdx';
import CreateWebhookSubscription_SubscriptionInput_maxEventsPerAttempt from './CreateWebhookSubscription_SubscriptionInput.maxEventsPerAttempt.mdx';
import CreateWebhookSubscription_SubscriptionInput_signingAlgorithmCode from './CreateWebhookSubscription_SubscriptionInput.signingAlgorithmCode.mdx';
import CreateWebhookSubscription_SubscriptionInput_secret from './CreateWebhookSubscription_SubscriptionInput.secret.mdx';
import CreateWebhookSubscriptionPayload_Conflict_conflictingWebhookSubscription from './CreateWebhookSubscriptionPayload_Conflict.conflictingWebhookSubscription.mdx';
import CreateWebhookSubscriptionPayload_Success_webhookSubscription from './CreateWebhookSubscriptionPayload_Success.webhookSubscription.mdx';
import UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput_id from './UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.id.mdx';
import UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput_url from './UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.url.mdx';
import UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput_maxEventsPerAttempt from './UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.maxEventsPerAttempt.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationInput_webhookSubscription from './UpdateWebhookSubscriptionDeliveryConfigurationInput.webhookSubscription.mdx';
import UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput_id from './UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.id.mdx';
import UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput_signingAlgorithmCode from './UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.signingAlgorithmCode.mdx';
import UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput_secret from './UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.secret.mdx';
import UpdateWebhookSubscriptionSigningConfigurationInput_webhookSubscription from './UpdateWebhookSubscriptionSigningConfigurationInput.webhookSubscription.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success_webhookSubscription from './UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success.webhookSubscription.mdx';
import UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict_conflictingWebhookSubscription from './UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict.conflictingWebhookSubscription.mdx';
import UpdateWebhookSubscriptionSigningConfigurationPayload_webhookSubscription from './UpdateWebhookSubscriptionSigningConfigurationPayload.webhookSubscription.mdx';
import DeleteWebhookSubscriptionInput_webhookSubscription from './DeleteWebhookSubscriptionInput.webhookSubscription.mdx';
import DeleteWebhookSubscription_SubscriptionInput_id from './DeleteWebhookSubscription_SubscriptionInput.id.mdx';
import ReplayWebhookSubscriptionInput_webhookSubscription from './ReplayWebhookSubscriptionInput.webhookSubscription.mdx';
import ReplayWebhookSubscriptionInput_filter from './ReplayWebhookSubscriptionInput.filter.mdx';
import ReplayWebhookSubscriptionInput_eventIds from './ReplayWebhookSubscriptionInput.eventIds.mdx';
import ReplayWebhookSubscription_SubscriptionInput_id from './ReplayWebhookSubscription_SubscriptionInput.id.mdx';
import ReplayWebhookSubscription_FilterInput_replayDeliveredEventsIndicator from './ReplayWebhookSubscription_FilterInput.replayDeliveredEventsIndicator.mdx';
import ReplayWebhookSubscription_FilterInput_createdAfterDateTime from './ReplayWebhookSubscription_FilterInput.createdAfterDateTime.mdx';
import ReplayWebhookSubscription_FilterInput_createdBeforeDateTime from './ReplayWebhookSubscription_FilterInput.createdBeforeDateTime.mdx';
import ReplayWebhookSubscription_FilterInput_hirerId from './ReplayWebhookSubscription_FilterInput.hirerId.mdx';
import DeleteWebhookSubscriptionPayload_webhookSubscription from './DeleteWebhookSubscriptionPayload.webhookSubscription.mdx';
import ReplayWebhookSubscriptionPayload_webhookSubscription from './ReplayWebhookSubscriptionPayload.webhookSubscription.mdx';
import WebhookAttempt_id from './WebhookAttempt.id.mdx';
import WebhookAttempt_createDateTime from './WebhookAttempt.createDateTime.mdx';
import WebhookAttempt_event from './WebhookAttempt.event.mdx';
import WebhookAttempt_webhookSubscription from './WebhookAttempt.webhookSubscription.mdx';
import WebhookAttempt_webhookRequest from './WebhookAttempt.webhookRequest.mdx';
import WebhookAttemptsConnection_edges from './WebhookAttemptsConnection.edges.mdx';
import WebhookAttemptsConnection_pageInfo from './WebhookAttemptsConnection.pageInfo.mdx';
import WebhookAttemptEdge_cursor from './WebhookAttemptEdge.cursor.mdx';
import WebhookAttemptEdge_node from './WebhookAttemptEdge.node.mdx';
import WebhookAttemptsFilterInput_afterDateTime from './WebhookAttemptsFilterInput.afterDateTime.mdx';
import WebhookAttemptsFilterInput_beforeDateTime from './WebhookAttemptsFilterInput.beforeDateTime.mdx';
import WebhookAttemptsFilterInput_descriptionCodes from './WebhookAttemptsFilterInput.descriptionCodes.mdx';
import WebhookRequest_requestId from './WebhookRequest.requestId.mdx';
import WebhookRequest_webhookSubscription from './WebhookRequest.webhookSubscription.mdx';
import WebhookRequest_statusCode from './WebhookRequest.statusCode.mdx';
import WebhookRequest_descriptionCode from './WebhookRequest.descriptionCode.mdx';
import WebhookRequest_latencyMs from './WebhookRequest.latencyMs.mdx';
import WebhookRequest_createDateTime from './WebhookRequest.createDateTime.mdx';
import WebhookRequest_attempts from './WebhookRequest.attempts.mdx';
import WebhookRequestFilterInput_afterDateTime from './WebhookRequestFilterInput.afterDateTime.mdx';
import WebhookRequestFilterInput_beforeDateTime from './WebhookRequestFilterInput.beforeDateTime.mdx';
import WebhookRequestFilterInput_descriptionCodes from './WebhookRequestFilterInput.descriptionCodes.mdx';
import WebhookRequestsConnection_edges from './WebhookRequestsConnection.edges.mdx';
import WebhookRequestsConnection_pageInfo from './WebhookRequestsConnection.pageInfo.mdx';
import WebhookRequestEdge_cursor from './WebhookRequestEdge.cursor.mdx';
import WebhookRequestEdge_node from './WebhookRequestEdge.node.mdx';
import WebhookSubscription_id from './WebhookSubscription.id.mdx';
import WebhookSubscription_eventTypeCode from './WebhookSubscription.eventTypeCode.mdx';
import WebhookSubscription_schemeId from './WebhookSubscription.schemeId.mdx';
import WebhookSubscription_hirerId from './WebhookSubscription.hirerId.mdx';
import WebhookSubscription_hirer from './WebhookSubscription.hirer.mdx';
import WebhookSubscription_url from './WebhookSubscription.url.mdx';
import WebhookSubscription_maxEventsPerAttempt from './WebhookSubscription.maxEventsPerAttempt.mdx';
import WebhookSubscription_signingAlgorithmCode from './WebhookSubscription.signingAlgorithmCode.mdx';
import WebhookSubscription_createDateTime from './WebhookSubscription.createDateTime.mdx';
import WebhookSubscription_updateDateTime from './WebhookSubscription.updateDateTime.mdx';
import WebhookSubscription_webhookRequests from './WebhookSubscription.webhookRequests.mdx';
import WebhookSubscription_webhookSubscriptionReplays from './WebhookSubscription.webhookSubscriptionReplays.mdx';
import WebhookSubscriptionReplay_id from './WebhookSubscriptionReplay.id.mdx';
import WebhookSubscriptionReplay_request from './WebhookSubscriptionReplay.request.mdx';
import WebhookSubscriptionReplay_createDateTime from './WebhookSubscriptionReplay.createDateTime.mdx';
import WebhookSubscriptionReplay_updateDateTime from './WebhookSubscriptionReplay.updateDateTime.mdx';
import WebhookSubscriptionReplay_webhookSubscription from './WebhookSubscriptionReplay.webhookSubscription.mdx';
import WebhookSubscriptionReplay_statusCode from './WebhookSubscriptionReplay.statusCode.mdx';
import WebhookSubscriptionReplayRequest_typeCode from './WebhookSubscriptionReplayRequest.typeCode.mdx';
import WebhookSubscriptionReplayByRangeRequest_typeCode from './WebhookSubscriptionReplayByRangeRequest.typeCode.mdx';
import WebhookSubscriptionReplayByRangeRequest_afterDateTime from './WebhookSubscriptionReplayByRangeRequest.afterDateTime.mdx';
import WebhookSubscriptionReplayByRangeRequest_beforeDateTime from './WebhookSubscriptionReplayByRangeRequest.beforeDateTime.mdx';
import WebhookSubscriptionReplayByRangeRequest_replayDeliveredEventsIndicator from './WebhookSubscriptionReplayByRangeRequest.replayDeliveredEventsIndicator.mdx';
import WebhookSubscriptionReplayByRangeRequest_hirer from './WebhookSubscriptionReplayByRangeRequest.hirer.mdx';
import WebhookSubscriptionReplayByIdRequest_typeCode from './WebhookSubscriptionReplayByIdRequest.typeCode.mdx';
import WebhookSubscriptionReplayByIdRequest_eventIds from './WebhookSubscriptionReplayByIdRequest.eventIds.mdx';
import WebhookSubscriptionReplaysConnection_edges from './WebhookSubscriptionReplaysConnection.edges.mdx';
import WebhookSubscriptionReplaysConnection_pageInfo from './WebhookSubscriptionReplaysConnection.pageInfo.mdx';
import WebhookSubscriptionReplayEdge_cursor from './WebhookSubscriptionReplayEdge.cursor.mdx';
import WebhookSubscriptionReplayEdge_node from './WebhookSubscriptionReplayEdge.node.mdx';
import WebhookSubscriptionReplaysFilterInput_statusCode from './WebhookSubscriptionReplaysFilterInput.statusCode.mdx';
import WebhookSubscriptionsConnection_edges from './WebhookSubscriptionsConnection.edges.mdx';
import WebhookSubscriptionsConnection_pageInfo from './WebhookSubscriptionsConnection.pageInfo.mdx';
import WebhookSubscriptionEdge_cursor from './WebhookSubscriptionEdge.cursor.mdx';
import WebhookSubscriptionEdge_node from './WebhookSubscriptionEdge.node.mdx';
import WebhookSubscriptionsFilterInput_afterDateTime from './WebhookSubscriptionsFilterInput.afterDateTime.mdx';
import WebhookSubscriptionsFilterInput_beforeDateTime from './WebhookSubscriptionsFilterInput.beforeDateTime.mdx';
import WebhookSubscriptionsFilterInput_eventTypeCodes from './WebhookSubscriptionsFilterInput.eventTypeCodes.mdx';
import WebhookSubscriptionsFilterInput_hirerIds from './WebhookSubscriptionsFilterInput.hirerIds.mdx';
import WebhookSubscriptionsFilterInput_hirerScopedIndicator from './WebhookSubscriptionsFilterInput.hirerScopedIndicator.mdx';
import HiringOrganization_id from './HiringOrganization.id.mdx';
import HiringOrganization_name from './HiringOrganization.name.mdx';
import HiringOrganization_seekApiCapabilities from './HiringOrganization.seekApiCapabilities.mdx';
import HiringOrganization_seekAnzAdvertiserId from './HiringOrganization.seekAnzAdvertiserId.mdx';
import HiringOrganizationApiCapabilities_relationshipTypeCodes from './HiringOrganizationApiCapabilities.relationshipTypeCodes.mdx';
import HiringOrganizationApiCapabilities_applicationMethodCodes from './HiringOrganizationApiCapabilities.applicationMethodCodes.mdx';
import HiringOrganizationsConnection_edges from './HiringOrganizationsConnection.edges.mdx';
import HiringOrganizationsConnection_pageInfo from './HiringOrganizationsConnection.pageInfo.mdx';
import HiringOrganizationEdge_cursor from './HiringOrganizationEdge.cursor.mdx';
import HiringOrganizationEdge_node from './HiringOrganizationEdge.node.mdx';
import HiringOrganizationsFilterInput_relationshipTypeCodes from './HiringOrganizationsFilterInput.relationshipTypeCodes.mdx';
import HiringOrganizationsFilterInput_nameSearch from './HiringOrganizationsFilterInput.nameSearch.mdx';
import Organization_name from './Organization.name.mdx';
import OrganizationInput_name from './OrganizationInput.name.mdx';
import PartnerOrganization_name from './PartnerOrganization.name.mdx';
import PartnerOrganization_credentialSelfServiceApprovedIndicator from './PartnerOrganization.credentialSelfServiceApprovedIndicator.mdx';
import SelfOrganizations_partner from './SelfOrganizations.partner.mdx';
import SelfOrganizations_hirer from './SelfOrganizations.hirer.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion_inPercentage from './_unstable_AdvertisementPerformance_DetailsWithConversion_Conversion.inPercentage.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion_similarAdsInPercentage from './_unstable_AdvertisementPerformance_DetailsWithConversion_Conversion.similarAdsInPercentage.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_total from './_unstable_AdvertisementPerformance_DetailsWithConversion.total.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_mobileInPercentage from './_unstable_AdvertisementPerformance_DetailsWithConversion.mobileInPercentage.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_similarAdsAverage from './_unstable_AdvertisementPerformance_DetailsWithConversion.similarAdsAverage.mdx';
import _unstable_AdvertisementPerformance_DetailsWithConversion_conversion from './_unstable_AdvertisementPerformance_DetailsWithConversion.conversion.mdx';
import _unstable_AdvertisementPerformance_Details_total from './_unstable_AdvertisementPerformance_Details.total.mdx';
import _unstable_AdvertisementPerformance_Details_mobileInPercentage from './_unstable_AdvertisementPerformance_Details.mobileInPercentage.mdx';
import _unstable_AdvertisementPerformance_Details_similarAdsAverage from './_unstable_AdvertisementPerformance_Details.similarAdsAverage.mdx';
import _unstable_AdvertisementPerformance_adRatingConfidenceCode from './_unstable_AdvertisementPerformance.adRatingConfidenceCode.mdx';
import _unstable_AdvertisementPerformance_comparisonLocation from './_unstable_AdvertisementPerformance.comparisonLocation.mdx';
import _unstable_AdvertisementPerformance_viewsInJobSearch from './_unstable_AdvertisementPerformance.viewsInJobSearch.mdx';
import _unstable_AdvertisementPerformance_clicksOnAd from './_unstable_AdvertisementPerformance.clicksOnAd.mdx';
import _unstable_AdvertisementPerformance_applicationsStarted from './_unstable_AdvertisementPerformance.applicationsStarted.mdx';
import _unstable_AdvertisementPerformance_applicationsCompleted from './_unstable_AdvertisementPerformance.applicationsCompleted.mdx';
import _unstable_AdvertisementPerformance_updateDateTime from './_unstable_AdvertisementPerformance.updateDateTime.mdx';
import ClosePostedPositionProfilePayload_positionProfile from './ClosePostedPositionProfilePayload.positionProfile.mdx';
import ClosePostedPositionProfile_PositionProfilePayload_profileId from './ClosePostedPositionProfile_PositionProfilePayload.profileId.mdx';
import CreatePositionOpeningPayload_positionOpening from './CreatePositionOpeningPayload.positionOpening.mdx';
import CreateUnpostedPositionProfileForOpeningPayload_positionProfile from './CreateUnpostedPositionProfileForOpeningPayload.positionProfile.mdx';
import DeletePositionOpeningPayload_positionOpening from './DeletePositionOpeningPayload.positionOpening.mdx';
import DeleteUnpostedPositionProfilePayload_positionProfile from './DeleteUnpostedPositionProfilePayload.positionProfile.mdx';
import PositionOpening_statusCode from './PositionOpening.statusCode.mdx';
import PositionOpening_documentId from './PositionOpening.documentId.mdx';
import PositionOpening_seekPartnerMetadata from './PositionOpening.seekPartnerMetadata.mdx';
import PositionOpening_postingRequester from './PositionOpening.postingRequester.mdx';
import PositionOpening_paginatedPositionProfiles from './PositionOpening.paginatedPositionProfiles.mdx';
import PositionOpening_positionProfiles from './PositionOpening.positionProfiles.mdx';
import PostingRequester_id from './PostingRequester.id.mdx';
import PostingRequester_name from './PostingRequester.name.mdx';
import PostingRequester_seekAnzAdvertiserId from './PostingRequester.seekAnzAdvertiserId.mdx';
import PostingRequester_roleCode from './PostingRequester.roleCode.mdx';
import PostingRequester_personContacts from './PostingRequester.personContacts.mdx';
import PositionOpeningEdge_cursor from './PositionOpeningEdge.cursor.mdx';
import PositionOpeningEdge_node from './PositionOpeningEdge.node.mdx';
import PositionOpeningConnection_edges from './PositionOpeningConnection.edges.mdx';
import PositionOpeningConnection_pageInfo from './PositionOpeningConnection.pageInfo.mdx';
import PositionProfile_profileId from './PositionProfile.profileId.mdx';
import PositionProfile_positionOpening from './PositionProfile.positionOpening.mdx';
import PositionProfile_seekTypeCode from './PositionProfile.seekTypeCode.mdx';
import PositionProfile_positionTitle from './PositionProfile.positionTitle.mdx';
import PositionProfile_positionOrganizations from './PositionProfile.positionOrganizations.mdx';
import PositionProfile_seekHirerJobReference from './PositionProfile.seekHirerJobReference.mdx';
import PositionProfile_seekBillingReference from './PositionProfile.seekBillingReference.mdx';
import PositionProfile_positionUri from './PositionProfile.positionUri.mdx';
import PositionProfile_positionFormattedDescriptions from './PositionProfile.positionFormattedDescriptions.mdx';
import PositionProfile_positionScheduleTypeCodes from './PositionProfile.positionScheduleTypeCodes.mdx';
import PositionProfile_offeredRemunerationPackage from './PositionProfile.offeredRemunerationPackage.mdx';
import PositionProfile_seekAnzWorkTypeCode from './PositionProfile.seekAnzWorkTypeCode.mdx';
import PositionProfile_jobCategories from './PositionProfile.jobCategories.mdx';
import PositionProfile_positionLocation from './PositionProfile.positionLocation.mdx';
import PositionProfile_seekApplicationQuestionnaire from './PositionProfile.seekApplicationQuestionnaire.mdx';
import PositionProfile_seekVideo from './PositionProfile.seekVideo.mdx';
import PositionProfile_postingInstructions from './PositionProfile.postingInstructions.mdx';
import PositionProfile_seekPartnerMetadata from './PositionProfile.seekPartnerMetadata.mdx';
import PositionProfile_seekCreatedBySelfIndicator from './PositionProfile.seekCreatedBySelfIndicator.mdx';
import PostedPositionProfile_profileId from './PostedPositionProfile.profileId.mdx';
import PostedPositionProfile_positionOpening from './PostedPositionProfile.positionOpening.mdx';
import PostedPositionProfile_seekTypeCode from './PostedPositionProfile.seekTypeCode.mdx';
import PostedPositionProfile_positionTitle from './PostedPositionProfile.positionTitle.mdx';
import PostedPositionProfile_positionOrganizations from './PostedPositionProfile.positionOrganizations.mdx';
import PostedPositionProfile_seekHirerJobReference from './PostedPositionProfile.seekHirerJobReference.mdx';
import PostedPositionProfile_seekBillingReference from './PostedPositionProfile.seekBillingReference.mdx';
import PostedPositionProfile_positionUri from './PostedPositionProfile.positionUri.mdx';
import PostedPositionProfile_seekApplicationUri from './PostedPositionProfile.seekApplicationUri.mdx';
import PostedPositionProfile_positionFormattedDescriptions from './PostedPositionProfile.positionFormattedDescriptions.mdx';
import PostedPositionProfile_positionScheduleTypeCodes from './PostedPositionProfile.positionScheduleTypeCodes.mdx';
import PostedPositionProfile_offeredRemunerationPackage from './PostedPositionProfile.offeredRemunerationPackage.mdx';
import PostedPositionProfile_seekAnzWorkTypeCode from './PostedPositionProfile.seekAnzWorkTypeCode.mdx';
import PostedPositionProfile_jobCategories from './PostedPositionProfile.jobCategories.mdx';
import PostedPositionProfile_positionLocation from './PostedPositionProfile.positionLocation.mdx';
import PostedPositionProfile_seekApplicationQuestionnaire from './PostedPositionProfile.seekApplicationQuestionnaire.mdx';
import PostedPositionProfile_seekVideo from './PostedPositionProfile.seekVideo.mdx';
import PostedPositionProfile_postingInstructions from './PostedPositionProfile.postingInstructions.mdx';
import PostedPositionProfile_seekPartnerMetadata from './PostedPositionProfile.seekPartnerMetadata.mdx';
import PostedPositionProfile_seekCreatedBySelfIndicator from './PostedPositionProfile.seekCreatedBySelfIndicator.mdx';
import PostedPositionProfile__unstable_seekAdvertisementPerformance from './PostedPositionProfile._unstable_seekAdvertisementPerformance.mdx';
import PositionFormattedDescriptionIdentifier_value from './PositionFormattedDescriptionIdentifier.value.mdx';
import UnpostedPositionProfile_profileId from './UnpostedPositionProfile.profileId.mdx';
import UnpostedPositionProfile_profileName from './UnpostedPositionProfile.profileName.mdx';
import UnpostedPositionProfile_positionOpening from './UnpostedPositionProfile.positionOpening.mdx';
import UnpostedPositionProfile_seekTypeCode from './UnpostedPositionProfile.seekTypeCode.mdx';
import UnpostedPositionProfile_positionTitle from './UnpostedPositionProfile.positionTitle.mdx';
import UnpostedPositionProfile_positionOrganizations from './UnpostedPositionProfile.positionOrganizations.mdx';
import UnpostedPositionProfile_seekHirerJobReference from './UnpostedPositionProfile.seekHirerJobReference.mdx';
import UnpostedPositionProfile_seekBillingReference from './UnpostedPositionProfile.seekBillingReference.mdx';
import UnpostedPositionProfile_positionUri from './UnpostedPositionProfile.positionUri.mdx';
import UnpostedPositionProfile_positionFormattedDescriptions from './UnpostedPositionProfile.positionFormattedDescriptions.mdx';
import UnpostedPositionProfile_positionScheduleTypeCodes from './UnpostedPositionProfile.positionScheduleTypeCodes.mdx';
import UnpostedPositionProfile_offeredRemunerationPackage from './UnpostedPositionProfile.offeredRemunerationPackage.mdx';
import UnpostedPositionProfile_seekAnzWorkTypeCode from './UnpostedPositionProfile.seekAnzWorkTypeCode.mdx';
import UnpostedPositionProfile_jobCategories from './UnpostedPositionProfile.jobCategories.mdx';
import UnpostedPositionProfile_positionLocation from './UnpostedPositionProfile.positionLocation.mdx';
import UnpostedPositionProfile_seekApplicationQuestionnaire from './UnpostedPositionProfile.seekApplicationQuestionnaire.mdx';
import UnpostedPositionProfile_seekVideo from './UnpostedPositionProfile.seekVideo.mdx';
import UnpostedPositionProfile_postingInstructions from './UnpostedPositionProfile.postingInstructions.mdx';
import UnpostedPositionProfile_seekPartnerMetadata from './UnpostedPositionProfile.seekPartnerMetadata.mdx';
import UnpostedPositionProfile_seekCreatedBySelfIndicator from './UnpostedPositionProfile.seekCreatedBySelfIndicator.mdx';
import PositionFormattedDescription_descriptionId from './PositionFormattedDescription.descriptionId.mdx';
import PositionFormattedDescription_content from './PositionFormattedDescription.content.mdx';
import RemunerationPackage_basisCode from './RemunerationPackage.basisCode.mdx';
import RemunerationPackage_ranges from './RemunerationPackage.ranges.mdx';
import RemunerationPackage_descriptions from './RemunerationPackage.descriptions.mdx';
import RemunerationRange_minimumAmount from './RemunerationRange.minimumAmount.mdx';
import RemunerationRange_maximumAmount from './RemunerationRange.maximumAmount.mdx';
import RemunerationRange_intervalCode from './RemunerationRange.intervalCode.mdx';
import RemunerationAmount_value from './RemunerationAmount.value.mdx';
import RemunerationAmount_currency from './RemunerationAmount.currency.mdx';
import PostingInstruction_start from './PostingInstruction.start.mdx';
import PostingInstruction_end from './PostingInstruction.end.mdx';
import PostingInstruction_seekAnzAdvertisementType from './PostingInstruction.seekAnzAdvertisementType.mdx';
import PostingInstruction_seekAdvertisementProduct from './PostingInstruction.seekAdvertisementProduct.mdx';
import PostingInstruction_applicationMethods from './PostingInstruction.applicationMethods.mdx';
import PostingInstruction_brandingId from './PostingInstruction.brandingId.mdx';
import PostingInstruction_branding from './PostingInstruction.branding.mdx';
import ApplicationMethod_applicationUri from './ApplicationMethod.applicationUri.mdx';
import ApplicationMethod_applicationEmail from './ApplicationMethod.applicationEmail.mdx';
import PostedPositionProfile_AdvertisementProduct_label from './PostedPositionProfile_AdvertisementProduct.label.mdx';
import SeekVideo_url from './SeekVideo.url.mdx';
import SeekVideo_seekAnzPositionCode from './SeekVideo.seekAnzPositionCode.mdx';
import PositionProfileEdge_cursor from './PositionProfileEdge.cursor.mdx';
import PositionProfileEdge_node from './PositionProfileEdge.node.mdx';
import PositionProfileConnection_edges from './PositionProfileConnection.edges.mdx';
import PositionProfileConnection_pageInfo from './PositionProfileConnection.pageInfo.mdx';
import PostPositionPayload_Success_positionOpening from './PostPositionPayload_Success.positionOpening.mdx';
import PostPositionPayload_Success_positionProfile from './PostPositionPayload_Success.positionProfile.mdx';
import PostPositionPayload_Conflict_conflictingPositionOpening from './PostPositionPayload_Conflict.conflictingPositionOpening.mdx';
import PostPositionPayload_Conflict_conflictingPositionProfile from './PostPositionPayload_Conflict.conflictingPositionProfile.mdx';
import PostPosition_PositionOpeningPayload_documentId from './PostPosition_PositionOpeningPayload.documentId.mdx';
import PostPosition_PositionProfilePayload_profileId from './PostPosition_PositionProfilePayload.profileId.mdx';
import PostPositionProfileForOpeningPayload_Success_positionProfile from './PostPositionProfileForOpeningPayload_Success.positionProfile.mdx';
import PostPositionProfileForOpeningPayload_Conflict_conflictingPositionProfile from './PostPositionProfileForOpeningPayload_Conflict.conflictingPositionProfile.mdx';
import PostPositionProfileForOpening_PositionProfilePayload_profileId from './PostPositionProfileForOpening_PositionProfilePayload.profileId.mdx';
import PositionOpeningsFilterInput_statusCode from './PositionOpeningsFilterInput.statusCode.mdx';
import UpdatePositionOpeningPersonContactsPayload_positionOpening from './UpdatePositionOpeningPersonContactsPayload.positionOpening.mdx';
import UpdatePositionOpeningStatusPayload_positionOpening from './UpdatePositionOpeningStatusPayload.positionOpening.mdx';
import UpdatePostedPositionProfilePayload_positionProfile from './UpdatePostedPositionProfilePayload.positionProfile.mdx';
import UpdatePostedPositionProfile_PositionProfilePayload_profileId from './UpdatePostedPositionProfile_PositionProfilePayload.profileId.mdx';
import UpdateUnpostedPositionProfilePayload_positionProfile from './UpdateUnpostedPositionProfilePayload.positionProfile.mdx';
import ApplicationMethodInput_applicationUri from './ApplicationMethodInput.applicationUri.mdx';
import ClosePostedPositionProfileInput_positionProfile from './ClosePostedPositionProfileInput.positionProfile.mdx';
import ClosePostedPositionProfile_PositionProfileInput_profileId from './ClosePostedPositionProfile_PositionProfileInput.profileId.mdx';
import CreatePostingInstructionInput_seekAnzAdvertisementType from './CreatePostingInstructionInput.seekAnzAdvertisementType.mdx';
import CreatePostingInstructionInput_seekAdvertisementProductId from './CreatePostingInstructionInput.seekAdvertisementProductId.mdx';
import CreatePostingInstructionInput_end from './CreatePostingInstructionInput.end.mdx';
import CreatePostingInstructionInput_idempotencyId from './CreatePostingInstructionInput.idempotencyId.mdx';
import CreatePostingInstructionInput_applicationMethods from './CreatePostingInstructionInput.applicationMethods.mdx';
import CreatePostingInstructionInput_brandingId from './CreatePostingInstructionInput.brandingId.mdx';
import PositionFormattedDescriptionInput_descriptionId from './PositionFormattedDescriptionInput.descriptionId.mdx';
import PositionFormattedDescriptionInput_content from './PositionFormattedDescriptionInput.content.mdx';
import CreatePositionOpeningInput_positionOpening from './CreatePositionOpeningInput.positionOpening.mdx';
import CreatePositionOpening_PositionOpeningInput_postingRequester from './CreatePositionOpening_PositionOpeningInput.postingRequester.mdx';
import CreatePositionOpening_PositionOpeningInput_seekPartnerMetadata from './CreatePositionOpening_PositionOpeningInput.seekPartnerMetadata.mdx';
import CreatePositionOpening_PositionOpeningInput_statusCode from './CreatePositionOpening_PositionOpeningInput.statusCode.mdx';
import UpdatePositionOpeningPersonContactsInput_positionOpening from './UpdatePositionOpeningPersonContactsInput.positionOpening.mdx';
import UpdatePositionOpeningPersonContactsPositionOpeningInput_documentId from './UpdatePositionOpeningPersonContactsPositionOpeningInput.documentId.mdx';
import UpdatePositionOpeningPersonContactsPositionOpeningInput_personContacts from './UpdatePositionOpeningPersonContactsPositionOpeningInput.personContacts.mdx';
import UpdatePositionOpeningStatusInput_positionOpening from './UpdatePositionOpeningStatusInput.positionOpening.mdx';
import UpdatePositionOpeningStatusPositionOpeningInput_documentId from './UpdatePositionOpeningStatusPositionOpeningInput.documentId.mdx';
import UpdatePositionOpeningStatusPositionOpeningInput_statusCode from './UpdatePositionOpeningStatusPositionOpeningInput.statusCode.mdx';
import DeletePositionOpeningInput_positionOpening from './DeletePositionOpeningInput.positionOpening.mdx';
import DeletePositionOpening_PositionOpeningInput_documentId from './DeletePositionOpening_PositionOpeningInput.documentId.mdx';
import PostingRequesterInput_id from './PostingRequesterInput.id.mdx';
import PostingRequesterInput_roleCode from './PostingRequesterInput.roleCode.mdx';
import PostingRequesterInput_personContacts from './PostingRequesterInput.personContacts.mdx';
import PostPositionInput_positionOpening from './PostPositionInput.positionOpening.mdx';
import PostPositionInput_positionProfile from './PostPositionInput.positionProfile.mdx';
import PostPosition_PositionProfileInput_positionTitle from './PostPosition_PositionProfileInput.positionTitle.mdx';
import PostPosition_PositionProfileInput_positionOrganizations from './PostPosition_PositionProfileInput.positionOrganizations.mdx';
import PostPosition_PositionProfileInput_seekHirerJobReference from './PostPosition_PositionProfileInput.seekHirerJobReference.mdx';
import PostPosition_PositionProfileInput_seekBillingReference from './PostPosition_PositionProfileInput.seekBillingReference.mdx';
import PostPosition_PositionProfileInput_positionFormattedDescriptions from './PostPosition_PositionProfileInput.positionFormattedDescriptions.mdx';
import PostPosition_PositionProfileInput_positionScheduleTypeCodes from './PostPosition_PositionProfileInput.positionScheduleTypeCodes.mdx';
import PostPosition_PositionProfileInput_offeredRemunerationPackage from './PostPosition_PositionProfileInput.offeredRemunerationPackage.mdx';
import PostPosition_PositionProfileInput_seekAnzWorkTypeCode from './PostPosition_PositionProfileInput.seekAnzWorkTypeCode.mdx';
import PostPosition_PositionProfileInput_jobCategories from './PostPosition_PositionProfileInput.jobCategories.mdx';
import PostPosition_PositionProfileInput_positionLocation from './PostPosition_PositionProfileInput.positionLocation.mdx';
import PostPosition_PositionProfileInput_seekApplicationQuestionnaireId from './PostPosition_PositionProfileInput.seekApplicationQuestionnaireId.mdx';
import PostPosition_PositionProfileInput_seekVideo from './PostPosition_PositionProfileInput.seekVideo.mdx';
import PostPosition_PositionProfileInput_postingInstructions from './PostPosition_PositionProfileInput.postingInstructions.mdx';
import PostPosition_PositionProfileInput_seekPartnerMetadata from './PostPosition_PositionProfileInput.seekPartnerMetadata.mdx';
import PostPositionProfileForOpeningInput_positionProfile from './PostPositionProfileForOpeningInput.positionProfile.mdx';
import PostPositionProfileForOpeningPositionProfileInput_positionOpeningId from './PostPositionProfileForOpeningPositionProfileInput.positionOpeningId.mdx';
import PostPositionProfileForOpeningPositionProfileInput_positionTitle from './PostPositionProfileForOpeningPositionProfileInput.positionTitle.mdx';
import PostPositionProfileForOpeningPositionProfileInput_positionOrganizations from './PostPositionProfileForOpeningPositionProfileInput.positionOrganizations.mdx';
import PostPositionProfileForOpeningPositionProfileInput_seekHirerJobReference from './PostPositionProfileForOpeningPositionProfileInput.seekHirerJobReference.mdx';
import PostPositionProfileForOpeningPositionProfileInput_seekBillingReference from './PostPositionProfileForOpeningPositionProfileInput.seekBillingReference.mdx';
import PostPositionProfileForOpeningPositionProfileInput_positionFormattedDescriptions from './PostPositionProfileForOpeningPositionProfileInput.positionFormattedDescriptions.mdx';
import PostPositionProfileForOpeningPositionProfileInput_positionScheduleTypeCodes from './PostPositionProfileForOpeningPositionProfileInput.positionScheduleTypeCodes.mdx';
import PostPositionProfileForOpeningPositionProfileInput_offeredRemunerationPackage from './PostPositionProfileForOpeningPositionProfileInput.offeredRemunerationPackage.mdx';
import PostPositionProfileForOpeningPositionProfileInput_seekAnzWorkTypeCode from './PostPositionProfileForOpeningPositionProfileInput.seekAnzWorkTypeCode.mdx';
import PostPositionProfileForOpeningPositionProfileInput_jobCategories from './PostPositionProfileForOpeningPositionProfileInput.jobCategories.mdx';
import PostPositionProfileForOpeningPositionProfileInput_positionLocation from './PostPositionProfileForOpeningPositionProfileInput.positionLocation.mdx';
import PostPositionProfileForOpeningPositionProfileInput_seekApplicationQuestionnaireId from './PostPositionProfileForOpeningPositionProfileInput.seekApplicationQuestionnaireId.mdx';
import PostPositionProfileForOpeningPositionProfileInput_seekVideo from './PostPositionProfileForOpeningPositionProfileInput.seekVideo.mdx';
import PostPositionProfileForOpeningPositionProfileInput_postingInstructions from './PostPositionProfileForOpeningPositionProfileInput.postingInstructions.mdx';
import PostPositionProfileForOpeningPositionProfileInput_seekPartnerMetadata from './PostPositionProfileForOpeningPositionProfileInput.seekPartnerMetadata.mdx';
import RemunerationPackageInput_basisCode from './RemunerationPackageInput.basisCode.mdx';
import RemunerationPackageInput_ranges from './RemunerationPackageInput.ranges.mdx';
import RemunerationPackageInput_descriptions from './RemunerationPackageInput.descriptions.mdx';
import RemunerationRangeInput_minimumAmount from './RemunerationRangeInput.minimumAmount.mdx';
import RemunerationRangeInput_maximumAmount from './RemunerationRangeInput.maximumAmount.mdx';
import RemunerationRangeInput_intervalCode from './RemunerationRangeInput.intervalCode.mdx';
import RemunerationAmountInput_value from './RemunerationAmountInput.value.mdx';
import RemunerationAmountInput_currency from './RemunerationAmountInput.currency.mdx';
import SeekPositionAddressInput_formattedAddress from './SeekPositionAddressInput.formattedAddress.mdx';
import SeekPositionAddressInput_postalCode from './SeekPositionAddressInput.postalCode.mdx';
import SeekPositionAddressInput_countryCode from './SeekPositionAddressInput.countryCode.mdx';
import SeekVideoInput_url from './SeekVideoInput.url.mdx';
import SeekVideoInput_seekAnzPositionCode from './SeekVideoInput.seekAnzPositionCode.mdx';
import CreateUnpostedPositionProfileForOpeningInput_positionProfile from './CreateUnpostedPositionProfileForOpeningInput.positionProfile.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionOpeningId from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionOpeningId.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_profileName from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.profileName.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionTitle from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionTitle.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionOrganizations from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionOrganizations.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_seekHirerJobReference from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.seekHirerJobReference.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_seekBillingReference from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.seekBillingReference.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionFormattedDescriptions from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionFormattedDescriptions.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionScheduleTypeCodes from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionScheduleTypeCodes.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_offeredRemunerationPackage from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.offeredRemunerationPackage.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_jobCategories from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.jobCategories.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionLocation from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionLocation.mdx';
import CreateUnpostedPositionProfileForOpeningPositionProfileInput_seekPartnerMetadata from './CreateUnpostedPositionProfileForOpeningPositionProfileInput.seekPartnerMetadata.mdx';
import UpdateUnpostedPositionProfileInput_positionProfile from './UpdateUnpostedPositionProfileInput.positionProfile.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_profileId from './UpdateUnpostedPositionProfile_PositionProfileInput.profileId.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_profileName from './UpdateUnpostedPositionProfile_PositionProfileInput.profileName.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_positionTitle from './UpdateUnpostedPositionProfile_PositionProfileInput.positionTitle.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_seekHirerJobReference from './UpdateUnpostedPositionProfile_PositionProfileInput.seekHirerJobReference.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_seekBillingReference from './UpdateUnpostedPositionProfile_PositionProfileInput.seekBillingReference.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_positionFormattedDescriptions from './UpdateUnpostedPositionProfile_PositionProfileInput.positionFormattedDescriptions.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_positionScheduleTypeCodes from './UpdateUnpostedPositionProfile_PositionProfileInput.positionScheduleTypeCodes.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_offeredRemunerationPackage from './UpdateUnpostedPositionProfile_PositionProfileInput.offeredRemunerationPackage.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_jobCategories from './UpdateUnpostedPositionProfile_PositionProfileInput.jobCategories.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_positionLocation from './UpdateUnpostedPositionProfile_PositionProfileInput.positionLocation.mdx';
import UpdateUnpostedPositionProfile_PositionProfileInput_seekPartnerMetadata from './UpdateUnpostedPositionProfile_PositionProfileInput.seekPartnerMetadata.mdx';
import DeleteUnpostedPositionProfileInput_positionProfile from './DeleteUnpostedPositionProfileInput.positionProfile.mdx';
import DeleteUnpostedPositionProfile_PositionProfileInput_profileId from './DeleteUnpostedPositionProfile_PositionProfileInput.profileId.mdx';
import UpdatePostedPositionProfileInput_positionProfile from './UpdatePostedPositionProfileInput.positionProfile.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_profileId from './UpdatePostedPositionProfile_PositionProfileInput.profileId.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_positionTitle from './UpdatePostedPositionProfile_PositionProfileInput.positionTitle.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_positionOrganizations from './UpdatePostedPositionProfile_PositionProfileInput.positionOrganizations.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_seekHirerJobReference from './UpdatePostedPositionProfile_PositionProfileInput.seekHirerJobReference.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_seekBillingReference from './UpdatePostedPositionProfile_PositionProfileInput.seekBillingReference.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_positionFormattedDescriptions from './UpdatePostedPositionProfile_PositionProfileInput.positionFormattedDescriptions.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_positionScheduleTypeCodes from './UpdatePostedPositionProfile_PositionProfileInput.positionScheduleTypeCodes.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_offeredRemunerationPackage from './UpdatePostedPositionProfile_PositionProfileInput.offeredRemunerationPackage.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_seekAnzWorkTypeCode from './UpdatePostedPositionProfile_PositionProfileInput.seekAnzWorkTypeCode.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_jobCategories from './UpdatePostedPositionProfile_PositionProfileInput.jobCategories.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_positionLocation from './UpdatePostedPositionProfile_PositionProfileInput.positionLocation.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_seekApplicationQuestionnaireId from './UpdatePostedPositionProfile_PositionProfileInput.seekApplicationQuestionnaireId.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_seekVideo from './UpdatePostedPositionProfile_PositionProfileInput.seekVideo.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_postingInstructions from './UpdatePostedPositionProfile_PositionProfileInput.postingInstructions.mdx';
import UpdatePostedPositionProfile_PositionProfileInput_seekPartnerMetadata from './UpdatePostedPositionProfile_PositionProfileInput.seekPartnerMetadata.mdx';
import UpdatePostingInstructionInput_seekAnzAdvertisementType from './UpdatePostingInstructionInput.seekAnzAdvertisementType.mdx';
import UpdatePostingInstructionInput_seekAdvertisementProductId from './UpdatePostingInstructionInput.seekAdvertisementProductId.mdx';
import UpdatePostingInstructionInput_end from './UpdatePostingInstructionInput.end.mdx';
import UpdatePostingInstructionInput_applicationMethods from './UpdatePostingInstructionInput.applicationMethods.mdx';
import UpdatePostingInstructionInput_brandingId from './UpdatePostingInstructionInput.brandingId.mdx';
import PostedPositionProfilePreview_PositionProfileInput_positionTitle from './PostedPositionProfilePreview_PositionProfileInput.positionTitle.mdx';
import PostedPositionProfilePreview_PositionProfileInput_profileId from './PostedPositionProfilePreview_PositionProfileInput.profileId.mdx';
import PostedPositionProfilePreview_PositionProfileInput_positionOrganizations from './PostedPositionProfilePreview_PositionProfileInput.positionOrganizations.mdx';
import PostedPositionProfilePreview_PositionProfileInput_positionFormattedDescriptions from './PostedPositionProfilePreview_PositionProfileInput.positionFormattedDescriptions.mdx';
import PostedPositionProfilePreview_PositionProfileInput_seekAnzWorkTypeCode from './PostedPositionProfilePreview_PositionProfileInput.seekAnzWorkTypeCode.mdx';
import PostedPositionProfilePreview_PositionProfileInput_jobCategories from './PostedPositionProfilePreview_PositionProfileInput.jobCategories.mdx';
import PostedPositionProfilePreview_PositionProfileInput_positionLocation from './PostedPositionProfilePreview_PositionProfileInput.positionLocation.mdx';
import PostedPositionProfilePreview_PositionProfileInput_seekVideo from './PostedPositionProfilePreview_PositionProfileInput.seekVideo.mdx';
import PostedPositionProfilePreview_PositionProfileInput_postingInstructions from './PostedPositionProfilePreview_PositionProfileInput.postingInstructions.mdx';
import PostedPositionProfilePreview_PositionProfileInput_offeredRemunerationPackage from './PostedPositionProfilePreview_PositionProfileInput.offeredRemunerationPackage.mdx';
import PostedPositionProfilePreview_PositionProfileInput_seekApplicationQuestionnaireId from './PostedPositionProfilePreview_PositionProfileInput.seekApplicationQuestionnaireId.mdx';
import PostedPositionProfilePreview_previewUri from './PostedPositionProfilePreview.previewUri.mdx';
import PostedPositionProfilePreview_PostingInstructionInput_seekAnzAdvertisementType from './PostedPositionProfilePreview_PostingInstructionInput.seekAnzAdvertisementType.mdx';
import PostedPositionProfilePreview_PostingInstructionInput_seekAdvertisementProductId from './PostedPositionProfilePreview_PostingInstructionInput.seekAdvertisementProductId.mdx';
import PostedPositionProfilePreview_PostingInstructionInput_applicationMethods from './PostedPositionProfilePreview_PostingInstructionInput.applicationMethods.mdx';
import PostedPositionProfilePreview_PostingInstructionInput_brandingId from './PostedPositionProfilePreview_PostingInstructionInput.brandingId.mdx';
import PostedPositionProfilePreview_RemunerationAmountInput_value from './PostedPositionProfilePreview_RemunerationAmountInput.value.mdx';
import PostedPositionProfilePreview_RemunerationAmountInput_currency from './PostedPositionProfilePreview_RemunerationAmountInput.currency.mdx';
import PostedPositionProfilePreview_RemunerationPackageInput_basisCode from './PostedPositionProfilePreview_RemunerationPackageInput.basisCode.mdx';
import PostedPositionProfilePreview_RemunerationPackageInput_descriptions from './PostedPositionProfilePreview_RemunerationPackageInput.descriptions.mdx';
import PostedPositionProfilePreview_RemunerationPackageInput_ranges from './PostedPositionProfilePreview_RemunerationPackageInput.ranges.mdx';
import PostedPositionProfilePreview_RemunerationRangeInput_minimumAmount from './PostedPositionProfilePreview_RemunerationRangeInput.minimumAmount.mdx';
import PostedPositionProfilePreview_RemunerationRangeInput_maximumAmount from './PostedPositionProfilePreview_RemunerationRangeInput.maximumAmount.mdx';
import PostedPositionProfilePreview_RemunerationRangeInput_intervalCode from './PostedPositionProfilePreview_RemunerationRangeInput.intervalCode.mdx';
import ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionTitle from './ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionTitle.mdx';
import ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionFormattedDescriptions from './ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionFormattedDescriptions.mdx';
import ApplicationLibraryQuestionSuggestions_PositionProfileInput_jobCategories from './ApplicationLibraryQuestionSuggestions_PositionProfileInput.jobCategories.mdx';
import ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionLocation from './ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionLocation.mdx';
import ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionOrganizations from './ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionOrganizations.mdx';
import ApplicationLibraryQuestionPreferenceSelection_typeCode from './ApplicationLibraryQuestionPreferenceSelection.typeCode.mdx';
import ApplicationLibraryQuestionPreferenceSelection_message from './ApplicationLibraryQuestionPreferenceSelection.message.mdx';
import ApplicationLibraryQuestionChoice_id from './ApplicationLibraryQuestionChoice.id.mdx';
import ApplicationLibraryQuestionChoice_text from './ApplicationLibraryQuestionChoice.text.mdx';
import ApplicationLibraryQuestion_id from './ApplicationLibraryQuestion.id.mdx';
import ApplicationLibraryQuestion_text from './ApplicationLibraryQuestion.text.mdx';
import ApplicationLibraryQuestion_statusCode from './ApplicationLibraryQuestion.statusCode.mdx';
import ApplicationLibraryQuestion_responseTypeCode from './ApplicationLibraryQuestion.responseTypeCode.mdx';
import ApplicationLibraryQuestion_preferenceSelection from './ApplicationLibraryQuestion.preferenceSelection.mdx';
import ApplicationLibraryQuestion_responseChoice from './ApplicationLibraryQuestion.responseChoice.mdx';
import ApplicationLibraryQuestionSuggestion_id from './ApplicationLibraryQuestionSuggestion.id.mdx';
import ApplicationLibraryQuestionSuggestion_applicationLibraryQuestion from './ApplicationLibraryQuestionSuggestion.applicationLibraryQuestion.mdx';
import ApplicationLibraryQuestionSearchResult_id from './ApplicationLibraryQuestionSearchResult.id.mdx';
import ApplicationLibraryQuestionSearchResult_applicationLibraryQuestion from './ApplicationLibraryQuestionSearchResult.applicationLibraryQuestion.mdx';
import ApplicationQuestionnaire_id from './ApplicationQuestionnaire.id.mdx';
import ApplicationQuestionnaire_hirer from './ApplicationQuestionnaire.hirer.mdx';
import ApplicationQuestionnaire_components from './ApplicationQuestionnaire.components.mdx';
import ApplicationQuestionnaireComponent_id from './ApplicationQuestionnaireComponent.id.mdx';
import ApplicationQuestionnaireComponent_componentTypeCode from './ApplicationQuestionnaireComponent.componentTypeCode.mdx';
import ApplicationQuestionnaireComponent_value from './ApplicationQuestionnaireComponent.value.mdx';
import ApplicationQuestion_id from './ApplicationQuestion.id.mdx';
import ApplicationQuestion_componentTypeCode from './ApplicationQuestion.componentTypeCode.mdx';
import ApplicationQuestion_sourceCode from './ApplicationQuestion.sourceCode.mdx';
import ApplicationQuestion_applicationLibraryQuestion from './ApplicationQuestion.applicationLibraryQuestion.mdx';
import ApplicationQuestion_value from './ApplicationQuestion.value.mdx';
import ApplicationQuestion_questionHtml from './ApplicationQuestion.questionHtml.mdx';
import ApplicationQuestion_responseTypeCode from './ApplicationQuestion.responseTypeCode.mdx';
import ApplicationQuestion_responseChoice from './ApplicationQuestion.responseChoice.mdx';
import ApplicationPrivacyConsent_id from './ApplicationPrivacyConsent.id.mdx';
import ApplicationPrivacyConsent_componentTypeCode from './ApplicationPrivacyConsent.componentTypeCode.mdx';
import ApplicationPrivacyConsent_value from './ApplicationPrivacyConsent.value.mdx';
import ApplicationPrivacyConsent_privacyPolicyUrl from './ApplicationPrivacyConsent.privacyPolicyUrl.mdx';
import ApplicationPrivacyConsent_descriptionHtml from './ApplicationPrivacyConsent.descriptionHtml.mdx';
import ApplicationQuestionChoice_id from './ApplicationQuestionChoice.id.mdx';
import ApplicationQuestionChoice_text from './ApplicationQuestionChoice.text.mdx';
import ApplicationQuestionChoice_value from './ApplicationQuestionChoice.value.mdx';
import ApplicationQuestionChoice_preferredIndicator from './ApplicationQuestionChoice.preferredIndicator.mdx';
import ApplicationQuestionChoice_selectedIndicator from './ApplicationQuestionChoice.selectedIndicator.mdx';
import ApplicationQuestionChoice_applicationLibraryQuestionChoice from './ApplicationQuestionChoice.applicationLibraryQuestionChoice.mdx';
import ApplicationQuestionnaireComponentResponse_component from './ApplicationQuestionnaireComponentResponse.component.mdx';
import ApplicationQuestionnaireComponentResponse_componentTypeCode from './ApplicationQuestionnaireComponentResponse.componentTypeCode.mdx';
import ApplicationQuestionAnswer_choice from './ApplicationQuestionAnswer.choice.mdx';
import ApplicationQuestionAnswer_answer from './ApplicationQuestionAnswer.answer.mdx';
import ApplicationQuestionResponse_component from './ApplicationQuestionResponse.component.mdx';
import ApplicationQuestionResponse_componentTypeCode from './ApplicationQuestionResponse.componentTypeCode.mdx';
import ApplicationQuestionResponse_answers from './ApplicationQuestionResponse.answers.mdx';
import ApplicationQuestionResponse_score from './ApplicationQuestionResponse.score.mdx';
import ApplicationPrivacyConsentResponse_component from './ApplicationPrivacyConsentResponse.component.mdx';
import ApplicationPrivacyConsentResponse_componentTypeCode from './ApplicationPrivacyConsentResponse.componentTypeCode.mdx';
import ApplicationPrivacyConsentResponse_consentGivenIndicator from './ApplicationPrivacyConsentResponse.consentGivenIndicator.mdx';
import ApplicationQuestionnaireSubmission_questionnaire from './ApplicationQuestionnaireSubmission.questionnaire.mdx';
import ApplicationQuestionnaireSubmission_responses from './ApplicationQuestionnaireSubmission.responses.mdx';
import ApplicationQuestionnaireSubmission_score from './ApplicationQuestionnaireSubmission.score.mdx';
import ApplicationPrivacyConsentInput_componentTypeCode from './ApplicationPrivacyConsentInput.componentTypeCode.mdx';
import ApplicationPrivacyConsentInput_value from './ApplicationPrivacyConsentInput.value.mdx';
import ApplicationPrivacyConsentInput_privacyPolicyUrl from './ApplicationPrivacyConsentInput.privacyPolicyUrl.mdx';
import ApplicationPrivacyConsentInput_descriptionHtml from './ApplicationPrivacyConsentInput.descriptionHtml.mdx';
import ApplicationQuestionInput_componentTypeCode from './ApplicationQuestionInput.componentTypeCode.mdx';
import ApplicationQuestionInput_questionHtml from './ApplicationQuestionInput.questionHtml.mdx';
import ApplicationQuestionInput_responseTypeCode from './ApplicationQuestionInput.responseTypeCode.mdx';
import ApplicationQuestionInput_value from './ApplicationQuestionInput.value.mdx';
import ApplicationQuestionInput_responseChoice from './ApplicationQuestionInput.responseChoice.mdx';
import ApplicationQuestionChoiceInput_text from './ApplicationQuestionChoiceInput.text.mdx';
import ApplicationQuestionChoiceInput_value from './ApplicationQuestionChoiceInput.value.mdx';
import ApplicationQuestionChoiceInput_preferredIndicator from './ApplicationQuestionChoiceInput.preferredIndicator.mdx';
import ApplicationLibraryQuestionInput_componentTypeCode from './ApplicationLibraryQuestionInput.componentTypeCode.mdx';
import ApplicationLibraryQuestionInput_id from './ApplicationLibraryQuestionInput.id.mdx';
import ApplicationLibraryQuestionInput_selectedResponseChoice from './ApplicationLibraryQuestionInput.selectedResponseChoice.mdx';
import ApplicationQuestionnaireComponentInput_componentTypeCode from './ApplicationQuestionnaireComponentInput.componentTypeCode.mdx';
import ApplicationQuestionnaireComponentInput_question from './ApplicationQuestionnaireComponentInput.question.mdx';
import ApplicationQuestionnaireComponentInput_libraryQuestion from './ApplicationQuestionnaireComponentInput.libraryQuestion.mdx';
import ApplicationQuestionnaireComponentInput_privacyConsent from './ApplicationQuestionnaireComponentInput.privacyConsent.mdx';
import CreateApplicationQuestionnaire_ApplicationQuestionnaireInput_hirerId from './CreateApplicationQuestionnaire_ApplicationQuestionnaireInput.hirerId.mdx';
import CreateApplicationQuestionnaire_ApplicationQuestionnaireInput_components from './CreateApplicationQuestionnaire_ApplicationQuestionnaireInput.components.mdx';
import CreateApplicationQuestionnaireInput_applicationQuestionnaire from './CreateApplicationQuestionnaireInput.applicationQuestionnaire.mdx';
import CreateApplicationQuestionnairePayload_applicationQuestionnaire from './CreateApplicationQuestionnairePayload.applicationQuestionnaire.mdx';
import webhook_CandidateApplicationCreatedEvent from './webhook.CandidateApplicationCreatedEvent.mdx';
import webhook_CandidateProfilePurchasedEvent from './webhook.CandidateProfilePurchasedEvent.mdx';
import webhook_HirerRelationshipChangedEvent from './webhook.HirerRelationshipChangedEvent.mdx';
import webhook_PositionProfileClosedEvent from './webhook.PositionProfileClosedEvent.mdx';
import webhook_PositionProfilePostedEvent from './webhook.PositionProfilePostedEvent.mdx';
import Mutation_sendApplicationSignal_input from './Mutation.sendApplicationSignal.input.mdx';
import Mutation_deleteUploadedCandidate_input from './Mutation.deleteUploadedCandidate.input.mdx';
import Mutation_uploadCandidate_input from './Mutation.uploadCandidate.input.mdx';
import Mutation_updateUploadedCandidatePerson_input from './Mutation.updateUploadedCandidatePerson.input.mdx';
import Mutation_updateUploadedCandidateProfileActions_input from './Mutation.updateUploadedCandidateProfileActions.input.mdx';
import Mutation_updateUploadedCandidateProfileDates_input from './Mutation.updateUploadedCandidateProfileDates.input.mdx';
import Mutation_updateUploadedCandidateProfilePositionPreferences_input from './Mutation.updateUploadedCandidateProfilePositionPreferences.input.mdx';
import Mutation_createCandidateProcessHistoryItem_input from './Mutation.createCandidateProcessHistoryItem.input.mdx';
import Mutation_deleteCandidateProcessHistoryItem_input from './Mutation.deleteCandidateProcessHistoryItem.input.mdx';
import Mutation_updateCandidateProcessHistoryItem_input from './Mutation.updateCandidateProcessHistoryItem.input.mdx';
import Mutation_createWebhookSubscription_input from './Mutation.createWebhookSubscription.input.mdx';
import Mutation_updateWebhookSubscriptionDeliveryConfiguration_input from './Mutation.updateWebhookSubscriptionDeliveryConfiguration.input.mdx';
import Mutation_updateWebhookSubscriptionSigningConfiguration_input from './Mutation.updateWebhookSubscriptionSigningConfiguration.input.mdx';
import Mutation_deleteWebhookSubscription_input from './Mutation.deleteWebhookSubscription.input.mdx';
import Mutation_replayWebhookSubscription_input from './Mutation.replayWebhookSubscription.input.mdx';
import Mutation_createPositionOpening_input from './Mutation.createPositionOpening.input.mdx';
import Mutation_updatePositionOpeningPersonContacts_input from './Mutation.updatePositionOpeningPersonContacts.input.mdx';
import Mutation_updatePositionOpeningStatus_input from './Mutation.updatePositionOpeningStatus.input.mdx';
import Mutation_deletePositionOpening_input from './Mutation.deletePositionOpening.input.mdx';
import Mutation_postPositionProfileForOpening_input from './Mutation.postPositionProfileForOpening.input.mdx';
import Mutation_updatePostedPositionProfile_input from './Mutation.updatePostedPositionProfile.input.mdx';
import Mutation_closePostedPositionProfile_input from './Mutation.closePostedPositionProfile.input.mdx';
import Mutation_postPosition_input from './Mutation.postPosition.input.mdx';
import Mutation_createUnpostedPositionProfileForOpening_input from './Mutation.createUnpostedPositionProfileForOpening.input.mdx';
import Mutation_updateUnpostedPositionProfile_input from './Mutation.updateUnpostedPositionProfile.input.mdx';
import Mutation_deleteUnpostedPositionProfile_input from './Mutation.deleteUnpostedPositionProfile.input.mdx';
import Mutation_createApplicationQuestionnaire_input from './Mutation.createApplicationQuestionnaire.input.mdx';
import Query_seekAnzHirerAdvertisementCreationProducts_hirerId from './Query.seekAnzHirerAdvertisementCreationProducts.hirerId.mdx';
import Query_seekAnzHirerAdvertisementCreationProducts_draftAdvertisement from './Query.seekAnzHirerAdvertisementCreationProducts.draftAdvertisement.mdx';
import Query_seekAnzHirerAdvertisementModificationProducts_hirerId from './Query.seekAnzHirerAdvertisementModificationProducts.hirerId.mdx';
import Query_seekAnzHirerAdvertisementModificationProducts_advertisementId from './Query.seekAnzHirerAdvertisementModificationProducts.advertisementId.mdx';
import Query_seekAnzHirerAdvertisementModificationProducts_draftAdvertisement from './Query.seekAnzHirerAdvertisementModificationProducts.draftAdvertisement.mdx';
import Query_seekAnzHirerAdvertisementModificationProductsAlt_hirerId from './Query.seekAnzHirerAdvertisementModificationProductsAlt.hirerId.mdx';
import Query_seekAnzHirerAdvertisementModificationProductsAlt_advertisement from './Query.seekAnzHirerAdvertisementModificationProductsAlt.advertisement.mdx';
import Query_seekAnzHirerAdvertisementModificationProductsAlt_draftAdvertisement from './Query.seekAnzHirerAdvertisementModificationProductsAlt.draftAdvertisement.mdx';
import Query_advertisementProducts_positionProfile from './Query.advertisementProducts.positionProfile.mdx';
import Query_advertisementProducts_selectedAdvertisementProductId from './Query.advertisementProducts.selectedAdvertisementProductId.mdx';
import Query_advertisementProducts_limit from './Query.advertisementProducts.limit.mdx';
import Query_applyWithSeekButton_input from './Query.applyWithSeekButton.input.mdx';
import Query_jobCategory_id from './Query.jobCategory.id.mdx';
import Query_jobCategories_positionProfile from './Query.jobCategories.positionProfile.mdx';
import Query_jobCategories_schemeId from './Query.jobCategories.schemeId.mdx';
import Query_jobCategories_countryCode from './Query.jobCategories.countryCode.mdx';
import Query_jobCategorySuggestions_positionProfile from './Query.jobCategorySuggestions.positionProfile.mdx';
import Query_jobCategorySuggestions_schemeId from './Query.jobCategorySuggestions.schemeId.mdx';
import Query_jobCategorySuggestions_first from './Query.jobCategorySuggestions.first.mdx';
import Query_location_id from './Query.location.id.mdx';
import Query_locationSuggestions_usageTypeCode from './Query.locationSuggestions.usageTypeCode.mdx';
import Query_locationSuggestions_schemeId from './Query.locationSuggestions.schemeId.mdx';
import Query_locationSuggestions_hirerId from './Query.locationSuggestions.hirerId.mdx';
import Query_locationSuggestions_text from './Query.locationSuggestions.text.mdx';
import Query_locationSuggestions_first from './Query.locationSuggestions.first.mdx';
import Query_nearestLocations_schemeId from './Query.nearestLocations.schemeId.mdx';
import Query_nearestLocations_geoLocation from './Query.nearestLocations.geoLocation.mdx';
import Query_nearestLocations_first from './Query.nearestLocations.first.mdx';
import Query_inferLocation_schemeId from './Query.inferLocation.schemeId.mdx';
import Query_inferLocation_address from './Query.inferLocation.address.mdx';
import Query_inferLocation_hirerId from './Query.inferLocation.hirerId.mdx';
import Query_currencies_usageTypeCode from './Query.currencies.usageTypeCode.mdx';
import Query_payTypes_schemeId from './Query.payTypes.schemeId.mdx';
import Query_advertisementBrandings_after from './Query.advertisementBrandings.after.mdx';
import Query_advertisementBrandings_before from './Query.advertisementBrandings.before.mdx';
import Query_advertisementBrandings_first from './Query.advertisementBrandings.first.mdx';
import Query_advertisementBrandings_last from './Query.advertisementBrandings.last.mdx';
import Query_advertisementBrandings_hirerId from './Query.advertisementBrandings.hirerId.mdx';
import Query_advertisementBranding_id from './Query.advertisementBranding.id.mdx';
import Query_candidateProfile_id from './Query.candidateProfile.id.mdx';
import Query_candidate_id from './Query.candidate.id.mdx';
import Query_candidateProcessHistoryItem_id from './Query.candidateProcessHistoryItem.id.mdx';
import Query_event_id from './Query.event.id.mdx';
import Query_events_after from './Query.events.after.mdx';
import Query_events_before from './Query.events.before.mdx';
import Query_events_first from './Query.events.first.mdx';
import Query_events_last from './Query.events.last.mdx';
import Query_events_filter from './Query.events.filter.mdx';
import Query_events_schemeId from './Query.events.schemeId.mdx';
import Query_webhookAttemptsForEvent_after from './Query.webhookAttemptsForEvent.after.mdx';
import Query_webhookAttemptsForEvent_before from './Query.webhookAttemptsForEvent.before.mdx';
import Query_webhookAttemptsForEvent_first from './Query.webhookAttemptsForEvent.first.mdx';
import Query_webhookAttemptsForEvent_last from './Query.webhookAttemptsForEvent.last.mdx';
import Query_webhookAttemptsForEvent_filter from './Query.webhookAttemptsForEvent.filter.mdx';
import Query_webhookAttemptsForEvent_eventId from './Query.webhookAttemptsForEvent.eventId.mdx';
import Query_webhookSubscription_id from './Query.webhookSubscription.id.mdx';
import Query_webhookSubscriptions_after from './Query.webhookSubscriptions.after.mdx';
import Query_webhookSubscriptions_before from './Query.webhookSubscriptions.before.mdx';
import Query_webhookSubscriptions_first from './Query.webhookSubscriptions.first.mdx';
import Query_webhookSubscriptions_last from './Query.webhookSubscriptions.last.mdx';
import Query_webhookSubscriptions_filter from './Query.webhookSubscriptions.filter.mdx';
import Query_webhookSubscriptions_schemeId from './Query.webhookSubscriptions.schemeId.mdx';
import Query_webhookSubscriptionReplay_id from './Query.webhookSubscriptionReplay.id.mdx';
import Query_webhookRequestsForSubscription_after from './Query.webhookRequestsForSubscription.after.mdx';
import Query_webhookRequestsForSubscription_before from './Query.webhookRequestsForSubscription.before.mdx';
import Query_webhookRequestsForSubscription_first from './Query.webhookRequestsForSubscription.first.mdx';
import Query_webhookRequestsForSubscription_last from './Query.webhookRequestsForSubscription.last.mdx';
import Query_webhookRequestsForSubscription_filter from './Query.webhookRequestsForSubscription.filter.mdx';
import Query_webhookRequestsForSubscription_subscriptionId from './Query.webhookRequestsForSubscription.subscriptionId.mdx';
import Query_webhookRequest_schemeId from './Query.webhookRequest.schemeId.mdx';
import Query_webhookRequest_requestId from './Query.webhookRequest.requestId.mdx';
import Query_hiringOrganization_id from './Query.hiringOrganization.id.mdx';
import Query_hiringOrganizations_schemeId from './Query.hiringOrganizations.schemeId.mdx';
import Query_hiringOrganizations_after from './Query.hiringOrganizations.after.mdx';
import Query_hiringOrganizations_before from './Query.hiringOrganizations.before.mdx';
import Query_hiringOrganizations_first from './Query.hiringOrganizations.first.mdx';
import Query_hiringOrganizations_last from './Query.hiringOrganizations.last.mdx';
import Query_hiringOrganizations_filter from './Query.hiringOrganizations.filter.mdx';
import Query_seekAnzAdvertiser_id from './Query.seekAnzAdvertiser.id.mdx';
import Query_positionOpening_id from './Query.positionOpening.id.mdx';
import Query_positionProfile_id from './Query.positionProfile.id.mdx';
import Query_positionOpenings_hirerId from './Query.positionOpenings.hirerId.mdx';
import Query_positionOpenings_after from './Query.positionOpenings.after.mdx';
import Query_positionOpenings_first from './Query.positionOpenings.first.mdx';
import Query_positionOpenings_filter from './Query.positionOpenings.filter.mdx';
import Query_postedPositionProfilePreview_positionProfile from './Query.postedPositionProfilePreview.positionProfile.mdx';
import Query_applicationLibraryQuestionSuggestions_positionProfile from './Query.applicationLibraryQuestionSuggestions.positionProfile.mdx';
import Query_applicationLibraryQuestionSuggestions_schemeId from './Query.applicationLibraryQuestionSuggestions.schemeId.mdx';
import Query_applicationLibraryQuestionSuggestions_first from './Query.applicationLibraryQuestionSuggestions.first.mdx';
import Query_applicationLibraryQuestionSearch_keywords from './Query.applicationLibraryQuestionSearch.keywords.mdx';
import Query_applicationLibraryQuestionSearch_hirerId from './Query.applicationLibraryQuestionSearch.hirerId.mdx';
import Query_applicationLibraryQuestionSearch_schemeId from './Query.applicationLibraryQuestionSearch.schemeId.mdx';
import Query_applicationLibraryQuestionSearch_first from './Query.applicationLibraryQuestionSearch.first.mdx';
import Query_applicationQuestionnaire_id from './Query.applicationQuestionnaire.id.mdx';
import Candidate_profiles_last from './Candidate.profiles.last.mdx';
import CandidateProfile_seekProcessHistory_after from './CandidateProfile.seekProcessHistory.after.mdx';
import CandidateProfile_seekProcessHistory_first from './CandidateProfile.seekProcessHistory.first.mdx';
import Event_webhookAttempts_after from './Event.webhookAttempts.after.mdx';
import Event_webhookAttempts_before from './Event.webhookAttempts.before.mdx';
import Event_webhookAttempts_first from './Event.webhookAttempts.first.mdx';
import Event_webhookAttempts_last from './Event.webhookAttempts.last.mdx';
import Event_webhookAttempts_filter from './Event.webhookAttempts.filter.mdx';
import CandidateApplicationCreatedEvent_webhookAttempts_after from './CandidateApplicationCreatedEvent.webhookAttempts.after.mdx';
import CandidateApplicationCreatedEvent_webhookAttempts_before from './CandidateApplicationCreatedEvent.webhookAttempts.before.mdx';
import CandidateApplicationCreatedEvent_webhookAttempts_first from './CandidateApplicationCreatedEvent.webhookAttempts.first.mdx';
import CandidateApplicationCreatedEvent_webhookAttempts_last from './CandidateApplicationCreatedEvent.webhookAttempts.last.mdx';
import CandidateApplicationCreatedEvent_webhookAttempts_filter from './CandidateApplicationCreatedEvent.webhookAttempts.filter.mdx';
import PositionProfileClosedEvent_webhookAttempts_after from './PositionProfileClosedEvent.webhookAttempts.after.mdx';
import PositionProfileClosedEvent_webhookAttempts_before from './PositionProfileClosedEvent.webhookAttempts.before.mdx';
import PositionProfileClosedEvent_webhookAttempts_first from './PositionProfileClosedEvent.webhookAttempts.first.mdx';
import PositionProfileClosedEvent_webhookAttempts_last from './PositionProfileClosedEvent.webhookAttempts.last.mdx';
import PositionProfileClosedEvent_webhookAttempts_filter from './PositionProfileClosedEvent.webhookAttempts.filter.mdx';
import PositionProfilePostedEvent_webhookAttempts_after from './PositionProfilePostedEvent.webhookAttempts.after.mdx';
import PositionProfilePostedEvent_webhookAttempts_before from './PositionProfilePostedEvent.webhookAttempts.before.mdx';
import PositionProfilePostedEvent_webhookAttempts_first from './PositionProfilePostedEvent.webhookAttempts.first.mdx';
import PositionProfilePostedEvent_webhookAttempts_last from './PositionProfilePostedEvent.webhookAttempts.last.mdx';
import PositionProfilePostedEvent_webhookAttempts_filter from './PositionProfilePostedEvent.webhookAttempts.filter.mdx';
import CandidateProfilePurchasedEvent_webhookAttempts_after from './CandidateProfilePurchasedEvent.webhookAttempts.after.mdx';
import CandidateProfilePurchasedEvent_webhookAttempts_before from './CandidateProfilePurchasedEvent.webhookAttempts.before.mdx';
import CandidateProfilePurchasedEvent_webhookAttempts_first from './CandidateProfilePurchasedEvent.webhookAttempts.first.mdx';
import CandidateProfilePurchasedEvent_webhookAttempts_last from './CandidateProfilePurchasedEvent.webhookAttempts.last.mdx';
import CandidateProfilePurchasedEvent_webhookAttempts_filter from './CandidateProfilePurchasedEvent.webhookAttempts.filter.mdx';
import HirerRelationshipChangedEvent_webhookAttempts_after from './HirerRelationshipChangedEvent.webhookAttempts.after.mdx';
import HirerRelationshipChangedEvent_webhookAttempts_before from './HirerRelationshipChangedEvent.webhookAttempts.before.mdx';
import HirerRelationshipChangedEvent_webhookAttempts_first from './HirerRelationshipChangedEvent.webhookAttempts.first.mdx';
import HirerRelationshipChangedEvent_webhookAttempts_last from './HirerRelationshipChangedEvent.webhookAttempts.last.mdx';
import HirerRelationshipChangedEvent_webhookAttempts_filter from './HirerRelationshipChangedEvent.webhookAttempts.filter.mdx';
import WebhookSubscription_webhookRequests_after from './WebhookSubscription.webhookRequests.after.mdx';
import WebhookSubscription_webhookRequests_before from './WebhookSubscription.webhookRequests.before.mdx';
import WebhookSubscription_webhookRequests_first from './WebhookSubscription.webhookRequests.first.mdx';
import WebhookSubscription_webhookRequests_last from './WebhookSubscription.webhookRequests.last.mdx';
import WebhookSubscription_webhookRequests_filter from './WebhookSubscription.webhookRequests.filter.mdx';
import WebhookSubscription_webhookSubscriptionReplays_after from './WebhookSubscription.webhookSubscriptionReplays.after.mdx';
import WebhookSubscription_webhookSubscriptionReplays_before from './WebhookSubscription.webhookSubscriptionReplays.before.mdx';
import WebhookSubscription_webhookSubscriptionReplays_first from './WebhookSubscription.webhookSubscriptionReplays.first.mdx';
import WebhookSubscription_webhookSubscriptionReplays_last from './WebhookSubscription.webhookSubscriptionReplays.last.mdx';
import WebhookSubscription_webhookSubscriptionReplays_filter from './WebhookSubscription.webhookSubscriptionReplays.filter.mdx';
import PositionOpening_paginatedPositionProfiles_after from './PositionOpening.paginatedPositionProfiles.after.mdx';
import PositionOpening_paginatedPositionProfiles_first from './PositionOpening.paginatedPositionProfiles.first.mdx';
import webhook_CandidateApplicationCreatedEvent_id from './webhook.CandidateApplicationCreatedEvent.id.mdx';
import webhook_CandidateApplicationCreatedEvent_candidateApplicationProfileId from './webhook.CandidateApplicationCreatedEvent.candidateApplicationProfileId.mdx';
import webhook_CandidateApplicationCreatedEvent_candidateId from './webhook.CandidateApplicationCreatedEvent.candidateId.mdx';
import webhook_CandidateApplicationCreatedEvent_hirerId from './webhook.CandidateApplicationCreatedEvent.hirerId.mdx';
import webhook_CandidateApplicationCreatedEvent_type from './webhook.CandidateApplicationCreatedEvent.type.mdx';
import webhook_CandidateApplicationCreatedEvent_createDateTime from './webhook.CandidateApplicationCreatedEvent.createDateTime.mdx';
import webhook_CandidateProfilePurchasedEvent_id from './webhook.CandidateProfilePurchasedEvent.id.mdx';
import webhook_CandidateProfilePurchasedEvent_candidateProfileId from './webhook.CandidateProfilePurchasedEvent.candidateProfileId.mdx';
import webhook_CandidateProfilePurchasedEvent_hirerId from './webhook.CandidateProfilePurchasedEvent.hirerId.mdx';
import webhook_CandidateProfilePurchasedEvent_type from './webhook.CandidateProfilePurchasedEvent.type.mdx';
import webhook_CandidateProfilePurchasedEvent_createDateTime from './webhook.CandidateProfilePurchasedEvent.createDateTime.mdx';
import webhook_HirerRelationshipChangedEvent_id from './webhook.HirerRelationshipChangedEvent.id.mdx';
import webhook_HirerRelationshipChangedEvent_hirerId from './webhook.HirerRelationshipChangedEvent.hirerId.mdx';
import webhook_HirerRelationshipChangedEvent_type from './webhook.HirerRelationshipChangedEvent.type.mdx';
import webhook_HirerRelationshipChangedEvent_createDateTime from './webhook.HirerRelationshipChangedEvent.createDateTime.mdx';
import webhook_PositionProfileClosedEvent_id from './webhook.PositionProfileClosedEvent.id.mdx';
import webhook_PositionProfileClosedEvent_hirerId from './webhook.PositionProfileClosedEvent.hirerId.mdx';
import webhook_PositionProfileClosedEvent_positionProfileId from './webhook.PositionProfileClosedEvent.positionProfileId.mdx';
import webhook_PositionProfileClosedEvent_type from './webhook.PositionProfileClosedEvent.type.mdx';
import webhook_PositionProfileClosedEvent_createDateTime from './webhook.PositionProfileClosedEvent.createDateTime.mdx';
import webhook_PositionProfilePostedEvent_id from './webhook.PositionProfilePostedEvent.id.mdx';
import webhook_PositionProfilePostedEvent_hirerId from './webhook.PositionProfilePostedEvent.hirerId.mdx';
import webhook_PositionProfilePostedEvent_positionProfileId from './webhook.PositionProfilePostedEvent.positionProfileId.mdx';
import webhook_PositionProfilePostedEvent_type from './webhook.PositionProfilePostedEvent.type.mdx';
import webhook_PositionProfilePostedEvent_createDateTime from './webhook.PositionProfilePostedEvent.createDateTime.mdx';
import webhook_events from './webhook.events.mdx';
import webhook_subscriptionId from './webhook.subscriptionId.mdx';
import webhook_url from './webhook.url.mdx';
export const content = {
  Query: Query,
  String: String,
  Int: Int,
  SeekAnzAdProduct: SeekAnzAdProduct,
  Boolean: Boolean,
  SeekAnzAdProductFeatures: SeekAnzAdProductFeatures,
  SeekAnzAdProductPrice: SeekAnzAdProductPrice,
  SeekAnzAdProductCheckoutEstimate: SeekAnzAdProductCheckoutEstimate,
  SeekAnzAdProductContractConsumption: SeekAnzAdProductContractConsumption,
  SeekAnzAdProductAdvertisementInput: SeekAnzAdProductAdvertisementInput,
  SeekAnzAdProductAdvertisementDraftInput:
    SeekAnzAdProductAdvertisementDraftInput,
  SeekAnzAdProductMessage: SeekAnzAdProductMessage,
  CurrencyMinorUnit: CurrencyMinorUnit,
  AdvertisementProducts: AdvertisementProducts,
  AdvertisementProduct: AdvertisementProduct,
  AdvertisementProductPriceDetails_Tax: AdvertisementProductPriceDetails_Tax,
  AdvertisementProductPriceDetails: AdvertisementProductPriceDetails,
  AdvertisementProductPaymentDetails: AdvertisementProductPaymentDetails,
  AdvertisementProductFeatures: AdvertisementProductFeatures,
  AdvertisementProductSellingPoint: AdvertisementProductSellingPoint,
  AdvertisementProductFeatures_Branding: AdvertisementProductFeatures_Branding,
  AdvertisementProductFeatures_SearchBulletPoints:
    AdvertisementProductFeatures_SearchBulletPoints,
  AdvertisementProducts_PositionProfileInput:
    AdvertisementProducts_PositionProfileInput,
  ApplyWithSeekButtonInput: ApplyWithSeekButtonInput,
  ApplyWithSeekButtonPayload: ApplyWithSeekButtonPayload,
  ApplyWithSeekButtonImages: ApplyWithSeekButtonImages,
  Mutation: Mutation,
  AddressComponent: AddressComponent,
  AddressComponentInput: AddressComponentInput,
  Address: Address,
  AddressInput: AddressInput,
  WebUrl: WebUrl,
  Email: Email,
  EmailInput: EmailInput,
  Phone: Phone,
  PhoneInput: PhoneInput,
  Communication: Communication,
  CommunicationInput: CommunicationInput,
  WebUrlInput: WebUrlInput,
  DateTime: DateTime,
  Date: Date,
  HistoryDate: HistoryDate,
  EffectiveTimePeriod: EffectiveTimePeriod,
  ObjectIdentifier: ObjectIdentifier,
  JobCategory: JobCategory,
  JobCategorySuggestionChoice: JobCategorySuggestionChoice,
  Float: Float,
  JobCategorySuggestionPositionProfileInput:
    JobCategorySuggestionPositionProfileInput,
  JobCategories_PositionProfileInput: JobCategories_PositionProfileInput,
  Location: Location,
  LocationSuggestion: LocationSuggestion,
  GeoLocation: GeoLocation,
  GeoLocationInput: GeoLocationInput,
  PageInfo: PageInfo,
  PersonName: PersonName,
  SpecifiedPerson: SpecifiedPerson,
  PersonNameInput: PersonNameInput,
  SpecifiedPersonInput: SpecifiedPersonInput,
  Currency: Currency,
  PayType: PayType,
  SendApplicationSignalInput: SendApplicationSignalInput,
  AdvertisementBranding: AdvertisementBranding,
  AdvertisementBrandingEdge: AdvertisementBrandingEdge,
  AdvertisementBrandingImage: AdvertisementBrandingImage,
  AdvertisementBrandingsConnection: AdvertisementBrandingsConnection,
  Candidate: Candidate,
  CandidatePerson: CandidatePerson,
  CandidatePersonInput: CandidatePersonInput,
  SeekAttachmentRole: SeekAttachmentRole,
  AssociatedPositionOpening: AssociatedPositionOpening,
  Attachment: Attachment,
  PositionHistory: PositionHistory,
  EmployerHistory: EmployerHistory,
  EducationDegree: EducationDegree,
  EducationAttendance: EducationAttendance,
  PersonCompetency: PersonCompetency,
  Certification: Certification,
  CandidateSource: CandidateSource,
  CandidateProfile: CandidateProfile,
  PersonAvailability: PersonAvailability,
  PersonAvailability_NoticePeriodMeasure:
    PersonAvailability_NoticePeriodMeasure,
  CreateCandidateProcessHistoryItem_CandidateProfileInput:
    CreateCandidateProcessHistoryItem_CandidateProfileInput,
  CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput:
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput,
  CreateCandidateProcessHistoryItemInput:
    CreateCandidateProcessHistoryItemInput,
  CreateCandidateProcessHistoryItemPayload_Conflict:
    CreateCandidateProcessHistoryItemPayload_Conflict,
  CreateCandidateProcessHistoryItemPayload_Success:
    CreateCandidateProcessHistoryItemPayload_Success,
  CreateCandidateProcessHistoryItemPayload:
    CreateCandidateProcessHistoryItemPayload,
  DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput:
    DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput,
  DeleteCandidateProcessHistoryItemInput:
    DeleteCandidateProcessHistoryItemInput,
  DeleteCandidateProcessHistoryItemPayload:
    DeleteCandidateProcessHistoryItemPayload,
  DeleteUploadedCandidate_CandidateInput:
    DeleteUploadedCandidate_CandidateInput,
  DeleteUploadedCandidateInput: DeleteUploadedCandidateInput,
  DeleteUploadedCandidatePayload: DeleteUploadedCandidatePayload,
  DistributionGuidelines: DistributionGuidelines,
  DistributionGuidelinesInput: DistributionGuidelinesInput,
  PositionPreference: PositionPreference,
  SeekSalaryExpectation: SeekSalaryExpectation,
  PositionPreferenceInput: PositionPreferenceInput,
  PreferredLocation: PreferredLocation,
  PreferredLocationInput: PreferredLocationInput,
  CandidateProcessAction: CandidateProcessAction,
  CandidateProcessActionInput: CandidateProcessActionInput,
  CandidateProcessStatus: CandidateProcessStatus,
  CandidateProcessStatusInput: CandidateProcessStatusInput,
  CandidateProcessParty: CandidateProcessParty,
  CandidateProcessPartyInput: CandidateProcessPartyInput,
  SeekProcessHistoryItemSource: SeekProcessHistoryItemSource,
  SeekProcessHistoryItemSourceInput: SeekProcessHistoryItemSourceInput,
  CandidateProcessHistoryItem: CandidateProcessHistoryItem,
  CandidateProcessHistoryItemInput: CandidateProcessHistoryItemInput,
  CandidateProcessHistoryItem_PositionProfileInput:
    CandidateProcessHistoryItem_PositionProfileInput,
  CandidateProcessHistoryItemEdge: CandidateProcessHistoryItemEdge,
  CandidateProcessHistoryItemConnection: CandidateProcessHistoryItemConnection,
  UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput:
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput,
  UpdateCandidateProcessHistoryItemInput:
    UpdateCandidateProcessHistoryItemInput,
  UpdateCandidateProcessHistoryItemPayload:
    UpdateCandidateProcessHistoryItemPayload,
  UpdateUploadedCandidatePerson_CandidateInput:
    UpdateUploadedCandidatePerson_CandidateInput,
  UpdateUploadedCandidatePersonInput: UpdateUploadedCandidatePersonInput,
  UpdateUploadedCandidatePersonPayload_Conflict:
    UpdateUploadedCandidatePersonPayload_Conflict,
  UpdateUploadedCandidatePersonPayload_Success:
    UpdateUploadedCandidatePersonPayload_Success,
  UpdateUploadedCandidatePersonPayload: UpdateUploadedCandidatePersonPayload,
  UpdateUploadedCandidateProfileActions_CandidateProfileInput:
    UpdateUploadedCandidateProfileActions_CandidateProfileInput,
  UpdateUploadedCandidateProfileActionsInput:
    UpdateUploadedCandidateProfileActionsInput,
  UpdateUploadedCandidateProfileActionsPayload:
    UpdateUploadedCandidateProfileActionsPayload,
  UpdateUploadedCandidateProfileDates_CandidateProfileInput:
    UpdateUploadedCandidateProfileDates_CandidateProfileInput,
  UpdateUploadedCandidateProfileDatesInput:
    UpdateUploadedCandidateProfileDatesInput,
  UpdateUploadedCandidateProfileDatesPayload:
    UpdateUploadedCandidateProfileDatesPayload,
  UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput:
    UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput,
  UpdateUploadedCandidateProfilePositionPreferencesInput:
    UpdateUploadedCandidateProfilePositionPreferencesInput,
  UpdateUploadedCandidateProfilePositionPreferencesPayload:
    UpdateUploadedCandidateProfilePositionPreferencesPayload,
  UploadCandidate_CandidateInput: UploadCandidate_CandidateInput,
  UploadCandidate_CandidateProfileInput: UploadCandidate_CandidateProfileInput,
  UploadCandidate_HiringOrganizationInput:
    UploadCandidate_HiringOrganizationInput,
  UploadCandidateInput: UploadCandidateInput,
  UploadCandidatePayload_Conflict: UploadCandidatePayload_Conflict,
  UploadCandidatePayload_Success: UploadCandidatePayload_Success,
  UploadCandidatePayload: UploadCandidatePayload,
  Event: Event,
  CandidateApplicationCreatedEvent: CandidateApplicationCreatedEvent,
  PositionProfileClosedEvent: PositionProfileClosedEvent,
  PositionProfilePostedEvent: PositionProfilePostedEvent,
  CandidateProfilePurchasedEvent: CandidateProfilePurchasedEvent,
  HirerRelationshipChangedEvent: HirerRelationshipChangedEvent,
  EventsConnection: EventsConnection,
  EventEdge: EventEdge,
  EventsFilterInput: EventsFilterInput,
  CreateWebhookSubscriptionInput: CreateWebhookSubscriptionInput,
  CreateWebhookSubscription_SubscriptionInput:
    CreateWebhookSubscription_SubscriptionInput,
  CreateWebhookSubscriptionPayload: CreateWebhookSubscriptionPayload,
  CreateWebhookSubscriptionPayload_Conflict:
    CreateWebhookSubscriptionPayload_Conflict,
  CreateWebhookSubscriptionPayload_Success:
    CreateWebhookSubscriptionPayload_Success,
  UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput:
    UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput,
  UpdateWebhookSubscriptionDeliveryConfigurationInput:
    UpdateWebhookSubscriptionDeliveryConfigurationInput,
  UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput:
    UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput,
  UpdateWebhookSubscriptionSigningConfigurationInput:
    UpdateWebhookSubscriptionSigningConfigurationInput,
  UpdateWebhookSubscriptionDeliveryConfigurationPayload:
    UpdateWebhookSubscriptionDeliveryConfigurationPayload,
  UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success:
    UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success,
  UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict:
    UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict,
  UpdateWebhookSubscriptionSigningConfigurationPayload:
    UpdateWebhookSubscriptionSigningConfigurationPayload,
  DeleteWebhookSubscriptionInput: DeleteWebhookSubscriptionInput,
  DeleteWebhookSubscription_SubscriptionInput:
    DeleteWebhookSubscription_SubscriptionInput,
  ReplayWebhookSubscriptionInput: ReplayWebhookSubscriptionInput,
  ReplayWebhookSubscription_SubscriptionInput:
    ReplayWebhookSubscription_SubscriptionInput,
  ReplayWebhookSubscription_FilterInput: ReplayWebhookSubscription_FilterInput,
  DeleteWebhookSubscriptionPayload: DeleteWebhookSubscriptionPayload,
  ReplayWebhookSubscriptionPayload: ReplayWebhookSubscriptionPayload,
  WebhookAttempt: WebhookAttempt,
  WebhookAttemptsConnection: WebhookAttemptsConnection,
  WebhookAttemptEdge: WebhookAttemptEdge,
  WebhookAttemptsFilterInput: WebhookAttemptsFilterInput,
  WebhookRequest: WebhookRequest,
  WebhookRequestFilterInput: WebhookRequestFilterInput,
  WebhookRequestsConnection: WebhookRequestsConnection,
  WebhookRequestEdge: WebhookRequestEdge,
  WebhookSubscription: WebhookSubscription,
  WebhookSubscriptionReplay: WebhookSubscriptionReplay,
  WebhookSubscriptionReplayRequest: WebhookSubscriptionReplayRequest,
  WebhookSubscriptionReplayByRangeRequest:
    WebhookSubscriptionReplayByRangeRequest,
  WebhookSubscriptionReplayByIdRequest: WebhookSubscriptionReplayByIdRequest,
  WebhookSubscriptionReplaysConnection: WebhookSubscriptionReplaysConnection,
  WebhookSubscriptionReplayEdge: WebhookSubscriptionReplayEdge,
  WebhookSubscriptionReplaysFilterInput: WebhookSubscriptionReplaysFilterInput,
  WebhookSubscriptionsConnection: WebhookSubscriptionsConnection,
  WebhookSubscriptionEdge: WebhookSubscriptionEdge,
  WebhookSubscriptionsFilterInput: WebhookSubscriptionsFilterInput,
  HiringOrganization: HiringOrganization,
  HiringOrganizationApiCapabilities: HiringOrganizationApiCapabilities,
  HiringOrganizationsConnection: HiringOrganizationsConnection,
  HiringOrganizationEdge: HiringOrganizationEdge,
  HiringOrganizationsFilterInput: HiringOrganizationsFilterInput,
  Organization: Organization,
  OrganizationInput: OrganizationInput,
  PartnerOrganization: PartnerOrganization,
  SelfOrganizations: SelfOrganizations,
  _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion:
    _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion,
  _unstable_AdvertisementPerformance_DetailsWithConversion:
    _unstable_AdvertisementPerformance_DetailsWithConversion,
  _unstable_AdvertisementPerformance_Details:
    _unstable_AdvertisementPerformance_Details,
  _unstable_AdvertisementPerformance: _unstable_AdvertisementPerformance,
  ClosePostedPositionProfilePayload: ClosePostedPositionProfilePayload,
  ClosePostedPositionProfile_PositionProfilePayload:
    ClosePostedPositionProfile_PositionProfilePayload,
  CreatePositionOpeningPayload: CreatePositionOpeningPayload,
  CreateUnpostedPositionProfileForOpeningPayload:
    CreateUnpostedPositionProfileForOpeningPayload,
  DeletePositionOpeningPayload: DeletePositionOpeningPayload,
  DeleteUnpostedPositionProfilePayload: DeleteUnpostedPositionProfilePayload,
  PositionOpening: PositionOpening,
  PostingRequester: PostingRequester,
  PositionOpeningEdge: PositionOpeningEdge,
  PositionOpeningConnection: PositionOpeningConnection,
  PositionProfile: PositionProfile,
  PostedPositionProfile: PostedPositionProfile,
  PositionFormattedDescriptionIdentifier:
    PositionFormattedDescriptionIdentifier,
  UnpostedPositionProfile: UnpostedPositionProfile,
  PositionFormattedDescription: PositionFormattedDescription,
  RemunerationPackage: RemunerationPackage,
  RemunerationRange: RemunerationRange,
  RemunerationAmount: RemunerationAmount,
  PostingInstruction: PostingInstruction,
  ApplicationMethod: ApplicationMethod,
  PostedPositionProfile_AdvertisementProduct:
    PostedPositionProfile_AdvertisementProduct,
  SeekVideo: SeekVideo,
  PositionProfileEdge: PositionProfileEdge,
  PositionProfileConnection: PositionProfileConnection,
  PostPositionPayload: PostPositionPayload,
  PostPositionPayload_Success: PostPositionPayload_Success,
  PostPositionPayload_Conflict: PostPositionPayload_Conflict,
  PostPosition_PositionOpeningPayload: PostPosition_PositionOpeningPayload,
  PostPosition_PositionProfilePayload: PostPosition_PositionProfilePayload,
  PostPositionProfileForOpeningPayload: PostPositionProfileForOpeningPayload,
  PostPositionProfileForOpeningPayload_Success:
    PostPositionProfileForOpeningPayload_Success,
  PostPositionProfileForOpeningPayload_Conflict:
    PostPositionProfileForOpeningPayload_Conflict,
  PostPositionProfileForOpening_PositionProfilePayload:
    PostPositionProfileForOpening_PositionProfilePayload,
  PositionOpeningsFilterInput: PositionOpeningsFilterInput,
  UpdatePositionOpeningPersonContactsPayload:
    UpdatePositionOpeningPersonContactsPayload,
  UpdatePositionOpeningStatusPayload: UpdatePositionOpeningStatusPayload,
  UpdatePostedPositionProfilePayload: UpdatePostedPositionProfilePayload,
  UpdatePostedPositionProfile_PositionProfilePayload:
    UpdatePostedPositionProfile_PositionProfilePayload,
  UpdateUnpostedPositionProfilePayload: UpdateUnpostedPositionProfilePayload,
  ApplicationMethodInput: ApplicationMethodInput,
  ClosePostedPositionProfileInput: ClosePostedPositionProfileInput,
  ClosePostedPositionProfile_PositionProfileInput:
    ClosePostedPositionProfile_PositionProfileInput,
  CreatePostingInstructionInput: CreatePostingInstructionInput,
  PositionFormattedDescriptionInput: PositionFormattedDescriptionInput,
  CreatePositionOpeningInput: CreatePositionOpeningInput,
  CreatePositionOpening_PositionOpeningInput:
    CreatePositionOpening_PositionOpeningInput,
  UpdatePositionOpeningPersonContactsInput:
    UpdatePositionOpeningPersonContactsInput,
  UpdatePositionOpeningPersonContactsPositionOpeningInput:
    UpdatePositionOpeningPersonContactsPositionOpeningInput,
  UpdatePositionOpeningStatusInput: UpdatePositionOpeningStatusInput,
  UpdatePositionOpeningStatusPositionOpeningInput:
    UpdatePositionOpeningStatusPositionOpeningInput,
  DeletePositionOpeningInput: DeletePositionOpeningInput,
  DeletePositionOpening_PositionOpeningInput:
    DeletePositionOpening_PositionOpeningInput,
  PostingRequesterInput: PostingRequesterInput,
  PostPositionInput: PostPositionInput,
  PostPosition_PositionProfileInput: PostPosition_PositionProfileInput,
  PostPositionProfileForOpeningInput: PostPositionProfileForOpeningInput,
  PostPositionProfileForOpeningPositionProfileInput:
    PostPositionProfileForOpeningPositionProfileInput,
  RemunerationPackageInput: RemunerationPackageInput,
  RemunerationRangeInput: RemunerationRangeInput,
  RemunerationAmountInput: RemunerationAmountInput,
  SeekPositionAddressInput: SeekPositionAddressInput,
  SeekVideoInput: SeekVideoInput,
  CreateUnpostedPositionProfileForOpeningInput:
    CreateUnpostedPositionProfileForOpeningInput,
  CreateUnpostedPositionProfileForOpeningPositionProfileInput:
    CreateUnpostedPositionProfileForOpeningPositionProfileInput,
  UpdateUnpostedPositionProfileInput: UpdateUnpostedPositionProfileInput,
  UpdateUnpostedPositionProfile_PositionProfileInput:
    UpdateUnpostedPositionProfile_PositionProfileInput,
  DeleteUnpostedPositionProfileInput: DeleteUnpostedPositionProfileInput,
  DeleteUnpostedPositionProfile_PositionProfileInput:
    DeleteUnpostedPositionProfile_PositionProfileInput,
  UpdatePostedPositionProfileInput: UpdatePostedPositionProfileInput,
  UpdatePostedPositionProfile_PositionProfileInput:
    UpdatePostedPositionProfile_PositionProfileInput,
  UpdatePostingInstructionInput: UpdatePostingInstructionInput,
  PostedPositionProfilePreview_PositionProfileInput:
    PostedPositionProfilePreview_PositionProfileInput,
  PostedPositionProfilePreview: PostedPositionProfilePreview,
  PostedPositionProfilePreview_PostingInstructionInput:
    PostedPositionProfilePreview_PostingInstructionInput,
  PostedPositionProfilePreview_RemunerationAmountInput:
    PostedPositionProfilePreview_RemunerationAmountInput,
  PostedPositionProfilePreview_RemunerationPackageInput:
    PostedPositionProfilePreview_RemunerationPackageInput,
  PostedPositionProfilePreview_RemunerationRangeInput:
    PostedPositionProfilePreview_RemunerationRangeInput,
  ApplicationLibraryQuestionSuggestions_PositionProfileInput:
    ApplicationLibraryQuestionSuggestions_PositionProfileInput,
  ApplicationLibraryQuestionPreferenceSelection:
    ApplicationLibraryQuestionPreferenceSelection,
  ApplicationLibraryQuestionChoice: ApplicationLibraryQuestionChoice,
  ApplicationLibraryQuestion: ApplicationLibraryQuestion,
  ApplicationLibraryQuestionSuggestion: ApplicationLibraryQuestionSuggestion,
  ApplicationLibraryQuestionSearchResult:
    ApplicationLibraryQuestionSearchResult,
  ApplicationQuestionnaire: ApplicationQuestionnaire,
  ApplicationQuestionnaireComponent: ApplicationQuestionnaireComponent,
  ApplicationQuestion: ApplicationQuestion,
  ApplicationPrivacyConsent: ApplicationPrivacyConsent,
  ApplicationQuestionChoice: ApplicationQuestionChoice,
  ApplicationQuestionnaireComponentResponse:
    ApplicationQuestionnaireComponentResponse,
  ApplicationQuestionAnswer: ApplicationQuestionAnswer,
  ApplicationQuestionResponse: ApplicationQuestionResponse,
  ApplicationPrivacyConsentResponse: ApplicationPrivacyConsentResponse,
  ApplicationQuestionnaireSubmission: ApplicationQuestionnaireSubmission,
  ApplicationPrivacyConsentInput: ApplicationPrivacyConsentInput,
  ApplicationQuestionInput: ApplicationQuestionInput,
  ApplicationQuestionChoiceInput: ApplicationQuestionChoiceInput,
  ApplicationLibraryQuestionInput: ApplicationLibraryQuestionInput,
  ApplicationQuestionnaireComponentInput:
    ApplicationQuestionnaireComponentInput,
  CreateApplicationQuestionnaire_ApplicationQuestionnaireInput:
    CreateApplicationQuestionnaire_ApplicationQuestionnaireInput,
  CreateApplicationQuestionnaireInput: CreateApplicationQuestionnaireInput,
  CreateApplicationQuestionnairePayload: CreateApplicationQuestionnairePayload,
  webhook: webhook,
  'Query.seekAnzHirerAdvertisementCreationProducts.deprecation':
    Query_seekAnzHirerAdvertisementCreationProducts_deprecation,
  'Query.seekAnzHirerAdvertisementModificationProducts.deprecation':
    Query_seekAnzHirerAdvertisementModificationProducts_deprecation,
  'Query.seekAnzHirerAdvertisementModificationProductsAlt.deprecation':
    Query_seekAnzHirerAdvertisementModificationProductsAlt_deprecation,
  'Mutation._empty.deprecation': Mutation__empty_deprecation,
  'Location.children.deprecation': Location_children_deprecation,
  'SeekAttachmentRole.RESUME.deprecation':
    SeekAttachmentRole_RESUME_deprecation,
  'SeekAttachmentRole.COVER_LETTER.deprecation':
    SeekAttachmentRole_COVER_LETTER_deprecation,
  'SeekAttachmentRole.SELECTION_CRITERIA.deprecation':
    SeekAttachmentRole_SELECTION_CRITERIA_deprecation,
  'Attachment.seekRole.deprecation': Attachment_seekRole_deprecation,
  'Certification.issueDate.deprecation': Certification_issueDate_deprecation,
  'CandidateProfile.associatedPositionOpenings.deprecation':
    CandidateProfile_associatedPositionOpenings_deprecation,
  'PositionOpening.positionProfiles.deprecation':
    PositionOpening_positionProfiles_deprecation,
  'PostingInstruction.seekAnzAdvertisementType.deprecation':
    PostingInstruction_seekAnzAdvertisementType_deprecation,
  'ApplicationMethod.applicationEmail.deprecation':
    ApplicationMethod_applicationEmail_deprecation,
  'CreatePostingInstructionInput.seekAnzAdvertisementType.deprecation':
    CreatePostingInstructionInput_seekAnzAdvertisementType_deprecation,
  'UpdatePostingInstructionInput.seekAnzAdvertisementType.deprecation':
    UpdatePostingInstructionInput_seekAnzAdvertisementType_deprecation,
  'PostedPositionProfilePreview_PostingInstructionInput.seekAnzAdvertisementType.deprecation':
    PostedPositionProfilePreview_PostingInstructionInput_seekAnzAdvertisementType_deprecation,
  'Query.seekAnzHirerAdvertisementCreationProducts':
    Query_seekAnzHirerAdvertisementCreationProducts,
  'Query.seekAnzHirerAdvertisementModificationProducts':
    Query_seekAnzHirerAdvertisementModificationProducts,
  'Query.seekAnzHirerAdvertisementModificationProductsAlt':
    Query_seekAnzHirerAdvertisementModificationProductsAlt,
  'Query.advertisementProducts': Query_advertisementProducts,
  'Query.applyWithSeekButton': Query_applyWithSeekButton,
  'Query.version': Query_version,
  'Query.jobCategory': Query_jobCategory,
  'Query.jobCategories': Query_jobCategories,
  'Query.jobCategorySuggestions': Query_jobCategorySuggestions,
  'Query.location': Query_location,
  'Query.locationSuggestions': Query_locationSuggestions,
  'Query.nearestLocations': Query_nearestLocations,
  'Query.inferLocation': Query_inferLocation,
  'Query.currencies': Query_currencies,
  'Query.payTypes': Query_payTypes,
  'Query.advertisementBrandings': Query_advertisementBrandings,
  'Query.advertisementBranding': Query_advertisementBranding,
  'Query.candidateProfile': Query_candidateProfile,
  'Query.candidate': Query_candidate,
  'Query.candidateProcessHistoryItem': Query_candidateProcessHistoryItem,
  'Query.event': Query_event,
  'Query.events': Query_events,
  'Query.webhookAttemptsForEvent': Query_webhookAttemptsForEvent,
  'Query.webhookSubscription': Query_webhookSubscription,
  'Query.webhookSubscriptions': Query_webhookSubscriptions,
  'Query.webhookSubscriptionReplay': Query_webhookSubscriptionReplay,
  'Query.webhookRequestsForSubscription': Query_webhookRequestsForSubscription,
  'Query.webhookRequest': Query_webhookRequest,
  'Query.hiringOrganization': Query_hiringOrganization,
  'Query.hiringOrganizations': Query_hiringOrganizations,
  'Query.seekAnzAdvertiser': Query_seekAnzAdvertiser,
  'Query.self': Query_self,
  'Query.positionOpening': Query_positionOpening,
  'Query.positionProfile': Query_positionProfile,
  'Query.positionOpenings': Query_positionOpenings,
  'Query.postedPositionProfilePreview': Query_postedPositionProfilePreview,
  'Query.applicationLibraryQuestionSuggestions':
    Query_applicationLibraryQuestionSuggestions,
  'Query.applicationLibraryQuestionSearch':
    Query_applicationLibraryQuestionSearch,
  'Query.applicationQuestionnaire': Query_applicationQuestionnaire,
  'SeekAnzAdProduct.advertisementTypeCode':
    SeekAnzAdProduct_advertisementTypeCode,
  'SeekAnzAdProduct.name': SeekAnzAdProduct_name,
  'SeekAnzAdProduct.description': SeekAnzAdProduct_description,
  'SeekAnzAdProduct.price': SeekAnzAdProduct_price,
  'SeekAnzAdProduct.enabledIndicator': SeekAnzAdProduct_enabledIndicator,
  'SeekAnzAdProduct.checkoutEstimate': SeekAnzAdProduct_checkoutEstimate,
  'SeekAnzAdProduct.messages': SeekAnzAdProduct_messages,
  'SeekAnzAdProduct.features': SeekAnzAdProduct_features,
  'SeekAnzAdProductFeatures.brandingIndicator':
    SeekAnzAdProductFeatures_brandingIndicator,
  'SeekAnzAdProductFeatures.searchBulletPointsIndicator':
    SeekAnzAdProductFeatures_searchBulletPointsIndicator,
  'SeekAnzAdProductPrice.amountExcludingTax':
    SeekAnzAdProductPrice_amountExcludingTax,
  'SeekAnzAdProductPrice.summary': SeekAnzAdProductPrice_summary,
  'SeekAnzAdProductPrice.visibleForHirerIndicator':
    SeekAnzAdProductPrice_visibleForHirerIndicator,
  'SeekAnzAdProductPrice.taxedIndicator': SeekAnzAdProductPrice_taxedIndicator,
  'SeekAnzAdProductCheckoutEstimate.paymentDueExcludingTax':
    SeekAnzAdProductCheckoutEstimate_paymentDueExcludingTax,
  'SeekAnzAdProductCheckoutEstimate.summary':
    SeekAnzAdProductCheckoutEstimate_summary,
  'SeekAnzAdProductCheckoutEstimate.contractConsumption':
    SeekAnzAdProductCheckoutEstimate_contractConsumption,
  'SeekAnzAdProductContractConsumption.summary':
    SeekAnzAdProductContractConsumption_summary,
  'SeekAnzAdProductContractConsumption.typeCode':
    SeekAnzAdProductContractConsumption_typeCode,
  'SeekAnzAdProductAdvertisementInput.typeCode':
    SeekAnzAdProductAdvertisementInput_typeCode,
  'SeekAnzAdProductAdvertisementInput.id':
    SeekAnzAdProductAdvertisementInput_id,
  'SeekAnzAdProductAdvertisementInput.hirerJobReference':
    SeekAnzAdProductAdvertisementInput_hirerJobReference,
  'SeekAnzAdProductAdvertisementInput.positionTitle':
    SeekAnzAdProductAdvertisementInput_positionTitle,
  'SeekAnzAdProductAdvertisementInput.jobCategoryId':
    SeekAnzAdProductAdvertisementInput_jobCategoryId,
  'SeekAnzAdProductAdvertisementInput.positionLocationId':
    SeekAnzAdProductAdvertisementInput_positionLocationId,
  'SeekAnzAdProductAdvertisementDraftInput.typeCode':
    SeekAnzAdProductAdvertisementDraftInput_typeCode,
  'SeekAnzAdProductAdvertisementDraftInput.hirerJobReference':
    SeekAnzAdProductAdvertisementDraftInput_hirerJobReference,
  'SeekAnzAdProductAdvertisementDraftInput.positionTitle':
    SeekAnzAdProductAdvertisementDraftInput_positionTitle,
  'SeekAnzAdProductAdvertisementDraftInput.jobCategoryId':
    SeekAnzAdProductAdvertisementDraftInput_jobCategoryId,
  'SeekAnzAdProductAdvertisementDraftInput.positionLocationId':
    SeekAnzAdProductAdvertisementDraftInput_positionLocationId,
  'SeekAnzAdProductMessage.typeCode': SeekAnzAdProductMessage_typeCode,
  'SeekAnzAdProductMessage.severityCode': SeekAnzAdProductMessage_severityCode,
  'SeekAnzAdProductMessage.visibilityCode':
    SeekAnzAdProductMessage_visibilityCode,
  'SeekAnzAdProductMessage.content': SeekAnzAdProductMessage_content,
  'CurrencyMinorUnit.value': CurrencyMinorUnit_value,
  'CurrencyMinorUnit.currency': CurrencyMinorUnit_currency,
  'AdvertisementProducts.products': AdvertisementProducts_products,
  'AdvertisementProducts.information': AdvertisementProducts_information,
  'AdvertisementProduct.id': AdvertisementProduct_id,
  'AdvertisementProduct.label': AdvertisementProduct_label,
  'AdvertisementProduct.selected': AdvertisementProduct_selected,
  'AdvertisementProduct.description': AdvertisementProduct_description,
  'AdvertisementProduct.payment': AdvertisementProduct_payment,
  'AdvertisementProduct.price': AdvertisementProduct_price,
  'AdvertisementProduct.features': AdvertisementProduct_features,
  'AdvertisementProduct.sellingPoints': AdvertisementProduct_sellingPoints,
  'AdvertisementProductPriceDetails_Tax.code':
    AdvertisementProductPriceDetails_Tax_code,
  'AdvertisementProductPriceDetails_Tax.value':
    AdvertisementProductPriceDetails_Tax_value,
  'AdvertisementProductPriceDetails.summary':
    AdvertisementProductPriceDetails_summary,
  'AdvertisementProductPriceDetails.value':
    AdvertisementProductPriceDetails_value,
  'AdvertisementProductPriceDetails.currency':
    AdvertisementProductPriceDetails_currency,
  'AdvertisementProductPriceDetails.tax': AdvertisementProductPriceDetails_tax,
  'AdvertisementProductPaymentDetails.summary':
    AdvertisementProductPaymentDetails_summary,
  'AdvertisementProductPaymentDetails.summaryHtml':
    AdvertisementProductPaymentDetails_summaryHtml,
  'AdvertisementProductFeatures.branding':
    AdvertisementProductFeatures_branding,
  'AdvertisementProductFeatures.searchBulletPoints':
    AdvertisementProductFeatures_searchBulletPoints,
  'AdvertisementProductSellingPoint.text':
    AdvertisementProductSellingPoint_text,
  'AdvertisementProductFeatures_Branding.logoIndicator':
    AdvertisementProductFeatures_Branding_logoIndicator,
  'AdvertisementProductFeatures_Branding.coverImageIndicator':
    AdvertisementProductFeatures_Branding_coverImageIndicator,
  'AdvertisementProductFeatures_SearchBulletPoints.limit':
    AdvertisementProductFeatures_SearchBulletPoints_limit,
  'AdvertisementProducts_PositionProfileInput.jobCategories':
    AdvertisementProducts_PositionProfileInput_jobCategories,
  'AdvertisementProducts_PositionProfileInput.offeredRemunerationPackage':
    AdvertisementProducts_PositionProfileInput_offeredRemunerationPackage,
  'AdvertisementProducts_PositionProfileInput.positionLocation':
    AdvertisementProducts_PositionProfileInput_positionLocation,
  'AdvertisementProducts_PositionProfileInput.positionOrganizations':
    AdvertisementProducts_PositionProfileInput_positionOrganizations,
  'AdvertisementProducts_PositionProfileInput.positionTitle':
    AdvertisementProducts_PositionProfileInput_positionTitle,
  'AdvertisementProducts_PositionProfileInput.profileId':
    AdvertisementProducts_PositionProfileInput_profileId,
  'AdvertisementProducts_PositionProfileInput.seekAnzWorkTypeCode':
    AdvertisementProducts_PositionProfileInput_seekAnzWorkTypeCode,
  'ApplyWithSeekButtonInput.redirectUri': ApplyWithSeekButtonInput_redirectUri,
  'ApplyWithSeekButtonInput.hirerId': ApplyWithSeekButtonInput_hirerId,
  'ApplyWithSeekButtonInput.token': ApplyWithSeekButtonInput_token,
  'ApplyWithSeekButtonInput.applicationUri':
    ApplyWithSeekButtonInput_applicationUri,
  'ApplyWithSeekButtonInput.seekHirerJobReference':
    ApplyWithSeekButtonInput_seekHirerJobReference,
  'ApplyWithSeekButtonPayload.url': ApplyWithSeekButtonPayload_url,
  'ApplyWithSeekButtonPayload.buttonLabel':
    ApplyWithSeekButtonPayload_buttonLabel,
  'ApplyWithSeekButtonPayload.images': ApplyWithSeekButtonPayload_images,
  'ApplyWithSeekButtonImages.accent': ApplyWithSeekButtonImages_accent,
  'ApplyWithSeekButtonImages.dark': ApplyWithSeekButtonImages_dark,
  'ApplyWithSeekButtonImages.light': ApplyWithSeekButtonImages_light,
  'Mutation._empty': Mutation__empty,
  'Mutation.sendApplicationSignal': Mutation_sendApplicationSignal,
  'Mutation.deleteUploadedCandidate': Mutation_deleteUploadedCandidate,
  'Mutation.uploadCandidate': Mutation_uploadCandidate,
  'Mutation.updateUploadedCandidatePerson':
    Mutation_updateUploadedCandidatePerson,
  'Mutation.updateUploadedCandidateProfileActions':
    Mutation_updateUploadedCandidateProfileActions,
  'Mutation.updateUploadedCandidateProfileDates':
    Mutation_updateUploadedCandidateProfileDates,
  'Mutation.updateUploadedCandidateProfilePositionPreferences':
    Mutation_updateUploadedCandidateProfilePositionPreferences,
  'Mutation.createCandidateProcessHistoryItem':
    Mutation_createCandidateProcessHistoryItem,
  'Mutation.deleteCandidateProcessHistoryItem':
    Mutation_deleteCandidateProcessHistoryItem,
  'Mutation.updateCandidateProcessHistoryItem':
    Mutation_updateCandidateProcessHistoryItem,
  'Mutation.createWebhookSubscription': Mutation_createWebhookSubscription,
  'Mutation.updateWebhookSubscriptionDeliveryConfiguration':
    Mutation_updateWebhookSubscriptionDeliveryConfiguration,
  'Mutation.updateWebhookSubscriptionSigningConfiguration':
    Mutation_updateWebhookSubscriptionSigningConfiguration,
  'Mutation.deleteWebhookSubscription': Mutation_deleteWebhookSubscription,
  'Mutation.replayWebhookSubscription': Mutation_replayWebhookSubscription,
  'Mutation.createPositionOpening': Mutation_createPositionOpening,
  'Mutation.updatePositionOpeningPersonContacts':
    Mutation_updatePositionOpeningPersonContacts,
  'Mutation.updatePositionOpeningStatus': Mutation_updatePositionOpeningStatus,
  'Mutation.deletePositionOpening': Mutation_deletePositionOpening,
  'Mutation.postPositionProfileForOpening':
    Mutation_postPositionProfileForOpening,
  'Mutation.updatePostedPositionProfile': Mutation_updatePostedPositionProfile,
  'Mutation.closePostedPositionProfile': Mutation_closePostedPositionProfile,
  'Mutation.postPosition': Mutation_postPosition,
  'Mutation.createUnpostedPositionProfileForOpening':
    Mutation_createUnpostedPositionProfileForOpening,
  'Mutation.updateUnpostedPositionProfile':
    Mutation_updateUnpostedPositionProfile,
  'Mutation.deleteUnpostedPositionProfile':
    Mutation_deleteUnpostedPositionProfile,
  'Mutation.createApplicationQuestionnaire':
    Mutation_createApplicationQuestionnaire,
  'AddressComponent.type': AddressComponent_type,
  'AddressComponent.value': AddressComponent_value,
  'AddressComponentInput.type': AddressComponentInput_type,
  'AddressComponentInput.value': AddressComponentInput_value,
  'Address.line': Address_line,
  'Address.extendedLines': Address_extendedLines,
  'Address.countryCode': Address_countryCode,
  'Address.countrySubDivisions': Address_countrySubDivisions,
  'Address.city': Address_city,
  'Address.postalCode': Address_postalCode,
  'Address.geoLocation': Address_geoLocation,
  'Address.formattedAddress': Address_formattedAddress,
  'AddressInput.line': AddressInput_line,
  'AddressInput.extendedLines': AddressInput_extendedLines,
  'AddressInput.countryCode': AddressInput_countryCode,
  'AddressInput.countrySubDivisions': AddressInput_countrySubDivisions,
  'AddressInput.city': AddressInput_city,
  'AddressInput.postalCode': AddressInput_postalCode,
  'AddressInput.geoLocation': AddressInput_geoLocation,
  'AddressInput.formattedAddress': AddressInput_formattedAddress,
  'WebUrl.url': WebUrl_url,
  'Email.address': Email_address,
  'EmailInput.address': EmailInput_address,
  'Phone.formattedNumber': Phone_formattedNumber,
  'PhoneInput.formattedNumber': PhoneInput_formattedNumber,
  'Communication.phone': Communication_phone,
  'Communication.email': Communication_email,
  'Communication.address': Communication_address,
  'Communication.seekDoNotContactIndicator':
    Communication_seekDoNotContactIndicator,
  'CommunicationInput.phone': CommunicationInput_phone,
  'CommunicationInput.email': CommunicationInput_email,
  'CommunicationInput.address': CommunicationInput_address,
  'CommunicationInput.seekDoNotContactIndicator':
    CommunicationInput_seekDoNotContactIndicator,
  'WebUrlInput.url': WebUrlInput_url,
  'EffectiveTimePeriod.validTo': EffectiveTimePeriod_validTo,
  'ObjectIdentifier.value': ObjectIdentifier_value,
  'JobCategory.id': JobCategory_id,
  'JobCategory.parent': JobCategory_parent,
  'JobCategory.children': JobCategory_children,
  'JobCategory.name': JobCategory_name,
  'JobCategorySuggestionChoice.jobCategory':
    JobCategorySuggestionChoice_jobCategory,
  'JobCategorySuggestionChoice.confidence':
    JobCategorySuggestionChoice_confidence,
  'JobCategorySuggestionPositionProfileInput.positionTitle':
    JobCategorySuggestionPositionProfileInput_positionTitle,
  'JobCategorySuggestionPositionProfileInput.positionLocation':
    JobCategorySuggestionPositionProfileInput_positionLocation,
  'JobCategorySuggestionPositionProfileInput.positionOrganizations':
    JobCategorySuggestionPositionProfileInput_positionOrganizations,
  'JobCategorySuggestionPositionProfileInput.positionFormattedDescriptions':
    JobCategorySuggestionPositionProfileInput_positionFormattedDescriptions,
  'JobCategories_PositionProfileInput.positionLocation':
    JobCategories_PositionProfileInput_positionLocation,
  'Location.id': Location_id,
  'Location.parent': Location_parent,
  'Location.children': Location_children,
  'Location.name': Location_name,
  'Location.contextualName': Location_contextualName,
  'Location.countryCode': Location_countryCode,
  'Location.currencies': Location_currencies,
  'LocationSuggestion.location': LocationSuggestion_location,
  'GeoLocation.latitude': GeoLocation_latitude,
  'GeoLocation.longitude': GeoLocation_longitude,
  'GeoLocationInput.latitude': GeoLocationInput_latitude,
  'GeoLocationInput.longitude': GeoLocationInput_longitude,
  'PageInfo.hasPreviousPage': PageInfo_hasPreviousPage,
  'PageInfo.hasNextPage': PageInfo_hasNextPage,
  'PageInfo.startCursor': PageInfo_startCursor,
  'PageInfo.endCursor': PageInfo_endCursor,
  'PersonName.formattedName': PersonName_formattedName,
  'PersonName.given': PersonName_given,
  'PersonName.family': PersonName_family,
  'SpecifiedPerson.name': SpecifiedPerson_name,
  'SpecifiedPerson.communication': SpecifiedPerson_communication,
  'SpecifiedPerson.roleCode': SpecifiedPerson_roleCode,
  'PersonNameInput.formattedName': PersonNameInput_formattedName,
  'PersonNameInput.given': PersonNameInput_given,
  'PersonNameInput.family': PersonNameInput_family,
  'SpecifiedPersonInput.name': SpecifiedPersonInput_name,
  'SpecifiedPersonInput.communication': SpecifiedPersonInput_communication,
  'SpecifiedPersonInput.roleCode': SpecifiedPersonInput_roleCode,
  'Currency.code': Currency_code,
  'PayType.basisCode': PayType_basisCode,
  'PayType.intervalCode': PayType_intervalCode,
  'PayType.label': PayType_label,
  'SendApplicationSignalInput.typeCode': SendApplicationSignalInput_typeCode,
  'SendApplicationSignalInput.token': SendApplicationSignalInput_token,
  'SendApplicationSignalInput.schemeId': SendApplicationSignalInput_schemeId,
  'AdvertisementBranding.id': AdvertisementBranding_id,
  'AdvertisementBranding.name': AdvertisementBranding_name,
  'AdvertisementBranding.images': AdvertisementBranding_images,
  'AdvertisementBranding.hirer': AdvertisementBranding_hirer,
  'AdvertisementBrandingEdge.cursor': AdvertisementBrandingEdge_cursor,
  'AdvertisementBrandingEdge.node': AdvertisementBrandingEdge_node,
  'AdvertisementBrandingImage.typeCode': AdvertisementBrandingImage_typeCode,
  'AdvertisementBrandingImage.url': AdvertisementBrandingImage_url,
  'AdvertisementBrandingsConnection.edges':
    AdvertisementBrandingsConnection_edges,
  'AdvertisementBrandingsConnection.pageInfo':
    AdvertisementBrandingsConnection_pageInfo,
  'AdvertisementBrandingsConnection.brandManagementUrl':
    AdvertisementBrandingsConnection_brandManagementUrl,
  'Candidate.documentId': Candidate_documentId,
  'Candidate.seekPrimaryEmailAddress': Candidate_seekPrimaryEmailAddress,
  'Candidate.distributionGuidelines': Candidate_distributionGuidelines,
  'Candidate.person': Candidate_person,
  'Candidate.profiles': Candidate_profiles,
  'CandidatePerson.name': CandidatePerson_name,
  'CandidatePerson.communication': CandidatePerson_communication,
  'CandidatePersonInput.name': CandidatePersonInput_name,
  'CandidatePersonInput.communication': CandidatePersonInput_communication,
  'SeekAttachmentRole.RESUME': SeekAttachmentRole_RESUME,
  'SeekAttachmentRole.COVER_LETTER': SeekAttachmentRole_COVER_LETTER,
  'SeekAttachmentRole.SELECTION_CRITERIA':
    SeekAttachmentRole_SELECTION_CRITERIA,
  'AssociatedPositionOpening.positionOpeningId':
    AssociatedPositionOpening_positionOpeningId,
  'AssociatedPositionOpening.positionOpening':
    AssociatedPositionOpening_positionOpening,
  'AssociatedPositionOpening.positionUri':
    AssociatedPositionOpening_positionUri,
  'AssociatedPositionOpening.candidateAppliedIndicator':
    AssociatedPositionOpening_candidateAppliedIndicator,
  'Attachment.id': Attachment_id,
  'Attachment.url': Attachment_url,
  'Attachment.descriptions': Attachment_descriptions,
  'Attachment.seekRoleCode': Attachment_seekRoleCode,
  'Attachment.seekRole': Attachment_seekRole,
  'PositionHistory.start': PositionHistory_start,
  'PositionHistory.end': PositionHistory_end,
  'PositionHistory.current': PositionHistory_current,
  'PositionHistory.title': PositionHistory_title,
  'PositionHistory.descriptions': PositionHistory_descriptions,
  'EmployerHistory.organization': EmployerHistory_organization,
  'EmployerHistory.positionHistories': EmployerHistory_positionHistories,
  'EducationDegree.name': EducationDegree_name,
  'EducationDegree.degreeGrantedStatus': EducationDegree_degreeGrantedStatus,
  'EducationDegree.date': EducationDegree_date,
  'EducationAttendance.institution': EducationAttendance_institution,
  'EducationAttendance.educationDegrees': EducationAttendance_educationDegrees,
  'EducationAttendance.descriptions': EducationAttendance_descriptions,
  'PersonCompetency.competencyName': PersonCompetency_competencyName,
  'Certification.name': Certification_name,
  'Certification.effectiveTimePeriod': Certification_effectiveTimePeriod,
  'Certification.issued': Certification_issued,
  'Certification.issueDate': Certification_issueDate,
  'Certification.issuingAuthority': Certification_issuingAuthority,
  'Certification.descriptions': Certification_descriptions,
  'CandidateSource.name': CandidateSource_name,
  'CandidateSource.type': CandidateSource_type,
  'CandidateProfile.candidate': CandidateProfile_candidate,
  'CandidateProfile.profileId': CandidateProfile_profileId,
  'CandidateProfile.createDateTime': CandidateProfile_createDateTime,
  'CandidateProfile.associatedPositionOpenings':
    CandidateProfile_associatedPositionOpenings,
  'CandidateProfile.associatedPositionProfile':
    CandidateProfile_associatedPositionProfile,
  'CandidateProfile.attachments': CandidateProfile_attachments,
  'CandidateProfile.employment': CandidateProfile_employment,
  'CandidateProfile.education': CandidateProfile_education,
  'CandidateProfile.qualifications': CandidateProfile_qualifications,
  'CandidateProfile.certifications': CandidateProfile_certifications,
  'CandidateProfile.candidateSources': CandidateProfile_candidateSources,
  'CandidateProfile.positionPreferences': CandidateProfile_positionPreferences,
  'CandidateProfile.updateDateTime': CandidateProfile_updateDateTime,
  'CandidateProfile.seekActions': CandidateProfile_seekActions,
  'CandidateProfile.seekProcessHistory': CandidateProfile_seekProcessHistory,
  'CandidateProfile.seekQuestionnaireSubmission':
    CandidateProfile_seekQuestionnaireSubmission,
  'CandidateProfile.personAvailability': CandidateProfile_personAvailability,
  'CandidateProfile.executiveSummary': CandidateProfile_executiveSummary,
  'PersonAvailability.noticePeriodMeasure':
    PersonAvailability_noticePeriodMeasure,
  'PersonAvailability.immediateStartIndicator':
    PersonAvailability_immediateStartIndicator,
  'PersonAvailability_NoticePeriodMeasure.value':
    PersonAvailability_NoticePeriodMeasure_value,
  'PersonAvailability_NoticePeriodMeasure.unitCode':
    PersonAvailability_NoticePeriodMeasure_unitCode,
  'CreateCandidateProcessHistoryItem_CandidateProfileInput.profileId':
    CreateCandidateProcessHistoryItem_CandidateProfileInput_profileId,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.idempotencyId':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_idempotencyId,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.actionDate':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_actionDate,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.action':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_action,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.status':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_status,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.positionProfile':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_positionProfile,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.seekSource':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_seekSource,
  'CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.primaryParties':
    CreateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_primaryParties,
  'CreateCandidateProcessHistoryItemInput.candidateProfile':
    CreateCandidateProcessHistoryItemInput_candidateProfile,
  'CreateCandidateProcessHistoryItemInput.candidateProcessHistoryItem':
    CreateCandidateProcessHistoryItemInput_candidateProcessHistoryItem,
  'CreateCandidateProcessHistoryItemPayload_Conflict.conflictingCandidateProcessHistoryItem':
    CreateCandidateProcessHistoryItemPayload_Conflict_conflictingCandidateProcessHistoryItem,
  'CreateCandidateProcessHistoryItemPayload_Conflict.candidateProfile':
    CreateCandidateProcessHistoryItemPayload_Conflict_candidateProfile,
  'CreateCandidateProcessHistoryItemPayload_Success.candidateProcessHistoryItem':
    CreateCandidateProcessHistoryItemPayload_Success_candidateProcessHistoryItem,
  'CreateCandidateProcessHistoryItemPayload_Success.candidateProfile':
    CreateCandidateProcessHistoryItemPayload_Success_candidateProfile,
  'DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.id':
    DeleteCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_id,
  'DeleteCandidateProcessHistoryItemInput.candidateProcessHistoryItem':
    DeleteCandidateProcessHistoryItemInput_candidateProcessHistoryItem,
  'DeleteCandidateProcessHistoryItemPayload.candidateProcessHistoryItem':
    DeleteCandidateProcessHistoryItemPayload_candidateProcessHistoryItem,
  'DeleteUploadedCandidate_CandidateInput.documentId':
    DeleteUploadedCandidate_CandidateInput_documentId,
  'DeleteUploadedCandidateInput.candidate':
    DeleteUploadedCandidateInput_candidate,
  'DeleteUploadedCandidatePayload.candidate':
    DeleteUploadedCandidatePayload_candidate,
  'DistributionGuidelines.seekProductCodes':
    DistributionGuidelines_seekProductCodes,
  'DistributionGuidelinesInput.seekProductCodes':
    DistributionGuidelinesInput_seekProductCodes,
  'PositionPreference.locations': PositionPreference_locations,
  'PositionPreference.seekAnzWorkTypeCodes':
    PositionPreference_seekAnzWorkTypeCodes,
  'PositionPreference.seekSalaryExpectations':
    PositionPreference_seekSalaryExpectations,
  'SeekSalaryExpectation.countryCode': SeekSalaryExpectation_countryCode,
  'SeekSalaryExpectation.amount': SeekSalaryExpectation_amount,
  'SeekSalaryExpectation.intervalCode': SeekSalaryExpectation_intervalCode,
  'PositionPreferenceInput.locations': PositionPreferenceInput_locations,
  'PreferredLocation.referenceLocation': PreferredLocation_referenceLocation,
  'PreferredLocationInput.referenceLocation':
    PreferredLocationInput_referenceLocation,
  'CandidateProcessAction.code': CandidateProcessAction_code,
  'CandidateProcessAction.description': CandidateProcessAction_description,
  'CandidateProcessAction.name': CandidateProcessAction_name,
  'CandidateProcessAction.seekUrl': CandidateProcessAction_seekUrl,
  'CandidateProcessActionInput.code': CandidateProcessActionInput_code,
  'CandidateProcessActionInput.description':
    CandidateProcessActionInput_description,
  'CandidateProcessActionInput.name': CandidateProcessActionInput_name,
  'CandidateProcessActionInput.seekUrl': CandidateProcessActionInput_seekUrl,
  'CandidateProcessStatus.code': CandidateProcessStatus_code,
  'CandidateProcessStatusInput.code': CandidateProcessStatusInput_code,
  'CandidateProcessParty.person': CandidateProcessParty_person,
  'CandidateProcessParty.organization': CandidateProcessParty_organization,
  'CandidateProcessPartyInput.person': CandidateProcessPartyInput_person,
  'CandidateProcessPartyInput.organization':
    CandidateProcessPartyInput_organization,
  'SeekProcessHistoryItemSource.name': SeekProcessHistoryItemSource_name,
  'SeekProcessHistoryItemSourceInput.name':
    SeekProcessHistoryItemSourceInput_name,
  'CandidateProcessHistoryItem.id': CandidateProcessHistoryItem_id,
  'CandidateProcessHistoryItem.candidateProfile':
    CandidateProcessHistoryItem_candidateProfile,
  'CandidateProcessHistoryItem.actionDate':
    CandidateProcessHistoryItem_actionDate,
  'CandidateProcessHistoryItem.action': CandidateProcessHistoryItem_action,
  'CandidateProcessHistoryItem.status': CandidateProcessHistoryItem_status,
  'CandidateProcessHistoryItem.positionProfile':
    CandidateProcessHistoryItem_positionProfile,
  'CandidateProcessHistoryItem.seekSource':
    CandidateProcessHistoryItem_seekSource,
  'CandidateProcessHistoryItem.primaryParties':
    CandidateProcessHistoryItem_primaryParties,
  'CandidateProcessHistoryItemInput.actionDate':
    CandidateProcessHistoryItemInput_actionDate,
  'CandidateProcessHistoryItemInput.action':
    CandidateProcessHistoryItemInput_action,
  'CandidateProcessHistoryItemInput.status':
    CandidateProcessHistoryItemInput_status,
  'CandidateProcessHistoryItemInput.positionProfile':
    CandidateProcessHistoryItemInput_positionProfile,
  'CandidateProcessHistoryItemInput.seekSource':
    CandidateProcessHistoryItemInput_seekSource,
  'CandidateProcessHistoryItemInput.primaryParties':
    CandidateProcessHistoryItemInput_primaryParties,
  'CandidateProcessHistoryItem_PositionProfileInput.profileId':
    CandidateProcessHistoryItem_PositionProfileInput_profileId,
  'CandidateProcessHistoryItemEdge.cursor':
    CandidateProcessHistoryItemEdge_cursor,
  'CandidateProcessHistoryItemEdge.node': CandidateProcessHistoryItemEdge_node,
  'CandidateProcessHistoryItemConnection.edges':
    CandidateProcessHistoryItemConnection_edges,
  'CandidateProcessHistoryItemConnection.pageInfo':
    CandidateProcessHistoryItemConnection_pageInfo,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.id':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_id,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.actionDate':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_actionDate,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.action':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_action,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.status':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_status,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.positionProfile':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_positionProfile,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.seekSource':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_seekSource,
  'UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput.primaryParties':
    UpdateCandidateProcessHistoryItem_CandidateProcessHistoryItemInput_primaryParties,
  'UpdateCandidateProcessHistoryItemInput.candidateProcessHistoryItem':
    UpdateCandidateProcessHistoryItemInput_candidateProcessHistoryItem,
  'UpdateCandidateProcessHistoryItemPayload.candidateProcessHistoryItem':
    UpdateCandidateProcessHistoryItemPayload_candidateProcessHistoryItem,
  'UpdateUploadedCandidatePerson_CandidateInput.documentId':
    UpdateUploadedCandidatePerson_CandidateInput_documentId,
  'UpdateUploadedCandidatePerson_CandidateInput.person':
    UpdateUploadedCandidatePerson_CandidateInput_person,
  'UpdateUploadedCandidatePerson_CandidateInput.seekPrimaryEmailAddress':
    UpdateUploadedCandidatePerson_CandidateInput_seekPrimaryEmailAddress,
  'UpdateUploadedCandidatePersonInput.candidate':
    UpdateUploadedCandidatePersonInput_candidate,
  'UpdateUploadedCandidatePersonPayload_Conflict.conflictingCandidate':
    UpdateUploadedCandidatePersonPayload_Conflict_conflictingCandidate,
  'UpdateUploadedCandidatePersonPayload_Success.candidate':
    UpdateUploadedCandidatePersonPayload_Success_candidate,
  'UpdateUploadedCandidateProfileActions_CandidateProfileInput.profileId':
    UpdateUploadedCandidateProfileActions_CandidateProfileInput_profileId,
  'UpdateUploadedCandidateProfileActions_CandidateProfileInput.seekActions':
    UpdateUploadedCandidateProfileActions_CandidateProfileInput_seekActions,
  'UpdateUploadedCandidateProfileActionsInput.candidateProfile':
    UpdateUploadedCandidateProfileActionsInput_candidateProfile,
  'UpdateUploadedCandidateProfileActionsPayload.candidateProfile':
    UpdateUploadedCandidateProfileActionsPayload_candidateProfile,
  'UpdateUploadedCandidateProfileDates_CandidateProfileInput.profileId':
    UpdateUploadedCandidateProfileDates_CandidateProfileInput_profileId,
  'UpdateUploadedCandidateProfileDates_CandidateProfileInput.createDateTime':
    UpdateUploadedCandidateProfileDates_CandidateProfileInput_createDateTime,
  'UpdateUploadedCandidateProfileDates_CandidateProfileInput.updateDateTime':
    UpdateUploadedCandidateProfileDates_CandidateProfileInput_updateDateTime,
  'UpdateUploadedCandidateProfileDatesInput.candidateProfile':
    UpdateUploadedCandidateProfileDatesInput_candidateProfile,
  'UpdateUploadedCandidateProfileDatesPayload.candidateProfile':
    UpdateUploadedCandidateProfileDatesPayload_candidateProfile,
  'UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput.profileId':
    UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput_profileId,
  'UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput.positionPreferences':
    UpdateUploadedCandidateProfilePositionPreferences_CandidateProfileInput_positionPreferences,
  'UpdateUploadedCandidateProfilePositionPreferencesInput.candidateProfile':
    UpdateUploadedCandidateProfilePositionPreferencesInput_candidateProfile,
  'UpdateUploadedCandidateProfilePositionPreferencesPayload.candidateProfile':
    UpdateUploadedCandidateProfilePositionPreferencesPayload_candidateProfile,
  'UploadCandidate_CandidateInput.seekPrimaryEmailAddress':
    UploadCandidate_CandidateInput_seekPrimaryEmailAddress,
  'UploadCandidate_CandidateInput.distributionGuidelines':
    UploadCandidate_CandidateInput_distributionGuidelines,
  'UploadCandidate_CandidateInput.person':
    UploadCandidate_CandidateInput_person,
  'UploadCandidate_CandidateProfileInput.createDateTime':
    UploadCandidate_CandidateProfileInput_createDateTime,
  'UploadCandidate_CandidateProfileInput.updateDateTime':
    UploadCandidate_CandidateProfileInput_updateDateTime,
  'UploadCandidate_CandidateProfileInput.seekProcessHistory':
    UploadCandidate_CandidateProfileInput_seekProcessHistory,
  'UploadCandidate_CandidateProfileInput.positionPreferences':
    UploadCandidate_CandidateProfileInput_positionPreferences,
  'UploadCandidate_CandidateProfileInput.seekActions':
    UploadCandidate_CandidateProfileInput_seekActions,
  'UploadCandidate_HiringOrganizationInput.id':
    UploadCandidate_HiringOrganizationInput_id,
  'UploadCandidateInput.candidate': UploadCandidateInput_candidate,
  'UploadCandidateInput.candidateProfile':
    UploadCandidateInput_candidateProfile,
  'UploadCandidateInput.hiringOrganization':
    UploadCandidateInput_hiringOrganization,
  'UploadCandidatePayload_Conflict.conflictingCandidate':
    UploadCandidatePayload_Conflict_conflictingCandidate,
  'UploadCandidatePayload_Conflict.hiringOrganization':
    UploadCandidatePayload_Conflict_hiringOrganization,
  'UploadCandidatePayload_Success.candidate':
    UploadCandidatePayload_Success_candidate,
  'UploadCandidatePayload_Success.candidateProcessHistoryItems':
    UploadCandidatePayload_Success_candidateProcessHistoryItems,
  'UploadCandidatePayload_Success.hiringOrganization':
    UploadCandidatePayload_Success_hiringOrganization,
  'Event.id': Event_id,
  'Event.schemeId': Event_schemeId,
  'Event.typeCode': Event_typeCode,
  'Event.createDateTime': Event_createDateTime,
  'Event.webhookAttempts': Event_webhookAttempts,
  'CandidateApplicationCreatedEvent.id': CandidateApplicationCreatedEvent_id,
  'CandidateApplicationCreatedEvent.schemeId':
    CandidateApplicationCreatedEvent_schemeId,
  'CandidateApplicationCreatedEvent.typeCode':
    CandidateApplicationCreatedEvent_typeCode,
  'CandidateApplicationCreatedEvent.createDateTime':
    CandidateApplicationCreatedEvent_createDateTime,
  'CandidateApplicationCreatedEvent.candidateApplicationProfileId':
    CandidateApplicationCreatedEvent_candidateApplicationProfileId,
  'CandidateApplicationCreatedEvent.candidateId':
    CandidateApplicationCreatedEvent_candidateId,
  'CandidateApplicationCreatedEvent.candidate':
    CandidateApplicationCreatedEvent_candidate,
  'CandidateApplicationCreatedEvent.candidateApplicationProfile':
    CandidateApplicationCreatedEvent_candidateApplicationProfile,
  'CandidateApplicationCreatedEvent.webhookAttempts':
    CandidateApplicationCreatedEvent_webhookAttempts,
  'PositionProfileClosedEvent.id': PositionProfileClosedEvent_id,
  'PositionProfileClosedEvent.schemeId': PositionProfileClosedEvent_schemeId,
  'PositionProfileClosedEvent.typeCode': PositionProfileClosedEvent_typeCode,
  'PositionProfileClosedEvent.createDateTime':
    PositionProfileClosedEvent_createDateTime,
  'PositionProfileClosedEvent.positionProfileId':
    PositionProfileClosedEvent_positionProfileId,
  'PositionProfileClosedEvent.positionProfile':
    PositionProfileClosedEvent_positionProfile,
  'PositionProfileClosedEvent.webhookAttempts':
    PositionProfileClosedEvent_webhookAttempts,
  'PositionProfilePostedEvent.id': PositionProfilePostedEvent_id,
  'PositionProfilePostedEvent.schemeId': PositionProfilePostedEvent_schemeId,
  'PositionProfilePostedEvent.typeCode': PositionProfilePostedEvent_typeCode,
  'PositionProfilePostedEvent.createDateTime':
    PositionProfilePostedEvent_createDateTime,
  'PositionProfilePostedEvent.positionProfileId':
    PositionProfilePostedEvent_positionProfileId,
  'PositionProfilePostedEvent.positionProfile':
    PositionProfilePostedEvent_positionProfile,
  'PositionProfilePostedEvent.webhookAttempts':
    PositionProfilePostedEvent_webhookAttempts,
  'CandidateProfilePurchasedEvent.id': CandidateProfilePurchasedEvent_id,
  'CandidateProfilePurchasedEvent.schemeId':
    CandidateProfilePurchasedEvent_schemeId,
  'CandidateProfilePurchasedEvent.typeCode':
    CandidateProfilePurchasedEvent_typeCode,
  'CandidateProfilePurchasedEvent.createDateTime':
    CandidateProfilePurchasedEvent_createDateTime,
  'CandidateProfilePurchasedEvent.candidateProfileId':
    CandidateProfilePurchasedEvent_candidateProfileId,
  'CandidateProfilePurchasedEvent.candidateProfile':
    CandidateProfilePurchasedEvent_candidateProfile,
  'CandidateProfilePurchasedEvent.webhookAttempts':
    CandidateProfilePurchasedEvent_webhookAttempts,
  'HirerRelationshipChangedEvent.id': HirerRelationshipChangedEvent_id,
  'HirerRelationshipChangedEvent.schemeId':
    HirerRelationshipChangedEvent_schemeId,
  'HirerRelationshipChangedEvent.typeCode':
    HirerRelationshipChangedEvent_typeCode,
  'HirerRelationshipChangedEvent.createDateTime':
    HirerRelationshipChangedEvent_createDateTime,
  'HirerRelationshipChangedEvent.hirerId':
    HirerRelationshipChangedEvent_hirerId,
  'HirerRelationshipChangedEvent.hirer': HirerRelationshipChangedEvent_hirer,
  'HirerRelationshipChangedEvent.webhookAttempts':
    HirerRelationshipChangedEvent_webhookAttempts,
  'EventsConnection.edges': EventsConnection_edges,
  'EventsConnection.pageInfo': EventsConnection_pageInfo,
  'EventEdge.cursor': EventEdge_cursor,
  'EventEdge.node': EventEdge_node,
  'EventEdge.streamDateTime': EventEdge_streamDateTime,
  'EventsFilterInput.afterDateTime': EventsFilterInput_afterDateTime,
  'EventsFilterInput.beforeDateTime': EventsFilterInput_beforeDateTime,
  'EventsFilterInput.deliveredIndicator': EventsFilterInput_deliveredIndicator,
  'EventsFilterInput.eventTypeCodes': EventsFilterInput_eventTypeCodes,
  'EventsFilterInput.subscriptionId': EventsFilterInput_subscriptionId,
  'CreateWebhookSubscriptionInput.webhookSubscription':
    CreateWebhookSubscriptionInput_webhookSubscription,
  'CreateWebhookSubscription_SubscriptionInput.eventTypeCode':
    CreateWebhookSubscription_SubscriptionInput_eventTypeCode,
  'CreateWebhookSubscription_SubscriptionInput.schemeId':
    CreateWebhookSubscription_SubscriptionInput_schemeId,
  'CreateWebhookSubscription_SubscriptionInput.hirerId':
    CreateWebhookSubscription_SubscriptionInput_hirerId,
  'CreateWebhookSubscription_SubscriptionInput.url':
    CreateWebhookSubscription_SubscriptionInput_url,
  'CreateWebhookSubscription_SubscriptionInput.maxEventsPerAttempt':
    CreateWebhookSubscription_SubscriptionInput_maxEventsPerAttempt,
  'CreateWebhookSubscription_SubscriptionInput.signingAlgorithmCode':
    CreateWebhookSubscription_SubscriptionInput_signingAlgorithmCode,
  'CreateWebhookSubscription_SubscriptionInput.secret':
    CreateWebhookSubscription_SubscriptionInput_secret,
  'CreateWebhookSubscriptionPayload_Conflict.conflictingWebhookSubscription':
    CreateWebhookSubscriptionPayload_Conflict_conflictingWebhookSubscription,
  'CreateWebhookSubscriptionPayload_Success.webhookSubscription':
    CreateWebhookSubscriptionPayload_Success_webhookSubscription,
  'UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.id':
    UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput_id,
  'UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.url':
    UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput_url,
  'UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput.maxEventsPerAttempt':
    UpdateWebhookSubscriptionDeliveryConfiguration_SubscriptionInput_maxEventsPerAttempt,
  'UpdateWebhookSubscriptionDeliveryConfigurationInput.webhookSubscription':
    UpdateWebhookSubscriptionDeliveryConfigurationInput_webhookSubscription,
  'UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.id':
    UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput_id,
  'UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.signingAlgorithmCode':
    UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput_signingAlgorithmCode,
  'UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput.secret':
    UpdateWebhookSubscriptionSigningConfiguration_SubscriptionInput_secret,
  'UpdateWebhookSubscriptionSigningConfigurationInput.webhookSubscription':
    UpdateWebhookSubscriptionSigningConfigurationInput_webhookSubscription,
  'UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success.webhookSubscription':
    UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success_webhookSubscription,
  'UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict.conflictingWebhookSubscription':
    UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict_conflictingWebhookSubscription,
  'UpdateWebhookSubscriptionSigningConfigurationPayload.webhookSubscription':
    UpdateWebhookSubscriptionSigningConfigurationPayload_webhookSubscription,
  'DeleteWebhookSubscriptionInput.webhookSubscription':
    DeleteWebhookSubscriptionInput_webhookSubscription,
  'DeleteWebhookSubscription_SubscriptionInput.id':
    DeleteWebhookSubscription_SubscriptionInput_id,
  'ReplayWebhookSubscriptionInput.webhookSubscription':
    ReplayWebhookSubscriptionInput_webhookSubscription,
  'ReplayWebhookSubscriptionInput.filter':
    ReplayWebhookSubscriptionInput_filter,
  'ReplayWebhookSubscriptionInput.eventIds':
    ReplayWebhookSubscriptionInput_eventIds,
  'ReplayWebhookSubscription_SubscriptionInput.id':
    ReplayWebhookSubscription_SubscriptionInput_id,
  'ReplayWebhookSubscription_FilterInput.replayDeliveredEventsIndicator':
    ReplayWebhookSubscription_FilterInput_replayDeliveredEventsIndicator,
  'ReplayWebhookSubscription_FilterInput.createdAfterDateTime':
    ReplayWebhookSubscription_FilterInput_createdAfterDateTime,
  'ReplayWebhookSubscription_FilterInput.createdBeforeDateTime':
    ReplayWebhookSubscription_FilterInput_createdBeforeDateTime,
  'ReplayWebhookSubscription_FilterInput.hirerId':
    ReplayWebhookSubscription_FilterInput_hirerId,
  'DeleteWebhookSubscriptionPayload.webhookSubscription':
    DeleteWebhookSubscriptionPayload_webhookSubscription,
  'ReplayWebhookSubscriptionPayload.webhookSubscription':
    ReplayWebhookSubscriptionPayload_webhookSubscription,
  'WebhookAttempt.id': WebhookAttempt_id,
  'WebhookAttempt.createDateTime': WebhookAttempt_createDateTime,
  'WebhookAttempt.event': WebhookAttempt_event,
  'WebhookAttempt.webhookSubscription': WebhookAttempt_webhookSubscription,
  'WebhookAttempt.webhookRequest': WebhookAttempt_webhookRequest,
  'WebhookAttemptsConnection.edges': WebhookAttemptsConnection_edges,
  'WebhookAttemptsConnection.pageInfo': WebhookAttemptsConnection_pageInfo,
  'WebhookAttemptEdge.cursor': WebhookAttemptEdge_cursor,
  'WebhookAttemptEdge.node': WebhookAttemptEdge_node,
  'WebhookAttemptsFilterInput.afterDateTime':
    WebhookAttemptsFilterInput_afterDateTime,
  'WebhookAttemptsFilterInput.beforeDateTime':
    WebhookAttemptsFilterInput_beforeDateTime,
  'WebhookAttemptsFilterInput.descriptionCodes':
    WebhookAttemptsFilterInput_descriptionCodes,
  'WebhookRequest.requestId': WebhookRequest_requestId,
  'WebhookRequest.webhookSubscription': WebhookRequest_webhookSubscription,
  'WebhookRequest.statusCode': WebhookRequest_statusCode,
  'WebhookRequest.descriptionCode': WebhookRequest_descriptionCode,
  'WebhookRequest.latencyMs': WebhookRequest_latencyMs,
  'WebhookRequest.createDateTime': WebhookRequest_createDateTime,
  'WebhookRequest.attempts': WebhookRequest_attempts,
  'WebhookRequestFilterInput.afterDateTime':
    WebhookRequestFilterInput_afterDateTime,
  'WebhookRequestFilterInput.beforeDateTime':
    WebhookRequestFilterInput_beforeDateTime,
  'WebhookRequestFilterInput.descriptionCodes':
    WebhookRequestFilterInput_descriptionCodes,
  'WebhookRequestsConnection.edges': WebhookRequestsConnection_edges,
  'WebhookRequestsConnection.pageInfo': WebhookRequestsConnection_pageInfo,
  'WebhookRequestEdge.cursor': WebhookRequestEdge_cursor,
  'WebhookRequestEdge.node': WebhookRequestEdge_node,
  'WebhookSubscription.id': WebhookSubscription_id,
  'WebhookSubscription.eventTypeCode': WebhookSubscription_eventTypeCode,
  'WebhookSubscription.schemeId': WebhookSubscription_schemeId,
  'WebhookSubscription.hirerId': WebhookSubscription_hirerId,
  'WebhookSubscription.hirer': WebhookSubscription_hirer,
  'WebhookSubscription.url': WebhookSubscription_url,
  'WebhookSubscription.maxEventsPerAttempt':
    WebhookSubscription_maxEventsPerAttempt,
  'WebhookSubscription.signingAlgorithmCode':
    WebhookSubscription_signingAlgorithmCode,
  'WebhookSubscription.createDateTime': WebhookSubscription_createDateTime,
  'WebhookSubscription.updateDateTime': WebhookSubscription_updateDateTime,
  'WebhookSubscription.webhookRequests': WebhookSubscription_webhookRequests,
  'WebhookSubscription.webhookSubscriptionReplays':
    WebhookSubscription_webhookSubscriptionReplays,
  'WebhookSubscriptionReplay.id': WebhookSubscriptionReplay_id,
  'WebhookSubscriptionReplay.request': WebhookSubscriptionReplay_request,
  'WebhookSubscriptionReplay.createDateTime':
    WebhookSubscriptionReplay_createDateTime,
  'WebhookSubscriptionReplay.updateDateTime':
    WebhookSubscriptionReplay_updateDateTime,
  'WebhookSubscriptionReplay.webhookSubscription':
    WebhookSubscriptionReplay_webhookSubscription,
  'WebhookSubscriptionReplay.statusCode': WebhookSubscriptionReplay_statusCode,
  'WebhookSubscriptionReplayRequest.typeCode':
    WebhookSubscriptionReplayRequest_typeCode,
  'WebhookSubscriptionReplayByRangeRequest.typeCode':
    WebhookSubscriptionReplayByRangeRequest_typeCode,
  'WebhookSubscriptionReplayByRangeRequest.afterDateTime':
    WebhookSubscriptionReplayByRangeRequest_afterDateTime,
  'WebhookSubscriptionReplayByRangeRequest.beforeDateTime':
    WebhookSubscriptionReplayByRangeRequest_beforeDateTime,
  'WebhookSubscriptionReplayByRangeRequest.replayDeliveredEventsIndicator':
    WebhookSubscriptionReplayByRangeRequest_replayDeliveredEventsIndicator,
  'WebhookSubscriptionReplayByRangeRequest.hirer':
    WebhookSubscriptionReplayByRangeRequest_hirer,
  'WebhookSubscriptionReplayByIdRequest.typeCode':
    WebhookSubscriptionReplayByIdRequest_typeCode,
  'WebhookSubscriptionReplayByIdRequest.eventIds':
    WebhookSubscriptionReplayByIdRequest_eventIds,
  'WebhookSubscriptionReplaysConnection.edges':
    WebhookSubscriptionReplaysConnection_edges,
  'WebhookSubscriptionReplaysConnection.pageInfo':
    WebhookSubscriptionReplaysConnection_pageInfo,
  'WebhookSubscriptionReplayEdge.cursor': WebhookSubscriptionReplayEdge_cursor,
  'WebhookSubscriptionReplayEdge.node': WebhookSubscriptionReplayEdge_node,
  'WebhookSubscriptionReplaysFilterInput.statusCode':
    WebhookSubscriptionReplaysFilterInput_statusCode,
  'WebhookSubscriptionsConnection.edges': WebhookSubscriptionsConnection_edges,
  'WebhookSubscriptionsConnection.pageInfo':
    WebhookSubscriptionsConnection_pageInfo,
  'WebhookSubscriptionEdge.cursor': WebhookSubscriptionEdge_cursor,
  'WebhookSubscriptionEdge.node': WebhookSubscriptionEdge_node,
  'WebhookSubscriptionsFilterInput.afterDateTime':
    WebhookSubscriptionsFilterInput_afterDateTime,
  'WebhookSubscriptionsFilterInput.beforeDateTime':
    WebhookSubscriptionsFilterInput_beforeDateTime,
  'WebhookSubscriptionsFilterInput.eventTypeCodes':
    WebhookSubscriptionsFilterInput_eventTypeCodes,
  'WebhookSubscriptionsFilterInput.hirerIds':
    WebhookSubscriptionsFilterInput_hirerIds,
  'WebhookSubscriptionsFilterInput.hirerScopedIndicator':
    WebhookSubscriptionsFilterInput_hirerScopedIndicator,
  'HiringOrganization.id': HiringOrganization_id,
  'HiringOrganization.name': HiringOrganization_name,
  'HiringOrganization.seekApiCapabilities':
    HiringOrganization_seekApiCapabilities,
  'HiringOrganization.seekAnzAdvertiserId':
    HiringOrganization_seekAnzAdvertiserId,
  'HiringOrganizationApiCapabilities.relationshipTypeCodes':
    HiringOrganizationApiCapabilities_relationshipTypeCodes,
  'HiringOrganizationApiCapabilities.applicationMethodCodes':
    HiringOrganizationApiCapabilities_applicationMethodCodes,
  'HiringOrganizationsConnection.edges': HiringOrganizationsConnection_edges,
  'HiringOrganizationsConnection.pageInfo':
    HiringOrganizationsConnection_pageInfo,
  'HiringOrganizationEdge.cursor': HiringOrganizationEdge_cursor,
  'HiringOrganizationEdge.node': HiringOrganizationEdge_node,
  'HiringOrganizationsFilterInput.relationshipTypeCodes':
    HiringOrganizationsFilterInput_relationshipTypeCodes,
  'HiringOrganizationsFilterInput.nameSearch':
    HiringOrganizationsFilterInput_nameSearch,
  'Organization.name': Organization_name,
  'OrganizationInput.name': OrganizationInput_name,
  'PartnerOrganization.name': PartnerOrganization_name,
  'PartnerOrganization.credentialSelfServiceApprovedIndicator':
    PartnerOrganization_credentialSelfServiceApprovedIndicator,
  'SelfOrganizations.partner': SelfOrganizations_partner,
  'SelfOrganizations.hirer': SelfOrganizations_hirer,
  '_unstable_AdvertisementPerformance_DetailsWithConversion_Conversion.inPercentage':
    _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion_inPercentage,
  '_unstable_AdvertisementPerformance_DetailsWithConversion_Conversion.similarAdsInPercentage':
    _unstable_AdvertisementPerformance_DetailsWithConversion_Conversion_similarAdsInPercentage,
  '_unstable_AdvertisementPerformance_DetailsWithConversion.total':
    _unstable_AdvertisementPerformance_DetailsWithConversion_total,
  '_unstable_AdvertisementPerformance_DetailsWithConversion.mobileInPercentage':
    _unstable_AdvertisementPerformance_DetailsWithConversion_mobileInPercentage,
  '_unstable_AdvertisementPerformance_DetailsWithConversion.similarAdsAverage':
    _unstable_AdvertisementPerformance_DetailsWithConversion_similarAdsAverage,
  '_unstable_AdvertisementPerformance_DetailsWithConversion.conversion':
    _unstable_AdvertisementPerformance_DetailsWithConversion_conversion,
  '_unstable_AdvertisementPerformance_Details.total':
    _unstable_AdvertisementPerformance_Details_total,
  '_unstable_AdvertisementPerformance_Details.mobileInPercentage':
    _unstable_AdvertisementPerformance_Details_mobileInPercentage,
  '_unstable_AdvertisementPerformance_Details.similarAdsAverage':
    _unstable_AdvertisementPerformance_Details_similarAdsAverage,
  '_unstable_AdvertisementPerformance.adRatingConfidenceCode':
    _unstable_AdvertisementPerformance_adRatingConfidenceCode,
  '_unstable_AdvertisementPerformance.comparisonLocation':
    _unstable_AdvertisementPerformance_comparisonLocation,
  '_unstable_AdvertisementPerformance.viewsInJobSearch':
    _unstable_AdvertisementPerformance_viewsInJobSearch,
  '_unstable_AdvertisementPerformance.clicksOnAd':
    _unstable_AdvertisementPerformance_clicksOnAd,
  '_unstable_AdvertisementPerformance.applicationsStarted':
    _unstable_AdvertisementPerformance_applicationsStarted,
  '_unstable_AdvertisementPerformance.applicationsCompleted':
    _unstable_AdvertisementPerformance_applicationsCompleted,
  '_unstable_AdvertisementPerformance.updateDateTime':
    _unstable_AdvertisementPerformance_updateDateTime,
  'ClosePostedPositionProfilePayload.positionProfile':
    ClosePostedPositionProfilePayload_positionProfile,
  'ClosePostedPositionProfile_PositionProfilePayload.profileId':
    ClosePostedPositionProfile_PositionProfilePayload_profileId,
  'CreatePositionOpeningPayload.positionOpening':
    CreatePositionOpeningPayload_positionOpening,
  'CreateUnpostedPositionProfileForOpeningPayload.positionProfile':
    CreateUnpostedPositionProfileForOpeningPayload_positionProfile,
  'DeletePositionOpeningPayload.positionOpening':
    DeletePositionOpeningPayload_positionOpening,
  'DeleteUnpostedPositionProfilePayload.positionProfile':
    DeleteUnpostedPositionProfilePayload_positionProfile,
  'PositionOpening.statusCode': PositionOpening_statusCode,
  'PositionOpening.documentId': PositionOpening_documentId,
  'PositionOpening.seekPartnerMetadata': PositionOpening_seekPartnerMetadata,
  'PositionOpening.postingRequester': PositionOpening_postingRequester,
  'PositionOpening.paginatedPositionProfiles':
    PositionOpening_paginatedPositionProfiles,
  'PositionOpening.positionProfiles': PositionOpening_positionProfiles,
  'PostingRequester.id': PostingRequester_id,
  'PostingRequester.name': PostingRequester_name,
  'PostingRequester.seekAnzAdvertiserId': PostingRequester_seekAnzAdvertiserId,
  'PostingRequester.roleCode': PostingRequester_roleCode,
  'PostingRequester.personContacts': PostingRequester_personContacts,
  'PositionOpeningEdge.cursor': PositionOpeningEdge_cursor,
  'PositionOpeningEdge.node': PositionOpeningEdge_node,
  'PositionOpeningConnection.edges': PositionOpeningConnection_edges,
  'PositionOpeningConnection.pageInfo': PositionOpeningConnection_pageInfo,
  'PositionProfile.profileId': PositionProfile_profileId,
  'PositionProfile.positionOpening': PositionProfile_positionOpening,
  'PositionProfile.seekTypeCode': PositionProfile_seekTypeCode,
  'PositionProfile.positionTitle': PositionProfile_positionTitle,
  'PositionProfile.positionOrganizations':
    PositionProfile_positionOrganizations,
  'PositionProfile.seekHirerJobReference':
    PositionProfile_seekHirerJobReference,
  'PositionProfile.seekBillingReference': PositionProfile_seekBillingReference,
  'PositionProfile.positionUri': PositionProfile_positionUri,
  'PositionProfile.positionFormattedDescriptions':
    PositionProfile_positionFormattedDescriptions,
  'PositionProfile.positionScheduleTypeCodes':
    PositionProfile_positionScheduleTypeCodes,
  'PositionProfile.offeredRemunerationPackage':
    PositionProfile_offeredRemunerationPackage,
  'PositionProfile.seekAnzWorkTypeCode': PositionProfile_seekAnzWorkTypeCode,
  'PositionProfile.jobCategories': PositionProfile_jobCategories,
  'PositionProfile.positionLocation': PositionProfile_positionLocation,
  'PositionProfile.seekApplicationQuestionnaire':
    PositionProfile_seekApplicationQuestionnaire,
  'PositionProfile.seekVideo': PositionProfile_seekVideo,
  'PositionProfile.postingInstructions': PositionProfile_postingInstructions,
  'PositionProfile.seekPartnerMetadata': PositionProfile_seekPartnerMetadata,
  'PositionProfile.seekCreatedBySelfIndicator':
    PositionProfile_seekCreatedBySelfIndicator,
  'PostedPositionProfile.profileId': PostedPositionProfile_profileId,
  'PostedPositionProfile.positionOpening':
    PostedPositionProfile_positionOpening,
  'PostedPositionProfile.seekTypeCode': PostedPositionProfile_seekTypeCode,
  'PostedPositionProfile.positionTitle': PostedPositionProfile_positionTitle,
  'PostedPositionProfile.positionOrganizations':
    PostedPositionProfile_positionOrganizations,
  'PostedPositionProfile.seekHirerJobReference':
    PostedPositionProfile_seekHirerJobReference,
  'PostedPositionProfile.seekBillingReference':
    PostedPositionProfile_seekBillingReference,
  'PostedPositionProfile.positionUri': PostedPositionProfile_positionUri,
  'PostedPositionProfile.seekApplicationUri':
    PostedPositionProfile_seekApplicationUri,
  'PostedPositionProfile.positionFormattedDescriptions':
    PostedPositionProfile_positionFormattedDescriptions,
  'PostedPositionProfile.positionScheduleTypeCodes':
    PostedPositionProfile_positionScheduleTypeCodes,
  'PostedPositionProfile.offeredRemunerationPackage':
    PostedPositionProfile_offeredRemunerationPackage,
  'PostedPositionProfile.seekAnzWorkTypeCode':
    PostedPositionProfile_seekAnzWorkTypeCode,
  'PostedPositionProfile.jobCategories': PostedPositionProfile_jobCategories,
  'PostedPositionProfile.positionLocation':
    PostedPositionProfile_positionLocation,
  'PostedPositionProfile.seekApplicationQuestionnaire':
    PostedPositionProfile_seekApplicationQuestionnaire,
  'PostedPositionProfile.seekVideo': PostedPositionProfile_seekVideo,
  'PostedPositionProfile.postingInstructions':
    PostedPositionProfile_postingInstructions,
  'PostedPositionProfile.seekPartnerMetadata':
    PostedPositionProfile_seekPartnerMetadata,
  'PostedPositionProfile.seekCreatedBySelfIndicator':
    PostedPositionProfile_seekCreatedBySelfIndicator,
  'PostedPositionProfile._unstable_seekAdvertisementPerformance':
    PostedPositionProfile__unstable_seekAdvertisementPerformance,
  'PositionFormattedDescriptionIdentifier.value':
    PositionFormattedDescriptionIdentifier_value,
  'UnpostedPositionProfile.profileId': UnpostedPositionProfile_profileId,
  'UnpostedPositionProfile.profileName': UnpostedPositionProfile_profileName,
  'UnpostedPositionProfile.positionOpening':
    UnpostedPositionProfile_positionOpening,
  'UnpostedPositionProfile.seekTypeCode': UnpostedPositionProfile_seekTypeCode,
  'UnpostedPositionProfile.positionTitle':
    UnpostedPositionProfile_positionTitle,
  'UnpostedPositionProfile.positionOrganizations':
    UnpostedPositionProfile_positionOrganizations,
  'UnpostedPositionProfile.seekHirerJobReference':
    UnpostedPositionProfile_seekHirerJobReference,
  'UnpostedPositionProfile.seekBillingReference':
    UnpostedPositionProfile_seekBillingReference,
  'UnpostedPositionProfile.positionUri': UnpostedPositionProfile_positionUri,
  'UnpostedPositionProfile.positionFormattedDescriptions':
    UnpostedPositionProfile_positionFormattedDescriptions,
  'UnpostedPositionProfile.positionScheduleTypeCodes':
    UnpostedPositionProfile_positionScheduleTypeCodes,
  'UnpostedPositionProfile.offeredRemunerationPackage':
    UnpostedPositionProfile_offeredRemunerationPackage,
  'UnpostedPositionProfile.seekAnzWorkTypeCode':
    UnpostedPositionProfile_seekAnzWorkTypeCode,
  'UnpostedPositionProfile.jobCategories':
    UnpostedPositionProfile_jobCategories,
  'UnpostedPositionProfile.positionLocation':
    UnpostedPositionProfile_positionLocation,
  'UnpostedPositionProfile.seekApplicationQuestionnaire':
    UnpostedPositionProfile_seekApplicationQuestionnaire,
  'UnpostedPositionProfile.seekVideo': UnpostedPositionProfile_seekVideo,
  'UnpostedPositionProfile.postingInstructions':
    UnpostedPositionProfile_postingInstructions,
  'UnpostedPositionProfile.seekPartnerMetadata':
    UnpostedPositionProfile_seekPartnerMetadata,
  'UnpostedPositionProfile.seekCreatedBySelfIndicator':
    UnpostedPositionProfile_seekCreatedBySelfIndicator,
  'PositionFormattedDescription.descriptionId':
    PositionFormattedDescription_descriptionId,
  'PositionFormattedDescription.content': PositionFormattedDescription_content,
  'RemunerationPackage.basisCode': RemunerationPackage_basisCode,
  'RemunerationPackage.ranges': RemunerationPackage_ranges,
  'RemunerationPackage.descriptions': RemunerationPackage_descriptions,
  'RemunerationRange.minimumAmount': RemunerationRange_minimumAmount,
  'RemunerationRange.maximumAmount': RemunerationRange_maximumAmount,
  'RemunerationRange.intervalCode': RemunerationRange_intervalCode,
  'RemunerationAmount.value': RemunerationAmount_value,
  'RemunerationAmount.currency': RemunerationAmount_currency,
  'PostingInstruction.start': PostingInstruction_start,
  'PostingInstruction.end': PostingInstruction_end,
  'PostingInstruction.seekAnzAdvertisementType':
    PostingInstruction_seekAnzAdvertisementType,
  'PostingInstruction.seekAdvertisementProduct':
    PostingInstruction_seekAdvertisementProduct,
  'PostingInstruction.applicationMethods':
    PostingInstruction_applicationMethods,
  'PostingInstruction.brandingId': PostingInstruction_brandingId,
  'PostingInstruction.branding': PostingInstruction_branding,
  'ApplicationMethod.applicationUri': ApplicationMethod_applicationUri,
  'ApplicationMethod.applicationEmail': ApplicationMethod_applicationEmail,
  'PostedPositionProfile_AdvertisementProduct.label':
    PostedPositionProfile_AdvertisementProduct_label,
  'SeekVideo.url': SeekVideo_url,
  'SeekVideo.seekAnzPositionCode': SeekVideo_seekAnzPositionCode,
  'PositionProfileEdge.cursor': PositionProfileEdge_cursor,
  'PositionProfileEdge.node': PositionProfileEdge_node,
  'PositionProfileConnection.edges': PositionProfileConnection_edges,
  'PositionProfileConnection.pageInfo': PositionProfileConnection_pageInfo,
  'PostPositionPayload_Success.positionOpening':
    PostPositionPayload_Success_positionOpening,
  'PostPositionPayload_Success.positionProfile':
    PostPositionPayload_Success_positionProfile,
  'PostPositionPayload_Conflict.conflictingPositionOpening':
    PostPositionPayload_Conflict_conflictingPositionOpening,
  'PostPositionPayload_Conflict.conflictingPositionProfile':
    PostPositionPayload_Conflict_conflictingPositionProfile,
  'PostPosition_PositionOpeningPayload.documentId':
    PostPosition_PositionOpeningPayload_documentId,
  'PostPosition_PositionProfilePayload.profileId':
    PostPosition_PositionProfilePayload_profileId,
  'PostPositionProfileForOpeningPayload_Success.positionProfile':
    PostPositionProfileForOpeningPayload_Success_positionProfile,
  'PostPositionProfileForOpeningPayload_Conflict.conflictingPositionProfile':
    PostPositionProfileForOpeningPayload_Conflict_conflictingPositionProfile,
  'PostPositionProfileForOpening_PositionProfilePayload.profileId':
    PostPositionProfileForOpening_PositionProfilePayload_profileId,
  'PositionOpeningsFilterInput.statusCode':
    PositionOpeningsFilterInput_statusCode,
  'UpdatePositionOpeningPersonContactsPayload.positionOpening':
    UpdatePositionOpeningPersonContactsPayload_positionOpening,
  'UpdatePositionOpeningStatusPayload.positionOpening':
    UpdatePositionOpeningStatusPayload_positionOpening,
  'UpdatePostedPositionProfilePayload.positionProfile':
    UpdatePostedPositionProfilePayload_positionProfile,
  'UpdatePostedPositionProfile_PositionProfilePayload.profileId':
    UpdatePostedPositionProfile_PositionProfilePayload_profileId,
  'UpdateUnpostedPositionProfilePayload.positionProfile':
    UpdateUnpostedPositionProfilePayload_positionProfile,
  'ApplicationMethodInput.applicationUri':
    ApplicationMethodInput_applicationUri,
  'ClosePostedPositionProfileInput.positionProfile':
    ClosePostedPositionProfileInput_positionProfile,
  'ClosePostedPositionProfile_PositionProfileInput.profileId':
    ClosePostedPositionProfile_PositionProfileInput_profileId,
  'CreatePostingInstructionInput.seekAnzAdvertisementType':
    CreatePostingInstructionInput_seekAnzAdvertisementType,
  'CreatePostingInstructionInput.seekAdvertisementProductId':
    CreatePostingInstructionInput_seekAdvertisementProductId,
  'CreatePostingInstructionInput.end': CreatePostingInstructionInput_end,
  'CreatePostingInstructionInput.idempotencyId':
    CreatePostingInstructionInput_idempotencyId,
  'CreatePostingInstructionInput.applicationMethods':
    CreatePostingInstructionInput_applicationMethods,
  'CreatePostingInstructionInput.brandingId':
    CreatePostingInstructionInput_brandingId,
  'PositionFormattedDescriptionInput.descriptionId':
    PositionFormattedDescriptionInput_descriptionId,
  'PositionFormattedDescriptionInput.content':
    PositionFormattedDescriptionInput_content,
  'CreatePositionOpeningInput.positionOpening':
    CreatePositionOpeningInput_positionOpening,
  'CreatePositionOpening_PositionOpeningInput.postingRequester':
    CreatePositionOpening_PositionOpeningInput_postingRequester,
  'CreatePositionOpening_PositionOpeningInput.seekPartnerMetadata':
    CreatePositionOpening_PositionOpeningInput_seekPartnerMetadata,
  'CreatePositionOpening_PositionOpeningInput.statusCode':
    CreatePositionOpening_PositionOpeningInput_statusCode,
  'UpdatePositionOpeningPersonContactsInput.positionOpening':
    UpdatePositionOpeningPersonContactsInput_positionOpening,
  'UpdatePositionOpeningPersonContactsPositionOpeningInput.documentId':
    UpdatePositionOpeningPersonContactsPositionOpeningInput_documentId,
  'UpdatePositionOpeningPersonContactsPositionOpeningInput.personContacts':
    UpdatePositionOpeningPersonContactsPositionOpeningInput_personContacts,
  'UpdatePositionOpeningStatusInput.positionOpening':
    UpdatePositionOpeningStatusInput_positionOpening,
  'UpdatePositionOpeningStatusPositionOpeningInput.documentId':
    UpdatePositionOpeningStatusPositionOpeningInput_documentId,
  'UpdatePositionOpeningStatusPositionOpeningInput.statusCode':
    UpdatePositionOpeningStatusPositionOpeningInput_statusCode,
  'DeletePositionOpeningInput.positionOpening':
    DeletePositionOpeningInput_positionOpening,
  'DeletePositionOpening_PositionOpeningInput.documentId':
    DeletePositionOpening_PositionOpeningInput_documentId,
  'PostingRequesterInput.id': PostingRequesterInput_id,
  'PostingRequesterInput.roleCode': PostingRequesterInput_roleCode,
  'PostingRequesterInput.personContacts': PostingRequesterInput_personContacts,
  'PostPositionInput.positionOpening': PostPositionInput_positionOpening,
  'PostPositionInput.positionProfile': PostPositionInput_positionProfile,
  'PostPosition_PositionProfileInput.positionTitle':
    PostPosition_PositionProfileInput_positionTitle,
  'PostPosition_PositionProfileInput.positionOrganizations':
    PostPosition_PositionProfileInput_positionOrganizations,
  'PostPosition_PositionProfileInput.seekHirerJobReference':
    PostPosition_PositionProfileInput_seekHirerJobReference,
  'PostPosition_PositionProfileInput.seekBillingReference':
    PostPosition_PositionProfileInput_seekBillingReference,
  'PostPosition_PositionProfileInput.positionFormattedDescriptions':
    PostPosition_PositionProfileInput_positionFormattedDescriptions,
  'PostPosition_PositionProfileInput.positionScheduleTypeCodes':
    PostPosition_PositionProfileInput_positionScheduleTypeCodes,
  'PostPosition_PositionProfileInput.offeredRemunerationPackage':
    PostPosition_PositionProfileInput_offeredRemunerationPackage,
  'PostPosition_PositionProfileInput.seekAnzWorkTypeCode':
    PostPosition_PositionProfileInput_seekAnzWorkTypeCode,
  'PostPosition_PositionProfileInput.jobCategories':
    PostPosition_PositionProfileInput_jobCategories,
  'PostPosition_PositionProfileInput.positionLocation':
    PostPosition_PositionProfileInput_positionLocation,
  'PostPosition_PositionProfileInput.seekApplicationQuestionnaireId':
    PostPosition_PositionProfileInput_seekApplicationQuestionnaireId,
  'PostPosition_PositionProfileInput.seekVideo':
    PostPosition_PositionProfileInput_seekVideo,
  'PostPosition_PositionProfileInput.postingInstructions':
    PostPosition_PositionProfileInput_postingInstructions,
  'PostPosition_PositionProfileInput.seekPartnerMetadata':
    PostPosition_PositionProfileInput_seekPartnerMetadata,
  'PostPositionProfileForOpeningInput.positionProfile':
    PostPositionProfileForOpeningInput_positionProfile,
  'PostPositionProfileForOpeningPositionProfileInput.positionOpeningId':
    PostPositionProfileForOpeningPositionProfileInput_positionOpeningId,
  'PostPositionProfileForOpeningPositionProfileInput.positionTitle':
    PostPositionProfileForOpeningPositionProfileInput_positionTitle,
  'PostPositionProfileForOpeningPositionProfileInput.positionOrganizations':
    PostPositionProfileForOpeningPositionProfileInput_positionOrganizations,
  'PostPositionProfileForOpeningPositionProfileInput.seekHirerJobReference':
    PostPositionProfileForOpeningPositionProfileInput_seekHirerJobReference,
  'PostPositionProfileForOpeningPositionProfileInput.seekBillingReference':
    PostPositionProfileForOpeningPositionProfileInput_seekBillingReference,
  'PostPositionProfileForOpeningPositionProfileInput.positionFormattedDescriptions':
    PostPositionProfileForOpeningPositionProfileInput_positionFormattedDescriptions,
  'PostPositionProfileForOpeningPositionProfileInput.positionScheduleTypeCodes':
    PostPositionProfileForOpeningPositionProfileInput_positionScheduleTypeCodes,
  'PostPositionProfileForOpeningPositionProfileInput.offeredRemunerationPackage':
    PostPositionProfileForOpeningPositionProfileInput_offeredRemunerationPackage,
  'PostPositionProfileForOpeningPositionProfileInput.seekAnzWorkTypeCode':
    PostPositionProfileForOpeningPositionProfileInput_seekAnzWorkTypeCode,
  'PostPositionProfileForOpeningPositionProfileInput.jobCategories':
    PostPositionProfileForOpeningPositionProfileInput_jobCategories,
  'PostPositionProfileForOpeningPositionProfileInput.positionLocation':
    PostPositionProfileForOpeningPositionProfileInput_positionLocation,
  'PostPositionProfileForOpeningPositionProfileInput.seekApplicationQuestionnaireId':
    PostPositionProfileForOpeningPositionProfileInput_seekApplicationQuestionnaireId,
  'PostPositionProfileForOpeningPositionProfileInput.seekVideo':
    PostPositionProfileForOpeningPositionProfileInput_seekVideo,
  'PostPositionProfileForOpeningPositionProfileInput.postingInstructions':
    PostPositionProfileForOpeningPositionProfileInput_postingInstructions,
  'PostPositionProfileForOpeningPositionProfileInput.seekPartnerMetadata':
    PostPositionProfileForOpeningPositionProfileInput_seekPartnerMetadata,
  'RemunerationPackageInput.basisCode': RemunerationPackageInput_basisCode,
  'RemunerationPackageInput.ranges': RemunerationPackageInput_ranges,
  'RemunerationPackageInput.descriptions':
    RemunerationPackageInput_descriptions,
  'RemunerationRangeInput.minimumAmount': RemunerationRangeInput_minimumAmount,
  'RemunerationRangeInput.maximumAmount': RemunerationRangeInput_maximumAmount,
  'RemunerationRangeInput.intervalCode': RemunerationRangeInput_intervalCode,
  'RemunerationAmountInput.value': RemunerationAmountInput_value,
  'RemunerationAmountInput.currency': RemunerationAmountInput_currency,
  'SeekPositionAddressInput.formattedAddress':
    SeekPositionAddressInput_formattedAddress,
  'SeekPositionAddressInput.postalCode': SeekPositionAddressInput_postalCode,
  'SeekPositionAddressInput.countryCode': SeekPositionAddressInput_countryCode,
  'SeekVideoInput.url': SeekVideoInput_url,
  'SeekVideoInput.seekAnzPositionCode': SeekVideoInput_seekAnzPositionCode,
  'CreateUnpostedPositionProfileForOpeningInput.positionProfile':
    CreateUnpostedPositionProfileForOpeningInput_positionProfile,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionOpeningId':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionOpeningId,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.profileName':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_profileName,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionTitle':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionTitle,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionOrganizations':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionOrganizations,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.seekHirerJobReference':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_seekHirerJobReference,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.seekBillingReference':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_seekBillingReference,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionFormattedDescriptions':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionFormattedDescriptions,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionScheduleTypeCodes':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionScheduleTypeCodes,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.offeredRemunerationPackage':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_offeredRemunerationPackage,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.jobCategories':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_jobCategories,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.positionLocation':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_positionLocation,
  'CreateUnpostedPositionProfileForOpeningPositionProfileInput.seekPartnerMetadata':
    CreateUnpostedPositionProfileForOpeningPositionProfileInput_seekPartnerMetadata,
  'UpdateUnpostedPositionProfileInput.positionProfile':
    UpdateUnpostedPositionProfileInput_positionProfile,
  'UpdateUnpostedPositionProfile_PositionProfileInput.profileId':
    UpdateUnpostedPositionProfile_PositionProfileInput_profileId,
  'UpdateUnpostedPositionProfile_PositionProfileInput.profileName':
    UpdateUnpostedPositionProfile_PositionProfileInput_profileName,
  'UpdateUnpostedPositionProfile_PositionProfileInput.positionTitle':
    UpdateUnpostedPositionProfile_PositionProfileInput_positionTitle,
  'UpdateUnpostedPositionProfile_PositionProfileInput.seekHirerJobReference':
    UpdateUnpostedPositionProfile_PositionProfileInput_seekHirerJobReference,
  'UpdateUnpostedPositionProfile_PositionProfileInput.seekBillingReference':
    UpdateUnpostedPositionProfile_PositionProfileInput_seekBillingReference,
  'UpdateUnpostedPositionProfile_PositionProfileInput.positionFormattedDescriptions':
    UpdateUnpostedPositionProfile_PositionProfileInput_positionFormattedDescriptions,
  'UpdateUnpostedPositionProfile_PositionProfileInput.positionScheduleTypeCodes':
    UpdateUnpostedPositionProfile_PositionProfileInput_positionScheduleTypeCodes,
  'UpdateUnpostedPositionProfile_PositionProfileInput.offeredRemunerationPackage':
    UpdateUnpostedPositionProfile_PositionProfileInput_offeredRemunerationPackage,
  'UpdateUnpostedPositionProfile_PositionProfileInput.jobCategories':
    UpdateUnpostedPositionProfile_PositionProfileInput_jobCategories,
  'UpdateUnpostedPositionProfile_PositionProfileInput.positionLocation':
    UpdateUnpostedPositionProfile_PositionProfileInput_positionLocation,
  'UpdateUnpostedPositionProfile_PositionProfileInput.seekPartnerMetadata':
    UpdateUnpostedPositionProfile_PositionProfileInput_seekPartnerMetadata,
  'DeleteUnpostedPositionProfileInput.positionProfile':
    DeleteUnpostedPositionProfileInput_positionProfile,
  'DeleteUnpostedPositionProfile_PositionProfileInput.profileId':
    DeleteUnpostedPositionProfile_PositionProfileInput_profileId,
  'UpdatePostedPositionProfileInput.positionProfile':
    UpdatePostedPositionProfileInput_positionProfile,
  'UpdatePostedPositionProfile_PositionProfileInput.profileId':
    UpdatePostedPositionProfile_PositionProfileInput_profileId,
  'UpdatePostedPositionProfile_PositionProfileInput.positionTitle':
    UpdatePostedPositionProfile_PositionProfileInput_positionTitle,
  'UpdatePostedPositionProfile_PositionProfileInput.positionOrganizations':
    UpdatePostedPositionProfile_PositionProfileInput_positionOrganizations,
  'UpdatePostedPositionProfile_PositionProfileInput.seekHirerJobReference':
    UpdatePostedPositionProfile_PositionProfileInput_seekHirerJobReference,
  'UpdatePostedPositionProfile_PositionProfileInput.seekBillingReference':
    UpdatePostedPositionProfile_PositionProfileInput_seekBillingReference,
  'UpdatePostedPositionProfile_PositionProfileInput.positionFormattedDescriptions':
    UpdatePostedPositionProfile_PositionProfileInput_positionFormattedDescriptions,
  'UpdatePostedPositionProfile_PositionProfileInput.positionScheduleTypeCodes':
    UpdatePostedPositionProfile_PositionProfileInput_positionScheduleTypeCodes,
  'UpdatePostedPositionProfile_PositionProfileInput.offeredRemunerationPackage':
    UpdatePostedPositionProfile_PositionProfileInput_offeredRemunerationPackage,
  'UpdatePostedPositionProfile_PositionProfileInput.seekAnzWorkTypeCode':
    UpdatePostedPositionProfile_PositionProfileInput_seekAnzWorkTypeCode,
  'UpdatePostedPositionProfile_PositionProfileInput.jobCategories':
    UpdatePostedPositionProfile_PositionProfileInput_jobCategories,
  'UpdatePostedPositionProfile_PositionProfileInput.positionLocation':
    UpdatePostedPositionProfile_PositionProfileInput_positionLocation,
  'UpdatePostedPositionProfile_PositionProfileInput.seekApplicationQuestionnaireId':
    UpdatePostedPositionProfile_PositionProfileInput_seekApplicationQuestionnaireId,
  'UpdatePostedPositionProfile_PositionProfileInput.seekVideo':
    UpdatePostedPositionProfile_PositionProfileInput_seekVideo,
  'UpdatePostedPositionProfile_PositionProfileInput.postingInstructions':
    UpdatePostedPositionProfile_PositionProfileInput_postingInstructions,
  'UpdatePostedPositionProfile_PositionProfileInput.seekPartnerMetadata':
    UpdatePostedPositionProfile_PositionProfileInput_seekPartnerMetadata,
  'UpdatePostingInstructionInput.seekAnzAdvertisementType':
    UpdatePostingInstructionInput_seekAnzAdvertisementType,
  'UpdatePostingInstructionInput.seekAdvertisementProductId':
    UpdatePostingInstructionInput_seekAdvertisementProductId,
  'UpdatePostingInstructionInput.end': UpdatePostingInstructionInput_end,
  'UpdatePostingInstructionInput.applicationMethods':
    UpdatePostingInstructionInput_applicationMethods,
  'UpdatePostingInstructionInput.brandingId':
    UpdatePostingInstructionInput_brandingId,
  'PostedPositionProfilePreview_PositionProfileInput.positionTitle':
    PostedPositionProfilePreview_PositionProfileInput_positionTitle,
  'PostedPositionProfilePreview_PositionProfileInput.profileId':
    PostedPositionProfilePreview_PositionProfileInput_profileId,
  'PostedPositionProfilePreview_PositionProfileInput.positionOrganizations':
    PostedPositionProfilePreview_PositionProfileInput_positionOrganizations,
  'PostedPositionProfilePreview_PositionProfileInput.positionFormattedDescriptions':
    PostedPositionProfilePreview_PositionProfileInput_positionFormattedDescriptions,
  'PostedPositionProfilePreview_PositionProfileInput.seekAnzWorkTypeCode':
    PostedPositionProfilePreview_PositionProfileInput_seekAnzWorkTypeCode,
  'PostedPositionProfilePreview_PositionProfileInput.jobCategories':
    PostedPositionProfilePreview_PositionProfileInput_jobCategories,
  'PostedPositionProfilePreview_PositionProfileInput.positionLocation':
    PostedPositionProfilePreview_PositionProfileInput_positionLocation,
  'PostedPositionProfilePreview_PositionProfileInput.seekVideo':
    PostedPositionProfilePreview_PositionProfileInput_seekVideo,
  'PostedPositionProfilePreview_PositionProfileInput.postingInstructions':
    PostedPositionProfilePreview_PositionProfileInput_postingInstructions,
  'PostedPositionProfilePreview_PositionProfileInput.offeredRemunerationPackage':
    PostedPositionProfilePreview_PositionProfileInput_offeredRemunerationPackage,
  'PostedPositionProfilePreview_PositionProfileInput.seekApplicationQuestionnaireId':
    PostedPositionProfilePreview_PositionProfileInput_seekApplicationQuestionnaireId,
  'PostedPositionProfilePreview.previewUri':
    PostedPositionProfilePreview_previewUri,
  'PostedPositionProfilePreview_PostingInstructionInput.seekAnzAdvertisementType':
    PostedPositionProfilePreview_PostingInstructionInput_seekAnzAdvertisementType,
  'PostedPositionProfilePreview_PostingInstructionInput.seekAdvertisementProductId':
    PostedPositionProfilePreview_PostingInstructionInput_seekAdvertisementProductId,
  'PostedPositionProfilePreview_PostingInstructionInput.applicationMethods':
    PostedPositionProfilePreview_PostingInstructionInput_applicationMethods,
  'PostedPositionProfilePreview_PostingInstructionInput.brandingId':
    PostedPositionProfilePreview_PostingInstructionInput_brandingId,
  'PostedPositionProfilePreview_RemunerationAmountInput.value':
    PostedPositionProfilePreview_RemunerationAmountInput_value,
  'PostedPositionProfilePreview_RemunerationAmountInput.currency':
    PostedPositionProfilePreview_RemunerationAmountInput_currency,
  'PostedPositionProfilePreview_RemunerationPackageInput.basisCode':
    PostedPositionProfilePreview_RemunerationPackageInput_basisCode,
  'PostedPositionProfilePreview_RemunerationPackageInput.descriptions':
    PostedPositionProfilePreview_RemunerationPackageInput_descriptions,
  'PostedPositionProfilePreview_RemunerationPackageInput.ranges':
    PostedPositionProfilePreview_RemunerationPackageInput_ranges,
  'PostedPositionProfilePreview_RemunerationRangeInput.minimumAmount':
    PostedPositionProfilePreview_RemunerationRangeInput_minimumAmount,
  'PostedPositionProfilePreview_RemunerationRangeInput.maximumAmount':
    PostedPositionProfilePreview_RemunerationRangeInput_maximumAmount,
  'PostedPositionProfilePreview_RemunerationRangeInput.intervalCode':
    PostedPositionProfilePreview_RemunerationRangeInput_intervalCode,
  'ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionTitle':
    ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionTitle,
  'ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionFormattedDescriptions':
    ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionFormattedDescriptions,
  'ApplicationLibraryQuestionSuggestions_PositionProfileInput.jobCategories':
    ApplicationLibraryQuestionSuggestions_PositionProfileInput_jobCategories,
  'ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionLocation':
    ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionLocation,
  'ApplicationLibraryQuestionSuggestions_PositionProfileInput.positionOrganizations':
    ApplicationLibraryQuestionSuggestions_PositionProfileInput_positionOrganizations,
  'ApplicationLibraryQuestionPreferenceSelection.typeCode':
    ApplicationLibraryQuestionPreferenceSelection_typeCode,
  'ApplicationLibraryQuestionPreferenceSelection.message':
    ApplicationLibraryQuestionPreferenceSelection_message,
  'ApplicationLibraryQuestionChoice.id': ApplicationLibraryQuestionChoice_id,
  'ApplicationLibraryQuestionChoice.text':
    ApplicationLibraryQuestionChoice_text,
  'ApplicationLibraryQuestion.id': ApplicationLibraryQuestion_id,
  'ApplicationLibraryQuestion.text': ApplicationLibraryQuestion_text,
  'ApplicationLibraryQuestion.statusCode':
    ApplicationLibraryQuestion_statusCode,
  'ApplicationLibraryQuestion.responseTypeCode':
    ApplicationLibraryQuestion_responseTypeCode,
  'ApplicationLibraryQuestion.preferenceSelection':
    ApplicationLibraryQuestion_preferenceSelection,
  'ApplicationLibraryQuestion.responseChoice':
    ApplicationLibraryQuestion_responseChoice,
  'ApplicationLibraryQuestionSuggestion.id':
    ApplicationLibraryQuestionSuggestion_id,
  'ApplicationLibraryQuestionSuggestion.applicationLibraryQuestion':
    ApplicationLibraryQuestionSuggestion_applicationLibraryQuestion,
  'ApplicationLibraryQuestionSearchResult.id':
    ApplicationLibraryQuestionSearchResult_id,
  'ApplicationLibraryQuestionSearchResult.applicationLibraryQuestion':
    ApplicationLibraryQuestionSearchResult_applicationLibraryQuestion,
  'ApplicationQuestionnaire.id': ApplicationQuestionnaire_id,
  'ApplicationQuestionnaire.hirer': ApplicationQuestionnaire_hirer,
  'ApplicationQuestionnaire.components': ApplicationQuestionnaire_components,
  'ApplicationQuestionnaireComponent.id': ApplicationQuestionnaireComponent_id,
  'ApplicationQuestionnaireComponent.componentTypeCode':
    ApplicationQuestionnaireComponent_componentTypeCode,
  'ApplicationQuestionnaireComponent.value':
    ApplicationQuestionnaireComponent_value,
  'ApplicationQuestion.id': ApplicationQuestion_id,
  'ApplicationQuestion.componentTypeCode':
    ApplicationQuestion_componentTypeCode,
  'ApplicationQuestion.sourceCode': ApplicationQuestion_sourceCode,
  'ApplicationQuestion.applicationLibraryQuestion':
    ApplicationQuestion_applicationLibraryQuestion,
  'ApplicationQuestion.value': ApplicationQuestion_value,
  'ApplicationQuestion.questionHtml': ApplicationQuestion_questionHtml,
  'ApplicationQuestion.responseTypeCode': ApplicationQuestion_responseTypeCode,
  'ApplicationQuestion.responseChoice': ApplicationQuestion_responseChoice,
  'ApplicationPrivacyConsent.id': ApplicationPrivacyConsent_id,
  'ApplicationPrivacyConsent.componentTypeCode':
    ApplicationPrivacyConsent_componentTypeCode,
  'ApplicationPrivacyConsent.value': ApplicationPrivacyConsent_value,
  'ApplicationPrivacyConsent.privacyPolicyUrl':
    ApplicationPrivacyConsent_privacyPolicyUrl,
  'ApplicationPrivacyConsent.descriptionHtml':
    ApplicationPrivacyConsent_descriptionHtml,
  'ApplicationQuestionChoice.id': ApplicationQuestionChoice_id,
  'ApplicationQuestionChoice.text': ApplicationQuestionChoice_text,
  'ApplicationQuestionChoice.value': ApplicationQuestionChoice_value,
  'ApplicationQuestionChoice.preferredIndicator':
    ApplicationQuestionChoice_preferredIndicator,
  'ApplicationQuestionChoice.selectedIndicator':
    ApplicationQuestionChoice_selectedIndicator,
  'ApplicationQuestionChoice.applicationLibraryQuestionChoice':
    ApplicationQuestionChoice_applicationLibraryQuestionChoice,
  'ApplicationQuestionnaireComponentResponse.component':
    ApplicationQuestionnaireComponentResponse_component,
  'ApplicationQuestionnaireComponentResponse.componentTypeCode':
    ApplicationQuestionnaireComponentResponse_componentTypeCode,
  'ApplicationQuestionAnswer.choice': ApplicationQuestionAnswer_choice,
  'ApplicationQuestionAnswer.answer': ApplicationQuestionAnswer_answer,
  'ApplicationQuestionResponse.component':
    ApplicationQuestionResponse_component,
  'ApplicationQuestionResponse.componentTypeCode':
    ApplicationQuestionResponse_componentTypeCode,
  'ApplicationQuestionResponse.answers': ApplicationQuestionResponse_answers,
  'ApplicationQuestionResponse.score': ApplicationQuestionResponse_score,
  'ApplicationPrivacyConsentResponse.component':
    ApplicationPrivacyConsentResponse_component,
  'ApplicationPrivacyConsentResponse.componentTypeCode':
    ApplicationPrivacyConsentResponse_componentTypeCode,
  'ApplicationPrivacyConsentResponse.consentGivenIndicator':
    ApplicationPrivacyConsentResponse_consentGivenIndicator,
  'ApplicationQuestionnaireSubmission.questionnaire':
    ApplicationQuestionnaireSubmission_questionnaire,
  'ApplicationQuestionnaireSubmission.responses':
    ApplicationQuestionnaireSubmission_responses,
  'ApplicationQuestionnaireSubmission.score':
    ApplicationQuestionnaireSubmission_score,
  'ApplicationPrivacyConsentInput.componentTypeCode':
    ApplicationPrivacyConsentInput_componentTypeCode,
  'ApplicationPrivacyConsentInput.value': ApplicationPrivacyConsentInput_value,
  'ApplicationPrivacyConsentInput.privacyPolicyUrl':
    ApplicationPrivacyConsentInput_privacyPolicyUrl,
  'ApplicationPrivacyConsentInput.descriptionHtml':
    ApplicationPrivacyConsentInput_descriptionHtml,
  'ApplicationQuestionInput.componentTypeCode':
    ApplicationQuestionInput_componentTypeCode,
  'ApplicationQuestionInput.questionHtml':
    ApplicationQuestionInput_questionHtml,
  'ApplicationQuestionInput.responseTypeCode':
    ApplicationQuestionInput_responseTypeCode,
  'ApplicationQuestionInput.value': ApplicationQuestionInput_value,
  'ApplicationQuestionInput.responseChoice':
    ApplicationQuestionInput_responseChoice,
  'ApplicationQuestionChoiceInput.text': ApplicationQuestionChoiceInput_text,
  'ApplicationQuestionChoiceInput.value': ApplicationQuestionChoiceInput_value,
  'ApplicationQuestionChoiceInput.preferredIndicator':
    ApplicationQuestionChoiceInput_preferredIndicator,
  'ApplicationLibraryQuestionInput.componentTypeCode':
    ApplicationLibraryQuestionInput_componentTypeCode,
  'ApplicationLibraryQuestionInput.id': ApplicationLibraryQuestionInput_id,
  'ApplicationLibraryQuestionInput.selectedResponseChoice':
    ApplicationLibraryQuestionInput_selectedResponseChoice,
  'ApplicationQuestionnaireComponentInput.componentTypeCode':
    ApplicationQuestionnaireComponentInput_componentTypeCode,
  'ApplicationQuestionnaireComponentInput.question':
    ApplicationQuestionnaireComponentInput_question,
  'ApplicationQuestionnaireComponentInput.libraryQuestion':
    ApplicationQuestionnaireComponentInput_libraryQuestion,
  'ApplicationQuestionnaireComponentInput.privacyConsent':
    ApplicationQuestionnaireComponentInput_privacyConsent,
  'CreateApplicationQuestionnaire_ApplicationQuestionnaireInput.hirerId':
    CreateApplicationQuestionnaire_ApplicationQuestionnaireInput_hirerId,
  'CreateApplicationQuestionnaire_ApplicationQuestionnaireInput.components':
    CreateApplicationQuestionnaire_ApplicationQuestionnaireInput_components,
  'CreateApplicationQuestionnaireInput.applicationQuestionnaire':
    CreateApplicationQuestionnaireInput_applicationQuestionnaire,
  'CreateApplicationQuestionnairePayload.applicationQuestionnaire':
    CreateApplicationQuestionnairePayload_applicationQuestionnaire,
  'webhook.CandidateApplicationCreatedEvent':
    webhook_CandidateApplicationCreatedEvent,
  'webhook.CandidateProfilePurchasedEvent':
    webhook_CandidateProfilePurchasedEvent,
  'webhook.HirerRelationshipChangedEvent':
    webhook_HirerRelationshipChangedEvent,
  'webhook.PositionProfileClosedEvent': webhook_PositionProfileClosedEvent,
  'webhook.PositionProfilePostedEvent': webhook_PositionProfilePostedEvent,
  'Mutation.sendApplicationSignal.input': Mutation_sendApplicationSignal_input,
  'Mutation.deleteUploadedCandidate.input':
    Mutation_deleteUploadedCandidate_input,
  'Mutation.uploadCandidate.input': Mutation_uploadCandidate_input,
  'Mutation.updateUploadedCandidatePerson.input':
    Mutation_updateUploadedCandidatePerson_input,
  'Mutation.updateUploadedCandidateProfileActions.input':
    Mutation_updateUploadedCandidateProfileActions_input,
  'Mutation.updateUploadedCandidateProfileDates.input':
    Mutation_updateUploadedCandidateProfileDates_input,
  'Mutation.updateUploadedCandidateProfilePositionPreferences.input':
    Mutation_updateUploadedCandidateProfilePositionPreferences_input,
  'Mutation.createCandidateProcessHistoryItem.input':
    Mutation_createCandidateProcessHistoryItem_input,
  'Mutation.deleteCandidateProcessHistoryItem.input':
    Mutation_deleteCandidateProcessHistoryItem_input,
  'Mutation.updateCandidateProcessHistoryItem.input':
    Mutation_updateCandidateProcessHistoryItem_input,
  'Mutation.createWebhookSubscription.input':
    Mutation_createWebhookSubscription_input,
  'Mutation.updateWebhookSubscriptionDeliveryConfiguration.input':
    Mutation_updateWebhookSubscriptionDeliveryConfiguration_input,
  'Mutation.updateWebhookSubscriptionSigningConfiguration.input':
    Mutation_updateWebhookSubscriptionSigningConfiguration_input,
  'Mutation.deleteWebhookSubscription.input':
    Mutation_deleteWebhookSubscription_input,
  'Mutation.replayWebhookSubscription.input':
    Mutation_replayWebhookSubscription_input,
  'Mutation.createPositionOpening.input': Mutation_createPositionOpening_input,
  'Mutation.updatePositionOpeningPersonContacts.input':
    Mutation_updatePositionOpeningPersonContacts_input,
  'Mutation.updatePositionOpeningStatus.input':
    Mutation_updatePositionOpeningStatus_input,
  'Mutation.deletePositionOpening.input': Mutation_deletePositionOpening_input,
  'Mutation.postPositionProfileForOpening.input':
    Mutation_postPositionProfileForOpening_input,
  'Mutation.updatePostedPositionProfile.input':
    Mutation_updatePostedPositionProfile_input,
  'Mutation.closePostedPositionProfile.input':
    Mutation_closePostedPositionProfile_input,
  'Mutation.postPosition.input': Mutation_postPosition_input,
  'Mutation.createUnpostedPositionProfileForOpening.input':
    Mutation_createUnpostedPositionProfileForOpening_input,
  'Mutation.updateUnpostedPositionProfile.input':
    Mutation_updateUnpostedPositionProfile_input,
  'Mutation.deleteUnpostedPositionProfile.input':
    Mutation_deleteUnpostedPositionProfile_input,
  'Mutation.createApplicationQuestionnaire.input':
    Mutation_createApplicationQuestionnaire_input,
  'Query.seekAnzHirerAdvertisementCreationProducts.hirerId':
    Query_seekAnzHirerAdvertisementCreationProducts_hirerId,
  'Query.seekAnzHirerAdvertisementCreationProducts.draftAdvertisement':
    Query_seekAnzHirerAdvertisementCreationProducts_draftAdvertisement,
  'Query.seekAnzHirerAdvertisementModificationProducts.hirerId':
    Query_seekAnzHirerAdvertisementModificationProducts_hirerId,
  'Query.seekAnzHirerAdvertisementModificationProducts.advertisementId':
    Query_seekAnzHirerAdvertisementModificationProducts_advertisementId,
  'Query.seekAnzHirerAdvertisementModificationProducts.draftAdvertisement':
    Query_seekAnzHirerAdvertisementModificationProducts_draftAdvertisement,
  'Query.seekAnzHirerAdvertisementModificationProductsAlt.hirerId':
    Query_seekAnzHirerAdvertisementModificationProductsAlt_hirerId,
  'Query.seekAnzHirerAdvertisementModificationProductsAlt.advertisement':
    Query_seekAnzHirerAdvertisementModificationProductsAlt_advertisement,
  'Query.seekAnzHirerAdvertisementModificationProductsAlt.draftAdvertisement':
    Query_seekAnzHirerAdvertisementModificationProductsAlt_draftAdvertisement,
  'Query.advertisementProducts.positionProfile':
    Query_advertisementProducts_positionProfile,
  'Query.advertisementProducts.selectedAdvertisementProductId':
    Query_advertisementProducts_selectedAdvertisementProductId,
  'Query.advertisementProducts.limit': Query_advertisementProducts_limit,
  'Query.applyWithSeekButton.input': Query_applyWithSeekButton_input,
  'Query.jobCategory.id': Query_jobCategory_id,
  'Query.jobCategories.positionProfile': Query_jobCategories_positionProfile,
  'Query.jobCategories.schemeId': Query_jobCategories_schemeId,
  'Query.jobCategories.countryCode': Query_jobCategories_countryCode,
  'Query.jobCategorySuggestions.positionProfile':
    Query_jobCategorySuggestions_positionProfile,
  'Query.jobCategorySuggestions.schemeId':
    Query_jobCategorySuggestions_schemeId,
  'Query.jobCategorySuggestions.first': Query_jobCategorySuggestions_first,
  'Query.location.id': Query_location_id,
  'Query.locationSuggestions.usageTypeCode':
    Query_locationSuggestions_usageTypeCode,
  'Query.locationSuggestions.schemeId': Query_locationSuggestions_schemeId,
  'Query.locationSuggestions.hirerId': Query_locationSuggestions_hirerId,
  'Query.locationSuggestions.text': Query_locationSuggestions_text,
  'Query.locationSuggestions.first': Query_locationSuggestions_first,
  'Query.nearestLocations.schemeId': Query_nearestLocations_schemeId,
  'Query.nearestLocations.geoLocation': Query_nearestLocations_geoLocation,
  'Query.nearestLocations.first': Query_nearestLocations_first,
  'Query.inferLocation.schemeId': Query_inferLocation_schemeId,
  'Query.inferLocation.address': Query_inferLocation_address,
  'Query.inferLocation.hirerId': Query_inferLocation_hirerId,
  'Query.currencies.usageTypeCode': Query_currencies_usageTypeCode,
  'Query.payTypes.schemeId': Query_payTypes_schemeId,
  'Query.advertisementBrandings.after': Query_advertisementBrandings_after,
  'Query.advertisementBrandings.before': Query_advertisementBrandings_before,
  'Query.advertisementBrandings.first': Query_advertisementBrandings_first,
  'Query.advertisementBrandings.last': Query_advertisementBrandings_last,
  'Query.advertisementBrandings.hirerId': Query_advertisementBrandings_hirerId,
  'Query.advertisementBranding.id': Query_advertisementBranding_id,
  'Query.candidateProfile.id': Query_candidateProfile_id,
  'Query.candidate.id': Query_candidate_id,
  'Query.candidateProcessHistoryItem.id': Query_candidateProcessHistoryItem_id,
  'Query.event.id': Query_event_id,
  'Query.events.after': Query_events_after,
  'Query.events.before': Query_events_before,
  'Query.events.first': Query_events_first,
  'Query.events.last': Query_events_last,
  'Query.events.filter': Query_events_filter,
  'Query.events.schemeId': Query_events_schemeId,
  'Query.webhookAttemptsForEvent.after': Query_webhookAttemptsForEvent_after,
  'Query.webhookAttemptsForEvent.before': Query_webhookAttemptsForEvent_before,
  'Query.webhookAttemptsForEvent.first': Query_webhookAttemptsForEvent_first,
  'Query.webhookAttemptsForEvent.last': Query_webhookAttemptsForEvent_last,
  'Query.webhookAttemptsForEvent.filter': Query_webhookAttemptsForEvent_filter,
  'Query.webhookAttemptsForEvent.eventId':
    Query_webhookAttemptsForEvent_eventId,
  'Query.webhookSubscription.id': Query_webhookSubscription_id,
  'Query.webhookSubscriptions.after': Query_webhookSubscriptions_after,
  'Query.webhookSubscriptions.before': Query_webhookSubscriptions_before,
  'Query.webhookSubscriptions.first': Query_webhookSubscriptions_first,
  'Query.webhookSubscriptions.last': Query_webhookSubscriptions_last,
  'Query.webhookSubscriptions.filter': Query_webhookSubscriptions_filter,
  'Query.webhookSubscriptions.schemeId': Query_webhookSubscriptions_schemeId,
  'Query.webhookSubscriptionReplay.id': Query_webhookSubscriptionReplay_id,
  'Query.webhookRequestsForSubscription.after':
    Query_webhookRequestsForSubscription_after,
  'Query.webhookRequestsForSubscription.before':
    Query_webhookRequestsForSubscription_before,
  'Query.webhookRequestsForSubscription.first':
    Query_webhookRequestsForSubscription_first,
  'Query.webhookRequestsForSubscription.last':
    Query_webhookRequestsForSubscription_last,
  'Query.webhookRequestsForSubscription.filter':
    Query_webhookRequestsForSubscription_filter,
  'Query.webhookRequestsForSubscription.subscriptionId':
    Query_webhookRequestsForSubscription_subscriptionId,
  'Query.webhookRequest.schemeId': Query_webhookRequest_schemeId,
  'Query.webhookRequest.requestId': Query_webhookRequest_requestId,
  'Query.hiringOrganization.id': Query_hiringOrganization_id,
  'Query.hiringOrganizations.schemeId': Query_hiringOrganizations_schemeId,
  'Query.hiringOrganizations.after': Query_hiringOrganizations_after,
  'Query.hiringOrganizations.before': Query_hiringOrganizations_before,
  'Query.hiringOrganizations.first': Query_hiringOrganizations_first,
  'Query.hiringOrganizations.last': Query_hiringOrganizations_last,
  'Query.hiringOrganizations.filter': Query_hiringOrganizations_filter,
  'Query.seekAnzAdvertiser.id': Query_seekAnzAdvertiser_id,
  'Query.positionOpening.id': Query_positionOpening_id,
  'Query.positionProfile.id': Query_positionProfile_id,
  'Query.positionOpenings.hirerId': Query_positionOpenings_hirerId,
  'Query.positionOpenings.after': Query_positionOpenings_after,
  'Query.positionOpenings.first': Query_positionOpenings_first,
  'Query.positionOpenings.filter': Query_positionOpenings_filter,
  'Query.postedPositionProfilePreview.positionProfile':
    Query_postedPositionProfilePreview_positionProfile,
  'Query.applicationLibraryQuestionSuggestions.positionProfile':
    Query_applicationLibraryQuestionSuggestions_positionProfile,
  'Query.applicationLibraryQuestionSuggestions.schemeId':
    Query_applicationLibraryQuestionSuggestions_schemeId,
  'Query.applicationLibraryQuestionSuggestions.first':
    Query_applicationLibraryQuestionSuggestions_first,
  'Query.applicationLibraryQuestionSearch.keywords':
    Query_applicationLibraryQuestionSearch_keywords,
  'Query.applicationLibraryQuestionSearch.hirerId':
    Query_applicationLibraryQuestionSearch_hirerId,
  'Query.applicationLibraryQuestionSearch.schemeId':
    Query_applicationLibraryQuestionSearch_schemeId,
  'Query.applicationLibraryQuestionSearch.first':
    Query_applicationLibraryQuestionSearch_first,
  'Query.applicationQuestionnaire.id': Query_applicationQuestionnaire_id,
  'Candidate.profiles.last': Candidate_profiles_last,
  'CandidateProfile.seekProcessHistory.after':
    CandidateProfile_seekProcessHistory_after,
  'CandidateProfile.seekProcessHistory.first':
    CandidateProfile_seekProcessHistory_first,
  'Event.webhookAttempts.after': Event_webhookAttempts_after,
  'Event.webhookAttempts.before': Event_webhookAttempts_before,
  'Event.webhookAttempts.first': Event_webhookAttempts_first,
  'Event.webhookAttempts.last': Event_webhookAttempts_last,
  'Event.webhookAttempts.filter': Event_webhookAttempts_filter,
  'CandidateApplicationCreatedEvent.webhookAttempts.after':
    CandidateApplicationCreatedEvent_webhookAttempts_after,
  'CandidateApplicationCreatedEvent.webhookAttempts.before':
    CandidateApplicationCreatedEvent_webhookAttempts_before,
  'CandidateApplicationCreatedEvent.webhookAttempts.first':
    CandidateApplicationCreatedEvent_webhookAttempts_first,
  'CandidateApplicationCreatedEvent.webhookAttempts.last':
    CandidateApplicationCreatedEvent_webhookAttempts_last,
  'CandidateApplicationCreatedEvent.webhookAttempts.filter':
    CandidateApplicationCreatedEvent_webhookAttempts_filter,
  'PositionProfileClosedEvent.webhookAttempts.after':
    PositionProfileClosedEvent_webhookAttempts_after,
  'PositionProfileClosedEvent.webhookAttempts.before':
    PositionProfileClosedEvent_webhookAttempts_before,
  'PositionProfileClosedEvent.webhookAttempts.first':
    PositionProfileClosedEvent_webhookAttempts_first,
  'PositionProfileClosedEvent.webhookAttempts.last':
    PositionProfileClosedEvent_webhookAttempts_last,
  'PositionProfileClosedEvent.webhookAttempts.filter':
    PositionProfileClosedEvent_webhookAttempts_filter,
  'PositionProfilePostedEvent.webhookAttempts.after':
    PositionProfilePostedEvent_webhookAttempts_after,
  'PositionProfilePostedEvent.webhookAttempts.before':
    PositionProfilePostedEvent_webhookAttempts_before,
  'PositionProfilePostedEvent.webhookAttempts.first':
    PositionProfilePostedEvent_webhookAttempts_first,
  'PositionProfilePostedEvent.webhookAttempts.last':
    PositionProfilePostedEvent_webhookAttempts_last,
  'PositionProfilePostedEvent.webhookAttempts.filter':
    PositionProfilePostedEvent_webhookAttempts_filter,
  'CandidateProfilePurchasedEvent.webhookAttempts.after':
    CandidateProfilePurchasedEvent_webhookAttempts_after,
  'CandidateProfilePurchasedEvent.webhookAttempts.before':
    CandidateProfilePurchasedEvent_webhookAttempts_before,
  'CandidateProfilePurchasedEvent.webhookAttempts.first':
    CandidateProfilePurchasedEvent_webhookAttempts_first,
  'CandidateProfilePurchasedEvent.webhookAttempts.last':
    CandidateProfilePurchasedEvent_webhookAttempts_last,
  'CandidateProfilePurchasedEvent.webhookAttempts.filter':
    CandidateProfilePurchasedEvent_webhookAttempts_filter,
  'HirerRelationshipChangedEvent.webhookAttempts.after':
    HirerRelationshipChangedEvent_webhookAttempts_after,
  'HirerRelationshipChangedEvent.webhookAttempts.before':
    HirerRelationshipChangedEvent_webhookAttempts_before,
  'HirerRelationshipChangedEvent.webhookAttempts.first':
    HirerRelationshipChangedEvent_webhookAttempts_first,
  'HirerRelationshipChangedEvent.webhookAttempts.last':
    HirerRelationshipChangedEvent_webhookAttempts_last,
  'HirerRelationshipChangedEvent.webhookAttempts.filter':
    HirerRelationshipChangedEvent_webhookAttempts_filter,
  'WebhookSubscription.webhookRequests.after':
    WebhookSubscription_webhookRequests_after,
  'WebhookSubscription.webhookRequests.before':
    WebhookSubscription_webhookRequests_before,
  'WebhookSubscription.webhookRequests.first':
    WebhookSubscription_webhookRequests_first,
  'WebhookSubscription.webhookRequests.last':
    WebhookSubscription_webhookRequests_last,
  'WebhookSubscription.webhookRequests.filter':
    WebhookSubscription_webhookRequests_filter,
  'WebhookSubscription.webhookSubscriptionReplays.after':
    WebhookSubscription_webhookSubscriptionReplays_after,
  'WebhookSubscription.webhookSubscriptionReplays.before':
    WebhookSubscription_webhookSubscriptionReplays_before,
  'WebhookSubscription.webhookSubscriptionReplays.first':
    WebhookSubscription_webhookSubscriptionReplays_first,
  'WebhookSubscription.webhookSubscriptionReplays.last':
    WebhookSubscription_webhookSubscriptionReplays_last,
  'WebhookSubscription.webhookSubscriptionReplays.filter':
    WebhookSubscription_webhookSubscriptionReplays_filter,
  'PositionOpening.paginatedPositionProfiles.after':
    PositionOpening_paginatedPositionProfiles_after,
  'PositionOpening.paginatedPositionProfiles.first':
    PositionOpening_paginatedPositionProfiles_first,
  'webhook.CandidateApplicationCreatedEvent.id':
    webhook_CandidateApplicationCreatedEvent_id,
  'webhook.CandidateApplicationCreatedEvent.candidateApplicationProfileId':
    webhook_CandidateApplicationCreatedEvent_candidateApplicationProfileId,
  'webhook.CandidateApplicationCreatedEvent.candidateId':
    webhook_CandidateApplicationCreatedEvent_candidateId,
  'webhook.CandidateApplicationCreatedEvent.hirerId':
    webhook_CandidateApplicationCreatedEvent_hirerId,
  'webhook.CandidateApplicationCreatedEvent.type':
    webhook_CandidateApplicationCreatedEvent_type,
  'webhook.CandidateApplicationCreatedEvent.createDateTime':
    webhook_CandidateApplicationCreatedEvent_createDateTime,
  'webhook.CandidateProfilePurchasedEvent.id':
    webhook_CandidateProfilePurchasedEvent_id,
  'webhook.CandidateProfilePurchasedEvent.candidateProfileId':
    webhook_CandidateProfilePurchasedEvent_candidateProfileId,
  'webhook.CandidateProfilePurchasedEvent.hirerId':
    webhook_CandidateProfilePurchasedEvent_hirerId,
  'webhook.CandidateProfilePurchasedEvent.type':
    webhook_CandidateProfilePurchasedEvent_type,
  'webhook.CandidateProfilePurchasedEvent.createDateTime':
    webhook_CandidateProfilePurchasedEvent_createDateTime,
  'webhook.HirerRelationshipChangedEvent.id':
    webhook_HirerRelationshipChangedEvent_id,
  'webhook.HirerRelationshipChangedEvent.hirerId':
    webhook_HirerRelationshipChangedEvent_hirerId,
  'webhook.HirerRelationshipChangedEvent.type':
    webhook_HirerRelationshipChangedEvent_type,
  'webhook.HirerRelationshipChangedEvent.createDateTime':
    webhook_HirerRelationshipChangedEvent_createDateTime,
  'webhook.PositionProfileClosedEvent.id':
    webhook_PositionProfileClosedEvent_id,
  'webhook.PositionProfileClosedEvent.hirerId':
    webhook_PositionProfileClosedEvent_hirerId,
  'webhook.PositionProfileClosedEvent.positionProfileId':
    webhook_PositionProfileClosedEvent_positionProfileId,
  'webhook.PositionProfileClosedEvent.type':
    webhook_PositionProfileClosedEvent_type,
  'webhook.PositionProfileClosedEvent.createDateTime':
    webhook_PositionProfileClosedEvent_createDateTime,
  'webhook.PositionProfilePostedEvent.id':
    webhook_PositionProfilePostedEvent_id,
  'webhook.PositionProfilePostedEvent.hirerId':
    webhook_PositionProfilePostedEvent_hirerId,
  'webhook.PositionProfilePostedEvent.positionProfileId':
    webhook_PositionProfilePostedEvent_positionProfileId,
  'webhook.PositionProfilePostedEvent.type':
    webhook_PositionProfilePostedEvent_type,
  'webhook.PositionProfilePostedEvent.createDateTime':
    webhook_PositionProfilePostedEvent_createDateTime,
  'webhook.events': webhook_events,
  'webhook.subscriptionId': webhook_subscriptionId,
  'webhook.url': webhook_url,
};
