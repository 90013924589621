
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier for the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationQuestionnaire",
        "title": "A set of questions presented to a candidate during an application"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{` to present to a candidate during their application.`}</p>
    <p>{`A preview of the question set may be displayed on the job ad.`}</p>
    <p>{`SEEK questionnaires are only supported on SEEK's Apply Form;
omit this field if an `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationMethodInput#/field/applicationUri",
        "title": "A URL of an external apply form"
      }}><inlineCode parentName="a">{`ApplicationMethodInput.applicationUri`}</inlineCode></a>{` is provided.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;