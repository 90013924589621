
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The code of the action.`}</p>
    <p>{`For process history actions, the following action codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AgencySubmission`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`CandidateWorkflowTransition`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Document`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/schema-new/named-type/Email",
            "title": "An email address"
          }}><inlineCode parentName="a">{`Email`}</inlineCode></a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Note`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PhoneCall`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PostPlacementActivity`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Screening`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`StatusChange`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`VerificationActivity`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Other`}</inlineCode></p>
      </li>
    </ul>
    <p>{`For profile actions, one action code is defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ViewProfile`}</inlineCode>{` indicates that the URL is used to view the candidate's profile.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;