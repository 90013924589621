
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The context that the location suggestions will be used in.`}</p>
    <p>{`Not all locations are accepted by all SEEK API operations.
Specifying the correct usage type ensures the returned locations are valid in the context they're consumed.`}</p>
    <p>{`Currently two codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PositionPosting`}</inlineCode>{` returns locations suitable for posting a position profile.
They would typically be passed to the `}<a parentName="p" {...{
            "href": "/schema-new/mutation/postPosition",
            "title": "Asynchronously posts a job ad for a new position opening"
          }}><inlineCode parentName="a">{`postPosition`}</inlineCode></a>{` or `}<a parentName="p" {...{
            "href": "/schema-new/mutation/postPositionProfileForOpening",
            "title": "Asynchronously posts a job ad for an existing position opening"
          }}><inlineCode parentName="a">{`postPositionProfileForOpening`}</inlineCode></a>{` mutation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ProactiveSourcing`}</inlineCode>{` returns locations suitable for proactively sourcing candidates.
They would typically be passed to the `}<a parentName="p" {...{
            "href": "/schema-new/mutation/createUnpostedPositionProfileForOpening",
            "title": "Creates a new unposted position profile for an opening"
          }}><inlineCode parentName="a">{`createUnpostedPositionProfileForOpening`}</inlineCode></a>{` mutation.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;