
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The identifier of this advertisement product.`}</p>
    <p>{`Identifiers may become stale, and should not be stored for long periods.`}</p>
    <p>{`It would be appropriate to save an identifier for use in a job ad draft, but not for use as a job ad template.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;