
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "auth"
    }}>{`Auth`}</h1>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`SEEK uses `}<a parentName="p" {...{
        "href": "https://oauth.net/2/"
      }}>{`OAuth 2.0`}</a>{` access tokens to authenticate access to the SEEK API.`}</p>
    <p>{`You are provided with client credentials which are exchanged for an expiring `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{`.
Partner tokens can be exchanged again for a restricted `}<a parentName="p" {...{
        "href": "/auth/browser-tokens"
      }}>{`browser token`}</a>{` to use from a hirer’s browser.`}</p>
    <h3 {...{
      "id": "client-credentials"
    }}>{`Client credentials`}</h3>
    <p>{`Your `}<a parentName="p" {...{
        "href": "https://oauth.net/2/"
      }}>{`OAuth 2.0`}</a>{` client credentials authenticate your organization to SEEK.
They grant full access to the data of your authorized SEEK hirers and their candidates.`}</p>
    <p>{`You may issue your credentials on the `}<a parentName="p" {...{
        "href": "/manage/credentials"
      }}>{`Developer Dashboard’s credentials page`}</a>{`.
You may also rotate your credentials there if they are lost or compromised.`}</p>
    <p>{`Store your client credentials securely in a system such as `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/secrets-manager/"
      }}>{`AWS Secrets Manager`}</a>{` or an encrypted local filesystem.
Never send your unencrypted credentials over an insecure channel such as email or Slack.`}</p>
    <h3 {...{
      "id": "partner-and-browser-tokens"
    }}>{`Partner and browser tokens`}</h3>
    <p>{`The SEEK API issues two types of access tokens depending on the calling system:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/auth/partner-tokens"
          }}>{`Partner tokens`}</a>{` authenticate your software’s backend to the SEEK API.
A partner token grants access to the data of any SEEK hirer you have a `}<a parentName="p" {...{
            "href": "/auth/hirer-relationships"
          }}>{`relationship`}</a>{` with.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`Browser tokens`}</a>{` allow you to query the SEEK API directly from a hirer’s browser or mobile app.
A browser token is scoped to a SEEK hirer and a set of actions that can be performed on the hirer’s behalf.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "hirer-relationships"
    }}>{`Hirer relationships`}</h2>
    <p>{`The SEEK API authorizes operations based on a set of `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`relationships`}</a>{` between you and your SEEK hirers.
Each `}<a parentName="p" {...{
        "href": "/"
      }}>{`use case`}</a>{` requires a corresponding relationship to allow you to act on behalf of the hirer.
SEEK’s support team must explicitly configure these relationships as part of onboarding a SEEK hirer to your software.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;