
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The scheme ID of the suggested job categories.`}</p>
    <p>{`Currently, only `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` and `}<inlineCode parentName="p">{`seekAnzPublicTest`}</inlineCode>{` are supported.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;