import { Box, Stack } from 'braid-design-system';
import type { ComponentProps, FunctionComponent } from 'react';

import { SIZE_TO_SPACE, type Size } from './size';

import * as styles from './Wrapper.css';

export type WrapperComponent = FunctionComponent<
  Pick<ComponentProps<typeof Stack>, 'children'>
>;

type WrapperProps = Pick<ComponentProps<typeof Stack>, 'children'> & {
  component?: WrapperComponent;
  size: Size;
};

export const Wrapper = ({
  children,
  component: Component,
  size,
}: WrapperProps) => {
  const space = SIZE_TO_SPACE[size];

  return (
    <Box className={styles.wrapper}>
      <Stack space={space}>
        {typeof Component === 'undefined' ? (
          children
        ) : (
          <Component>{children}</Component>
        )}
      </Stack>
    </Box>
  );
};
