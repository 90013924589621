
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether the candidate must not be contacted by hirers.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For uploaded candidates from the Proactive Sourcing use case,
this field may be set to prevent hirers from contacting them through SEEK Talent Search.
A `}<inlineCode parentName="p">{`null`}</inlineCode>{` value is treated the same as an explicit `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For `}<a parentName="p" {...{
            "href": "/schema-new/named-type/PositionOpening",
            "title": "A job requisition or position opening within an organization"
          }}><inlineCode parentName="a">{`PositionOpening`}</inlineCode></a>{` contact people from the Job Posting and Proactive Sourcing use cases,
this field is always ignored.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;