import {
  Alert,
  Bleed,
  Box,
  Column,
  Columns,
  Divider,
  FieldLabel,
  Heading,
  IconChevron,
  List,
  Stack,
  type Step,
  Text,
} from 'braid-design-system';
import { Interweave } from 'interweave';
import React, {
  type ComponentProps,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useState,
} from 'react';
import { CodeBlock, InlineCode, SmartTextLink } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

import * as styles from './AdSelectionPanel.css';

/**
 * TODO: replace this with a real Playground response via Lovato snapshot.
 */
export const SNAPSHOT_AD_PRODUCTS = {
  products: [
    {
      id: {
        value:
          'globalPublicTest:adProduct:seekApi:EWHKTY9D6mJFiA5EH6P7MFbCR3cKV9WdNSMC6e1vqiUJ',
      },
      label: 'Classic',
      description: 'Find great candidates with our basic ad',
      sellingPoints: [
        {
          text: '30 day listing on SEEK',
        },
        {
          text: 'Your job ad emailed to relevant candidates',
        },
      ],
      price: {
        summary: 'AUD 269.89',
      },
      selected: false,
      features: {
        branding: null,
        searchBulletPoints: null,
      },
      payment: {
        summaryHtml:
          'We’ll deduct <strong>AUD 269.89</strong> from your contract.',
      },
    },
    {
      id: {
        value:
          'globalPublicTest:adProduct:seekApi:EWHKTY9D6mJFiA5EH6P7MFbByJ1JVteAQQkfa3uMJLPJ',
      },
      label: 'Branded',
      description: 'Stand out with branding and key selling points',
      sellingPoints: [
        {
          text: 'Logo and cover image to promote your brand',
        },
      ],
      price: {
        summary: 'AUD 364.59',
      },
      selected: false,
      features: {
        branding: {
          coverImageIndicator: true,
          logoIndicator: true,
        },
        searchBulletPoints: {
          limit: 3,
        },
      },
      payment: {
        summaryHtml:
          'We’ll deduct <strong>AUD 364.59</strong> from your contract.',
      },
    },
    {
      id: {
        value:
          'globalPublicTest:adProduct:seekApi:EWHKTY9D6mJFiA5EH6P7MFaUj6LchctVWMg2zsCHxbpQ',
      },
      label: 'Premium',
      description: 'Attract more candidates on average with a featured listing',
      sellingPoints: [
        {
          text: 'Priority listing in search',
        },
      ],
      price: {
        summary: 'AUD 645.00',
      },
      selected: false,
      features: {
        branding: {
          coverImageIndicator: true,
          logoIndicator: true,
        },
        searchBulletPoints: {
          limit: 3,
        },
      },
      payment: {
        summaryHtml:
          'We’ll deduct <strong>AUD 645.00</strong> from your contract.',
      },
    },
  ],
  information:
    "Ad prices vary based on many factors including the supply of, and demand for, candidates for the advertised role. Prices shown represent today's prices only. If you schedule your job ad for a future date, you acknowledge and agree you'll be charged the price of that ad at the date it's published on SEEK's website.",
};

/**
 * TODO: replace this with a real Playground response via Lovato snapshot.
 */
const AD_PRODUCTS = {
  products: [
    {
      description: 'Find great candidates with our basic ad',
      features: {
        branding: {
          coverImageIndicator: false,
          logoIndicator: true,
        },
        searchBulletPoints: null,
      },
      id: {
        value:
          'globalPublicTest:advertisementProduct:products:AXwwLCx2cqKvgEvULe6reS',
      },
      label: 'Basic',
      payment: {
        summaryHtml:
          'We’ll deduct <strong>SGD 260.00</strong> from your contract.',
      },
      price: {
        summary: 'SGD 260.00',
      },
      selected: true,
      sellingPoints: [
        { text: 'Similar ads get between 10 and 20 applications' },
        { text: '30-day listing' },
        { text: 'Include your company logo' },
      ],
    },
    {
      description: 'Stand out with branding and key selling points',
      features: {
        branding: {
          coverImageIndicator: true,
          logoIndicator: true,
        },
        searchBulletPoints: {
          limit: 3,
        },
      },
      id: {
        value:
          'globalPublicTest:advertisementProduct:products:5VcLr7hZgRMwF17zTWCoKQ',
      },
      label: 'Branded',
      payment: {
        summaryHtml:
          'We’ll deduct <strong>SGD 320.00</strong> from your contract.',
      },
      price: {
        summary: 'SGD 320.00',
      },
      selected: false,
      sellingPoints: [
        { text: 'Similar ads get between 20 and 30 applications' },
        { text: 'Add company images to promote your brand' },
        { text: 'Highlight 3 key selling points to attract candidates' },
      ],
    },
    {
      description: 'Attract more candidates on average with a featured listing',
      features: {
        branding: {
          coverImageIndicator: true,
          logoIndicator: true,
        },
        searchBulletPoints: {
          limit: 3,
        },
      },
      id: {
        value:
          'globalPublicTest:advertisementProduct:products:41ne8pFzgNsj2hQUd46B9F',
      },
      label: 'Premium',
      payment: {
        summaryHtml:
          'We’ll deduct <strong>SGD 540.00</strong> from your contract.',
      },
      price: {
        summary: 'SGD 540.00',
      },
      selected: false,
      sellingPoints: [
        { text: 'Similar ads get between 30 and 40 applications' },
        { text: 'Priority listing in search' },
        { text: 'Get candidates fast' },
      ],
    },
  ],
  information:
    'Ad prices vary based on many factors including the supply of, and demand for, candidates for the advertised role. Prices shown represent today’s prices only. If you schedule your job ad for a future date, you acknowledge and agree you’ll be charged the price of that ad at the date it’s published on SEEK’s website.',
};

const STEP_MAP = {
  products: {
    description:
      'Display products in the returned list order. We recommend a tile layout.',
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProducts/field/products',
  },
  'products[].selected': {
    description: [
      <>
        Pre-select the product with a <InlineCode>selected</InlineCode>{' '}
        indicator set to <InlineCode>true</InlineCode>. If all indicators are{' '}
        <InlineCode>false</InlineCode>, then no ad product should be selected by
        default.
      </>,
      <>
        This is typically returned when you provide{' '}
        <SmartTextLink href="https://developer.seek.com/schema/#/named-type/AdvertisementProducts_PositionProfileInput/field/profileId">
          <InlineCode>positionProfile.profileId</InlineCode>
        </SmartTextLink>{' '}
        to update a job ad or{' '}
        <SmartTextLink href="https://developer.seek.com/schema/#/query/advertisementProducts/argument/selectedAdvertisementProductId">
          <InlineCode>selectedAdvertisementProductId</InlineCode>
        </SmartTextLink>{' '}
        to restore the previous selection of a job ad draft.
      </>,
    ],
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/selected',
  },
  'products[].label': {
    description: 'Display the label as the headline of the product.',
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/label',
  },
  'products[].price.summary': {
    description:
      'Display the price summary after the label.  If the price is null, do not display a price.',
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProductPriceDetails/field/summary',
  },
  'products[].description': {
    description: 'Display the description after the price summary.',
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/description',
  },
  'products[].sellingPoints': {
    description: (
      <>
        Display the selling points alongside the product. We recommend an
        unordered list of the inner{' '}
        <SmartTextLink href="https://developer.seek.com/schema/#/named-type/AdvertisementProductSellingPoint/field/text">
          <InlineCode>text</InlineCode>
        </SmartTextLink>
        s.
      </>
    ),
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/sellingPoints',
  },
  information: {
    description:
      'Display informational text below the products. This field must be dynamically retrieved from the response as it may contain tailored legal disclaimers.',
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProducts/field/information',
  },
  'products[].payment.summaryHtml': {
    description: [
      'Conditionally display the payment summary below the products. This field should only be displayed when a product is selected.',
      <>
        If rendering HTML is impractical for your software, you may use the
        plain-text{' '}
        <SmartTextLink href="https://developer.seek.com/schema/#/named-type/AdvertisementProductPaymentDetails/field/summary">
          <InlineCode>summary</InlineCode>
        </SmartTextLink>{' '}
        instead.
      </>,
    ],
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProductPaymentDetails/field/summaryHtml',
  },
  'products[].features': {
    description:
      'Conditionally display subsequent fields to select a brand or enter search bullet points on the job posting form. Each field should only be displayed when a product is selected and the corresponding feature is supported by the selected product.',
    schema:
      'https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/features',
  },
};

const STEPS = Object.keys(STEP_MAP) as Array<keyof typeof STEP_MAP>;

type Step = (typeof STEPS)[number];

const ctx = React.createContext<
  [StepState, Dispatch<SetStateAction<StepState>>]
>(['hide', () => undefined]);

interface Props {
  children: ReactNode;
  enabled: boolean;
}

type StepState = number | 'show' | 'hide';

export const StepProvider = ({ children, enabled }: Props) => {
  const state = useState<StepState>(enabled ? 'show' : 'hide');

  return <ctx.Provider value={state}>{children}</ctx.Provider>;
};

export const useStepState = () => useContext(ctx);

interface HighlightBoxProps {
  children: JSX.Element | ((highlighted: boolean) => JSX.Element);
  disabled?: boolean;
  id?: string;
  height?: 'full';
  highlight: Step;
  paddingX?: ComponentProps<typeof Bleed>['horizontal'];
  paddingY?: ComponentProps<typeof Bleed>['vertical'];
}

const HighlightBox = ({
  children,
  disabled,
  height,
  highlight,
  paddingX,
  paddingY,
}: HighlightBoxProps) => {
  const [stepState, setStepState] = useStepState();

  const highlighted =
    typeof stepState === 'number' && highlight === STEPS[stepState];

  const inner =
    typeof children === 'function' ? children(highlighted) : children;

  if (disabled) {
    return inner;
  }

  const Wrapper = (props: { children: JSX.Element }) =>
    paddingX ?? paddingY ? (
      <Bleed horizontal={paddingX} vertical={paddingY}>
        {props.children}
      </Bleed>
    ) : (
      props.children
    );

  return (
    <Wrapper>
      <Box
        background={highlighted ? 'formAccentSoft' : undefined}
        boxShadow={highlighted ? 'borderFormAccent' : undefined}
        height={height}
        paddingX={paddingX}
        paddingY={paddingY}
        position="relative"
      >
        {inner}

        {stepState !== 'hide' && (
          <Box
            display="flex"
            justifyContent="flexEnd"
            margin="xsmall"
            onMouseEnter={(event) => {
              event.stopPropagation();

              setStepState(STEPS.indexOf(highlight));
            }}
            position="absolute"
            right={0}
            top={0}
          >
            <Text size="xsmall" tone="formAccent">
              <InlineCode>
                {stepState === 'show' || highlighted ? `${highlight} ` : ''}
                {STEPS.indexOf(highlight) + 1}
              </InlineCode>
            </Text>
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

interface AdProductProps {
  description: string;
  label: string;
  onClick: () => void;
  preselected: boolean;
  price: string;
  selected: boolean;
  sellingPoints: ReadonlyArray<{ text: string }>;
}

const AdProduct = ({
  description,
  label,
  onClick,
  preselected,
  price,
  sellingPoints,
  selected,
}: AdProductProps) => (
  <Box
    position="relative"
    borderRadius="large"
    cursor="pointer"
    display="flex"
    height="full"
    justifyContent="spaceBetween"
    boxShadow="borderNeutralLight"
    onClick={onClick}
    className={styles.adProductTile}
  >
    <Box
      position="absolute"
      inset={0}
      opacity={selected ? undefined : 0}
      transition="fast"
      pointerEvents="none"
      borderRadius="large"
      boxShadow={selected ? 'borderFormAccentLarge' : 'borderFormAccent'}
      className={selected ? undefined : styles.hoverOverlay}
    />
    <HighlightBox
      disabled={!preselected}
      height="full"
      highlight="products[].selected"
    >
      <Box paddingX="gutter" paddingY="large">
        <Stack space="gutter">
          <Stack space="medium">
            <HighlightBox
              highlight="products[].label"
              paddingX="gutter"
              paddingY="small"
            >
              <Text size="large" weight="strong">
                {label}
              </Text>
            </HighlightBox>

            <HighlightBox
              highlight="products[].price.summary"
              paddingX="gutter"
              paddingY="small"
            >
              <Heading level="3">{price}</Heading>
            </HighlightBox>

            <HighlightBox
              highlight="products[].description"
              paddingX="gutter"
              paddingY="small"
            >
              <Text size="small" tone="secondary">
                {description}
              </Text>
            </HighlightBox>
          </Stack>

          <HighlightBox
            highlight="products[].sellingPoints"
            paddingX="gutter"
            paddingY="small"
          >
            <List size="small" space="small">
              {sellingPoints.map((point) => (
                <Text key={point.text} tone="secondary">
                  {point.text}
                </Text>
              ))}
            </List>
          </HighlightBox>
        </Stack>
      </Box>
    </HighlightBox>
  </Box>
);

interface AdSelectionPanelContentProps {
  data?: typeof AD_PRODUCTS;
  mode: 'graphql' | 'panel' | 'panelDev';
}

const AdSelectionPanelContent = ({
  data = AD_PRODUCTS,
  mode,
}: AdSelectionPanelContentProps) => {
  const id = 'ad-selection-panel-input';

  const preselectedIndex = data.products.findIndex(
    (product) => product.selected,
  );

  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    preselectedIndex === -1 ? null : preselectedIndex,
  );

  const [, setStepState] = useStepState();

  const onChange =
    selectedIndex === null
      ? null
      : {
          selectedProduct: {
            id: data.products[selectedIndex].id.value,
            features: data.products[selectedIndex].features,
          },
        };

  const hasFeature =
    selectedIndex !== null &&
    Object.values(data.products[selectedIndex].features).some(Boolean);

  return (
    <Box alignItems="flexStart" display="flex" flexDirection="column">
      <Box background="surface" width="full">
        <Stack space="gutter">
          <Text
            align="center"
            icon={<IconChevron direction="down" />}
            size="xsmall"
            tone="secondary"
          />

          <BorderCard className={styles.minPanelWidth}>
            <Stack space="large">
              <Stack space="gutter">
                <HighlightBox
                  highlight="products"
                  paddingX="gutter"
                  paddingY="small"
                >
                  <Stack space="small">
                    <FieldLabel
                      htmlFor={`${id}-product`}
                      label="Select an ad type"
                    />

                    <Columns space="small">
                      {data.products.map((product, index) => (
                        <Column key={product.id.value}>
                          <AdProduct
                            description={product.description}
                            label={product.label}
                            onClick={() =>
                              setSelectedIndex((prev) =>
                                prev === index ? null : index,
                              )
                            }
                            preselected={product.selected}
                            price={product.price.summary}
                            selected={index === selectedIndex}
                            sellingPoints={product.sellingPoints}
                          />
                        </Column>
                      ))}
                    </Columns>
                  </Stack>
                </HighlightBox>

                <HighlightBox
                  highlight="information"
                  paddingX="gutter"
                  paddingY="small"
                >
                  <Text size="xsmall" tone="secondary">
                    {data.information}
                  </Text>
                </HighlightBox>

                {(mode === 'graphql' || selectedIndex !== null) && (
                  <HighlightBox
                    highlight="products[].payment.summaryHtml"
                    paddingY="small"
                    paddingX="gutter"
                  >
                    {(highlighted) => (
                      <>
                        {highlighted && selectedIndex === null && (
                          <Text size="xsmall" tone="formAccent">
                            Hide payment summary until a product has been
                            selected.
                          </Text>
                        )}

                        {selectedIndex !== null && (
                          <Alert tone="info">
                            <Text>
                              <Interweave
                                allowList={['strong']}
                                content={
                                  data.products[selectedIndex].payment
                                    .summaryHtml
                                }
                              />
                            </Text>
                          </Alert>
                        )}
                      </>
                    )}
                  </HighlightBox>
                )}

                {(mode === 'graphql' ||
                  (mode === 'panelDev' && hasFeature)) && (
                  <HighlightBox
                    highlight="products[].features"
                    paddingX="gutter"
                    paddingY="small"
                  >
                    {(highlighted) => (
                      <Stack space="large">
                        {highlighted && selectedIndex === null && (
                          <Text size="xsmall" tone="formAccent">
                            Hide features until a product has been selected.
                          </Text>
                        )}

                        {highlighted &&
                          selectedIndex !== null &&
                          !hasFeature && (
                            <Text size="xsmall" tone="formAccent">
                              Hide features when they are not supported by the
                              selected product.
                            </Text>
                          )}

                        {selectedIndex !== null &&
                          data.products[selectedIndex].features.branding && (
                            <Stack space="small">
                              <FieldLabel
                                htmlFor={`${id}-branding`}
                                label="Branding"
                              />

                              <Text
                                id={`${id}-branding`}
                                size="small"
                                tone="secondary"
                              >
                                Display the brand selection field here.
                              </Text>
                            </Stack>
                          )}

                        {selectedIndex !== null &&
                          data.products[selectedIndex].features
                            .searchBulletPoints && (
                            <Stack space="small">
                              <FieldLabel
                                htmlFor={`${id}-search-bullet-points`}
                                label="Search bullet points"
                              />

                              <Text
                                id={`${id}-search-bullet-points`}
                                size="small"
                                tone="secondary"
                              >
                                Display search bullet point fields here.
                              </Text>
                            </Stack>
                          )}
                      </Stack>
                    )}
                  </HighlightBox>
                )}
              </Stack>

              {mode === 'panelDev' && (
                <>
                  <Divider />
                  <CodeBlock label="onChange" language="json">
                    {JSON.stringify(onChange, null, 2)}
                  </CodeBlock>
                </>
              )}
            </Stack>
          </BorderCard>
        </Stack>
      </Box>

      {mode === 'graphql' ? (
        <Box onMouseLeave={() => setStepState('show')} paddingTop="large">
          <List space="large" type="number">
            {Object.entries(STEP_MAP).map(
              ([label, { description, schema }], index) => (
                <Bleed right="gutter" key={label}>
                  <Box
                    className={styles.step}
                    paddingRight="gutter"
                    onMouseEnter={() => setStepState(index)}
                    transition="touchable"
                  >
                    <Stack space="medium">
                      <Text>
                        <SmartTextLink href={schema}>
                          <InlineCode>{label}</InlineCode>
                        </SmartTextLink>
                      </Text>

                      {(Array.isArray(description)
                        ? description
                        : [description]
                      ).map((line, lineIndex) => (
                        <Text key={lineIndex}>{line}</Text>
                      ))}
                    </Stack>
                  </Box>
                </Bleed>
              ),
            )}
          </List>
        </Box>
      ) : null}
    </Box>
  );
};

interface AdSelectionPanelProps extends AdSelectionPanelContentProps {}

export const AdSelectionPanel = (props: AdSelectionPanelProps) => (
  <StepProvider enabled={props.mode === 'graphql'}>
    <AdSelectionPanelContent {...props} />
  </StepProvider>
);
