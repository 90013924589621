import { IconDocumentBroken } from 'braid-design-system';
import { useLocation } from 'react-router-dom';

import { Oops } from 'src/App/OopsPage';

import { BaseSchemaPage } from '../components/BaseSchemaPage';

export const OopsSchemaPage = () => {
  const location = useLocation();

  return (
    <BaseSchemaPage
      title={location.pathname}
      titleText="Not found"
      icon={<IconDocumentBroken />}
    >
      <Oops />
    </BaseSchemaPage>
  );
};
