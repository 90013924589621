import type { GraphQLNamedType } from 'graphql';

interface SubordinateType {
  type: GraphQLNamedType;
  parentName: string;
  label: string;
}

export interface NestedTypes {
  rootTypes: GraphQLNamedType[];
  subordinateTypes: Map<string, SubordinateType[]>;
}

// Types that can have subordinate types
const TYPE_GROUP_SUFFIXES = ['Payload', 'Input'];

// We render operation types specially
const HIDDEN_TYPE_NAMES = ['Mutation', 'Query', 'Subscription'];

/**
 * Returns a subordinate type for a given named type
 *
 * If this doesn't look like a subordinate type this will return `undefined`
 */
const trySubordinateType = (
  type: GraphQLNamedType,
): SubordinateType | undefined => {
  const suffix = TYPE_GROUP_SUFFIXES.find((s) => type.name.endsWith(s));
  if (!suffix) {
    return;
  }

  const nameComponents = type.name.split('_');
  if (nameComponents.length !== 2) {
    return;
  }

  const parentName = `${nameComponents[0]}${suffix}`;

  return {
    parentName,
    type,
    // Build a label relative to our root type
    label: type.name.slice(parentName.length - suffix.length + 1),
  };
};

export const nestSubordinateTypes = (
  namedTypes: Record<string, GraphQLNamedType>,
): NestedTypes => {
  const rootTypes: GraphQLNamedType[] = [];
  const subordinateTypes = new Map<string, SubordinateType[]>();

  for (const type of Object.values(namedTypes)) {
    const shouldHide = type.name.startsWith('_');

    if (shouldHide || HIDDEN_TYPE_NAMES.includes(type.name)) {
      // Shouldn't appear in the navigation
      continue;
    }

    const subordinateType = trySubordinateType(type);

    if (subordinateType && namedTypes[subordinateType.parentName]) {
      const subordinates =
        subordinateTypes.get(subordinateType.parentName) ?? [];

      subordinates.push(subordinateType);
      subordinateTypes.set(subordinateType.parentName, subordinates);
    } else {
      rootTypes.push(type);
    }
  }

  return { rootTypes, subordinateTypes };
};
