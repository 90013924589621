import { List, Text } from 'braid-design-system';
import { Table, TableRow } from 'scoobie';

export const MinimumFieldList = () => (
  <Table header={['Category', 'Required fields']}>
    <TableRow>
      <Text>Personal details</Text>
      <List>
        <Text>First name</Text>
        <Text>Last name</Text>
        <Text>Phone number</Text>
        <Text>Email address</Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Career history</Text>
      <List>
        <Text>Job title</Text>
        <Text>Company name</Text>
        <Text>Started</Text>
        <Text>Ended</Text>
        <Text>Description</Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Attachments</Text>
      <List>
        <Text>Resume file</Text>
      </List>
    </TableRow>
  </Table>
);
