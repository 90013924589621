import {
  Box,
  FieldLabel,
  IconPromote,
  Stack,
  Text,
  Tiles,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import { BorderCard } from 'src/components/BorderCard/BorderCard';

import * as styles from './BoardSelect.css';

type Tone = 'brandAccent' | 'formAccent' | 'secondary';

interface BoardProps {
  description: string;
  icon?: ReactNode;
  name: string;
  tone: Tone;
}

const BOARDS: BoardProps[] = [
  {
    description: 'Now with Jobstreet & Jobsdb.',
    icon: <IconPromote />,
    name: 'SEEK',
    tone: 'brandAccent',
  },
  {
    description: 'We add rockets to everything.',
    name: 'Acme',
    tone: 'formAccent',
  },
  {
    description: 'Newspapers are back, baby!',
    name: 'Classifieds',
    tone: 'formAccent',
  },
  {
    description: 'More jobs in real time.',
    name: 'Jora',
    tone: 'formAccent',
  },
  {
    description: 'Only available through SEEK.',
    name: 'Jobstreet',
    tone: 'secondary',
  },
  {
    description: 'Only available through SEEK.',
    name: 'Jobsdb',
    tone: 'secondary',
  },
];

const Board = ({ description, icon, name, tone }: BoardProps) => (
  <Box
    borderRadius="large"
    className={styles.boardCard[tone]}
    height="full"
    padding="gutter"
  >
    <Stack space="medium">
      <Text weight="strong" tone={tone}>
        {name}
      </Text>

      <Text size="small" tone={tone}>
        {icon}
        {icon ? ' ' : undefined}
        {description}
      </Text>
    </Stack>
  </Box>
);

export const BoardSelect = () => {
  const id = 'board-select-input';

  return (
    <BorderCard>
      <Stack space="small">
        <FieldLabel htmlFor={id} label="Select a job board" />

        <Box id={id}>
          <Tiles columns={[1, 3]} space="small">
            {BOARDS.map((board) => (
              <Board {...board} key={board.name} />
            ))}
          </Tiles>
        </Box>
      </Stack>
    </BorderCard>
  );
};
