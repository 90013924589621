import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Heading, Notice, Stack, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';

import { SystemStatus } from './components/SystemStatus/SystemStatus';

export const DashboardHomePage = () => {
  const { user } = useAuth0();

  const name = user?.given_name ?? user?.name ?? user?.email;

  return (
    <PageWrapper
      heading={
        <Heading level="2" weight="weak">
          👋 Hi there{name ? `, ${name}` : ''}!
        </Heading>
      }
    >
      <Stack space="large">
        <PageGutter>
          <Notice tone="promote">
            <Text>
              The Developer Dashboard lets you interactively observe and
              configure your SEEK API integration.
            </Text>
          </Notice>
        </PageGutter>

        <Divider />

        <SystemStatus />

        <Divider />

        <PageGutter>
          <Text>
            You may also want to check out{' '}
            <SmartTextLink href="https://developer.seek.com">
              https://developer.seek.com
            </SmartTextLink>
            , our documentation site.
          </Text>
        </PageGutter>
      </Stack>
    </PageWrapper>
  );
};
