import {
  Actions,
  Box,
  Button,
  Divider,
  IconDocument,
  IconDownload,
  Stack,
  Text,
} from 'braid-design-system';
import { printSchema } from 'graphql';
import { InlineCode, SmartTextLink } from 'scoobie';

import { BaseSchemaPage } from '../components/BaseSchemaPage';
import { schema } from '../schema';

import * as styles from './SchemaDefinitionPage.css';

const FILENAME = 'seek-api-schema.graphql';

const downloadSchema = (data: string) => {
  const blob = new Blob([data], { type: 'text/csv' });

  const elem = window.document.createElement('a');

  elem.setAttribute('href', window.URL.createObjectURL(blob));

  elem.setAttribute('download', FILENAME);
  elem.style.display = 'none';

  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const SchemaDefinitionPage = () => (
  <BaseSchemaPage
    icon={<IconDocument alignY="lowercase" />}
    title="Schema definition"
  >
    <Text>
      The SEEK API’s schema expressed in{' '}
      <SmartTextLink href="https://graphql.org/learn/schema/#type-language">
        GraphQL schema definition language
      </SmartTextLink>
      .
    </Text>

    <Divider />

    <SchemaDefinitionSection />
  </BaseSchemaPage>
);

export const SchemaDefinitionSection = () => {
  const printed = printSchema(schema);

  return (
    <Stack space="xlarge">
      <Actions>
        <Button icon={<IconDownload />} onClick={() => downloadSchema(printed)}>
          Download
        </Button>
      </Actions>

      <Text>
        <Box className={styles.schemaContainer}>
          <InlineCode weight="weak">{printed}</InlineCode>
        </Box>
      </Text>
    </Stack>
  );
};
