
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The completed candidate submission for the position profile's questionnaire.`}</p>
    <p>{`For Apply with SEEK candidate profiles, this is always `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`This field is redacted for a deleted candidate or job application.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;