import { Stack, Text } from 'braid-design-system';
import { TableRow } from 'scoobie';

import { NameLabel } from 'src/components/NameLabel/NameLabel';
import { NameTextLink } from 'src/components/NameTextLink/NameTextLink';
import { hrefForWebhookSchemaProperty } from 'src/utils/href';
import type { AnyType } from 'src/utils/jsonSchema';

import { SchemaMarkdown } from './SchemaMarkdown';
import { TypeLabel } from './TypeLabel';

interface Props {
  objectName?: string;
  name: string;
  type: AnyType;
  required?: boolean;
  highlight?: boolean;
  markdownPath: string;
}

export const PropertyDetails = ({
  objectName,
  name,
  type,
  required,
  highlight,
  markdownPath,
}: Props) => (
  <TableRow selected={highlight}>
    <Stack space="medium">
      {objectName ? (
        <NameTextLink
          name={name}
          required={required}
          highlight={highlight}
          to={hrefForWebhookSchemaProperty(objectName, name)}
          id={`${objectName}-${name}`}
          deprecated={false}
        />
      ) : (
        <NameLabel required={required}>{name}</NameLabel>
      )}
      <Text>
        <TypeLabel type={type} />
      </Text>
    </Stack>

    <SchemaMarkdown type={markdownPath} />
  </TableRow>
);
