
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The date & time the `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile",
        "title": "A profile of a position opening"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` was closed.`}</p>
    <p><a parentName="p" {...{
        "href": "/schema-new/named-type/PositionProfile",
        "title": "A profile of a position opening"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{`s are closed automatically when they reach their `}<a parentName="p" {...{
        "href": "/schema-new/named-type/PostingInstruction#/field/end",
        "title": "The end date of the posting"
      }}><inlineCode parentName="a">{`PostingInstruction.end`}</inlineCode></a>{` date.
They can also be closed early using the `}<a parentName="p" {...{
        "href": "/schema-new/mutation/closePostedPositionProfile",
        "title": "Asynchronously closes a live job ad"
      }}><inlineCode parentName="a">{`closePostedPositionProfile`}</inlineCode></a>{` mutation.`}</p>
    <p>{`This field has weak ordering guarantees, so it should not be used as a pagination argument.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;