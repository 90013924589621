
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The country to list job categories for.`}</p>
    <p>{`This is a two-letter ISO 3166-1:2013 country code, in uppercase.`}</p>
    <p>{`Only use this field if your software has technical limitations that prevent it from dynamically providing a more specific position location to the `}<a parentName="p" {...{
        "href": "/schema-new/query/jobCategories",
        "title": "A list of top-level job categories for the provided scheme"
      }}><inlineCode parentName="a">{`jobCategories`}</inlineCode></a>{` query.
Provide `}<inlineCode parentName="p">{`positionProfile.positionLocation`}</inlineCode>{` instead and omit this field where possible.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;