
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Asynchronously updates a live job ad.`}</p>
    <p>{`The position profile's existing fields will be replaced with the provided input fields.
This will update the live job ad under its current URL.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;