
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A library question component of an `}<a parentName="p" {...{
        "href": "/schema-new/named-type/ApplicationQuestionnaire",
        "title": "A set of questions presented to a candidate during an application"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{`.`}</p>
    <p>{`Either this or `}<inlineCode parentName="p">{`question`}</inlineCode>{` must be provided if the `}<inlineCode parentName="p">{`componentTypeCode`}</inlineCode>{` is `}<inlineCode parentName="p">{`Question`}</inlineCode>{`.`}</p>
    <p>{`The SEEK library question is sourced from the `}<a parentName="p" {...{
        "href": "/schema-new/query/applicationLibraryQuestionSuggestions",
        "title": "An array of suggested application questions for the provided partial `PositionProfile` in decreasing order of relevance"
      }}><inlineCode parentName="a">{`applicationLibraryQuestionSuggestions`}</inlineCode></a>{` query.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;