
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The three-letter ISO 4217 currency code, in uppercase.`}</p>
    <p>{`For the `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` scheme, the following currencies are accepted:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AUD`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`BDT`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`CNY`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`EUR`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`GBP`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`HKD`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`IDR`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`INR`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`JPY`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`MYR`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`NZD`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PHP`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SGD`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`THB`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`USD`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`VND`}</inlineCode></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;