
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An identifier to ensure that multiple candidate process history items are not created on retries.`}</p>
    <p>{`The identifier must be unique within the given candidate profile.
The same identifier must be provided when retrying after create failures.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;