
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether the querying partner has been approved to self-service their live SEEK API client credentials.`}</p>
    <p>{`SEEK will approve partners once they have demonstrated progress through the development process.
When true, administrative users will be able to issue and rotate client credentials in the Developer Dashboard.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;