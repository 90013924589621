import { Divider, Heading, Stack, Text, TextField } from 'braid-design-system';
import { useEffect, useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { JobCategorySelect, LocationSuggest } from 'wingman-fe';

import { useEnvironmentConfig } from 'src/hooks/environment';
import type {
  HiringOrganizationIdFieldsFragment,
  JobDetailsQuery,
} from 'src/types/graphql';

type PostedPositionProfile = Extract<
  Required<JobDetailsQuery['positionProfile']>,
  { __typename: 'PostedPositionProfile' }
>;

type JobCategory = PostedPositionProfile['jobCategories'][number];

type Location = PostedPositionProfile['positionLocation'][number];

import { AdSelectionPanel } from './components/AdSelectionPanel';

interface Props {
  hirer?: HiringOrganizationIdFieldsFragment;
}

export const AdSelectionSection = ({ hirer }: Props) => {
  const { seekAnzSchemeId } = useEnvironmentConfig();
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [jobCategory, setJobCategory] = useState<JobCategory>();
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    if (!hirer) {
      // Reset form when selected hirer is cleared
      setPositionTitle('');
      setLocation(undefined);
      setJobCategory(undefined);
    }
  }, [hirer]);

  return (
    <Stack space="large">
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/ad-selection/v2/panel">
          Ad Selection Panel
        </SmartTextLink>
      </Heading>

      <Divider />

      {!hirer ? (
        <Text tone="secondary">
          Select a hirer to view the Ad Selection Panel.
        </Text>
      ) : (
        <Stack space="large">
          <Stack space="large">
            <TextField
              id="adSelectionPositionTitle"
              label="Job title"
              message="e.g. Business Analyst"
              onClear={() => {
                setPositionTitle('');
              }}
              value={positionTitle}
              onChange={(event) => setPositionTitle(event.currentTarget.value)}
            />
            <LocationSuggest
              id="adSelectionLocationSuggest"
              label="Location"
              schemeId={seekAnzSchemeId}
              onSelect={setLocation}
              onClear={() => {
                setLocation(undefined);
              }}
            />
            <JobCategorySelect
              id="adSelectionJobCategorySelect"
              onSelect={(category, type) => {
                if (type === 'child') {
                  setJobCategory(category);
                  return;
                }
                setJobCategory(undefined);
              }}
              schemeId={seekAnzSchemeId}
            />
          </Stack>

          <Divider />

          <AdSelectionPanel
            positionTitle={positionTitle}
            positionOrganizations={hirer?.id.value ? [hirer.id.value] : []}
            positionLocation={location?.id.value ? [location?.id.value] : []}
            jobCategories={jobCategory?.id.value ? [jobCategory?.id.value] : []}
          />
        </Stack>
      )}
    </Stack>
  );
};
