
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The role of the attachment within a profile.`}</p>
    <p>{`Currently, three codes are defined:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`CoverLetter`}</inlineCode>{` is used for a candidate's cover letter for a specific position.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Resume`}</inlineCode>{` is used for a candidate's resume or CV.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SelectionCriteria`}</inlineCode>{` is used for a candidate's selection criteria for a specific position.`}</p>
      </li>
    </ul>
    <p>{`Additional documents will have a `}<inlineCode parentName="p">{`null`}</inlineCode>{` role code.
They can be distinguished by their free text `}<inlineCode parentName="p">{`descriptions`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;