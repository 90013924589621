
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ad products available when initially posting a job ad.`}</p>
    <p>{`This query accepts browser tokens that include the `}<inlineCode parentName="p">{`query:ad-products`}</inlineCode>{` scope.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;